import axiosHttpService from "../../axioscall";
import { getNextRepaymentDate } from "../accounting";
import convertTimestampToDate from "../../Helpers/dateFunctions";
import { getTransactionByField } from "../../ApiCalls/transactionCalls";
import { getGreenBondsByField } from "../../ApiCalls/greenBondCalls";
import { getTokenizedBondsByField } from "../../ApiCalls/tokenizedBondCalls";
import { getUserProfile } from "../../Helpers/usersHelper";

const { ethers } = require("ethers");
const opportunityPool = require("../../../artifacts/contracts/protocol/OpportunityPool.sol/OpportunityPool.json");
const seniorPool = require("../../../artifacts/contracts/protocol/SeniorPool.sol/SeniorPool.json");
const { requestAccount, getEthAddress } = require("./commonConnectors");
const {
	getOpportunity,
	InvestorTransactionType,
	BondStatus,
	compareDelayInDDMMYY,
	TransactionStatus,
} = require("../opportunityConnectors");
const Sentry = require("@sentry/react");
const {
	getDisplayAmount,
	formatNumberWithCommas,
} = require("../../Helpers/displayTextHelper");
const { retrieveFileFromURL } = require("../../Helpers/fileHelper");
const {
	getIPFSFileURL,
	retrieveFiles,
	getIPFSFileURLOption2,
	getIPFSFileURLOption3,
} = require("../../Helpers/web3storageIPFS");

const sixDecimals = 6;

export const withdrawAllJunior = async (poolAddress) => {
	Sentry.captureMessage("withdrawAllJunior", "info");
	try {
		if (typeof window.ethereum !== "undefined") {
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const signer = provider.getSigner();
			const poolContract = new ethers.Contract(
				poolAddress,
				opportunityPool.abi,
				signer
			);

			const transaction = await poolContract.withdrawAll(0); // 0 is juniorpool ID
			await transaction.wait();
			return { transaction, success: true };
		} else {
			Sentry.captureMessage("Wallet connect error", "warning");
			return {
				success: false,
				msg: "Please connect your wallet!",
			};
		}
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const withdrawSeniorPoolInvestment = async (amount) => {
	Sentry.captureMessage("withdrawSeniorPoolInvestment", "info");
	try {
		if (!amount || +amount <= 0) {
			Sentry.captureMessage("Invalid amount", "warning");
			return {
				success: false,
				msg: "Invalid Amount",
			};
		}
		if (typeof window.ethereum !== "undefined") {
			await requestAccount();
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const signer = provider.getSigner();
			console.log({ signer });
			const contract = new ethers.Contract(
				process.env.REACT_APP_SENIORPOOL,
				seniorPool.abi,
				signer
			);

			amount = ethers.utils.parseUnits(amount, sixDecimals);
			if (amount && amount > 0) {
				let transaction = await contract.withdrawWithLP(amount);
				await transaction.wait();
				return { transaction, success: true };
			}
		} else {
			Sentry.captureMessage("Wallet connect error", "warning");
			return {
				success: false,
				msg: "Please connect your wallet!",
			};
		}
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getTotalInvestmentOfInvestor = async (userId) => {
	Sentry.captureMessage("getTotalInvestmentOfInvestor", "info");
	try {
		if (!userId) {
			return;
		}

		let transactions = await getTransactionByField("subscriberId", userId);
		transactions = transactions.res.records ? transactions.res.records : [];
		transactions = transactions.map((tx) => tx.data);
		transactions = transactions.filter(
			(tx) => tx.status === TransactionStatus.Completed
		);

		let totalInvestment = transactions.reduce((total, trx) => {
			if (
				trx.investorTransactionType === InvestorTransactionType.Invest
			) {
				return total + +trx.amount;
			} else return total;
		}, 0);
		let totalYield = transactions.reduce((total, trx) => {
			if (
				trx.investorTransactionType ===
					InvestorTransactionType.Payout &&
				+trx.interestPortion
			) {
				return total + +trx.interestPortion;
			} else return total;
		}, 0);
		let totalPrincipalReceived = transactions.reduce((total, trx) => {
			if (
				trx.investorTransactionType ===
					InvestorTransactionType.Payout &&
				trx.principalPortion
			) {
				return total + trx.principalPortion;
			} else return total;
		}, 0);

		return {
			totalInvestment,
			totalYield,
			totalPrincipalReceived,
			success: true,
			transactions,
		};
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getSeniorPoolSharePrice = async () => {
	Sentry.captureMessage("getSeniorPoolSharePrice", "info");
	try {
		if (typeof window.ethereum !== "undefined") {
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const contract = new ethers.Contract(
				process.env.REACT_APP_SENIORPOOL,
				seniorPool.abi,
				provider
			);
			let sharePrice = await contract.sharePrice();
			sharePrice =
				ethers.utils.formatUnits(sharePrice, sixDecimals) * 100;

			return { sharePrice, success: true };
		} else {
			Sentry.captureMessage("Wallet connect error", "warning");
			return {
				success: false,
				msg: "Please connect your wallet!",
			};
		}
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getSeniorPoolDisplaySharePrice = async () => {
	Sentry.captureMessage("getSeniorPoolDisplaySharePrice", "info");
	let backendSharePrice = await getSeniorPoolSharePrice();

	if (backendSharePrice.success) {
		return {
			displaySharePrice:
				parseFloat(backendSharePrice.sharePrice).toFixed(2) + "%",
			sharePriceFromContract: backendSharePrice.sharePrice,
			success: true,
		};
	} else {
		return backendSharePrice;
	}
};

export const getJuniorWithdrawableOp = async (trx) => {
	Sentry.captureMessage("getJuniorWithdrawableOp", "info");
	try {
		if (!trx) {
			return;
		}
		let opportunityList = [];
		let ghgEmissionReduction = 0;
		for (let index = 0; index < trx.length; index++) {
			try {
				const element = trx[index];
				let bond = await getGreenBondsByField("Id", element.bondId);
				bond = bond.res.data;
				let { obj } = getOpportunity(bond);
				if (bond.custodian) {
					const custodian = await getUserProfile({
						email: bond?.custodian,
					});
					obj.custodian = custodian ? custodian : bond?.custodian;
				}
				let tokenizedBonds;
				if (obj.status >= BondStatus.Tokenized) {
					tokenizedBonds = await getTokenizedBondsByField(
						"bondId",
						bond.Id
					);
					tokenizedBonds = tokenizedBonds.res.records.map(
						(tokenizedBond) => tokenizedBond.data
					);

					let reDate = getNextRepaymentDate(
						tokenizedBonds[0].repaymentStartTime,
						tokenizedBonds[0].repaymentCounter,
						tokenizedBonds[0].paymentFrequencyInDays
					);
					obj.nextDueDate = reDate.repaymentDisplayDate;
					obj.totalAmountDue = formatNumberWithCommas(
						tokenizedBonds[0].emiAmount
					);
					obj.nftId = tokenizedBonds[0].nftId;
					const delay = compareDelayInDDMMYY(obj?.nextDueDate);
					if (delay) {
						obj.delayInDays = delay;
						obj.delayChargeRatePercentage =
							bond.delayChargeRatePercentage;
						obj.isOverDue = true;
					}
					if (obj.status === BondStatus.Matured) {
						obj.nextDueDate = "--";
						obj.totalAmountDue = "--";
					}
				} else {
					obj.nextDueDate = "--";
					obj.totalAmountDue = "--";
				}

				// Get Amortisation Schedule
				let options = {
					url: `${
						process.env.REACT_APP_FIREBASE_FUNCTIONS_URI
					}/accounting/${
						bond.loan_type == 0
							? "getBulletLoanAmortisationSchedule"
							: "getTermLoanAmortisationSchedule"
					}`,
					method: "post",
					headers: {},
					data: {
						loanAmount: obj.actualLoanAmount,
						interestRatePercentage: obj.loanActualInterest,
						tenureInMonths: obj.actualLoanTenure,
						paymentFrequencyInDays: obj.actualPaymentFrequency,
						disbursmentDate: convertTimestampToDate(
							tokenizedBonds[0]?.repaymentStartTime
								? tokenizedBonds[0].repaymentStartTime
								: bond.createdOn
						),
						investorUpfrontFees: bond.investorUpfrontFeesPercentage,
						platformFeesPercentage: bond.percentageOfCoupon,
						JuniorContributionPercentage:
							bond.juniorTranchPercentage,
						JuniorPrincipalFloatPercentage:
							bond.juniorTranchFloatInterestPercentage,
					},
				};
				let res = await axiosHttpService(options);
				const { seniorXirr, juniorXirr } = res.res;

				obj.seniorXirr = seniorXirr.toFixed(2).toString() + "% pa";
				obj.juniorXirr = juniorXirr.toFixed(2).toString() + "% pa";

				switch (obj.status) {
					case BondStatus.PendingForAdminApproval:
						obj.displayStatus = "Pending For Admin's Approval";
						break;
					case BondStatus.RejectedByAdmin:
						obj.displayStatus = "Rejected By Admin";
						break;
					case BondStatus.UnderReview:
						obj.displayStatus = "Under Review";
						break;
					case BondStatus.Rejected:
						obj.displayStatus = "Rejected";
						break;
					case BondStatus.Approved:
						obj.displayStatus = "Approved";
						break;
					case BondStatus.Tokenized:
						obj.displayStatus = "Tokenized";
						break;
					case BondStatus.Matured:
						obj.displayStatus = "Matured";
						break;
				}

				obj.estimatedAPY = obj.loanInterest;
				var opIndex = opportunityList.findIndex(
					(x) => x.Id == element.bondId
				);
				obj.capitalInvested = 0;
				obj.yieldGenerated = 0;
				if (opIndex !== -1) {
					if (
						element.investorTransactionType ===
						InvestorTransactionType.Invest
					) {
						opportunityList[opIndex].capitalInvested +=
							+element.amount;
					} else {
						opportunityList[opIndex].yieldGenerated +=
							+element.interestPortion;
					}
				} else {
					if (
						element.investorTransactionType ===
						InvestorTransactionType.Invest
					) {
						obj.capitalInvested = +element.amount;
					} else {
						obj.yieldGenerated = +element.interestPortion;
					}
				}
				if (opIndex === -1) {
					opportunityList.push(obj);
					ghgEmissionReduction =
						ghgEmissionReduction + +bond.ghgEmissionReduction;
				}
			} catch (error) {
				Sentry.captureException(error);
			}
		}
		return {
			opportunityList,
			success: true,
			ghgEmissionReduction: ghgEmissionReduction,
		};
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getUserSeniorPoolInvestment = async () => {
	Sentry.captureMessage("getUserSeniorPoolInvestment", "info");
	try {
		if (typeof window.ethereum !== "undefined") {
			await requestAccount();
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const signer = provider.getSigner();
			console.log({ signer });
			const contract = new ethers.Contract(
				process.env.REACT_APP_SENIORPOOL,
				seniorPool.abi,
				signer
			);

			let data = await contract.getUserInvestment();
			if (data) {
				return {
					data: {
						stakingAmt: parseFloat(
							ethers.utils.formatUnits(
								data.stakingAmt,
								sixDecimals
							)
						),
						withdrawableAmt: parseFloat(
							ethers.utils.formatUnits(
								data.withdrawableAmt,
								sixDecimals
							)
						),
					},
					success: true,
				};
			}
		} else {
			Sentry.captureMessage("Wallet connect error", "warning");
			return {
				success: false,
				msg: "Please connect your wallet!",
			};
		}
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const investInSeniorPool = async (amount) => {
	Sentry.captureMessage("investInSeniorPool", "info");
	try {
		if (typeof window.ethereum !== "undefined") {
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			console.log({ provider });
			const signer = provider.getSigner();
			const contract = new ethers.Contract(
				process.env.REACT_APP_SENIORPOOL,
				seniorPool.abi,
				signer
			);
			amount = ethers.utils.parseUnits(amount, sixDecimals);
			let transaction = await contract.stake(amount);
			await transaction.wait();
			return { transaction, success: true };
		} else {
			Sentry.captureMessage("Wallet connect error", "warning");
			return {
				success: false,
				msg: "Please connect your wallet!",
			};
		}
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const investInJuniorPool = async (data) => {
	Sentry.captureMessage("investInJuniorPool", "info");
	try {
		if (!data) {
			return {
				success: false,
				msg: "Invalid investment data",
			};
		}
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/transaction/createTx`,
			method: "POST",
			headers: {},
			data: {
				...data,
				status: TransactionStatus.InVerification,
			},
		};
		const res = await axiosHttpService(options);
		if (res.code === 201) {
			return {
				success: true,
				msg: "Successfully stored tx!",
			};
		} else {
			return {
				success: false,
				msg: "Failed to store tx!",
			};
		}
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getSeniorPoolData = async () => {
	Sentry.captureMessage("getOpportunityJson", "info");
	try {
		let file = await retrieveFiles(process.env.REACT_APP_SENIORPOOL_CID);
		let dataReader = await retrieveFileFromURL(
			getIPFSFileURL(process.env.REACT_APP_SENIORPOOL_CID) +
				"/seniorPoolData.json"
		);
		if (!dataReader) {
			dataReader = await retrieveFileFromURL(
				getIPFSFileURLOption2(process.env.REACT_APP_SENIORPOOL_CID) +
					"/seniorPoolData.json"
			);
		}
		if (!dataReader) {
			dataReader = await retrieveFileFromURL(
				getIPFSFileURLOption3(process.env.REACT_APP_SENIORPOOL_CID) +
					"/seniorPoolData.json"
			);
		}
		return dataReader;
	} catch (error) {
		Sentry.captureException(error);
	}
};
