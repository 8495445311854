import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { signInWithEmail } from "../../services/BackendConnectors/userConnectors/userLogin";
import { useContext } from "react";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import { captureMessage, captureException } from "@sentry/react";

const LoginPage = ({ redirect_path, role }) => {
	const path = useNavigate();
	const { login } = useContext(LoginContext);
	const [isSubmit, setisSubmit] = useState(false);
	const [isDisable, setIsDisable] = useState(true);
	const [loginResponse, setloginResponse] = useState(false);
	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validate: (values) => {
			const errors = {};

			if (!values.email) {
				errors.email = "Email is required";
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
			) {
				errors.email = "Invalid email address";
			}

			if (!values.password) {
				errors.password = "Password is required";
			}

			return errors;
		},
		onSubmit: async (values) => {
			// Handle login logic here
			setisSubmit(true);

			try {
				const res = await signInWithEmail(
					values.email,
					values.password,
					role
				);

				if (res) {
					setloginResponse({
						status: true,
						msg: "successfully login...",
					});
					login(res);
					setisSubmit(false);
					path(redirect_path);
					captureMessage(res);
				} else {
					setloginResponse({
						status: false,
						msg: "Invalid email or password...",
					});
					setisSubmit(false);
				}
			} catch (error) {
				setloginResponse({
					status: false,
					msg: "Invalid email or password...",
				});
				setisSubmit(false);
				captureException(error);
			}
		},
	});
	useEffect(() => {
		if (formik.values.email && formik.values.password) {
			setIsDisable(false);
		} else {
			setIsDisable(true);
		}

		if (isSubmit) {
			setIsDisable(true);
		}
	}, [formik.values.email, formik.values.password, isSubmit]);
	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				<div className="md:gap-3 w-1/3 m-auto mt-6">
					<div className={`w-full  md:mr-2 mb-5 md:mb-0`}>
						<label className="label text-lg">
							<span className="text-black dark:text-white">
								Email
							</span>
						</label>
						<input
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name="email"
							value={formik.values.email}
							type="text"
							placeholder="Enter Email"
							className="input input-bordered w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
							style={{
								borderWidth: "1px",
								borderRadius: "8px",
							}}
						/>
						{formik.touched.email && formik.errors.email && (
							<p
								style={{
									color: "red",
									margin: "0px",
								}}
							>
								<small>{formik.errors.email}</small>
							</p>
						)}
					</div>

					<div className={`w-full  md:mr-2 mb-5 md:mb-0`}>
						<label className="label text-lg">
							<span className="text-black dark:text-white">
								Password
							</span>
						</label>
						<input
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name="password"
							value={formik.values.password}
							type="password"
							placeholder="Enter Password"
							className="input input-bordered w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
							style={{
								borderWidth: "1px",
								borderRadius: "8px",
							}}
						/>
						{formik.touched.password && formik.errors.password && (
							<p
								style={{
									color: "red",
									margin: "0px",
								}}
							>
								<small>{formik.errors.password}</small>
							</p>
						)}
					</div>
				</div>
				{loginResponse && !loginResponse?.status && (
					<p
						style={{
							color: "red",
							marginLeft: "443px",
						}}
					>
						<small>{loginResponse.msg}</small>
					</p>
				)}
				<div className="mt-10 flex  justify-around">
					<button
						disabled={
							!formik.values.email && !formik.values.password
						}
						data-modal-toggle="popup-modal"
						type="submit"
						className={`${
							!isDisable
								? "py-2 relative px-12 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
								: "py-2 relative px-12 bg-gradient-to-r bg-neutral-400 cursor-not-allowed  opacity-40 capitalize font-medium border-none rounded-3xl"
						}`}
					>
						Login
						{isSubmit && (
							<div className="animate-spin border-solid  top-3 left-16 absolute border-[2.4px] border-t-[#14171F] border-r-[#14171F] border-[#fff] w-[1.3rem] h-[1.3rem] rounded-full"></div>
						)}
					</button>
				</div>
				<div>
					{loginResponse && loginResponse?.status && (
						<p
							style={{
								color: "green",
								margin: "0px",
							}}
						>
							<small>{loginResponse.msg}</small>
						</p>
					)}
				</div>
			</form>
		</>
	);
};

export default LoginPage;
