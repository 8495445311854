import convertTimestampToDate from "../Helpers/dateFunctions";
import { getGUID } from "../Helpers/displayTextHelper";
import axiosHttpService from "../axioscall";
import {
	BondStatus,
	BorrowerTransactionType,
	InvestorTransactionType,
	TransactionStatus,
} from "./opportunityConnectors";
import { convertDate } from "./userConnectors/commonConnectors";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";

function getTermLoanEMI(loanAmt, rate, period, frequency) {
	if (
		!rate ||
		!period ||
		!loanAmt ||
		!frequency ||
		rate <= 0 ||
		period <= 0 ||
		loanAmt <= 0 ||
		frequency <= 0
	) {
		return;
	}

	//Calculate the monthly rate
	const totalRepayments = period / (frequency / 30);
	const monthlyRate = rate / totalRepayments / 100;
	const term = Math.pow(1 + monthlyRate, totalRepayments);
	let emi = (monthlyRate * loanAmt * term) / (term - 1);
	return emi.toFixed(2);
}

function getBulletLoanEMI(loanAmt, rate, frequency) {
	if (
		!rate ||
		!loanAmt ||
		!frequency ||
		rate <= 0 ||
		loanAmt <= 0 ||
		frequency <= 0
	) {
		return;
	}

	//Calculate the monthly rate
	const interestForMonth = frequency / 30;
	const monthlyRate = rate / 12 / 100;
	let emi = loanAmt * monthlyRate * interestForMonth;
	return emi.toFixed(2);
}

export function getTermLoanInterest(
	outstandingPrincipal,
	rate,
	period,
	frequency
) {
	if (
		!rate ||
		!period ||
		!outstandingPrincipal ||
		!frequency ||
		rate <= 0 ||
		period <= 0 ||
		outstandingPrincipal <= 0 ||
		frequency <= 0
	) {
		return;
	}
	//Calculate the monthly rate
	const totalRepayments = period / (frequency / 30);
	const monthlyRate = rate / totalRepayments / 100;
	let intrest = outstandingPrincipal * monthlyRate;
	return intrest.toFixed(2);
}

export async function createTokenizedBonds(bond, bankDetails) {
	if (!bond) {
		return;
	}
	try {
		// Add transaction for borrower
		const txHash = getGUID();
		const data = {
			txHash,
			issuerId: bond.borrower,
			bondId: bond.Id,
			amount: bond.actualLoanAmount,
			bondName: bond.opportunityName,
			investedOn: Date.now(),
			borrowerTransactionType: BorrowerTransactionType.Borrowed,
			status: TransactionStatus.InVerification,
			...bankDetails,
		};

		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/transaction/createTx`,
			method: "POST",
			headers: {},
			data: {
				...data,
			},
		};
		const res = await axiosHttpService(options);

		if (res.res.Id) {
			return { success: true };
		}
	} catch (error) {
		console.log(error);
		return { success: false, error };
	}
}

export function getNextRepaymentDate(
	repaymentStartDate,
	repaymentCounter,
	paymentFrequency
) {
	if (!repaymentStartDate || !repaymentCounter || !paymentFrequency) {
		return;
	}
	function pad(s) {
		return s < 10 ? "0" + s : s;
	}

	var repaymentDate = new Date(repaymentStartDate);
	repaymentDate.setDate(
		repaymentDate.getDate() + repaymentCounter * paymentFrequency
	);
	const repaymentDisplayDate = [
		pad(repaymentDate.getDate()),
		pad(repaymentDate.getMonth() + 1),
		repaymentDate.getFullYear(),
	].join("/");
	return { repaymentDate, repaymentDisplayDate };
}

export async function distributePay(bond, bankDetails, email) {
	if (!bond) {
		return;
	}
	try {
		let res;
		for (let i = 0; i < bond.paymentPendingTrx.length; i++) {
			const pendingPayment = bond.paymentPendingTrx[i];
			for (let index = 0; index < bond.trx.length; index++) {
				const transaction = bond.trx[index];

				// Calculate the amount for transfer
				const strPer = transaction.subPercentage.slice(0, -1);
				const amt = (pendingPayment.amount * +strPer) / 100;
				const principalPortion =
					(pendingPayment.principalPortion * +strPer) / 100;
				const interestPortion =
					(pendingPayment.interestPortion * +strPer) / 100;

				// Add transaction in database
				const txHash = getGUID();
				const data = {
					txHash,
					subscriberId: transaction.Id,
					bondId: bond.Id,
					amount: amt,
					bondName: bond.opportunityName,
					investedOn: Date.now(),
					investorTransactionType: InvestorTransactionType.Payout,
					principalPortion,
					interestPortion: interestPortion.toString(),
					status: TransactionStatus.InVerification,
					...bankDetails,
				};
				let options = {
					url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/transaction/createTx`,
					method: "POST",
					headers: {},
					data: {
						...data,
					},
				};
				res = await axiosHttpService(options);
			}
		}
		return { success: true, res };
	} catch (error) {
		console.log(error);
		return { success: false, error };
	}
}
