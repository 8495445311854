import React from "react";

const ProfileIcon = ({ color }) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 21C16.9705 21 21 16.9705 21 12C21 7.0295 16.9705 3 12 3C7.0295 3 3 7.0295 3 12C3 16.9705 7.0295 21 12 21ZM12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
				fill={color ? "#9281FF" : "#64748B"}
			/>
			<path
				d="M6 17.815C6 17.2985 6.386 16.862 6.9 16.805C10.7575 16.378 13.26 16.4165 17.109 16.8145C17.3012 16.8347 17.4834 16.9104 17.6333 17.0324C17.7831 17.1544 17.8942 17.3175 17.953 17.5016C18.0117 17.6857 18.0156 17.8829 17.9641 18.0692C17.9126 18.2555 17.808 18.4227 17.663 18.5505C13.1205 22.51 10.5245 22.4555 6.32 18.5545C6.115 18.3645 6 18.0945 6 17.8155V17.815Z"
				fill={color ? "#9281FF" : "#64748B"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.0575 17.3115C13.239 16.9165 10.7725 16.8795 6.9545 17.302C6.82882 17.3167 6.71296 17.3771 6.6291 17.4719C6.54524 17.5666 6.49928 17.689 6.5 17.8155C6.5 17.9585 6.5595 18.094 6.66 18.188C8.744 20.121 10.323 20.9945 11.8665 21C13.4155 21.0055 15.0795 20.139 17.3345 18.174C17.4061 18.1102 17.4577 18.027 17.483 17.9345C17.5082 17.842 17.506 17.7441 17.4767 17.6528C17.4473 17.5615 17.3921 17.4807 17.3177 17.4202C17.2433 17.3597 17.1529 17.3221 17.0575 17.312V17.3115ZM6.845 16.308C10.743 15.8765 13.282 15.9155 17.161 16.317C17.4502 16.3472 17.7242 16.461 17.9497 16.6446C18.1751 16.8281 18.3422 17.0734 18.4304 17.3505C18.5185 17.6275 18.524 17.9242 18.4462 18.2043C18.3683 18.4844 18.2105 18.7357 17.992 18.9275C15.7045 20.9215 13.7995 22.0075 11.8635 22C9.922 21.993 8.101 20.8885 5.9805 18.921C5.82868 18.7796 5.70765 18.6084 5.62497 18.4181C5.5423 18.2278 5.49976 18.0225 5.5 17.815C5.49927 17.4422 5.6361 17.0823 5.88428 16.8041C6.13247 16.5259 6.47455 16.3491 6.845 16.3075V16.308Z"
				fill={color ? "#9281FF" : "#64748B"}
			/>
			<path
				d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10C8 8.93913 8.42143 7.92172 9.17157 7.17157C9.92172 6.42143 10.9391 6 12 6C13.0609 6 14.0783 6.42143 14.8284 7.17157C15.5786 7.92172 16 8.93913 16 10Z"
				fill={color ? "#9281FF" : "#64748B"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 13C12.7956 13 13.5587 12.6839 14.1213 12.1213C14.6839 11.5587 15 10.7956 15 10C15 9.20435 14.6839 8.44129 14.1213 7.87868C13.5587 7.31607 12.7956 7 12 7C11.2044 7 10.4413 7.31607 9.87868 7.87868C9.31607 8.44129 9 9.20435 9 10C9 10.7956 9.31607 11.5587 9.87868 12.1213C10.4413 12.6839 11.2044 13 12 13ZM12 14C13.0609 14 14.0783 13.5786 14.8284 12.8284C15.5786 12.0783 16 11.0609 16 10C16 8.93913 15.5786 7.92172 14.8284 7.17157C14.0783 6.42143 13.0609 6 12 6C10.9391 6 9.92172 6.42143 9.17157 7.17157C8.42143 7.92172 8 8.93913 8 10C8 11.0609 8.42143 12.0783 9.17157 12.8284C9.92172 13.5786 10.9391 14 12 14Z"
				fill={color ? "#9281FF" : "#64748B"}
			/>
		</svg>
	);
};

export default ProfileIcon;
