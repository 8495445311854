import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { changePassword } from "../../services/BackendConnectors/userConnectors/userLogin";
import axiosHttpService from "../../services/axioscall";

const ChangePassowrdModal = ({
	userData,
	login,
	isSelected,
	setIsSelected,
	setSuccessmsg,
	setErrormsg,
}) => {
	const [isSubmit, setisSubmit] = useState(false);
	const [isDisable, setIsDisable] = useState(true);
	const [loginResponse, setloginResponse] = useState(false);

	const formik = useFormik({
		initialValues: {
			newPassword: "",
			confirmPassword: "",
		},
		validate: (values) => {
			const errors = {};
			if (!values.newPassword || !values.confirmPassword) {
				errors.confirmPassword = "Password is required";
			}

			if (
				values.newPassword.length < 8 ||
				values.newPassword.length < 8
			) {
				errors.confirmPassword =
					"Password is required with minimum legnth of 8 characters";
			}

			if (values.newPassword !== values.confirmPassword) {
				errors.confirmPassword = "Password is not same";
			}

			return errors;
		},
		onSubmit: async (values) => {
			setisSubmit(true);

			try {
				const res = await changePassword(values.newPassword);

				console.log(res, "😎1");
				let newUserData = { ...userData };
				delete newUserData.idToken;
				delete newUserData.ledgerMetadata;

				if (res) {
					const options = {
						url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/borrower/createProfile`,
						method: "POST",
						headers: {},
						data: {
							...newUserData,
							isNewUser: false,
							action: "PasswordChange",
						},
					};
					await axiosHttpService(options);
				}
				login({ ...userData, isNewUser: false });
				setisSubmit(false);
				setIsSelected(false);
				setSuccessmsg({
					status: true,
					msg: "Password Changed Successfully!",
				});
			} catch (error) {
				console.log(error, "😎😎");
				if (error.code === "auth/requires-recent-login") {
					setloginResponse({
						status: false,
						msg: "Please relogin to change password",
					});
				} else {
					setloginResponse({
						status: false,
						msg: "Failed to change Password",
					});
				}
				setisSubmit(false);
				setErrormsg({
					status: true,
					msg: "Failed to change Password",
				});
			}
		},
	});

	useEffect(() => {
		if (formik.values.newPassword && formik.values.confirmPassword) {
			setIsDisable(false);
		} else {
			setIsDisable(true);
		}

		if (isSubmit) {
			setIsDisable(true);
		}
	}, [formik.values.newPassword, formik.values.confirmPassword, isSubmit]);
	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={isSelected}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal"
			>
				<div className="relative p-4 w-full max-w-md h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<h3 className="font-normal text-xl">
								Change Password
							</h3>

							<button
								type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-3"
								data-modal-toggle="popup-modal"
								onClick={() => {
									setIsSelected(false);
								}}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>

						<div className="p-6  overflow-hidden">
							{/* <h2 className="font-normal">{`${isLoginModal.title} Login`}</h2> */}

							<form onSubmit={formik.handleSubmit}>
								<div className="md:gap-3">
									<div
										className={`w-full  md:mr-2 mb-5 md:mb-0`}
									>
										<label className="label text-lg">
											<span className="text-black dark:text-white">
												New Password
											</span>
										</label>
										<input
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name="newPassword"
											value={formik.values.newPassword}
											type="password"
											placeholder="Enter New Password"
											className="input input-bordered w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
											style={{
												borderWidth: "1px",
												borderRadius: "8px",
											}}
										/>
										{formik.touched.newPassword &&
											formik.errors.newPassword && (
												<p
													style={{
														color: "red",
														margin: "0px",
													}}
												>
													<small>
														{
															formik.errors
																.newPassword
														}
													</small>
												</p>
											)}
									</div>
									<div
										className={`w-full  md:mr-2 mb-5 md:mb-0`}
									>
										<label className="label text-lg">
											<span className="text-black dark:text-white">
												Confirm Password
											</span>
										</label>
										<input
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name="confirmPassword"
											value={
												formik.values.confirmPassword
											}
											type="password"
											placeholder="Confirm Password"
											className="input input-bordered w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
											style={{
												borderWidth: "1px",
												borderRadius: "8px",
											}}
										/>
										{formik.touched.confirmPassword &&
											formik.errors.confirmPassword && (
												<p
													style={{
														color: "red",
														margin: "0px",
													}}
												>
													<small>
														{
															formik.errors
																.confirmPassword
														}
													</small>
												</p>
											)}
									</div>
								</div>
								{loginResponse && !loginResponse?.status && (
									<p
										style={{
											color: "red",
											margin: "0px",
										}}
									>
										<small>{loginResponse.msg}</small>
									</p>
								)}
								<div className="mt-10 flex  justify-around">
									<button
										onClick={() => {
											setIsSelected(false);
										}}
										data-modal-toggle="popup-modal"
										type="button"
										className={`py-2 px-12 capitalize font-medium border-2 border-neutral-500 rounded-3xl hover:bg-gray-50`}
									>
										Cancel
									</button>
									<button
										// onClick={() => {
										// 	setIsLoginModal({
										// 		status: false,
										// 		title: "",
										// 		url: "",
										// 	});
										// }}
										disabled={
											!formik.values.newPassword &&
											!formik.values.confirmPassword
										}
										data-modal-toggle="popup-modal"
										type="submit"
										className={`${
											!isDisable
												? "py-2 relative px-12 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
												: "py-2 relative px-12 bg-gradient-to-r bg-neutral-400 cursor-not-allowed  opacity-40 capitalize font-medium border-none rounded-3xl"
										}`}
									>
										Submit
										{isSubmit && (
											<div className="animate-spin border-solid  top-3 left-16 absolute border-[2.4px] border-t-[#14171F] border-r-[#14171F] border-[#fff] w-[1.3rem] h-[1.3rem] rounded-full"></div>
										)}
									</button>
								</div>
								<div>
									{loginResponse && loginResponse?.status && (
										<p
											style={{
												color: "green",
												margin: "0px",
											}}
										>
											<small>{loginResponse.msg}</small>
										</p>
									)}
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ChangePassowrdModal;
