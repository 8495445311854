import React, { useEffect, useState } from "react";
import { convertDate } from "../../services/BackendConnectors/userConnectors/commonConnectors";
import { getDisplayAmount } from "../../services/Helpers/displayTextHelper";
import DollarImage from "../../assets/Dollar-icon.svg";
import { TransactionStatus } from "../../services/BackendConnectors/opportunityConnectors";
import ConfirmOrRejectModal from "../Modal/ConfirmOrRejectModal";
import { createTransaction } from "../../services/ApiCalls/transactionCalls";
import { captureException } from "@sentry/react";
import { TransactionAction } from "../../services/Helpers/transactionsHelper";
import { TransactionType } from "../../services/Helpers/transactionsHelper";

const TransactionsCard = ({ data, txType, setReloadData }) => {
	const [amount, setAmount] = useState();
	const [date, setDate] = useState();
	const [isSelected, setIsSelected] = useState(false);
	const [confirmText, setConfirmText] = useState({
		action: "",
		text: [""],
	});

	useEffect(() => {
		if (data) {
			setAmount(getDisplayAmount(data.amount));
			setDate(convertDate(data.investedOn));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = async () => {
		try {
			let tx = { ...data };
			delete tx.transactionType;
			let action = "";
			if (tx?.investorTransactionType !== undefined) {
				action =
					tx?.investorTransactionType === 0
						? TransactionType.Invest
						: TransactionType.Payout;
			} else if (tx?.borrowerTransactionType !== undefined) {
				action =
					tx?.borrowerTransactionType === 0
						? TransactionType.Borrow
						: TransactionType.Repay;
			}
			if (confirmText.action === TransactionAction.Confirm) {
				tx.status = TransactionStatus.Completed;
				tx.action = `${action}${TransactionAction.Confirm}`;
			} else if (confirmText.action === TransactionAction.Reject) {
				tx.status = TransactionStatus.Failed;
				tx.action = `${action}${TransactionAction.Reject}`;
			}
			let result = await createTransaction(tx);
			if (result.error) {
				throw new Error(result.error);
			}
			if (setReloadData) {
				setReloadData(Math.random());
			}
		} catch (error) {
			captureException(error);
		} finally {
			setIsSelected(false);
		}
	};

	return (
		<>
			{isSelected && (
				<ConfirmOrRejectModal
					isSelected={isSelected}
					setIsSelected={setIsSelected}
					text={confirmText.text}
					handleSubmit={handleSubmit}
				/>
			)}
			<div
				className={`collapse collapse-arrow ${
					(typeof txType === "object"
						? txType.includes(data?.transactionType)
						: txType === data?.transactionType) && !data?.status
						? "collapse-open"
						: ""
				}  dark:bg-darkmode-500 bg-lightmode-200 rounded-xl`}
			>
				<input type="checkbox" className="peer" />

				<div
					className={`collapse-title flex gap-4 md:gap-8 text-center w-full`}
				>
					<p className="w-1/3 md:w-1/5 my-auto ">{data?.bondName}</p>
					<p className="hidden md:block w-1/3 md:w-1/5 my-auto">
						{date}
					</p>
					<p className="hidden md:block w-1/3 md:w-1/5 my-auto">
						{data?.transactionType}
					</p>
					<p className="flex gap-1 items-center w-1/3 md:w-1/5 my-auto justify-center">
						<img
							src={DollarImage}
							className="w-4"
							alt="Dollarimage"
						/>
						{amount}
					</p>
					{!data?.status && (
						<p className="w-1/3 md:w-1/5 my-auto text-[#ccc056] font-semibold">
							In Verification
						</p>
					)}

					{data?.status === TransactionStatus.Completed && (
						<p className="w-1/3 md:w-1/5 my-auto text-[#51B960] font-semibold">
							Completed
						</p>
					)}
					{data?.status === TransactionStatus.Failed && (
						<p className="w-1/3 md:w-1/5 my-auto text-[#E73838] font-semibold">
							Failed
						</p>
					)}
				</div>

				<div className="collapse-content">
					<div className="border-t border-gray-400 ml-2">
						<div className="flex items-center mt-3 px-4 justify-between w-full">
							<p className="text-xl text-left">Payment Details</p>
							{(typeof txType === "object"
								? txType.includes(data?.transactionType)
								: txType === data?.transactionType) &&
								!data?.status && (
									<div className="flex gap-3 mr-14">
										<p className="my-auto transaction-button">
											<button
												className="bg-gradient-to-r from-[#E73838] to-[#FFBABA]"
												onClick={() => {
													setConfirmText({
														action: TransactionAction.Reject,
														text: [
															"Are you sure you want to reject the transaction?",
														],
													});
													setIsSelected(true);
												}}
											>
												Reject
											</button>
										</p>
										<p className="my-auto transaction-button">
											<button
												className="bg-gradient-to-r from-[#51B960] to-[#83DC90]"
												onClick={() => {
													setConfirmText({
														action: TransactionAction.Confirm,
														text: [
															"Are you sure you want to confirm the transaction?",
															"Before confirming, please verify that you have received the amount in your bank account.",
														],
													});
													setIsSelected(true);
												}}
											>
												Confirm
											</button>
										</p>
									</div>
								)}
						</div>
						<div className="font-normal pt-6 pb-2">
							<div className="flex gap-8 xl:gap-14 2xl:gap-[8vw] ">
								<div className="flex justify-between w-full px-4">
									<div>
										<p className="text-neutral-500">
											Beneficiary Name:
										</p>
										<p className="text-lg">
											{data?.benificiaryName}
										</p>
									</div>

									<div>
										<p className="text-neutral-500">
											Beneficiary Bank Acc No:
										</p>
										<p className="text-lg">
											{data?.benificiaryBankAccNo}
										</p>
									</div>
									<div className="">
										<p className="text-neutral-500">
											Beneficiary Bank:
										</p>
										<p className="text-lg">
											{data?.benificiaryBank}
										</p>
									</div>

									<div className="">
										<p className="text-neutral-500">
											UTR No:
										</p>
										<p className="text-lg">{data?.utrNo}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TransactionsCard;
