export const sustainableCheckedData = [
	"No Poverty",
	"Zero Hunger",
	"Good Health And Well-Being",
	"Quality Education",
	"Gender Equaltiy",
	"Clean Water And Sanitation",
	"Affordable And Clean Energy",
	"Decent Work And Economic Growth",
	"Industry, Innovation And Infrastructure",
	"Reduced Inequalities",
	"Sustainable Cities And Communities",
	"Responsible Consumption And Production",
	"Climate Action",
	"Life Below Water",
	"Life On Land",
	"Peace, Justice And Strong Institutions",
	"Partnerships For The Goals",
];
