import * as Yup from "yup";
import { useFormik } from "formik";
import GradientButton from "../../../../uiTools/Button/GradientButton";
import ArrowLeft from "../../../SVGIcons/ArrowLeft";
import { useState } from "react";
import Selection from "../../../../uiTools/Inputs/Selection";

export default function AssignCustodian({
	formData,
	handleNext,
	handlePrev,
	custodians,
}) {
	const [outline, setoutline] = useState(false);

	const formik = useFormik({
		initialValues: formData,
		validationSchema: Yup.object().shape({
			custodian: Yup.string().label("Custodian").required(),
		}),
		onSubmit: (values, { resetForm }) => {
			handleNext(values, false);
		},
	});

	return (
		<>
			<div className=" flex flex-col mt-20 md:mt-16 md:px-5 ">
				<form onSubmit={formik.handleSubmit}>
					<div className="py-12 my-4 mx-16 px-6 rounded-lg bg-slate-100 border-slate-200 border-2">
						<div className="flex gap-8 items-center justify-center mb-6 h-6 w-full">
							<div className={`w-full  md:mr-2 mb-5 md:mb-0`}>
								<label className="label text-lg">
									<span className="text-black dark:text-white">
										Select Custodian
									</span>
								</label>
								<div
									className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] ${
										outline
											? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
											: ""
									}`}
								>
									<Selection
										onChange={(value) => {
											formik.setFieldValue(
												"custodian",
												value.value
											);
										}}
										value={formik.values.custodian}
										placeholder="Select&nbsp;Custodian"
										options={custodians}
										focus={setoutline}
										className=" w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
									></Selection>
								</div>
								{formik.touched.custodian &&
								formik.errors.custodian ? (
									<p style={{ color: "red" }}>
										<small>{formik.errors.custodian}</small>
									</p>
								) : null}
							</div>
						</div>
					</div>

					<div className="flex justify-center items-center flex-col-reverse  md:flex-row md:justify-around gap-5  mt-6 -mb-2 overflow-hidden">
						<div className="pb-10 md:pb-0 md:mt-10">
							<label
								onClick={handlePrev}
								className="text-gray-500 flex-row cursor-pointer flex ml-1 md:pl-14 md:-mt-6 text-lg items-center"
							>
								<ArrowLeft color="#64748B" />
								Back
							</label>
						</div>
						<div className="md:pr-40 lg:pr-10 xl:pr-0 py-1">
							<GradientButton type="submit">Next</GradientButton>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
