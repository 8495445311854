import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import { captureException } from "@sentry/react";
import axiosHttpService from "../../axioscall";

function progressEvent(progress, file, status) {
	const event = new CustomEvent("progressDetail", {
		detail: {
			progress: progress,
			file: file,
			status: status,
		},
	});

	document.dispatchEvent(event);
}

export async function uploadFileToStorage(id, file) {
	if (!id || !file || !storage) {
		return;
	}
	try {
		const storageRef = ref(storage, `${id}/${file.name}`);
		const snapshot = await uploadBytes(storageRef, file);
		return await getDownloadURL(snapshot.ref); //snapshot.metadata.fullPath;
	} catch (error) {
		console.log(error);
	}
}

export async function getSignedFileURL(filePath) {
	if (!filePath) {
		return;
	}
	try {
		// set options to get signed url
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/utility/getStorageFileSignedURL?filePath=${filePath}`,
			method: "GET",
			headers: {},
		};
		const signedURL = await axiosHttpService(options);
		return signedURL;
	} catch (error) {
		captureException(error);
	}
}
