import React from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { LoginContext } from "../../LoginProvider/LoginProvider";
const SessionExpiredModal = ({ isSelected, setIsSelected }) => {
	const path = useNavigate();
	const { logout } = useContext(LoginContext);
	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={isSelected}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal text-black"
			>
				<div className="relative p-4 w-full max-w-md h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<h3 className="font-normal text-xl">
								Session Expired
							</h3>
						</div>

						<div className="p-6  overflow-hidden">
							<p className="mb-4 font-normal text-xl text-center">
								Your session has been expired. Please login
								again.
							</p>
							<div className="mt-10 flex mx-10 justify-around">
								{/* <button
									onClick={() => {
										// path("/");
										setIsSelected(false);
									}}
									data-modal-toggle="popup-modal"
									type="button"
									className={`py-2 px-12 capitalize font-medium border-2 border-neutral-500 rounded-3xl hover:bg-gray-50`}
								>
									NO
								</button> */}
								<button
									onClick={() => {
										setIsSelected(false);
										path("/");
										logout();
									}}
									data-modal-toggle="popup-modal"
									type="button"
									className={`py-2 px-12 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]`}
								>
									Ok
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SessionExpiredModal;
