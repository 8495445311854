import React, { useEffect, useState, useContext } from "react";
import TransactionsCard from "../../uiTools/Card/TransactionCard";
import Loader from "../../uiTools/Loading/Loader";
import { getTokenTransactions } from "../../services/Helpers/transactionsHelper";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import { InvestorTransactionType } from "../../services/Helpers/transactionsHelper";

const Transactions = () => {
	const [transactions, setTransactions] = useState([]);
	const [loading, setLoading] = useState(true);
	const [reloadData, setReloadData] = useState(null);
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});
	const { userData } = useContext(LoginContext);

	useEffect(() => {
		setLoading(true);
		if (userData?.Id) {
			getTokenTransactions(
				"subscriberId",
				userData?.Id,
				"investedOn"
			).then((trxData) => {
				if (trxData) {
					setTransactions(trxData);
				}
				setLoading(false);
			});
		}
	}, [userData, reloadData]);

	return (
		<div className={`relative mb-16 ${loading ? "h-[100vh]" : ""}`}>
			{loading && <Loader />}
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			<div className={` ${loading ? "filter blur-sm" : ""}`}>
				<h2 className="font-normal text-[1.4375rem] lg:text-[2.0625rem]">
					Transaction History
				</h2>
				{transactions?.length !== 0 ? (
					<>
						<div className="collapse-title my-4 font-bold flex gap-4 md:gap-8 text-center py-6 border-y border-neutral-300 dark:border-darkmode-500 w-full">
							<p className="w-1/3 md:w-1/5 my-auto ">
								Request Name
							</p>
							<p className="hidden md:block w-1/3 md:w-1/5 my-auto ">
								Date
							</p>
							<p className="hidden md:block w-1/3 md:w-1/5 my-auto ">
								Transaction Type
							</p>
							<p className=" w-1/3 md:w-1/5 my-auto ">Amount</p>
							<p className="w-1/3 md:w-1/5 my-auto ">Status</p>
							{/* <p className="hidden md:block w-1/3 md:w-1/6 my-auto ">
								View on Explorer
							</p> */}
						</div>

						<div className="my-5 flex flex-col gap-3">
							{transactions
								? transactions.map((item) => (
										<TransactionsCard
											key={item.id}
											data={item}
											txType={
												InvestorTransactionType.Received
											}
											setReloadData={setReloadData}
										/>
								  ))
								: null}
						</div>
					</>
				) : (
					<div className="relative h-screen flex justify-center">
						<div className="text-[#64748B] text-xl text-center mt-3 absolute top-40">
							<p>No transactions available.</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Transactions;
