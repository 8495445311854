import { useNavigate } from "react-router-dom";
import DollarImage from "../../../../assets/Dollar-icon.svg";
const TransactionsCard = ({ data }) => {
	return (
		<div className="px-2 bg-neutral-100 dark:bg-darkmode-800 flex justify-around rounded-xl py-3 gap-4 md:gap-6 text-center text-neutral-900 dark:text-neutral-50">
			<p className="hidden md:block w-1/6 md:w-1/6 my-auto">
				{data.opportunityName}
			</p>
			<p className="hidden md:block w-1/6 md:w-1/6 my-auto">
				{data.companyDetails?.companyName}
			</p>

			<p className="flex gap-1 items-center w-1/6 md:w-1/6 my-auto justify-center">
				<img src={DollarImage} className="w-4" alt="Dollarimage" />
				{data.opportunityAmount}
			</p>

			<p className="flex gap-1 items-center w-1/6 md:w-1/6 my-auto justify-center">
				{data.totalRepaidAmount !== "--" && (
					<img src={DollarImage} className="w-4" alt="Dollarimage" />
				)}
				{data.totalRepaidAmount}
			</p>

			<p className="w-1/6 md:w-1/6 my-auto transaction-button">
				{data.subscribedPercentage}
			</p>

			<p className="w-1/6 md:w-1/6 my-auto transaction-button">
				<button
					className={`bg-gradient-to-r text-white w-28 ${
						data.bondDisplayStatus === "Open"
							? "bg-[#0000FF]" // Blue
							: data.bondDisplayStatus === "Subscribed"
							? "bg-[#00AA00]" // Green
							: data.bondDisplayStatus === "Tokenized"
							? "bg-[#6A0DAD]" // Purple
							: data.bondDisplayStatus === "Part Paid"
							? "bg-[#FFC107]" // Amber
							: data.bondDisplayStatus === "Delayed"
							? "bg-[#E01B1B]" // Red
							: data.bondDisplayStatus === "Fully Paid"
							? "bg-[#10104e]" // (Closer to black)
							: data.bondDisplayStatus === "Matured"
							? "bg-[#808080]" // Gray
							: undefined // Default color (no value)
					} `}
				>
					<span className="text-white">{data.bondDisplayStatus}</span>
				</button>
			</p>
		</div>
	);
};

export default TransactionsCard;
