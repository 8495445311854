import ArrowLeft from "../../../SVGIcons/ArrowLeft";

export default function Final({ handlePrev, finalSubmit, formData }) {
	const handleClick = () => {
		finalSubmit(formData);
	};

	return (
		<div className="flex flex-col mt-20 md:mt-14 gap-1 md:gap-1 md:px-5 overflow-hidden">
			<div className="flex flex-col gap-3 items-center mt-2">
				<div className=" flex flex-col gap-1 md:gap-1">
					<h4 className="text-primary font-medium text-lg 2xl:text-xl mb-2 text-[#9281FF]">
						Final&nbsp;Details
					</h4>

					<div className="flex w-full flex-col md:flex-row  justify-between">
						<div className="flex flex-col gap-2">
							<div className="font-[500] text-base w-full flex justify-between  md:gap-7">
								<span className=" text-[#A0ABBB]">
									Delay Charge Rate (%)
								</span>
								<span className="text-black dark:text-[#fff] items-start w-32">
									{formData.delayChargeRatePercentage}
								</span>
							</div>

							<div className="font-[500] text-base w-full flex justify-between  md:gap-7">
								<span className=" text-[#A0ABBB]">
									Junior Tranche (%)
								</span>
								<span className="text-black dark:text-[#fff] items-start w-32">
									{formData.juniorTranchPercentage}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col-reverse gap-10  md:gap-0 justify-center md:justify-around md:flex-row md:items-center  w-full items-center content-center mt-6">
				<div className="pb-10 md:pb-0 md:mt-10">
					<label
						onClick={handlePrev}
						className="text-gray-500 flex-row cursor-pointer flex ml-1 md:pl-14 md:-mt-6 text-lg items-center"
					>
						<ArrowLeft color="#64748B" />
						Back
					</label>
				</div>

				<button
					onClick={handleClick}
					style={{
						borderRadius: "100px",
						padding: "12px 24px",
						color: "white",
					}}
					className={`mt-10 md:mt-2 btn btn-wide  capitalize font-medium border-none text-lg 
					bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] focus:outline-[#9281FF]
					disabled:opacity-40 
							
					`}
				>
					Submit
				</button>
			</div>
		</div>
	);
}
