import React from "react";
import DocumentCard from "../../uiTools/Card/DocumentCard";

const DiliginceDocument = ({ companyDetails, opDetails }) => {
	return (
		<div>
			<div className="w-full my-3 mt-10 text-lg font-medium xl:w-1/2">
				<div>KYB Details</div>
				<h6 className="dark:text-[#A0ABBB] text-[#4B5768] mt-2 mb-0.5">
					Business Identity Proof
				</h6>
				<DocumentCard
					docName={
						companyDetails
							? companyDetails.businessIdFile?.businessIdDocName
							: ""
					}
					docCid={
						companyDetails
							? companyDetails.businessIdFile?.businessIdFileCID
							: null
					}
					fileName={
						companyDetails
							? companyDetails.businessIdFile?.businessIdFileName
							: null
					}
				/>

				<h6 className="dark:text-[#A0ABBB] text-[#4B5768]  mb-0.5">
					Business Address Proof
				</h6>
				<DocumentCard
					docName={
						companyDetails
							? companyDetails.businessAddFile?.businessAddDocName
							: ""
					}
					docCid={
						companyDetails
							? companyDetails.businessAddFile?.businessAddFileCID
							: null
					}
					fileName={
						companyDetails
							? companyDetails.businessAddFile
									?.businessAddFileName
							: null
					}
				/>
				<h6 className="dark:text-[#A0ABBB] text-[#4B5768]  mb-0.5">
					Business Incorporation Proof
				</h6>
				<DocumentCard
					docName={
						companyDetails
							? companyDetails.businessIncoFile
									?.businessIncoDocName
							: ""
					}
					docCid={
						companyDetails
							? companyDetails.businessIncoFile
									?.businessIncoFileCID
							: null
					}
					fileName={
						companyDetails
							? companyDetails.businessIncoFile
									?.businessIncoFileName
							: null
					}
				/>
				{companyDetails && companyDetails.businessLicFile ? (
					<>
						<h6 className="dark:text-[#A0ABBB] text-[#4B5768]  mb-0.5">
							Business License Proof
						</h6>
						<DocumentCard
							docName={
								companyDetails
									? companyDetails.businessLicFile
											?.businessLicDocName
									: ""
							}
							docCid={
								companyDetails
									? companyDetails.businessLicFile
											?.businessLicFileCID
									: null
							}
							fileName={
								companyDetails
									? companyDetails.businessLicFile
											?.businessLicFileName
									: null
							}
						/>
					</>
				) : (
					<></>
				)}
			</div>

			<div className="w-full my-3 mt-10 text-lg font-medium xl:w-1/2">
				<div>Due Diligence Details</div>
				<h6 className="dark:text-[#A0ABBB] text-[#4B5768] mt-2 mb-0.5">
					Audited Financial Statements For The Past 3 Years
				</h6>
				<DocumentCard
					docName={
						companyDetails
							? companyDetails.dueDiliAuFile?.dueDiliAuDocName
							: ""
					}
					docCid={
						companyDetails
							? companyDetails.dueDiliAuFile?.dueDiliAuFileCID
							: null
					}
					fileName={
						companyDetails
							? companyDetails.dueDiliAuFile?.dueDiliAuFileName
							: null
					}
				/>

				<h6 className="dark:text-[#A0ABBB] text-[#4B5768]  mb-0.5">
					GST Returns For The Past 1 Year
				</h6>
				<DocumentCard
					docName={
						companyDetails
							? companyDetails.dueDiliGstFile?.dueDiliGstDocName
							: ""
					}
					docCid={
						companyDetails
							? companyDetails.dueDiliGstFile?.dueDiliGstFileCID
							: null
					}
					fileName={
						companyDetails
							? companyDetails.dueDiliGstFile?.dueDiliGstFileName
							: null
					}
				/>
				<h6 className="dark:text-[#A0ABBB] text-[#4B5768]  mb-0.5">
					Relevant Bank Statement For The Past 1 Year
				</h6>
				<DocumentCard
					docName={
						companyDetails
							? companyDetails.dueDiliRelFile?.dueDiliRelDocName
							: ""
					}
					docCid={
						companyDetails
							? companyDetails.dueDiliRelFile?.dueDiliRelFileCID
							: null
					}
					fileName={
						companyDetails
							? companyDetails.dueDiliRelFile?.dueDiliRelFileName
							: null
					}
				/>

				<h6 className="dark:text-[#A0ABBB] text-[#4B5768]  mb-0.5">
					KYC of The Promoters
				</h6>
				<DocumentCard
					docName={
						companyDetails
							? companyDetails.dueDiliKycFile?.dueDiliKycDocName
							: ""
					}
					docCid={
						companyDetails
							? companyDetails.dueDiliKycFile?.dueDiliKycFileCID
							: null
					}
					fileName={
						companyDetails
							? companyDetails.dueDiliKycFile?.dueDiliKycFileName
							: null
					}
				/>
			</div>
			<div className="w-full my-3 mt-10 text-lg font-medium xl:w-1/2">
				<div>Issue Note</div>
				<DocumentCard
					docCid={
						opDetails?.issueNoteDoc ? opDetails?.issueNoteDoc : null
					}
				/>
			</div>
			<br />
		</div>
	);
};

export default DiliginceDocument;
