import React from "react";
import DollarImage from "../../../../assets/Dollar-icon.svg";

const RepaymentModal = ({
	data,
	handleRepayment,
	setSelectedBankDetails,
	setSelected,
}) => {
	return (
		<div>
			<input
				type="checkbox"
				id="repayment-modal"
				className="modal-toggle"
			/>
			<div className="modal backdrop-filter backdrop-brightness-[100%] backdrop-blur-lg">
				<div
					className={`bg-white dark:bg-darkmode-800  w-[100vw] h-[100vh] flex flex-col md:block md:h-auto md:w-[70%] lg:w-[50%] xl:w-[45%] 2xl:w-[40%] pb-[6em] md:rounded-xl md:pb-8 `}
				>
					<div className=" flex justify-between px-4 md:px-8 md:border-b mt-[4em] md:mt-0 py-4">
						<h3 className="font-normal text-xl">Repayment</h3>

						<label
							htmlFor="repayment-modal"
							className="hover:text-primary-600 text-xl"
							onClick={() => handleRepayment()}
						>
							✕
						</label>
					</div>

					<div className="px-4 md:px-8 mt-10 flex flex-col gap-1">
						<div className="flex justify-between font-normal">
							<p>Request Name</p>
							<p>{data?.opportunityName}</p>
						</div>

						<div className="flex gap-1 font-normal">
							<p className="flex justify-start">
								Current Total Payable
							</p>

							<img
								src={DollarImage}
								className="ml-auto w-[1rem]"
								alt="DollarImage"
							/>
							<p className="font-normal text-[1.125rem]">
								{data?.finalRepaymentDisplayAmount}
							</p>
						</div>
						{data?.isOverDue && (
							<div className="flex gap-1 font-normal">
								<p className="flex justify-start text-error-500">
									Overdue Charge
								</p>

								<img
									src={DollarImage}
									className="ml-auto w-[1rem]"
									alt="DollarImage"
								/>
								<p className="font-normal text-[1.125rem]">
									{data?.overDueCharge}
								</p>
							</div>
						)}
					</div>

					<div className="px-4 md:px-8 mt-auto md:mt-8">
						<button
							className={`block font-normal text-white focus:outline-offset-2 bg-gradient-to-r from-[#4B74FF] to-primary-500 w-[100%] cursor-pointer focus:outline-none focus:outline-[#9281FF] hover:outline-[#9281FF]
							 text-center py-2 rounded-[1.8em] select-none`}
							onClick={() => {
								setSelected(null);
								setSelectedBankDetails(true);
							}}
						>
							Make Repayment
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RepaymentModal;
