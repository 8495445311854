export const stateNumOptions = [
	{ value: 0, label: "Subscriber" },
	{ value: 1, label: "Issuer" },
	{ value: 2, label: "Custodian" },
	{ value: 3, label: "Regulator" },
	{ value: 5, label: "Diligence" },
];

export const kycStatusOptions = [
	{ value: true, label: "Yes" },
	{ value: false, label: "No" },
];
