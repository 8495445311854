import React, { useEffect, useState, useContext } from "react";
import DocumentCard from "../../uiTools/Card/DocumentCard";
import { useNavigate, useLocation } from "react-router-dom";
import KYBModal from "./Components/Modal/KYB/KYBModal";
import Twitter from "../SVGIcons/Twitter";
import LinkedIn from "../SVGIcons/LinkedIn";
import Email from "../SVGIcons/Email";
import Website from "../SVGIcons/Website";
import Edits from "../SVGIcons/Edits";
import Loader from "../../uiTools/Loading/Loader";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import { captureException } from "@sentry/react";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import BorrowerProfileConf from "../Borrower/BorrowerProfileConf";
import { sustainableCheckedData } from "./SustainableData/sustainableData";

const BorrowerProfile = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);

	const [selected, setSelected] = useState(null);

	const [borrowerJson, setborrowerJson] = useState();
	const [logoImgSrc, setLogoImgSrc] = useState();
	const [companyName, setCompanyName] = useState();
	const [companyRepName, setCompanyRepName] = useState();
	const [companyBio, setCompanyBio] = useState();
	const [website, setWebsite] = useState();
	const [email, setEmail] = useState();
	const [twitter, setTwitter] = useState();
	const [linkedin, setLinkedin] = useState();
	const location = useLocation();
	const [profileStatus, setProfileStatus] = useState(false);
	const [kycStatus, setKycStatus] = useState(true);
	const [hasKey, setHaskey] = useState();
	const [hasGstKey, setHasGstKey] = useState();
	const [hasBankKey, setBankHasKey] = useState();
	const [sustainableChecked, setSustainableChecked] = useState("");
	const [isCheckModal, setIsCheckModal] = useState({
		status: false,
		title: "",
		url: "",
	});

	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});
	const { userData } = useContext(LoginContext);
	let brJson = location.state;
	const [collapse, setCollapse] = useState(false);

	const handleForm = () => {
		setSelected(null);
	};

	useEffect(() => {
		// make the call to get borrower specific cid to fetch the data
		// currently we'll mock the cid
		if (userData) {
			setKycStatus(userData?.kycStatus);
		} else {
			setErrormsg({
				status: false,
				msg: "Please login first and then navigate to this page",
			});
		}

		if (!brJson && userData?.profile) {
			brJson = JSON.parse(userData?.profile);
		}
		loadBorrowerData(brJson);
		setborrowerJson(brJson);
		setHaskey(brJson ? "businessLicFile" in brJson : false);
		setHasGstKey(brJson ? "dueDiliGstFile" in brJson : false);
		setBankHasKey(brJson ? "dueDiliRelFile" in brJson : false);
		setLoading(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brJson, userData]);

	useEffect(() => {
		if (sustainableChecked.length > 0) {
			setCollapse(true);
		}
	}, [sustainableChecked]);

	const loadBorrowerProfileData = (profileData) => {
		let data;
		if (brJson) data = brJson;
		else data = profileData;
		if (data) {
			setProfileStatus(true);
		}
		if (data) {
			try {
				if (data.companyName) {
					setCompanyName(data.companyName);
				}
				if (data.companyRepName) {
					setCompanyRepName(data.companyRepName);
				}
				if (data.companyBio) {
					setCompanyBio(data.companyBio);
				}
				if (data.website) {
					setWebsite(data.website);
				}
				if (data.email) {
					setEmail("mailto:" + data.email);
				}
				if (data.twitter) {
					setTwitter(data.twitter);
				}
				if (data.linkedin) {
					setLinkedin(data.linkedin);
				}
				if (data.sustainableChecked) {
					// Sort SDGs in proper order
					let sustainableData = [...data.sustainableChecked];
					sustainableData.sort((a, b) => {
						return (
							sustainableCheckedData.indexOf(a) -
							sustainableCheckedData.indexOf(b)
						);
					});
					setSustainableChecked(sustainableData);
				}
			} catch (error) {
				captureException(error);
			} finally {
				setLoading(false);
			}
		}
	};

	const loadBorrowerData = (jsonData) => {
		try {
			if (jsonData) {
				// Load the Logo image if there is any
				let imgUrl = jsonData.companyLogoFile.businessLogoFileCID;
				if (imgUrl) {
					setLogoImgSrc(imgUrl);
				}
				// Load rest of the data
				loadBorrowerProfileData(jsonData);
			}
		} catch (error) {
			captureException(error);
		} finally {
			setLoading(false);
		}
	};

	const redirectToURl = (event) => {
		let url;
		let platform = event.target.id;

		switch (platform) {
			case "twitter":
				url = twitter;
				break;
			case "linkedin":
				url = linkedin;
				break;
			case "website":
				url = website;
				break;
			default:
				break;
		}

		if (url) {
			let protocol = "https://";
			let position = url.search(protocol);
			// if there is no "https://" in the url then it is not opened correctly
			if (position === -1) {
				url = protocol + url;
			}

			window.open(url, "_blank");
		}
	};

	const redirectForEmail = () => {
		if (email) {
			window.location.href = email;
		}
	};

	return (
		<>
			<div className="relative mb-16">
				{loading && <Loader />}
				<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
				<BorrowerProfileConf
					isCheckModal={isCheckModal}
					setIsCheckModal={setIsCheckModal}
				/>
				<div className={`${loading ? "filter blur-sm" : ""}`}>
					{selected && <KYBModal handleForm={handleForm} />}
					{!profileStatus && (
						<div className="">
							<div className="flex items-center justify-between">
								<h2 className="font-normal text-[1.4375rem] lg:text-[2.0625rem]">
									Issuer Profile
								</h2>

								<button
									onClick={() => {
										// navigate("/borrowerDashboard/editProfile");

										setIsCheckModal({
											status: true,
											title: "Borrower Profile",
											url: "/borrowerDashboard/editProfile",
										});
									}}
									className="CreateProfileIcon font-normal border border-neutral-500 flex gap-1 items-center rounded-3xl py-2 px-3 sm:px-5"
								>
									<p>Create Profile</p>
									<Edits />
								</button>
							</div>
							<p className="justify-center flex text-[#64748B] mt-10">
								Complete your profile.
							</p>
						</div>
					)}
					{profileStatus ? (
						<>
							<div className="flex justify-between items-center">
								<div className="flex gap-2 items-center ">
									<div className="avatar">
										<div className="rounded-full w-20">
											<img
												alt=""
												src={
													logoImgSrc
														? logoImgSrc
														: require("../../assets/noImage.jpeg")
												}
											/>
										</div>
									</div>
									<div className=" font-normal ">
										<p className="text-[1.1875rem]">
											{companyName}
										</p>
										<p className="text-neutral-300">
											{companyRepName}
										</p>
									</div>
								</div>

								<button
									onClick={() =>
										navigate(
											"/borrowerDashboard/editProfile",
											{
												state: borrowerJson
													? borrowerJson
													: brJson,
											}
										)
									}
									className="CreateProfileIcon border border-neutral-500 flex gap-1 items-center rounded-3xl py-2 px-3 sm:px-5"
								>
									<p>Edit Profile</p>
									<Edits />
								</button>
							</div>

							{!kycStatus ? (
								<div className="border border-r-[1.8rem] border-secondary-500 w-full md:w-[35rem] my-8 rounded-xl py-5 px-3 md:px-4 bg-lightmode-200 dark:bg-darkmode-800">
									<label id="blockpass-kyc-connect">
										<p className="font-normal text-[1.1875rem]">
											KYC verification is in process
										</p>
										<br />
										<p>
											Thanks for completing your profile.
											You will be intimated by email on
											KYC verification, post which you
											will be able to continue the
											process.
										</p>
									</label>
								</div>
							) : (
								<></>
							)}

							<div className=" font-normal flex flex-col md:flex-row md:justify-between gap-2 mt-10">
								<h2 className="text-2xl font-normal">Bio</h2>

								<div className=" flex gap-1 md:gap-3 ">
									{twitter ? (
										<button
											id="twitter"
											onClick={redirectToURl}
											className="CreateProfileIcon border border-neutral-500 flex gap-1 items-center py-1 px-2 rounded-2xl"
										>
											<Twitter />
											<p className="text-xs md:text-base pointer-events-none">
												twitter
											</p>
										</button>
									) : (
										<></>
									)}
									{linkedin ? (
										<button
											id="linkedin"
											onClick={redirectToURl}
											className="CreateProfileIcon border border-neutral-500 flex gap-1 items-center py-1 px-2 rounded-2xl"
										>
											<LinkedIn />
											<p className="text-xs md:text-base pointer-events-none">
												linkedIn
											</p>
										</button>
									) : (
										<></>
									)}
									{email ? (
										<button
											id="email"
											onClick={redirectForEmail}
											className="CreateProfileIcon border border-neutral-500 flex gap-1 items-center py-1 px-2 rounded-2xl"
										>
											<Email />
											<p className="text-xs md:text-base pointer-events-none">
												email
											</p>
										</button>
									) : (
										<></>
									)}
									{website ? (
										<button
											id="website"
											onClick={redirectToURl}
											className="border  border-neutral-500 flex gap-1 items-center py-1 px-2 rounded-2xl"
										>
											<Website />
											<p className="text-xs md:text-base pointer-events-none">
												website
											</p>
										</button>
									) : (
										<></>
									)}
								</div>
							</div>

							<div className="my-6">
								<p className="mt-1 text-lg css-fix">
									{companyBio}
								</p>
							</div>

							<div className="mb-8 font-normal">
								<h3 className="text-[1.1875rem] mb-3">
									KYB Details
								</h3>
								<h5 className="text-[1.1875rem] text-neutral-400">
									Business Identity Proof
								</h5>
								<DocumentCard
									docName={
										brJson
											? brJson.businessIdFile
													.businessIdDocName
											: borrowerJson
											? borrowerJson.businessIdFile
													.businessIdDocName
											: ""
									}
									docCid={
										brJson
											? brJson.businessIdFile
													.businessIdFileCID
											: borrowerJson
											? borrowerJson.businessIdFile
													.businessIdFileCID
											: null
									}
									fileName={
										brJson
											? brJson.businessIdFile
													.businessIdFileName
											: borrowerJson
											? borrowerJson.businessIdFile
													.businessIdFileName
											: null
									}
								/>

								<h5 className="text-[1.1875rem] text-neutral-400">
									Business Address Proof
								</h5>
								<DocumentCard
									docName={
										brJson
											? brJson.businessAddFile
													.businessAddDocName
											: borrowerJson
											? borrowerJson.businessAddFile
													.businessAddDocName
											: null
									}
									docCid={
										brJson
											? brJson.businessAddFile
													.businessAddFileCID
											: borrowerJson
											? borrowerJson.businessAddFile
													.businessAddFileCID
											: null
									}
									fileName={
										brJson
											? brJson.businessAddFile
													.businessAddFileName
											: borrowerJson
											? borrowerJson.businessAddFile
													.businessAddFileName
											: null
									}
								/>
								<h5 className="text-[1.1875rem] text-neutral-400">
									Business Incorporation Proof
								</h5>
								<DocumentCard
									docName={
										brJson
											? brJson.businessIncoFile
													.businessIncoDocName
											: borrowerJson
											? borrowerJson.businessIncoFile
													.businessIncoDocName
											: null
									}
									docCid={
										brJson
											? brJson.businessIncoFile
													.businessIncoFileCID
											: borrowerJson
											? borrowerJson.businessIncoFile
													.businessIncoFileCID
											: null
									}
									fileName={
										brJson
											? brJson.businessIncoFile
													.businessIncoFileName
											: borrowerJson
											? borrowerJson.businessIncoFile
													.businessIncoFileName
											: null
									}
								/>
								<h5 className="text-[1.1875rem] text-neutral-400">
									Business License Proof
								</h5>
								{hasKey ? (
									<DocumentCard
										docName={
											brJson
												? brJson.businessLicFile
														.businessLicDocName
												: borrowerJson
												? borrowerJson.businessLicFile
														.businessLicDocName
												: null
										}
										docCid={
											brJson
												? brJson.businessLicFile
														.businessLicFileCID
												: borrowerJson
												? borrowerJson.businessLicFile
														.businessLicFileCID
												: null
										}
										fileName={
											brJson
												? brJson.businessLicFile
														.businessLicFileName
												: borrowerJson
												? borrowerJson.businessLicFile
														.businessLicFileName
												: null
										}
									/>
								) : (
									<DocumentCard
										docName={"No document added!"}
										disable={true}
									/>
								)}
							</div>

							{sustainableChecked.length > 0 && (
								<div className="collapse collapse-arrow bg-lightmode-200 dark:bg-[#24272F] outline outline-1 outline-offset-0 dark:outline-[#3A3C43] outline-[#BBC0CC] mt-7 rounded-xl">
									<input
										type="checkbox"
										className="peer"
										checked={collapse}
										onClick={() => {
											setCollapse((prev) => !prev);
										}}
									/>

									<div className="collapse-title flex gap-4 md:gap-8 text-center text-lg">
										<p className="">
											Sustainable Development Goals
										</p>
									</div>
									<div className="collapse-content">
										<div className="font-normal pt-2 pb-4">
											<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
												{sustainableChecked &&
													sustainableChecked.map(
														(data) => (
															<div
																key={data}
																className="flex items-center border  pl-4 dark:border-[#3A3C43] border-[#BBC0CC] rounded"
															>
																<svg
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M6.28571 4.5C5.02514 4.5 4 5.52514 4 6.78571V18.2143C4 19.4749 5.02514 20.5 6.28571 20.5H17.7143C18.9749 20.5 20 19.4749 20 18.2143V6.78571C20 5.52514 18.9749 4.5 17.7143 4.5H6.28571ZM6.28571 18.2143V6.78571H17.7143L17.7166 18.2143H6.28571Z"
																		fill="#10B981"
																	/>
																	<path
																		d="M10.696 12.767L9.4 11.496L8 12.926L10.704 15.573L15.403 10.922L13.997 9.5L10.696 12.767Z"
																		fill="#10B981"
																	/>
																</svg>

																<label
																	htmlFor="bordered-checkbox-1"
																	className="w-full flex  align-middle  items-center py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
																>
																	<img
																		src={`IconSustainable/${data}.png`}
																		className="w-16 h-16 rounded-md"
																		alt=""
																	/>
																	<span
																		style={{
																			marginLeft:
																				"0.2rem",
																		}}
																	>
																		{data}
																	</span>
																</label>
															</div>
														)
													)}
											</div>
										</div>
									</div>
								</div>
							)}
							<br />

							<div className="mb-8 font-normal">
								<h3 className="text-[1.1875rem] mb-3">
									Due Diligence Details
								</h3>
								<h5 className="text-[1.1875rem] text-neutral-400">
									Audited Financial Statements for the past 3
									years
								</h5>
								<DocumentCard
									docName={
										brJson
											? brJson.dueDiliAuFile
													.dueDiliAuDocName
											: borrowerJson
											? borrowerJson.dueDiliAuFile
													.dueDiliAuDocName
											: ""
									}
									docCid={
										brJson
											? brJson.dueDiliAuFile
													.dueDiliAuFileCID
											: borrowerJson
											? borrowerJson.dueDiliAuFile
													.dueDiliAuFileCID
											: null
									}
									fileName={
										brJson
											? brJson.dueDiliAuFile
													.dueDiliAuFileName
											: borrowerJson
											? borrowerJson.dueDiliAuFile
													.dueDiliAuFileName
											: null
									}
								/>

								<h5 className="text-[1.1875rem] text-neutral-400">
									GST Returns for the past 1 year
								</h5>
								{hasGstKey ? (
									<DocumentCard
										docName={
											brJson
												? brJson.dueDiliGstFile
														.dueDiliGstDocName
												: borrowerJson
												? borrowerJson.dueDiliGstFile
														.dueDiliGstDocName
												: null
										}
										docCid={
											brJson
												? brJson.dueDiliGstFile
														.dueDiliGstFileCID
												: borrowerJson
												? borrowerJson.dueDiliGstFile
														.dueDiliGstFileCID
												: null
										}
										fileName={
											brJson
												? brJson.dueDiliGstFile
														.dueDiliGstFileName
												: borrowerJson
												? borrowerJson.dueDiliGstFile
														.dueDiliGstFileName
												: null
										}
									/>
								) : (
									<DocumentCard
										docName={"No document added!"}
										disable={true}
									/>
								)}
								<h5 className="text-[1.1875rem] text-neutral-400">
									Relevant Bank Statements for the past 1 year
								</h5>
								{hasBankKey ? (
									<DocumentCard
										docName={
											brJson
												? brJson.dueDiliRelFile
														.dueDiliRelDocName
												: borrowerJson
												? borrowerJson.dueDiliRelFile
														.dueDiliRelDocName
												: null
										}
										docCid={
											brJson
												? brJson.dueDiliRelFile
														.dueDiliRelFileCID
												: borrowerJson
												? borrowerJson.dueDiliRelFile
														.dueDiliRelFileCID
												: null
										}
										fileName={
											brJson
												? brJson.dueDiliRelFile
														.dueDiliRelFileName
												: borrowerJson
												? borrowerJson.dueDiliRelFile
														.dueDiliRelFileName
												: null
										}
									/>
								) : (
									<DocumentCard
										docName={"No document added!"}
										disable={true}
									/>
								)}
								<h5 className="text-[1.1875rem] text-neutral-400">
									KYC of the Promoters
								</h5>
								<DocumentCard
									docName={
										brJson
											? brJson.dueDiliKycFile
													.dueDiliKycDocName
											: borrowerJson
											? borrowerJson.dueDiliKycFile
													.dueDiliKycDocName
											: null
									}
									docCid={
										brJson
											? brJson.dueDiliKycFile
													.dueDiliKycFileCID
											: borrowerJson
											? borrowerJson.dueDiliKycFile
													.dueDiliKycFileCID
											: null
									}
									fileName={
										brJson
											? brJson.dueDiliKycFile
													.dueDiliKycFileName
											: borrowerJson
											? borrowerJson.dueDiliKycFile
													.dueDiliKycFileName
											: null
									}
								/>
							</div>
						</>
					) : null}
				</div>
			</div>
		</>
	);
};

export default BorrowerProfile;
