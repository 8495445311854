import React, { useState, useEffect } from "react";
import Loader from "../../uiTools/Loading/Loader.js";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import DoughnutGreenChart from "../../uiTools/Chart/DoughnutGreenChart.js";
import DygnifyImage from "../../assets/Dygnify_Image.png";
import { useLocation } from "react-router-dom";
import {
	getDisplayAmount,
	getTrimmedString,
} from "../../services/Helpers/displayTextHelper";

const dummyArray = [
	{
		no: 1,
		name: "Object 1",
		issuer: "Issuer 1",
		amount: 1000,
		subscribed: 75,
		status: "open",
	},
	{
		no: 2,
		name: "Object 2",
		issuer: "Issuer 2",
		amount: 2000,
		subscribed: 40,
		status: "tokenized",
	},
	{
		no: 3,
		name: "Object 3",
		issuer: "Issuer 3",
		amount: 3000,
		subscribed: 90,
		status: "matured",
	},
	{
		no: 4,
		name: "Object 4",
		issuer: "Issuer 4",
		amount: 4000,
		subscribed: 60,
		status: "open",
	},
	{
		no: 5,
		name: "Object 5",
		issuer: "Issuer 5",
		amount: 5000,
		subscribed: 20,
		status: "tokenized",
	},
];

const Payment = () => {
	const location = useLocation();

	const [details, setDetails] = useState(false);

	console.log(details);

	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});
	const [seniorPoolLoading, setSeniorPoolLoading] = useState(false); //true
	const [juniorPoolLoading, setJuniorPoolLoading] = useState(false); //true
	const [totalRepaidAmt, setTotalRepaidAmt] = useState("--");
	const [totalLoanAmtWithInterest, setTotalLoanAmtWithInterest] = useState(0);
	const [totalOutstandingAmt, setTotalOutstandingAmt] = useState("--");
	const [logoImgSrc, setLogoImgSrc] = useState();
	const [totalInvestment, setTotalInvestment] = useState(0);
	const [totalYield, setTotalYield] = useState(0);

	useEffect(() => {
		if (location?.state) {
			console.log(location?.state);
			// setPoolData(location.state);
			setDetails(location?.state?.data);
			setLogoImgSrc(
				location?.state?.data?.companyDetails?.companyLogoFile
					?.businessLogoFileCID
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			<div>
				<div className="">
					<h2 className="text-[1.4375rem] lg:text-[2.0625rem] ">
						All Requests
					</h2>
				</div>
				<br />

				<div className="flex items-center gap-3 md:gap-5">
					<img
						alt="dygnigyImage"
						src={logoImgSrc ? logoImgSrc : DygnifyImage}
						style={{ aspectRatio: "1/1" }}
						className="rounded-[50%] w-[3em] sm:w-[4em] md:w-[5em]"
					/>
					<div>
						<p className="text-lg font-normal">
							{getTrimmedString(details?.opportunityName, 20)}
						</p>
						<p className="text-neutral-500">
							{details?.companyDetails
								? details?.companyDetails?.companyName
								: ""}
						</p>
					</div>
				</div>

				<br />

				<div className="pt-3 pb-4 rounded-xl flex w-[100%] gap-5 items-center px-10 ">
					<div className="flex flex-col gap-4 card-gradient px-4 py-4 rounded-xl md:flex-row sm:px-[4vw] md:pl-2 md:pr-[1.5vw] md:gap-3 lg:px-[1vw] xl:px-[1vw] xl:gap-8 md:w-1/2 2xl:items-center 2xl:justify-around">
						<div className="flex flex-col items-center gap-2 md:items-start	">
							<h2 className="text-xl font-bold text-[#64748B] md:text-sm 2xl:text-lg">
								Yield Earned
							</h2>

							<div className="m-1">
								{totalInvestment || totalYield ? (
									<DoughnutGreenChart
										data={[
											totalInvestment,
											totalYield ? totalYield : 0,
										]}
										color={["#5375FE", "#ffffff"]}
										width={200}
										labels={[
											"Total Investment",
											"Total Yield",
										]}
										borderWidth={[1, 8]}
										legendStyle={{ display: false }}
									/>
								) : (
									<DoughnutGreenChart
										data={[1]}
										color={["#64748B"]}
										width={200}
										labels={[
											"Total Investment",
											"Total Yield",
										]}
										borderWidth={[1, 8]}
										legendStyle={{ display: false }}
									/>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-3 justify-center">
							<div className="flex items-center md:flex-col md:items-start gap-1">
								<p className="text-sm lg:text-base text-[#64748B] flex gap-1 items-center">
									<span className="inline-block w-3 h-2 bg-[#5375FE] rounded-3xl"></span>
									Total amount invested
								</p>

								{totalInvestment === 0 ? (
									<p className="ml-auto md:ml-0  text-xl lg:text-[1.75rem] px-5">
										- -
									</p>
								) : (
									<div className="ml-auto md:ml-0 font-normal flex items-end gap-2 px-4">
										<p className=" text-xl lg:text-[1.75rem] ">
											{getDisplayAmount(totalInvestment)}
										</p>
										<p className="text-base lg:text-xl">
											{process.env.REACT_APP_TOKEN_NAME}
										</p>
									</div>
								)}
							</div>

							<div className="flex items-center md:flex-col md:items-start gap-1">
								<p className="text-sm lg:text-base text-[#64748B]  flex gap-1 items-center">
									<span className="inline-block w-3 h-2 bg-white rounded-3xl"></span>
									Total Yield Earned
								</p>

								{totalYield === 0 ? (
									<p className="ml-auto md:ml-0 text-xl lg:text-[1.75rem] px-5">
										- -
									</p>
								) : (
									<div className="ml-auto md:ml-0 font-normal flex items-end gap-2 px-5">
										<p className=" text-xl lg:text-[1.75rem] ">
											{getDisplayAmount(totalYield)}
										</p>
										<p className="text-base lg:text-xl">
											{process.env.REACT_APP_TOKEN_NAME}
										</p>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="flex flex-col gap-4 card-gradient px-4 py-4 rounded-xl md:flex-row sm:px-[4vw] md:pl-2 md:pr-[1.5vw] md:gap-3 lg:px-[1vw] xl:px-[1vw] xl:gap-8 md:w-1/2 2xl:items-center 2xl:justify-around card-gradient">
						<div className="flex gap-7 w-full py-8 px-10">
							<div className="w-[55%] space-y-3">
								<p>Subscribed Amount :</p>

								<p>Amount Outstanding:</p>

								<p>Interest Payment Freq :</p>

								<p>Principal Payment Freq :</p>
							</div>
							<div className="space-y-3">
								<p>{details?.opportunityAmount}</p>
								<p>{details?.opportunityAmount}</p>
								<p>{details?.paymentFrequencyInDays}</p>
								<p>{details?.actualPaymentFrequency}</p>
							</div>
						</div>
					</div>
				</div>

				<div className="mt-4">
					{/* <div className="mb-16 flex flex-col gap-5">
						<h2 className="font-normal text-[1.4375rem] md:text-[1.75rem]">
							Issuer Name:
						</h2>
						<div>
							{seniorPoolLoading && (
								<div className="relative">
									<Loader />
								</div>
							)}
							{dummyArray ? (
								<div className="flex  gap-16 overflow-x-auto  py-5 px-10 w-[100%]">
									<div class="relative overflow-x-auto rounded-md border border-gray-300 w-[100%]">
										<table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
											<thead class="text-xs text-gray-700 uppercase bg-[#E7EAEE] dark:bg-gray-700 dark:text-gray-400">
												<tr className="border-b-[1px] border-gray-300">
													<th
														scope="col"
														class="px-6 py-3 border-l"
														align="center"
													>
														Next Due Date
													</th>
													<th
														scope="col"
														class="px-6 py-3 border-l border-gray-300"
														align="center"
													>
														Type of Payment
													</th>
													<th
														scope="col"
														class="px-6 py-3 border-l border-gray-300"
														align="center"
													>
														Total Amount
													</th>
													<th
														scope="col"
														class=" border-l border-gray-300"
														align="center"
														colspan="2"
													>
														<div className="border-b w-full py-2 border-gray-300">
															Due to
														</div>
														<div className="w-full flex justify-between px-6 py-2">
															<span className="w-1/2">Subscriber</span>
															<span className="border-l w-1/2">Ammount</span>
														</div>
													</th>
												</tr>
											</thead>
											<tbody>
												<tr
													class="bg-neutral-100 border-b dark:bg-gray-800 dark:border-gray-700"
													align="center"
												>
													<th
														scope="row"
														class="px-6 py-4   font-medium text-gray-900 whitespace-nowrap dark:text-white"
													>
														23/43/32
													</th>
													<td class="px-6 py-4 border-l border-gray-300">
														cash
													</td>

													<td class="px-6 py-4 border-l border-gray-300">
														10,000
													</td>
													<td class="px-6 py-4 border-l border-gray-300">
														123
													</td>
													<td class="px-6 py-4 border-l border-gray-300">
														40,00
													</td>
												</tr>
												<tr
													class="bg-neutral-100 border-b dark:bg-gray-800 dark:border-gray-700"
													align="center"
												>
													<th
														scope="row"
														class="px-6 py-4   font-medium text-gray-900 whitespace-nowrap dark:text-white"
													>
														23/43/32
													</th>
													<td class="px-6 py-4 border-l border-gray-300">
														cash
													</td>

													<td class="px-6 py-4 border-l border-gray-300">
														10,000
													</td>
													<td class="px-6 py-4 border-l border-gray-300">
														123
													</td>
													<td class="px-6 py-4 border-l border-gray-300">
														40,00
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							) : (
								<div className="text-center">
									<p className="text-neutral-500 text-lg">
										{juniorPoolLoading ? "" : "No stats are available."}
									</p>
								</div>
							)}
						</div>
					</div> */}

					<div className="mb-16 flex flex-col gap-5">
						<h2 className="font-normal text-[1.4375rem] md:text-[1.75rem]">
							Transactions
						</h2>

						<div
							className={`relative ${
								juniorPoolLoading ? "h-[18rem]" : ""
							}`}
						>
							{juniorPoolLoading && <Loader />}
							{dummyArray.length === 0 ? (
								<div className="text-center">
									<p className="text-neutral-500 text-lg">
										{juniorPoolLoading
											? ""
											: "No stats are available."}
									</p>
								</div>
							) : (
								<div className="flex  gap-16 overflow-x-auto  py-5 px-10 w-[100%]">
									<div class="relative overflow-x-auto rounded-md border border-gray-300 w-[100%] ">
										<table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
											<thead class="text-xs text-gray-700 uppercase bg-[#E7EAEE] dark:bg-gray-700 dark:text-gray-400">
												<tr className="border-b-[1px] border-gray-300">
													<th
														scope="col"
														class="px-6 py-3 "
														align="center"
													>
														S/no.
													</th>
													<th
														scope="col"
														class="px-6 py-3 border-l border-gray-300"
														align="center"
													>
														Txn Type
													</th>
													<th
														scope="col"
														class="px-6 py-3 border-l border-gray-300"
														align="center"
													>
														Amount
													</th>
													<th
														scope="col"
														class="px-6 py-3 border-l border-gray-300"
														align="center"
													>
														Date
													</th>
													<th
														scope="col"
														class="px-6 py-3 border-l border-gray-300"
														align="center"
													>
														From
													</th>
													<th
														scope="col"
														class="px-6 py-3 border-l border-gray-300"
														align="center"
													>
														To
													</th>
												</tr>
											</thead>
											<tbody>
												<tr
													class="bg-neutral-100 border-b dark:bg-gray-800 dark:border-gray-700"
													align="center"
												>
													<th
														scope="row"
														class="px-6 py-4  font-medium text-gray-900 whitespace-nowrap dark:text-white"
													>
														1
													</th>
													<td class="px-6 py-4 border-l border-gray-300">
														60,00,00
													</td>
													<td class="px-6 py-4 border-l border-gray-300">
														60,00,00
													</td>
													<td class="px-6 py-4 border-l border-gray-300">
														60,00,00
													</td>
													<td class="px-6 py-4 border-l border-gray-300">
														60,00,00
													</td>
													<td class="px-6 py-4 border-l border-gray-300">
														<div className="w-32 flex justify-center align-middle items-center">
															<p className=" my-auto transaction-button">
																<button
																	className={`bg-gradient-to-r w-28 ${
																		"pai" ===
																		"paid"
																			? "from-[gray] to-[#cacaca]"
																			: "make as Paid" ===
																			  "make as Paid"
																			? "from-[#0b8cbe] to-[skyblue]"
																			: ""
																	} `}
																>
																	make as Paid
																</button>
															</p>
														</div>
													</td>
												</tr>
												<tr
													class="bg-neutral-100 border-b dark:bg-gray-800 dark:border-gray-700"
													align="center"
												>
													<th
														scope="row"
														class="px-6 py-4  font-medium text-gray-900 whitespace-nowrap dark:text-white"
													>
														2
													</th>
													<td class="px-6 py-4 border-l border-gray-300">
														40,00,00
													</td>
													<td class="px-6 py-4 border-l border-gray-300">
														40,00,00
													</td>
													<td class="px-6 py-4 border-l border-gray-300">
														40,00,00
													</td>
													<td class="px-6 py-4 border-l border-gray-300">
														40,00,00
													</td>

													<td class="px-6 py-4 border-l border-gray-300">
														40,00,00
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Payment;
