import { useState, useEffect, useContext } from "react";
import Loader from "../../uiTools/Loading/Loader.js";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import PoolCardCustodian from "../Custodian/components/Cards/PoolCardCustodian.js";
import { getAllApprovedBonds } from "../../services/BackendConnectors/opportunityConnectors";
import {
	createTokenizedBonds,
	distributePay,
} from "../../services/BackendConnectors/accounting";
import ScrollableCardSection from "./components/Cards/ScrollableCardSection";
import { formatNumberWithCommas } from "../../services/Helpers/displayTextHelper.js";
import BankDetailsModal from "./components/BankDetailsModal.js";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import { captureException } from "@sentry/react";

const MaturedBonds = () => {
	const [juniorPool, setJuniorPool] = useState([]);
	const [details, setDetails] = useState(null);
	const [maturityDate, setMaturityDate] = useState(null);
	const [reloadData, setReloadData] = useState(false);
	const [activeButton, setActiveButton] = useState({
		Tokenize: { status: false, isDisable: false },
		DistributePay: { status: false, isDisable: false },
		Burn: { status: false, isDisable: false },
	});
	const [loading, setLoading] = useState(true);

	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});
	const [selected, setSelected] = useState(false);
	const { userData } = useContext(LoginContext);

	useEffect(() => {
		if (details?.bondDisplayStatus) {
			if (details?.bondDisplayStatus === "Open") {
				setActiveButton({
					Tokenize: { status: true, isDisable: true },
					DistributePay: { status: false, isDisable: true },
					Burn: { status: false, isDisable: true },
				});
			} else if (details?.bondDisplayStatus === "Subscribed") {
				setActiveButton({
					Tokenize: { status: true, isDisable: false },
					DistributePay: { status: false, isDisable: true },
					Burn: { status: false, isDisable: true },
				});
			} else if (details?.bondDisplayStatus === "Tokenized") {
				setActiveButton({
					Tokenize: { status: false, isDisable: true },
					DistributePay: { status: false, isDisable: true },
					Burn: { status: true, isDisable: true },
				});
			} else if (details?.bondDisplayStatus === "Part Paid") {
				setActiveButton({
					Tokenize: { status: false, isDisable: true },
					DistributePay: { status: true, isDisable: false },
					Burn: { status: false, isDisable: true },
				});
			} else if (details?.bondDisplayStatus === "Matured") {
				setActiveButton({
					Tokenize: { status: false, isDisable: true },
					DistributePay: { status: false, isDisable: true },
					Burn: { status: true, isDisable: true },
				});
			} else if (details?.bondDisplayStatus === "Fully Paid") {
				setActiveButton({
					Tokenize: { status: false, isDisable: true },
					DistributePay: { status: false, isDisable: true },
					Burn: { status: true, isDisable: false },
				});
			}
		}

		// Maturity date
		if (details?.epochCreationDate) {
			var date = new Date(+details.epochCreationDate);
			date.setDate(date.getDate() + details.actualLoanTenure * 30);
			function pad(s) {
				return s < 10 ? "0" + s : s;
			}

			const maturityDisplayDate = [
				pad(date.getDate()),
				pad(date.getMonth() + 1),
				date.getFullYear(),
			].join("/");
			setMaturityDate(maturityDisplayDate);
		}
	}, [details, reloadData]);

	useEffect(() => {
		setLoading(true);
		getAllApprovedBonds(userData?.email, "Matured").then((res) => {
			if (res.success) {
				setJuniorPool(res.opportunities);
			}
			setLoading(false);
		});
	}, [reloadData]);

	useEffect(() => {
		if (details) {
			localStorage.setItem("selectedDataId", details?.Id);
			const data = juniorPool.filter(
				(item, ind) => item.Id === details?.Id
			);
			setDetails(data[0]);
		} else {
			const Id = localStorage.getItem("selectedDataId");

			if (Id === "null" || Id === undefined) {
				setDetails(juniorPool[0]);
			} else {
				const data = juniorPool.filter((item, ind) => item.Id === Id);
				setDetails(data[0]);
			}
		}
	}, [details, juniorPool, reloadData]);

	const handleTokenize = async (bankDetails) => {
		try {
			const res = await createTokenizedBonds(
				{ ...details, email: userData?.email },
				bankDetails
			);

			if (res?.success) {
				setActiveButton({
					Tokenize: { status: true, isDisable: true },
					DistributePay: { status: false, isDisable: true },
					Burn: { status: false, isDisable: true },
				});
			}

			setReloadData((prev) => !prev);
		} catch (error) {
			captureException(error);
		}
	};

	const handleDistribute = async (bankDetails) => {
		try {
			const res = await distributePay(details, bankDetails);
			if (res?.success) {
				setActiveButton({
					Tokenize: { status: false, isDisable: true },
					DistributePay: { status: false, isDisable: true },
					Burn: { status: false, isDisable: true },
				});
			}
			setReloadData((prev) => !prev);
		} catch (error) {
			captureException(error);
		}
	};

	const handleBurn = async (bankDetails) => {
		try {
			const res = await distributePay(
				details,
				bankDetails,
				userData?.email
			);
			if (res?.success) {
				setActiveButton({
					Tokenize: { status: false, isDisable: true },
					DistributePay: { status: false, isDisable: true },
					Burn: { status: true, isDisable: true },
				});
			}
			setReloadData((prev) => !prev);
		} catch (error) {
			captureException(error);
		}
	};

	return (
		<div className={`lg:relative ${loading ? "h-[100vh]" : ""}`}>
			{loading && <Loader />}
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			{selected && (
				<BankDetailsModal
					selected={selected}
					setSelected={setSelected}
					onSubmit={
						activeButton.Tokenize.status
							? handleTokenize
							: activeButton.DistributePay.status
							? handleDistribute
							: handleBurn
					}
				/>
			)}
			<div className={`${loading ? "blur-sm" : ""}`}>
				<div className="flex items-center mb-8">
					<h2 className="text-[1.4rem] lg:text-[1.75rem] w-[50%] font-normal">
						Matured Requests
					</h2>
				</div>

				<div className="flex flex-col gap-5 w-full">
					<div>
						{juniorPool.length === 0 ? (
							<div className="text-center">
								<p className="text-neutral-500 text-lg">
									No data available.
								</p>
							</div>
						) : (
							<ScrollableCardSection>
								{juniorPool.map((juniorPoolData, index) => (
									<PoolCardCustodian
										Id={juniorPoolData?.Id}
										details={details}
										key={juniorPoolData.Id}
										data={juniorPoolData}
										setDetails={setDetails}
									/>
								))}
							</ScrollableCardSection>
						)}
					</div>
				</div>
				<br />
				{details && (
					<div className=" w-[100%] p-5 rounded-md bg-neutral-100 border border-gray-300 px-10">
						<div className="flex justify-between w-[100%]">
							<div className="flex justify-between w-[70%]">
								<div className="w-[35%]">
									<p>Issue Date :</p>
									<p>Tenure :</p>
									<p>Interest Rate :</p>
									<p>Maturity Date :</p>
								</div>
								<div className="w-[35%] mr-48">
									<p>{details?.issueDisplayDate}</p>
									<p>{details?.loanTenure}</p>
									<p>{details?.loanInterest}</p>
									<p>{details?.burningDisplayDate}</p>
								</div>
							</div>

							<div className="float-right mt-20 w-[30%]">
								<div className="py-[0.1rem] w-[60%]">
									{activeButton.Tokenize.status && (
										<button
											disabled={
												activeButton.Tokenize.isDisable
											}
											className={`${
												!activeButton.Tokenize.isDisable
													? "w-[100%] p-5 shadow-2xl py-[0.3rem] bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
													: "w-[100%] shadow-2xl py-[0.3rem]   bg-gradient-to-r bg-neutral-400 cursor-not-allowed  opacity-40 capitalize font-medium border-none rounded-3xl"
											}`}
											onClick={() => setSelected(true)}
										>
											Tokenize
										</button>
									)}
									{activeButton.DistributePay.status && (
										<button
											disabled={
												activeButton.DistributePay
													.isDisable
											}
											className={`${
												!activeButton.DistributePay
													.isDisable
													? "w-[100%] p-5 shadow-2xl py-[0.3rem] bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
													: "w-[100%] shadow-2xl py-[0.3rem]   bg-gradient-to-r bg-neutral-400 cursor-not-allowed  opacity-40 capitalize font-medium border-none rounded-3xl"
											}`}
											onClick={() => setSelected(true)}
										>
											Distribute Pay
										</button>
									)}
									{activeButton.Burn.status && (
										<button
											disabled={
												activeButton.Burn.isDisable
											}
											className={`${
												!activeButton.Burn.isDisable
													? "w-[100%] p-5 shadow-2xl py-[0.3rem] bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
													: "w-[100%] shadow-2xl py-[0.3rem]   bg-gradient-to-r bg-neutral-400 cursor-not-allowed  opacity-40 capitalize font-medium border-none rounded-3xl"
											}`}
											onClick={() => setSelected(true)}
										>
											Burn
										</button>
									)}
								</div>
							</div>
						</div>
						<br />

						{details?.trx?.length > 0 && (
							<div className="relative overflow-x-auto rounded-md border border-gray-300">
								<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
									<thead className="text-xs text-gray-700 uppercase bg-[#E7EAEE] dark:bg-gray-700 dark:text-gray-400 border-b-[1px] border-gray-300">
										<tr>
											<th
												scope="col"
												className="px-6"
												align="center"
											>
												Subscriber name
											</th>
											<th
												scope="col"
												className=" border-l border-gray-300"
												align="center"
												colSpan="2"
											>
												<div className="border-b w-full py-2 border-gray-300">
													Subscription
												</div>
												<div className="w-full flex justify-between px-6 py-2">
													<span className="w-1/2">
														Amount
													</span>
													<span className="border-l w-1/2">
														%
													</span>
												</div>
											</th>
											<th
												scope="col"
												className="px-6 border-l border-gray-300"
												align="center"
											>
												Subscribed on
											</th>
											<th
												scope="col"
												className="px-6 border-l border-gray-300"
												align="center"
											>
												Status
											</th>
										</tr>
									</thead>
									<tbody>
										{details?.trx.map((item) => {
											return (
												<tr
													className="bg-neutral-100 border-b border-gray-300 dark:bg-gray-800 dark:border-gray-700"
													align="center"
													key={item?.Id}
												>
													<th
														scope="row"
														className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
													>
														{item?.name}
													</th>
													<td className="px-6 py-4 border-l border-gray-300 ">
														{formatNumberWithCommas(
															item?.amount
														)}
													</td>
													<td className="px-6 py-4 border-l border-gray-300">
														{item?.subPercentage}
													</td>
													<td className="px-6 py-4 border-l border-gray-300">
														{item?.investedOn}
													</td>
													<td className="px-6 py-4 border-l border-gray-300">
														{item?.status}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
						{details?.repayments?.length > 0 && (
							<div className="mt-6 relative overflow-x-auto rounded-md border border-gray-300">
								<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
									<thead className="text-xs text-gray-700 uppercase bg-[#E7EAEE] dark:bg-gray-700 dark:text-gray-400 border-b-[1px] border-gray-300">
										<tr>
											<th
												scope="col"
												className="px-6 py-4"
												align="center"
											>
												Repayment Amount
											</th>
											<th
												scope="col"
												className=" border-l border-gray-300"
												align="center"
											>
												Repayment Type
											</th>
											<th
												scope="col"
												className="px-6 border-l border-gray-300"
												align="center"
											>
												Repayment Date
											</th>
											<th
												scope="col"
												className="px-6 border-l border-gray-300"
												align="center"
											>
												Status
											</th>
										</tr>
									</thead>
									<tbody>
										{details?.repayments.map((item) => {
											return (
												<tr
													className="bg-neutral-100 border-b border-gray-300 dark:bg-gray-800 dark:border-gray-700"
													align="center"
													key={item?.Id}
												>
													<td className="px-6 py-4">
														{formatNumberWithCommas(
															item?.amount
														)}
													</td>
													<td className="px-6 py-4 border-l border-gray-300 ">
														{item?.repaymentType}
													</td>
													<td className="px-6 py-4 border-l border-gray-300">
														{item?.transactionDate}
													</td>
													<td className="px-6 py-4 border-l border-gray-300">
														Completed
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default MaturedBonds;
