import React, { useState, useEffect, useRef, useContext } from "react";
import { Formik } from "formik";
import TextField from "../../uiTools/Inputs/TextField";
import InputGroup from "../../uiTools/Inputs/InputGroup";
import TextArea from "../../uiTools/Inputs/TextArea";
import FileUploader from "../../uiTools/FileUploader/FileUploader";
import { useLocation, useNavigate } from "react-router-dom";
import GradientButton from "../../uiTools/Button/GradientButton";
import Loader from "../../uiTools/Loading/Loader";
import { captureException, captureMessage } from "@sentry/react";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import ProcessingModal from "../Borrower/Components/Modal/FileUpload/ProcessingModal";
import axiosHttpService from "../../services/axioscall";
import Joi from "joi";
import { joiFormikAdapter } from "joi-formik-adapter";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import { uploadFiles } from "../../services/Helpers/fileHelper";

const URL =
	/^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

const EditCustodianProfile = () => {
	const navigate = useNavigate();

	const [fileErr, setFileErr] = useState({
		bip: false,
		bap: false,
		bicp: false,
	});

	const [profileState, setProfileState] = useState(null);
	const [hasKey, setHasKey] = useState();
	const [loading, setLoading] = useState();
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});
	const [logoFile, setLogoFile] = useState();
	const [businessIdentityFiles, setBusinessIdentityFiles] = useState();
	const [businessAddressFiles, setBusinessAddressFiles] = useState();
	const [kycFiles, setKycFiles] = useState();
	const [businessIncorporationFiles, setBusinessIncorporationFiles] =
		useState();
	const [businessLicenseFiles, setBusinessLicenseFiles] = useState();

	const [uploading, setUploading] = useState(false);
	const [fileUploadStatus, setFileUploadStatus] = useState([]);
	const [logoError, setLogoError] = useState(false);
	const [checkLicense, setcheckLicense] = useState({
		err: false,
		msg: "",
	});

	const [lincenseText, setLincenseText] = useState("");
	const [lincenseFile, setLincenseFile] = useState(false);
	const { userData, login } = useContext(LoginContext);

	const location = useLocation();
	const oldBrJson = location.state;

	let logoFileCID = useRef();
	let kycFilesCID = useRef();
	let businessIdFilesCID = useRef();
	let businessLicFilesCID = useRef();
	let businessAddFilesCID = useRef();
	let businessIncoFilesCID = useRef();

	let allowSubmit = true;

	const validationSchema = Joi.object({
		companyName: Joi.string().trim().required().label("Company Name"),
		companyRepName: Joi.string()
			.trim()
			.required()
			.label("Company Representative Name"),
		companyBio: Joi.string().trim().required().label("Company Bio"),
		bizIdFileName: Joi.string()
			.trim()
			.required("File name is required")
			.label("Business Identity Proof"),
		bizAddFileName: Joi.string()
			.trim()
			.required("File name is required")
			.label("Business Address Proof"),
		bizLicFileName: Joi.string().trim().label("Business License Proof"),
		bizIncoFileName: Joi.string()
			.trim()
			.required("File name is required")
			.label("Business Incorporation Proof"),
		kycFileName: Joi.string()
			.required("File name is required")
			.label("KYC"),
		website: Joi.string()
			.regex(URL, "valid URL")
			.trim()
			.required("Website is required")
			.label("Website"),
		email: Joi.string()
			.email({ tlds: false })
			.trim()
			.required()
			.label("Email Address"),
		twitter: Joi.string().regex(URL, "valid URL").trim().label("Twitter"),
		linkedin: Joi.string().regex(URL, "valid URL").trim().label("Linkedin"),
	});

	useEffect(() => {
		if (location.state) {
			setProfileState(location.state);
			setHasKey(
				location.state ? "businessLicFile" in location.state : true
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialValues = {
		companyName: profileState ? profileState.companyName : "",
		companyRepName: profileState ? profileState.companyRepName : "",
		companyBio: profileState ? profileState.companyBio : "",
		bizIdFileName: profileState
			? profileState.businessIdFile.businessIdDocName
			: "",
		bizAddFileName: profileState
			? profileState.businessAddFile.businessAddDocName
			: "",
		bizLicFileName:
			profileState && hasKey
				? profileState.businessLicFile.businessLicDocName
				: "",
		bizIncoFileName: profileState
			? profileState.businessIncoFile.businessIncoDocName
			: "",
		kycFileName: profileState ? profileState?.kycFile?.kycDocName : "",
		website: profileState ? profileState.website : "",
		email: profileState ? profileState.email : "",
		twitter: profileState ? profileState.twitter : "",
		linkedin: profileState ? profileState.linkedin : "",
	};

	useEffect(() => {
		if (profileState) {
			setLoading(true);
			const {
				businessIdFile: IdFile,
				businessAddFile: AddFile,
				businessIncoFile: IncoFile,
				businessLicFile: LicFile,
				companyLogoFile: LogoFile,
			} = profileState;

			if (hasKey) {
				businessLicFilesCID.current = LicFile.businessLicFileCID;
			}

			logoFileCID.current = LogoFile.businessLogoFileCID;
			businessIdFilesCID.current = IdFile.businessIdFileCID;

			businessAddFilesCID.current = AddFile.businessAddFileCID;
			businessIncoFilesCID.current = IncoFile.businessIncoFileCID;
			setLoading(false);
		}
		checkFunction(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profileState]);

	const checkEdited = (brJson) => {
		if (location.state) {
			if (JSON.stringify(brJson) === JSON.stringify(oldBrJson)) {
				return (allowSubmit = false);
			} //return setAllowSubmit(false);
		}
	};

	const validations = () => {
		// setLogoError(false);

		if (!logoFile && !location.state) setLogoError(true);

		const tempFileStatus = {
			bip: fileErr.bip,
			bap: fileErr.bap,
			bicp: fileErr.bicp,
		};

		if (!location.state) {
			if (!businessIdentityFiles) tempFileStatus.bip = true;

			if (!businessAddressFiles) tempFileStatus.bap = true;

			if (!businessIncorporationFiles) tempFileStatus.bicp = true;
		}

		setFileErr(tempFileStatus);

		if (
			!(
				businessIdentityFiles &&
				businessIncorporationFiles &&
				businessAddressFiles &&
				logoFile
			) &&
			!location.state
		) {
			setUploading(false);
		}
	};

	const onLogoFileUpload = (files) => {
		setLogoFile(files);
		setLogoError(false);
	};

	const onBusinessIdentityFilesUpload = (files) => {
		setBusinessIdentityFiles(files);
		setFileErr((prev) => {
			return {
				...prev,
				bip: false,
			};
		});
	};
	const onBusinessAddressFilesUpload = (files) => {
		setBusinessAddressFiles(files);
		setFileErr((prev) => {
			return {
				...prev,
				bap: false,
			};
		});
	};
	const onBusinessIncorporationFilesUpload = (files) => {
		setBusinessIncorporationFiles(files);
		setFileErr((prev) => {
			return {
				...prev,
				bicp: false,
			};
		});
	};
	const onBusinessLicenseFilesUpload = (files) => {
		setBusinessLicenseFiles(files);
		checkFunction(files);
	};

	const onKycFilesUpload = (files) => {
		setKycFiles(files);
		setFileErr((prev) => {
			return {
				...prev,
				bip: false,
			};
		});
	};

	const uploadCustodianData = async (formData) => {
		setUploading(true);
		try {
			const {
				companyName,
				companyRepName,
				companyBio,
				bizIdFileName,
				bizAddFileName,
				bizLicFileName,
				bizIncoFileName,
				kycFileName,
				website,
				email,
				twitter,
				linkedin,
			} = formData;

			validations();
			let key = false;
			let tempFileStatus = [];
			if (businessLicenseFiles) key = true;

			if (!logoError || !fileErr.bip || !fileErr.bap || !fileErr.bicp) {
				if (logoFile && logoFile.length) {
					let logoFileObj = {
						fileName: logoFile[0].name,
						progress: 0,
						status: "Pending",
					};

					tempFileStatus.push(logoFileObj);
				}

				if (businessIdentityFiles && businessIdentityFiles.length) {
					let businessIdentityFilesObj = {
						fileName: businessIdentityFiles[0].name,
						progress: 0,
						status: "Pending",
					};

					tempFileStatus.push(businessIdentityFilesObj);
				}

				if (businessAddressFiles && businessAddressFiles.length) {
					let businessAddressFilesObj = {
						fileName: businessAddressFiles[0].name,
						progress: 0,
						status: "Pending",
					};

					tempFileStatus.push(businessAddressFilesObj);
				}

				if (
					businessIncorporationFiles &&
					businessIncorporationFiles.length
				) {
					let businessIncorporationFilesObj = {
						fileName: businessIncorporationFiles[0].name,
						progress: 0,
						status: "Pending",
					};

					tempFileStatus.push(businessIncorporationFilesObj);
				}

				if (businessLicenseFiles && businessLicenseFiles.length) {
					let businessLicenseFilesObj = {
						fileName: businessLicenseFiles[0].name,
						progress: 0,
						status: "Pending",
					};

					tempFileStatus.push(businessLicenseFilesObj);
				}

				setFileUploadStatus(tempFileStatus);

				if (
					businessIdentityFiles ||
					businessAddressFiles ||
					businessIncorporationFiles ||
					businessLicenseFiles ||
					logoFile
				)
					if ((logoFile && logoFile.length) || profileState) {
						logoFileCID.current = await uploadFiles(
							userData?.Id,
							logoFile ? logoFile : profileState.companyLogoFile
						);
					}
				if (
					(businessIdentityFiles && businessIdentityFiles.length) ||
					profileState
				) {
					businessIdFilesCID.current = await uploadFiles(
						userData?.Id,
						businessIdentityFiles
							? businessIdentityFiles
							: profileState.businessIdFile
					);
				}
				if (
					(businessAddressFiles && businessAddressFiles.length) ||
					profileState
				) {
					businessAddFilesCID.current = await uploadFiles(
						userData?.Id,
						businessAddressFiles
							? businessAddressFiles
							: profileState.businessAddFile
					);
				}
				if (
					(businessIncorporationFiles &&
						businessIncorporationFiles.length) ||
					profileState
				) {
					businessIncoFilesCID.current = await uploadFiles(
						userData?.Id,
						businessIncorporationFiles
							? businessIncorporationFiles
							: profileState.businessIncoFile
					);
				}
				if (
					(businessLicenseFiles &&
						businessLicenseFiles.length &&
						(hasKey || key)) ||
					(profileState && (hasKey || key))
				) {
					businessLicFilesCID.current = await uploadFiles(
						userData?.Id,
						businessLicenseFiles
							? businessLicenseFiles
							: profileState.businessLicFile
					);
				}
				if ((kycFiles && kycFiles.length) || profileState) {
					kycFilesCID.current = await uploadFiles(
						userData?.Id,
						kycFiles ? kycFiles : profileState.kycFile
					);
				}
				// Prepare a json file with custodian data
				let custodianJsonData = {
					companyName: companyName,
					companyRepName: companyRepName,
					companyBio: companyBio,
					companyLogoFile: {
						businessLogoFileName: logoFile
							? logoFile[0].name
							: profileState.companyLogoFile.businessLogoFileName,
						businessLogoFileCID: logoFile
							? logoFileCID.current
							: profileState.companyLogoFile.businessLogoFileCID,
					},
					businessIdFile: {
						businessIdDocName: bizIdFileName
							? bizIdFileName
							: profileState.businessIdFile.businessIdDocName,
						businessIdFileCID: businessIdentityFiles
							? businessIdFilesCID.current
							: profileState.businessIdFile.businessIdFileCID,
						businessIdFileName: businessIdentityFiles
							? businessIdentityFiles[0].name
							: profileState.businessIdFile.businessIdFileName,
					},
					businessAddFile: {
						businessAddDocName: bizAddFileName
							? bizAddFileName
							: profileState.businessAddFile.businessAddDocName,
						businessAddFileCID: businessAddressFiles
							? businessAddFilesCID.current
							: profileState.businessAddFile.businessAddFileCID,
						businessAddFileName: businessAddressFiles
							? businessAddressFiles[0].name
							: profileState.businessAddFile.businessAddFileName,
					},
					businessIncoFile: {
						businessIncoDocName: bizIncoFileName
							? bizIncoFileName
							: profileState.businessIncoFile.businessIncoDocName,
						businessIncoFileCID: businessIncorporationFiles
							? businessIncoFilesCID.current
							: profileState.businessIncoFile.businessIncoFileCID,
						businessIncoFileName: businessIncorporationFiles
							? businessIncorporationFiles[0].name
							: profileState.businessIncoFile
									.businessIncoFileName,
					},
					kycFile: {
						kycDocName: kycFileName
							? kycFileName
							: profileState.kycFile.kycDocName,
						kycFileCID: kycFiles
							? kycFilesCID.current
							: profileState.kycFile.kycFileCID,
						kycFileName: kycFiles
							? kycFiles[0].name
							: profileState.kycFile.kycFileName,
					},
					website: website,
					email: email,
					twitter: twitter,
					linkedin: linkedin,
				};

				if ((businessLicenseFiles || profileState) && (hasKey || key)) {
					const licenseFile = {
						businessLicFile: {
							businessLicDocName: bizLicFileName
								? bizLicFileName
								: profileState
								? profileState.businessLicFile
										.businessLicDocName
								: null,
							businessLicFileCID: businessLicenseFiles
								? businessLicFilesCID.current
								: profileState?.businessLicFile
										.businessLicFileCID,
							businessLicFileName: businessLicenseFiles
								? businessLicenseFiles[0].name
								: profileState?.businessLicFile
										.businessLicFileName,
						},
					};
					custodianJsonData = {
						...custodianJsonData,
						...licenseFile,
					};
				}
				checkEdited(custodianJsonData);

				if (allowSubmit) {
					// Save profile data
					try {
						const options = {
							url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/borrower/createProfile`,
							method: "POST",
							headers: {},
							data: {
								email: userData.email,
								role: userData.role,
								Id: userData.Id,
								profile: JSON.stringify(custodianJsonData),
								kycStatus: userData.kycStatus,
								action: profileState
									? "ProfileUpdate"
									: "ProfileCreation",
							},
						};
						const result = await axiosHttpService(options);
					} catch (error) {
						captureException(error);
					}

					login({
						...userData,
						profile: JSON.stringify(custodianJsonData),
					});
					captureMessage(
						"Custodian profile updated successfully on blockchain",
						"info"
					);
					navigate("/custodianDashboard/custodianProfile", {
						state: custodianJsonData,
					});
				} else {
					navigate("/custodianDashboard/custodianProfile", {
						state: custodianJsonData,
					});
				}
			}
		} catch (error) {
			captureException(error);
			setUploading(false);
			setErrormsg({ status: true, msg: error.message });
		}
	};

	function checkFunction(file) {
		if (file || businessLicFilesCID.current) {
			setLincenseFile(true);
		}
	}

	useEffect(() => {
		if (lincenseText && lincenseFile) {
			setcheckLicense({
				err: false,
				msg: "",
			});
		} else {
			if (!lincenseFile && lincenseText) {
				setcheckLicense({
					err: true,
					msg: "File required",
				});
			} else if (!lincenseText && lincenseFile) {
				setcheckLicense({
					err: true,
					msg: "File name is required",
				});
			} else {
				setcheckLicense({
					err: false,
					msg: "",
				});
			}
		}
	}, [lincenseText, lincenseFile]);

	return (
		<div className={`${loading ? "relative" : ""}`}>
			{
				<ProcessingModal
					setUploading={setUploading}
					uploading={uploading}
					fileUploadStatus={fileUploadStatus}
				/>
			}
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			{loading && <Loader />}

			<Formik
				initialValues={initialValues}
				onSubmit={(values) => uploadCustodianData(values)}
				validationSchema={joiFormikAdapter(validationSchema)}
				enableReinitialize={true}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
				}) => (
					<>
						{setLincenseText(values.bizLicFileName)}
						<div className={loading ? "blur-sm" : ""}>
							<div className="font-normal">
								<div className="">
									<h2 className="text-[1.4375rem] ">
										Custodian Details
									</h2>

									<div className="mt-2 flex flex-col gap-2">
										<div className=" flex flex-col gap-2 md:flex-row md:flex-wrap xl:justify-between">
											<FileUploader
												filetype={"image/*"}
												label="Business Logo *"
												className="md:w-[45%] xl:w-[30%]"
												handleFile={onLogoFileUpload}
												fileName={
													profileState
														? profileState
																.companyLogoFile
																.businessLogoFileName
														: null
												}
												error={
													logoError
														? "Please upload logo!"
														: null
												}
											/>
											<TextField
												name="companyName"
												label="Business Name *"
												placeholder="Enter Business Name"
												className="md:w-[45%] md:ml-auto xl:ml-0 xl:w-[30%]"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.companyName}
												error={
													touched.companyName &&
													errors.companyName
														? errors.companyName
														: null
												}
											/>
											<TextField
												name="companyRepName"
												label="Business Representative Name *"
												placeholder="Enter Name"
												className="md:w-[45%] xl:w-[30%]"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.companyRepName}
												error={
													touched.companyRepName &&
													errors.companyRepName
														? errors.companyRepName
														: null
												}
											/>
										</div>

										<div className="">
											<TextArea
												name="companyBio"
												label="Bio *"
												placeholder="Summary About the Organization/Business"
												className="w-full"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.companyBio}
												error={
													touched.companyBio &&
													errors.companyBio
														? errors.companyBio
														: null
												}
											/>
										</div>
									</div>
								</div>

								<div className="my-8 flex flex-col gap-3">
									<h2 className="text-[1.4375rem] font-normal">
										KYB Documents
									</h2>

									<InputGroup
										caption="Business Identity Proof *"
										name="bizIdFileName"
										value={values.bizIdFileName}
										onChangeText={handleChange}
										onChange={onBusinessIdentityFilesUpload}
										onBlur={handleBlur}
										error={
											errors.bizIdFileName &&
											touched.bizIdFileName
												? errors.bizIdFileName
												: fileErr.bip
												? "File required"
												: ""
										}
										fileName={
											profileState
												? profileState.businessIdFile
														.businessIdFileName
												: ""
										}
									/>
									<InputGroup
										caption="Business Address Proof *"
										name="bizAddFileName"
										value={values.bizAddFileName}
										onChangeText={handleChange}
										onChange={onBusinessAddressFilesUpload}
										onBlur={handleBlur}
										error={
											errors.bizAddFileName &&
											touched.bizAddFileName
												? errors.bizAddFileName
												: fileErr.bap
												? "File required"
												: ""
										}
										fileName={
											profileState
												? profileState.businessAddFile
														.businessAddFileName
												: ""
										}
									/>
									<InputGroup
										caption="Business Incorporation Proof *"
										name="bizIncoFileName"
										value={values.bizIncoFileName}
										onChangeText={handleChange}
										onChange={
											onBusinessIncorporationFilesUpload
										}
										onBlur={handleBlur}
										error={
											errors.bizIncoFileName &&
											touched.bizIncoFileName
												? errors.bizIncoFileName
												: fileErr.bicp
												? "File required"
												: ""
										}
										fileName={
											profileState
												? profileState.businessIncoFile
														.businessIncoFileName
												: ""
										}
									/>
									<InputGroup
										caption="Business License Proof"
										name="bizLicFileName"
										value={values.bizLicFileName}
										onChangeText={handleChange}
										onChange={onBusinessLicenseFilesUpload}
										error={
											checkLicense.err
												? checkLicense.msg
												: ""
										}
										onBlur={handleBlur}
										fileName={
											profileState && hasKey
												? profileState.businessLicFile
														.businessLicFileName
												: ""
										}
									/>
								</div>
								<div className="my-8 flex flex-col gap-3">
									<h2 className="text-[1.4375rem] font-normal">
										KYC Documents
									</h2>
									<InputGroup
										caption="KYC *"
										name="kycFileName"
										value={values?.kycFileName}
										onChangeText={handleChange}
										onChange={onKycFilesUpload}
										onBlur={handleBlur}
										error={
											errors?.kycFileName &&
											touched?.kycFileName
												? errors?.kycFileName
												: fileErr.bicp
												? "File required"
												: ""
										}
										fileName={
											profileState
												? profileState?.kycFile
														?.kycFileName
												: ""
										}
									/>
								</div>
								<div className="mb-6">
									<h2 className="text-[1.4375rem] font-normal">
										Socials
									</h2>

									<div className="flex flex-col md:flex-row md:flex-wrap md:justify-between gap-3">
										<TextField
											name="website"
											label="Website *"
											placeholder="Enter Website URL"
											className="w-full md:w-[48%]"
											onChange={handleChange}
											value={values.website}
											onBlur={handleBlur}
											error={
												touched.website &&
												errors.website
													? errors.website
													: ""
											}
										/>
										<TextField
											name="email"
											label="Email Address *"
											placeholder="Enter Email Address"
											className="w-full md:w-[48%]"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.email}
											error={
												touched.email && errors.email
													? errors.email
													: ""
											}
										/>
										<TextField
											name="twitter"
											label="Twitter"
											placeholder="Enter Twitter URL"
											className="w-full md:w-[48%]"
											onChange={handleChange}
											value={values.twitter}
											onBlur={handleBlur}
											error={
												touched.twitter &&
												errors.twitter
													? errors.twitter
													: ""
											}
										/>
										<TextField
											name="linkedin"
											label="LinkedIn"
											placeholder="Enter LinkedIn URL"
											className="w-full md:w-[48%]"
											onChange={handleChange}
											value={values.linkedin}
											onBlur={handleBlur}
											error={
												touched.linkedin &&
												errors.linkedin
													? errors.linkedin
													: ""
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<p>Fields marked with an asterisk (*) are mandatory.</p>
						<div className="my-10 font-normal flex flex-col gap-5 md:gap-8 md:flex-row md:justify-center">
							<button
								onClick={() =>
									navigate(
										"/custodianDashboard/custodianProfile",
										{
											state: oldBrJson,
										}
									)
								}
								className="border-2 border-neutral-500 rounded-3xl py-3 md:w-[40%] xl:w-[min(40%,25rem)]"
							>
								Exit
							</button>
							<GradientButton
								className="w-full md:w-[40%] xl:w-[min(40%,25rem)]"
								onClick={() => {
									validations();
									if (!checkLicense.err) {
										handleSubmit();
									}
								}}
							>
								Save and Exit
							</GradientButton>
						</div>
					</>
				)}
			</Formik>
		</div>
	);
};

export default EditCustodianProfile;
