import React, { useState } from "react";
import Stepper from "../../../../uiTools/Stepper/Stepper";
import Details from "../../EditBondParamsForm/Steps/Details";
import Final from "../../EditBondParamsForm/Steps/Final";
import img from "../../../../assets/loading.png";
import axiosHttpService from "../../../../services/axioscall";

const EditBondParamsModal = ({
	setSelected,
	handleForm,
	bond,
	setErrormsg,
	setSuccessmsg,
	title,
	steps,
	setLoading,
	setOpDetails,
}) => {
	const [formData, setFormData] = useState({
		loan_amount: "",
		loan_interest: "",
		loan_tenure: "",
	});
	const [currentStep, setCurrentStep] = useState(1);

	const displayStep = (step) => {
		switch (step) {
			case 1:
				return (
					<Details
						handleNext={handleNext}
						formData={formData}
						handleForm={handleForm}
						bond={bond}
					/>
				);
			case 2:
				return (
					<Final
						handlePrev={handlePrev}
						finalSubmit={finalSubmit}
						formData={formData}
					/>
				);
			default:
		}
	};

	const finalSubmit = async (data) => {
		setSelected(false);
		setLoading(true);
		let { loan_amount, loan_interest, loan_tenure } = data;
		setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
		let bondObj = {
			loan_amount: loan_amount.toString(),
			loan_interest: loan_interest.toString(),
			loan_tenure: loan_tenure * 30,
		};
		console.log(data);
		console.log(bondObj);
		try {
			const options = {
				url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/bond/createBond`,
				method: "POST",
				headers: {},
				data: {
					...bond,
					...bondObj,
				},
			};
			const result = await axiosHttpService(options);
			console.log("createGreenBond ", result);
			if (result.code === 201) {
				console.log("success in createGreenBond");
				setOpDetails((prev) => {
					let updatedDetails = {
						...prev,
						...bondObj,
					};
					return updatedDetails;
				});
				setSuccessmsg({
					status: true,
					msg: "GreenBond Details Updated Successfully!",
				});
			} else {
				console.log("error occured in createGreenBond");
				setErrormsg({
					status: true,
					msg: "Error occured! Failed to Update GreenBond Details",
				});
			}
		} catch (error) {
			console.log(error);
			setErrormsg({
				status: true,
				msg: "Error occured! Failed to Update GreenBond Details",
			});
		}
		// setUpdateRepayment(Math.random());
		setLoading(false);
	};

	const handleNext = (newData, value) => {
		if (value === true) {
			let temp = { ...formData, ...newData };
			setFormData(temp);
		} else {
			setFormData((prev) => ({ ...prev, ...newData }));
		}
		setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
	};

	const handlePrev = (newData, value) => {
		if (value === true) {
			let temp = { ...formData, ...newData };
			setFormData(temp);
		} else {
			setFormData((prev) => ({ ...prev, ...newData }));
		}
		setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
		displayStep(currentStep);
	};

	return (
		<div>
			<input
				type="checkbox"
				id="editForm-modal"
				className="modal-toggle"
			/>
			<div className="modal block backdrop-filter backdrop-brightness-[100%] backdrop-opacity-100 dark:backdrop-brightness-[40%] backdrop-blur-xl md:flex">
				<div className="w-screen h-full modal-box max-w-full max-h-full rounded-none md:h-auto md:w-1/2 md:max-w-4xl bg-white dark:bg-[#14171F]   md:rounded-[16px]">
					<div className="py-5 md:-mt-2 mb-5 md:py-0 flex justify-between items-center text-center md:border-b-2 md:border-b-[#292C33] -mx-5">
						<h3 className="font-medium text-lg  md:border-b-[#292C33] ml-5 md:pb-3 flex gap-2 items-center">
							<img src={img} className="w-9 h-9" alt="" /> {title}
						</h3>
						<label
							htmlFor="editForm-modal"
							className="btn btn-ghost font-bold w-14 md:pb-3"
							onClick={handleForm}
						>
							<svg
								width="19"
								height="19"
								viewBox="0 0 19 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									className="dark:fill-white fill-black"
									d="M18.4596 3.44507C18.8501 3.05454 18.8501 2.42138 18.4596 2.03085L17.1358 0.707107C16.7453 0.316583 16.1121 0.316583 15.7216 0.707107L9.58333 6.84538L3.44507 0.707107C3.05454 0.316583 2.42138 0.316583 2.03085 0.707107L0.707107 2.03085C0.316583 2.42138 0.316582 3.05454 0.707107 3.44507L6.84538 9.58333L0.707107 15.7216C0.316583 16.1121 0.316583 16.7453 0.707107 17.1358L2.03085 18.4596C2.42138 18.8501 3.05454 18.8501 3.44507 18.4596L9.58333 12.3213L15.7216 18.4596C16.1121 18.8501 16.7453 18.8501 17.1358 18.4596L18.4596 17.1358C18.8501 16.7453 18.8501 16.1121 18.4596 15.7216L12.3213 9.58333L18.4596 3.44507Z"
									fill="white"
								/>
							</svg>
						</label>
					</div>

					<div className="md:-mx-5 -mx-2 pb-2 md:mt-4">
						<Stepper steps={steps} currentStep={currentStep} />
						<div>{displayStep(currentStep)}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditBondParamsModal;
