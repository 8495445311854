import axiosHttpService from "../axioscall";
import { captureException } from "@sentry/react";
import { TransactionType } from "../Helpers/transactionsHelper";
import { getGreenBondsByField } from "./greenBondCalls";

export const createTransaction = async (data) => {
	let options = {
		url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/transaction/createTx`,
		method: "POST",
		headers: {},
		data: {
			...data,
		},
	};
	const res = await axiosHttpService(options);
	return res;
};

export const getTransactionByField = async (field, value) => {
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/transaction/getTx`,
			method: "POST",
			headers: {},
			data: {
				field: field,
				value: value,
			},
		};

		const transactions = await axiosHttpService(options);
		return transactions;
	} catch (error) {
		captureException(error);
	}
};

export const getAllTransactions = async (pageSize, bookmark) => {
	try {
		let options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/transaction/getAllTx`,
			method: "POST",
			headers: {},
			data: {
				pageSize: pageSize,
				bookmark: bookmark,
			},
		};
		const transactions = await axiosHttpService(options);
		return transactions;
	} catch (error) {
		captureException(error);
	}
};

export const getAllCustodianAssignedTransactions = async (email) => {
	try {
		let bonds = await getGreenBondsByField("custodian", email);
		bonds = bonds?.res?.data?.GreenBond;
		let bondIds = [];
		if (bonds) {
			bonds.forEach((element) => {
				bondIds.push(element.Id);
			});
		}

		let transactions = [];
		for (const bonsId of bondIds) {
			let txs = await getTransactionByField("bondId", bonsId);
			if (txs?.res?.records) {
				txs = txs.res.records;
				transactions.push(...txs);
			}
		}
		transactions = transactions.map((tx) => tx.data);
		if (bondIds.length) {
			transactions = transactions.filter((tx) =>
				bondIds.includes(tx.bondId)
			);
		}
		transactions = transactions.map((tx) => {
			if (tx?.investorTransactionType !== undefined) {
				tx.transactionType =
					tx?.investorTransactionType === 0
						? TransactionType.Invest
						: TransactionType.Payout;
			} else if (tx?.borrowerTransactionType !== undefined) {
				tx.transactionType =
					tx?.borrowerTransactionType === 0
						? TransactionType.Borrow
						: TransactionType.Repay;
			}
			return tx;
		});
		return { transactions, success: true };
	} catch (error) {
		captureException(error);
		return { transactions: [], success: false };
	}
};
