import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { signInWithEmail } from "../../services/BackendConnectors/userConnectors/userLogin";
import { Role } from "../../services/BackendConnectors/userConnectors/userLogin";
import { useContext } from "react";
import { LoginContext } from "../../LoginProvider/LoginProvider";

// lender: 0,
// borrower: 1,
// custodian: 2,
// regulator: 3,

const LoginModal = ({ isLoginModal, setIsLoginModal, setIsSelected }) => {
	const path = useNavigate();
	const { login } = useContext(LoginContext);
	const [stateNum, setStateNum] = useState(null);
	const [isSubmit, setisSubmit] = useState(false);
	const [isDisable, setIsDisable] = useState(true);
	const [loginResponse, setloginResponse] = useState(false);

	useEffect(() => {
		if (isLoginModal.title === "Subscriber") {
			setStateNum(Role.Subscriber);
		} else if (isLoginModal.title === "Issuer") {
			setStateNum(Role.Issuer);
		} else if (isLoginModal.title === "Diligence") {
			setStateNum(Role.DiligenceExpert);
		} else if (isLoginModal.title === "Custodian") {
			setStateNum(Role.Custodian);
		} else if (isLoginModal.title === "Regulator") {
			setStateNum(Role.Regulator);
		}
	}, [isLoginModal]);

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validate: (values) => {
			const errors = {};

			if (!values.email) {
				errors.email = "Email is required";
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
			) {
				errors.email = "Invalid email address";
			}

			if (!values.password) {
				errors.password = "Password is required";
			}

			return errors;
		},
		onSubmit: async (values) => {
			// Handle login logic here
			setisSubmit(true);

			try {
				const res = await signInWithEmail(
					values.email,
					values.password,
					stateNum
				);

				console.log(res, "😎1");

				const loginData = res;

				if (res) {
					setloginResponse({
						status: true,
						msg: "successfully login...",
					});
					login(loginData);
					setisSubmit(false);
					setIsLoginModal({
						status: false,
						title: "",
						url: "",
					});
					path(isLoginModal.url);
				}
			} catch (error) {
				console.log(error, "😎😎");
				if (error.code === "auth/user-disabled") {
					setloginResponse({
						status: false,
						msg: "Your account has been disabled!",
					});
				} else {
					setloginResponse({
						status: false,
						msg: "Invalid email or password...",
					});
				}
				setisSubmit(false);
			}
		},
	});

	useEffect(() => {
		if (formik.values.email && formik.values.password) {
			setIsDisable(false);
		} else {
			setIsDisable(true);
		}

		if (isSubmit) {
			setIsDisable(true);
		}
	}, [formik.values.email, formik.values.password, isSubmit]);
	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={isLoginModal.status}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal"
			>
				<div className="relative p-4 w-full max-w-md h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<h3 className="font-normal text-xl">{`${isLoginModal.title} Login`}</h3>

							<button
								type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-3"
								data-modal-toggle="popup-modal"
								onClick={() => {
									setIsLoginModal({
										status: false,
										title: "",
										url: "",
									});
								}}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>

						<div className="p-6  overflow-hidden">
							{/* <h2 className="font-normal">{`${isLoginModal.title} Login`}</h2> */}

							<form onSubmit={formik.handleSubmit}>
								<div className="md:gap-3">
									<div
										className={`w-full  md:mr-2 mb-5 md:mb-0`}
									>
										<label className="label text-lg">
											<span className="text-black dark:text-white">
												Email
											</span>
										</label>
										<input
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name="email"
											value={formik.values.email}
											type="text"
											placeholder="Enter Email"
											className="input input-bordered w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
											style={{
												borderWidth: "1px",
												borderRadius: "8px",
											}}
										/>
										{formik.touched.email &&
											formik.errors.email && (
												<p
													style={{
														color: "red",
														margin: "0px",
													}}
												>
													<small>
														{formik.errors.email}
													</small>
												</p>
											)}
									</div>

									<div
										className={`w-full  md:mr-2 mb-5 md:mb-0`}
									>
										<label className="label text-lg">
											<span className="text-black dark:text-white">
												Password
											</span>
										</label>
										<input
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name="password"
											value={formik.values.password}
											type="password"
											placeholder="Enter Password"
											className="input input-bordered w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
											style={{
												borderWidth: "1px",
												borderRadius: "8px",
											}}
										/>
										{formik.touched.password &&
											formik.errors.password && (
												<p
													style={{
														color: "red",
														margin: "0px",
													}}
												>
													<small>
														{formik.errors.password}
													</small>
												</p>
											)}
									</div>
								</div>
								<div className="mt-2 mb-0 ml-1 ">
									<label
										className="text-sm text-[#4B74FF] cursor-pointer"
										onClick={() => {
											setIsSelected(true);
											setIsLoginModal({
												status: false,
												title: "",
												url: "",
											});
										}}
									>
										Forgot Password
									</label>
								</div>

								{loginResponse && !loginResponse?.status && (
									<p
										style={{
											color: "red",
											margin: "0px",
										}}
									>
										<small>{loginResponse.msg}</small>
									</p>
								)}
								<div className="mt-6 flex  justify-around">
									<button
										onClick={() => {
											setIsLoginModal({
												status: false,
												title: "",
												url: "",
											});
										}}
										data-modal-toggle="popup-modal"
										type="button"
										className={`py-2 px-12 capitalize font-medium border-2 border-neutral-500 rounded-3xl hover:bg-gray-50`}
									>
										Cancel
									</button>
									<button
										// onClick={() => {
										// 	setIsLoginModal({
										// 		status: false,
										// 		title: "",
										// 		url: "",
										// 	});
										// }}
										disabled={
											!formik.values.email &&
											!formik.values.password
										}
										data-modal-toggle="popup-modal"
										type="submit"
										className={`${
											!isDisable
												? "py-2 relative px-12 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
												: "py-2 relative px-12 bg-gradient-to-r bg-neutral-400 cursor-not-allowed  opacity-40 capitalize font-medium border-none rounded-3xl"
										}`}
									>
										Submit
										{isSubmit && (
											<div className="animate-spin border-solid  top-3 left-16 absolute border-[2.4px] border-t-[#14171F] border-r-[#14171F] border-[#fff] w-[1.3rem] h-[1.3rem] rounded-full"></div>
										)}
									</button>
								</div>
								<div>
									{loginResponse && loginResponse?.status && (
										<p
											style={{
												color: "green",
												margin: "0px",
											}}
										>
											<small>{loginResponse.msg}</small>
										</p>
									)}
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginModal;
