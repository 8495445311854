import { captureException } from "@sentry/react";
import { getUser, getUserById } from "../ApiCalls/userCalls";

export const getUserProfile = async ({ Id, email }) => {
	if (!Id && !email) {
		return;
	}
	try {
		let user;
		if (email) {
			user = await getUser(email);
		} else if (Id) {
			user = await getUserById(Id);
			user = user.res.data;
		}
		const userProfile = JSON.parse(user.profile);
		const userCompanyName = userProfile.companyName;
		return userCompanyName;
	} catch (error) {
		captureException(error);
	}
};
