function getTrimmedString(str, trimLength) {
	if (str) {
		return (
			str.toString().slice(0, trimLength ? trimLength : 10) +
			(str.length > 10 ? "..." : "")
		);
	}
	return null;
}

function getExtendableTextBreakup(text, sliceAt) {
	let isSliced = false;
	let firstText, secondText;
	if (text && sliceAt) {
		let textStr = text.toString();
		if (textStr.length > sliceAt) {
			isSliced = true;
			firstText = textStr.slice(0, sliceAt);
			secondText = textStr.slice(++sliceAt);
		} else {
			firstText = textStr;
		}
	}
	return { isSliced, firstText, secondText };
}

function getOnlyErrorText(str) {
	return str.substring(str.indexOf(":") + 1);
}

function getDisplayAmount(amount) {
	let displayAmt;
	if (amount) {
		amount = parseFloat(amount).toFixed(2);
		if (amount >= 1000000000) {
			displayAmt = (amount / 1000000000).toFixed(2).toString() + "B";
		} else if (amount >= 1000000) {
			displayAmt = (amount / 1000000).toFixed(2).toString() + "M";
		} else if (amount > 999) {
			displayAmt = (amount / 1000).toFixed(2).toString() + "K";
		} else {
			displayAmt = amount;
		}
	}

	return displayAmt;
}

function getGUID() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(
			c ^
			(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
		).toString(16)
	);
}

function formatNumberWithCommas(number) {
	var fNum = parseFloat(number).toFixed(2);
	// Convert number to string
	let numStr = String(fNum);

	// Split the string into integer and decimal parts
	let parts = numStr.split(".");
	let integerPart = parts[0];
	let decimalPart = parts.length > 1 ? "." + parts[1] : "";

	// Add commas to the integer part
	let integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

	// Concatenate integer and decimal parts
	return integerWithCommas + decimalPart;
}

module.exports = {
	getTrimmedString,
	getExtendableTextBreakup,
	getDisplayAmount,
	getOnlyErrorText,
	getGUID,
	formatNumberWithCommas,
};
