import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GradientButton from "../../uiTools/Button/GradientBtnForLanding";
const BorrowerProfileConf = ({ isCheckModal, setIsCheckModal }) => {
	const [agreeChecked, setAgreeChecked] = useState(false);
	const [disagreeChecked, setDisagreeChecked] = useState(false);
	const path = useNavigate();

	const handleAgreeChange = () => {
		setAgreeChecked(!agreeChecked);
		path(isCheckModal?.url);
		setIsCheckModal({
			status: false,
			title: "",
			url: "",
		});
	};

	const handleDisagreeChange = () => {
		setDisagreeChecked(!disagreeChecked);

		setIsCheckModal({
			status: false,
			title: "",
			url: "",
		});
	};

	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={isCheckModal?.status}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal"
			>
				<div className="relative p-4 w-full max-w-3xl h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<h3 className="font-normal text-xl ">
								Consent for Use of Data and Terms and Conditions
							</h3>

							<button
								type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-3"
								data-modal-toggle="popup-modal"
								onClick={() => {
									setIsCheckModal({
										status: false,
										title: "",
										url: "",
									});
								}}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>

						<div className="p-5 px-10 mb-[-3rem] overflow-y-auto max-h-[60vh]">
							{/* Add overflow-y-auto and max-h-[60vh] classes */}
							<div className="container mx-auto">
								<p>
									By using this electronic platform as an
									issuer of a requests, you hereby acknowledge
									and agree to the following:
								</p>

								<h2 className="text-xl font-medium mt-6">
									Data Usage Consent:
								</h2>

								<p>
									You consent to the collection, storage,
									processing, and use of your personal and
									financial information provided by you on
									this platform for the purpose of
									facilitating the request issuance process
									and associated services. This includes
									verifying your identity, assessing
									creditworthiness, and facilitating the
									request transaction. Your data will be
									handled in accordance with applicable
									privacy laws and our Privacy Policy.
								</p>

								<h2 className="text-xl font-medium mt-6">
									Accuracy of Information:
								</h2>

								<p>
									You acknowledge that all information
									provided by you on this platform is true,
									accurate, and complete to the best of your
									knowledge. You agree to promptly update any
									changes to your information to ensure its
									ongoing accuracy.
								</p>

								<h2 className="text-xl font-medium mt-6">
									Terms and Conditions:
								</h2>

								<p>
									You agree to be bound by the Terms and
									Conditions outlined on this platform, which
									govern your use of the platform as an issuer
									of a request. This includes but is not
									limited to the terms related to request
									issuance eligibility, interest rates,
									maturity dates, payment terms, fees, and any
									other applicable terms specific to the
									request issuance process.
								</p>

								<h2 className="text-xl font-medium mt-6">
									Responsibility for Request Obligations:
								</h2>

								<p>
									You acknowledge and accept full
									responsibility for fulfilling your
									obligations as an issuer of the request,
									including payment of interest and principal
									amounts, in accordance with the agreed-upon
									terms and conditions.
								</p>

								<h2 className="text-xl font-medium mt-6">
									Platform Use and Security:
								</h2>

								<p>
									You agree to use this platform solely for
									its intended purpose and in compliance with
									applicable laws and regulations. You are
									responsible for maintaining the
									confidentiality and security of your login
									credentials and for any activities conducted
									using your account.
								</p>

								<p>
									By accepting these terms and conditions, you
									confirm that you have read, understood, and
									agreed to be legally bound by this Consent
									for Use of Data and the Terms and Conditions
									of this electronic platform as an issuer of
									a request.
								</p>
							</div>
						</div>

						<div className="p-6 overflow-hidden">
							<div className="mt-10 flex justify-around items-center gap-24 md:gap-0 md:flex-row md:px-5 md:justify-between lg:px-32 xl:px-40 2xl:px-74">
								<GradientButton onClick={handleDisagreeChange}>
									Disagree
								</GradientButton>
								<GradientButton
									onClick={handleAgreeChange}
									className="ml-10"
								>
									Agree
								</GradientButton>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BorrowerProfileConf;
