import { useFormik } from "formik";
import GradientButton from "../../../../uiTools/Button/GradientButton";
import { useEffect } from "react";

const loantenure = [
	{ value: 6, label: 6 },
	{ value: 12, label: 12 },
	{ value: 18, label: 18 },
	{ value: 24, label: 24 },
	{ value: 30, label: 30 },
	{ value: 36, label: 36 },
	{ value: 42, label: 42 },
	{ value: 48, label: 48 },
	{ value: 54, label: 54 },
	{ value: 60, label: 60 },
	{ value: 66, label: 66 },
	{ value: 72, label: 72 },
	{ value: 78, label: 78 },
	{ value: 84, label: 84 },
];

export default function Details({ formData, handleNext, handleForm, bond }) {
	useEffect(() => {
		console.log("formdata", bond);
	}, []);

	const formik = useFormik({
		initialValues: {
			loan_amount: bond.loan_amount,
			loan_interest: bond.loan_interest,
			loan_tenure: (bond.loan_tenure / 30).toString(),
		},
		onSubmit: (values, { resetForm }) => {
			console.log("clicked.", values);
			handleNext(values, false);
		},
	});

	return (
		<>
			<div className=" flex flex-col mt-20 md:mt-16 md:px-5 ">
				<form onSubmit={formik.handleSubmit}>
					<div className="py-12 my-4 mx-16 px-6 rounded-lg bg-slate-200">
						<div className="flex items-center justify-center mb-4">
							<label htmlFor="loan_amount" className="ml-2 w-60">
								{`Request amount (${process.env.REACT_APP_TOKEN_NAME})`}
							</label>
							<input
								name="loan_amount"
								type="number"
								placeholder="Enter value"
								className="input input-bordered input-sm w-56 max-w-xs"
								data-theme="light"
								value={formik.values.loan_amount}
								onChange={formik.handleChange}
								min={0}
								required
							/>
						</div>
						<div className="flex items-center justify-center mb-4">
							<label
								htmlFor="loan_interest"
								className="ml-2 w-60"
							>
								Interest rate (%)
							</label>
							<input
								name="loan_interest"
								type="number"
								placeholder="Enter value"
								className="input input-bordered input-sm w-56 max-w-xs"
								data-theme="light"
								step="0.001"
								value={formik.values.loan_interest}
								onChange={formik.handleChange}
								min={0}
								max={100}
								required
							/>
						</div>
						{/* <div className="flex items-center justify-center">
							<label htmlFor="loan_tenure" className="ml-2 w-60">
								Request tenure (Months)
							</label>
							<select
								className="select select-bordered select-sm w-56 font-normal"
								data-theme="light"
								name="loan_tenure"
								value={formik.values.loan_tenure}
								onChange={formik.handleChange}
							>
								{loantenure.map((option) => {
									return (
										<option value={option.value}>
											{option.label}
										</option>
									);
								})}
							</select>
						</div> */}
					</div>

					<div className="flex justify-center items-center flex-col-reverse  md:flex-row md:justify-around gap-5  mt-6 -mb-2 overflow-hidden">
						<div className="">
							<label
								className="cursor-pointer md:pl-24 ml-1 text-lg"
								onClick={handleForm}
							>
								Cancel
							</label>
						</div>
						<div className="md:pr-40 lg:pr-10 xl:pr-0 py-1">
							<GradientButton type="submit">Next</GradientButton>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
