import * as Yup from "yup";
import { useFormik } from "formik";
import GradientButton from "../../../../uiTools/Button/GradientButton";
import FileUploader from "../../../../uiTools/FileUploader/FileUploader";
import ArrowLeft from "../../../SVGIcons/ArrowLeft";

export default function UploadIssueNote({ formData, handleNext, handlePrev }) {
	const formik = useFormik({
		initialValues: formData,
		validationSchema: Yup.object().shape({
			issue_note_document: Yup.mixed().label("Issue Note Doc").required(),
		}),
		onSubmit: (values, { resetForm }) => {
			console.log("clicked.", values);
			handleNext(values, false);
		},
	});

	return (
		<>
			<div className=" flex flex-col mt-20 md:mt-16 md:px-5 ">
				<form onSubmit={formik.handleSubmit}>
					<div className="py-12 my-4 mx-16 px-6 rounded-lg bg-slate-200">
						<div className="flex gap-8 items-center justify-center mb-6 h-6 w-full">
							<FileUploader
								name="issue_note_document"
								handleFile={(file) => {
									formik.setFieldValue(
										"issue_note_document",
										file
									);
								}}
								onBlur={formik.handleBlur}
								error={
									formik.touched.issue_note_document &&
									formik.errors.issue_note_document
										? formik.errors.issue_note_document
										: null
								}
								label="Upload Issue Note Document"
								className="w-full md:w-1/2 md:mr-2 mb-5 md:mb-0"
								fileName={
									formData.issue_note_document
										? formData.issue_note_document[0].name
										: ""
								}
							/>
						</div>
					</div>

					<div className="flex justify-center items-center flex-col-reverse  md:flex-row md:justify-around gap-5  mt-6 -mb-2 overflow-hidden">
						<div className="pb-10 md:pb-0 md:mt-10">
							<label
								onClick={handlePrev}
								className="text-gray-500 flex-row cursor-pointer flex ml-1 md:pl-14 md:-mt-6 text-lg items-center"
							>
								<ArrowLeft color="#64748B" />
								Back
							</label>
						</div>
						<div className="md:pr-40 lg:pr-10 xl:pr-0 py-1">
							<GradientButton type="submit">Next</GradientButton>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
