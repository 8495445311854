import { captureMessage, captureException } from "@sentry/react";
import { BondStatus, getOpportunity } from "../opportunityConnectors";
import axiosHttpService from "../../axioscall";
import { getGreenBondsByField } from "../../ApiCalls/greenBondCalls";

export const getApprovalHistory = async (email) => {
	captureMessage("getApprovalHistory", "info");
	try {
		let bonds = await getGreenBondsByField("diligence", email);
		bonds = bonds?.res?.data?.GreenBond;
		bonds = bonds.filter((bond) => bond.status >= BondStatus.Rejected);
		let modifiedBonds = [];
		for (let i = 0; i < bonds.length; i++) {
			let { obj } = getOpportunity(bonds[i]);
			modifiedBonds.push(obj);
		}
		return { modifiedBonds, success: true };
	} catch (error) {
		captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getAdminApprovalHistory = async (pageSize, bookmark) => {
	captureMessage("getAdminApprovalHistory", "info");
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/bond/getAllBonds`,
			method: "POST",
			headers: {},
			data: {
				pageSize: pageSize,
				bookmark: bookmark,
			},
		};
		let bonds = await axiosHttpService(options);
		const bookmarkString = bonds?.res?.bookmark;
		bonds = bonds.res.records ? bonds.res.records : [];
		bonds = bonds.map((bond) => bond.data);
		bonds = bonds.filter(
			(bond) => bond.status > BondStatus.PendingForAdminApproval
		);
		let modifiedBonds = [];
		for (let i = 0; i < bonds.length; i++) {
			// let { obj } = getOpportunity(bonds[i]);
			modifiedBonds.push(bonds[i]);
		}
		return {
			modifiedBonds,
			bonds: bonds,
			bookmark: bookmarkString,
			success: true,
		};
	} catch (error) {
		captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};
