import React, { useState } from "react";
import GradientButton from "../../uiTools/Button/GradientBtnForLanding";
import Header from "../Layout/Header";
import { useNavigate } from "react-router-dom";
import BorrowImage from "../../assets/Borrow.svg";
import InvestImage from "../../assets/Invest.svg";
import InvestImageLightmode from "../../assets/lender.png";
import BorrowImageLightmode from "../../assets/borrower.png";
import CustodianImageLightmode from "../../assets/custodian.png";
import RegulatorImageLightmode from "../../assets/regulator.png";
import DiligenceImage from "../../assets/diligence.jpeg";
import LoginModal from "../../uiTools/Modal/LoginModal";
import { useContext } from "react";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import ForgotPasswordModal from "../../uiTools/Modal/ForgotPasswordModal";
import SuccessModal from "../../uiTools/Modal/SuccessModal";
import ErrorModal from "../../uiTools/Modal/ErrorModal";

const LandingPage = () => {
	const path = useNavigate();

	const { isLoggedIn, userData } = useContext(LoginContext);

	const [darkMode, setDarkMode] = useState(false);
	const [isLoginModal, setIsLoginModal] = useState({
		status: false,
		title: "",
		url: "",
	});
	const [isSelected, setIsSelected] = useState(false);
	const [successmsg, setSuccessmsg] = useState({
		status: false,
		msg: "",
	});
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});

	localStorage.theme = "light";

	// lender: 0,
	// borrower: 1,
	// custodian: 2,
	// regulator: 3,

	return (
		<div className={`${darkMode ? "dark" : ""} background`}>
			{isSelected && (
				<ForgotPasswordModal
					isSelected={isSelected}
					setIsSelected={setIsSelected}
					setSuccessmsg={setSuccessmsg}
					setErrormsg={setErrormsg}
				/>
			)}
			<SuccessModal
				successmsg={successmsg}
				setSuccessmsg={setSuccessmsg}
			/>
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			<div className="dark:bg-[#000000] bg-white dark:text-white text-black pb-20 md:pb-32 xl:pb-20 w-full h-full ">
				<div className="landing-backgroundimage">
					<Header darkMode={darkMode} setDarkMode={setDarkMode} />
					<LoginModal
						isLoginModal={isLoginModal}
						setIsLoginModal={setIsLoginModal}
						isSelected={isSelected}
						setIsSelected={setIsSelected}
						setErrormsg={setErrormsg}
						setSuccessmsg={setSuccessmsg}
					/>
					<div className="px-4 pt-5">
						<div className="flex-col items-center flex pt-20 xl:pt-10">
							<h1 className="text-4xl font-bold text-center w-[90%]  md:w-[78%] md:text-5xl xl:text-[3.1rem] xl:w-[63%] 2xl:text-[3.5]">
								Infrastructure for issuance of Tokenised Climate
								Instruments
							</h1>

							<h6 className="font-medium text-base w-[90%] text-center my-6 md:w-[78%] xl:w-[63%] 2xl:w-[50%]">
								Empowering climate focused investors to invest
								with assurance in climate action business in
								global South, without worrying about “Green
								Washing” and equipping them with tools to assess
								risk using dynamic climate specific risk scoring
								algorithms.
							</h6>
						</div>
						<br />
						<br />

						<div className="flex flex-col w-full  items-center gap-24 md:gap-0 md:flex-row md:px-5 md:justify-between lg:px-32 xl:px-40 2xl:px-64">
							<div className="flex flex-col justify-center items-center  ">
								<div className="avatar mb-5">
									<div className="rounded-full w-40 shadow-2xl">
										<img
											alt="Subscriber"
											src={
												darkMode
													? InvestImage
													: InvestImageLightmode
											}
											className="w-72 p-10 px-auto md:w-[13rem] 2xl:w-[21rem] mb-4"
										/>
									</div>
								</div>
								<GradientButton
									onClick={() => {
										if (
											isLoggedIn &&
											userData?.role === 0
										) {
											path("/investorDashboard/overview");
										} else {
											setIsLoginModal({
												status: true,
												title: "Subscriber",
												url: "/investorDashboard/overview",
											});
										}
									}}
								>
									Subscriber
								</GradientButton>
							</div>
							<div className="flex flex-col justify-center items-center  ">
								<div className="avatar mb-5">
									<div className="rounded-full w-40  shadow-2xl">
										<img
											alt="Issuer"
											src={
												darkMode
													? BorrowImage
													: BorrowImageLightmode
											}
											className="w-72 p-10 px-auto md:w-[13rem] 2xl:w-[21rem] mb-4"
										/>
									</div>
								</div>
								<GradientButton
									onClick={() => {
										if (
											isLoggedIn &&
											userData?.role === 1
										) {
											path("/borrowerDashboard/overview");
										} else {
											setIsLoginModal({
												status: true,
												title: "Issuer",
												url: "/borrowerDashboard/overview",
											});
										}
									}}
								>
									Issuer
								</GradientButton>
							</div>
							<div className="flex flex-col justify-center items-center  ">
								<div className="avatar mb-5">
									<div className="rounded-full w-40  shadow-2xl">
										<img
											alt="Diligence"
											src={
												darkMode
													? DiligenceImage
													: DiligenceImage
											}
											className="w-72 p-7 px-auto md:w-[13rem] 2xl:w-[21rem] mb-4"
										/>
									</div>
								</div>
								<GradientButton
									onClick={() => {
										if (
											isLoggedIn &&
											userData?.role === 5
										) {
											path(
												"/diligenceExpert/borrowRequest"
											);
										} else {
											setIsLoginModal({
												status: true,
												title: "Diligence",
												url: "/diligenceExpert/borrowRequest",
											});
										}
									}}
								>
									Diligence
								</GradientButton>
							</div>
							<div className="flex flex-col justify-center items-center   ">
								<div className="avatar mb-5">
									<div className="rounded-full w-40  shadow-2xl">
										<img
											alt="Custodian"
											src={
												darkMode
													? InvestImage
													: CustodianImageLightmode
											}
											className="w-72 p-10 px-auto md:w-[13rem] 2xl:w-[21rem] mb-4"
										/>
									</div>
								</div>
								<GradientButton
									onClick={() => {
										if (
											isLoggedIn &&
											userData?.role === 2
										) {
											path(
												"/custodianDashboard/openBonds"
											);
										} else {
											setIsLoginModal({
												status: true,
												title: "Custodian",
												url: "/custodianDashboard/openBonds",
											});
										}
									}}
								>
									Custodian
								</GradientButton>
							</div>
							<div className="flex flex-col justify-center items-center   ">
								<div className="avatar mb-5">
									<div className="rounded-full w-40  shadow-2xl">
										<img
											alt="Regulator"
											src={
												darkMode
													? InvestImage
													: RegulatorImageLightmode
											}
											className="w-72 p-10 px-auto md:w-[13rem] 2xl:w-[21rem] mb-4"
										/>
									</div>
								</div>
								<GradientButton
									onClick={() => {
										if (
											isLoggedIn &&
											userData?.role === 3
										) {
											path(
												"/regulatorDashboard/bondReports"
											);
										} else {
											setIsLoginModal({
												status: true,
												title: "Regulator",
												url: "/regulatorDashboard/bondReports",
											});
										}
									}}
								>
									Regulator
								</GradientButton>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LandingPage;
