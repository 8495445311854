import Joi from "joi";

export const loanDetailsValidationSchema = Joi.object({
	loan_name: Joi.string().trim().label("Request Name").required().min(3),
	loan_type: Joi.number().label("Request Type").required(),
	loan_purpose: Joi.string().trim().label("Request Purpose").required(),
	loan_amount: Joi.number().positive().label("Request Amount").required(),
	loan_tenure: Joi.number().required().positive().label("Request Tenure"),
	loan_interest: Joi.number()
		.required()
		.positive()
		.min(1)
		.max(100)
		.label("Interest Rate"),
	payment_frequency: Joi.number()
		.required()
		.positive()
		.min(1)
		.label("Payment Frequency"),
	sop_document: Joi.any().label("SOP Document").required(),
	ghg_emission_reduction: Joi.number()
		.positive()
		.label("GHG Emission Reduction")
		.required(),
});

const SUPPORTED_FORMATS = [
	"image/jpg",
	"image/jpeg",
	"image/png",
	"application/pdf",
	"application/msword",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const CollateralDetailsValidationSchema = Joi.object({
	collateral_document_name: Joi.string()
		.trim()
		.label("Document Name")
		.required(),
	collateral_document_description: Joi.string()
		.trim()
		.label("Document Description")
		.required(),
	collateral_document: Joi.any().label("Collateral Document").required(),
	capital_loss: Joi.number().min(0).max(100).label("First loss capital"),
});
