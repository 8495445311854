import React, { useState, useEffect } from "react";
import GradientButtonHeader from "../../uiTools/Button/GradientButtonHeader";
import Loader from "../../uiTools/Loading/Loader";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import CreateUserModal from "./Components/Modal/CreateUserModal";
import SuccessModal from "../../uiTools/Modal/SuccessModal";
import UsersCard from "./Components/UsersCard";
import { getAllUsers } from "../../services/BackendConnectors/userConnectors/userLogin";
import { sortByPropertyAscending } from "../../services/BackendConnectors/opportunityConnectors";
import EditUserModal from "./Components/Modal/EditUserModal";
import DeleteUserModal from "./Components/Modal/DeleteUserModal";
import { captureException } from "@sentry/react";
import { Role as UserRole } from "../../services/BackendConnectors/userConnectors/userLogin";
import { deleteUser } from "../../services/ApiCalls/userCalls";

let Role = {
	...UserRole,
};

// Delete Admin from Role object
delete Role.Admin;

const RoleArray = Object.keys(UserRole);

const UserManagement = () => {
	const [selected, setSelected] = useState(false);
	const [item, setItem] = useState({});
	const [selectedEditModal, setSelectedEditModal] = useState(false);
	const [selectedDeleteUserModal, setSelectedDeleteUserModal] =
		useState(false);
	const [loading, setLoading] = useState(true);
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});
	const [successmsg, setSuccessmsg] = useState({
		status: false,
		msg: "",
	});
	const [users, setUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [currentUser, setCurrentUser] = useState({});
	const [reloadData, setReloadData] = useState(false);
	const [roleFilter, setRoleFilter] = useState([]);
	const [showFilter, setShowFilter] = useState(false);
	const [bookmark, setBookmark] = useState(null);

	const handleRoleFilterChange = (event) => {
		const { name, checked } = event.target;
		setRoleFilter((prev) =>
			checked ? [...prev, name] : prev.filter((role) => role !== name)
		);
	};

	const applyRoleFilter = () => {
		// setReloadData(true);
		setFilteredUsers(
			users.filter((user) =>
				roleFilter.includes(String(RoleArray[user.role]))
			)
		);
		setShowFilter(false);
	};

	const handleDeleteUser = async () => {
		try {
			setLoading(true);
			setSelectedDeleteUserModal(false);
			const result = await deleteUser(item.email, item.role);
			if (result?.success) {
				setSuccessmsg({
					status: true,
					msg: "User deleted Successfully!",
				});
				if (filteredUsers.length > 0) {
					setFilteredUsers((prev) =>
						prev.filter((user) => user.email !== item.email)
					);
				}
				setUsers((prev) =>
					prev.filter((user) => user.email !== item.email)
				);
			} else {
				setErrormsg({
					status: true,
					msg: "User does not exists...",
				});
			}
			setSelectedDeleteUserModal(false);
			setLoading(false);
		} catch (error) {
			captureException(error);
			setErrormsg({
				status: true,
				msg: "User does not exists...",
			});
			setSelectedDeleteUserModal(false);
			setLoading(false);
		}
	};

	const handleLoadMore = async () => {
		setLoading(true);
		let result = await getAllUsers(
			process.env.REACT_APP_SPYDRA_RESULT_PAGE_SIZE,
			bookmark
		);
		setBookmark(result?.bookmark);
		if (result?.records) {
			let usersData = result?.records?.map((user) => user.data);
			usersData = usersData.filter((user) => user.role !== 4);
			const newUsers = users.concat(usersData);
			setUsers(newUsers.sort(sortByPropertyAscending("role")));
			if (roleFilter.length > 0) {
				usersData = newUsers.filter((user) =>
					roleFilter.includes(String(RoleArray[user.role]))
				);
				setFilteredUsers(usersData);
			} else {
				setFilteredUsers([]);
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		(async () => {
			setReloadData(false);
			let result = await getAllUsers(
				process.env.REACT_APP_SPYDRA_RESULT_PAGE_SIZE
			);
			setBookmark(result?.bookmark);
			let usersData = result?.records?.map((user) => user.data);
			usersData = usersData.sort(sortByPropertyAscending("role"));
			usersData = usersData.filter((user) => user.role !== 4);
			setUsers(usersData);
			if (roleFilter.length > 0) {
				usersData = usersData.filter((user) =>
					roleFilter.includes(String(RoleArray[user.role]))
				);
				setFilteredUsers(usersData);
			} else {
				setFilteredUsers([]);
			}
			setLoading(false);
		})();
	}, [reloadData]);

	return (
		<div className={`mb-8 -mx-4 ${loading ? "relative h-[80vh]" : ""}`}>
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			<SuccessModal
				successmsg={successmsg}
				setSuccessmsg={setSuccessmsg}
			/>
			{loading && <Loader />}
			{selected && (
				<CreateUserModal
					selected={selected}
					setSelected={setSelected}
					setErrormsg={setErrormsg}
					setSuccessmsg={setSuccessmsg}
					setReloadData={setReloadData}
				/>
			)}
			{selectedEditModal && (
				<EditUserModal
					selectedEditModal={selectedEditModal}
					setSelectedEditModal={setSelectedEditModal}
					setErrormsg={setErrormsg}
					setSuccessmsg={setSuccessmsg}
					user={currentUser}
					users={users}
					setUsers={setUsers}
					filteredUsers={filteredUsers}
					setFilteredUsers={setFilteredUsers}
				/>
			)}
			{selectedDeleteUserModal && (
				<DeleteUserModal
					isDeleteUser={selectedDeleteUserModal}
					handleDeleteUser={handleDeleteUser}
					setIsDeleteUser={setSelectedDeleteUserModal}
				/>
			)}
			<div className={`${loading ? "blur-sm" : ""}`}>
				<div className="flex items-center mb-8 md:pl-1 lg:pl-2 xl:pl-2">
					<h2 className="text-left text-2xl lg:mb-8 xl:mb-10 xl:mt-1">
						User Management Dashboard
					</h2>
					<GradientButtonHeader
						onClick={() => {
							setSelected(true);
						}}
						className="ml-auto text-white mb-8"
					>
						+ Create User
					</GradientButtonHeader>
				</div>
				{users?.length !== 0 ? (
					<>
						<div className="px-1 mt-8 py-6 gap-4 md:gap-0 md:justify-around flex font-bold border-y border-darkmode-500 text-center">
							<p className="w-1/3 md:w-1/6 my-auto pr-4">Email</p>
							<div className="w-1/3 md:w-1/6 my-auto pr-2 relative">
								<p>Role</p>
								<button
									onClick={() => setShowFilter(!showFilter)}
									className="absolute right-8 top-[3px] mr-2"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-funnel"
										viewBox="0 0 16 16"
									>
										<path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
									</svg>
								</button>
								{showFilter && (
									<div className="absolute right-0 mt-2 w-40 bg-white border shadow-xl z-10 rounded-md pl-4 py-3">
										{Object.keys(Role).map((role) => (
											<div className="flex items-center">
												<label
													key={role}
													className="block px-4 py-2 text-sm cursor-pointer"
												>
													<input
														type="checkbox"
														name={role}
														checked={roleFilter.includes(
															role
														)}
														onChange={
															handleRoleFilterChange
														}
														data-theme="light"
														className="mr-1"
													/>
													{role}
												</label>
											</div>
										))}
										<button
											onClick={applyRoleFilter}
											className="block text-center px-3 py-1 text-sm bg-blue-500  hover:bg-blue-600 text-white rounded-md ml-7 mt-2"
										>
											Apply
										</button>
									</div>
								)}
							</div>
							<p className="w-1/3 md:w-1/6 my-auto ">Edit</p>
							<p className="w-1/3 md:w-1/6 my-auto ">
								Enable/Disable
							</p>
							<p className="w-1/3 md:w-1/6 my-auto ">Delete</p>
						</div>
						<div className="my-5 flex flex-col gap-3">
							{(filteredUsers.length > 0
								? filteredUsers
								: users
							)?.map((item) => (
								<>
									<UsersCard
										key={item.Id}
										data={item}
										setSelectedEditModal={
											setSelectedEditModal
										}
										setCurrentUser={setCurrentUser}
										setErrormsg={setErrormsg}
										setSuccessmsg={setSuccessmsg}
										setLoading={setLoading}
										setSelectedDeleteUserModal={
											setSelectedDeleteUserModal
										}
										setItem={setItem}
									/>
								</>
							))}
							{bookmark && (
								<div className="text-center">
									<button
										className="py-2 px-8 mt-3 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
										onClick={handleLoadMore}
									>
										Load More
									</button>
								</div>
							)}
						</div>
					</>
				) : (
					<div className="relative h-screen flex justify-center">
						<div className="text-[#64748B] text-xl text-center mt-3 absolute top-40">
							<p>No Users available.</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default UserManagement;
