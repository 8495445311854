import { useFormik } from "formik";
import GradientButton from "../../../../uiTools/Button/GradientButton";
import TextArea from "../../../../uiTools/Inputs/TextArea";
import TextField from "../../../../uiTools/Inputs/TextField";
import { loanDetailsValidationSchema } from "../validations/validation";
import Selection from "../../../../uiTools/Inputs/Selection";
import { useEffect, useRef, useState } from "react";
import FileUploader from "../../../../uiTools/FileUploader/FileUploader";
import { joiFormikAdapter } from "joi-formik-adapter";
import { requestType } from "../../../../services/BackendConnectors/opportunityConnectors";

const loantenure = [
	{ value: 0.033, label: 0.033 },
	{ value: 0.066, label: 0.066 },
	{ value: 0.1, label: 0.1 },
	{ value: 0.166, label: 0.166 },
	{ value: 0.233, label: 0.233 },
	{ value: 0.5, label: 0.5 },
	{ value: 1, label: 1 },
	{ value: 3, label: 3 },
	{ value: 6, label: 6 },
	// { value: 12, label: 12 },
	// { value: 18, label: 18 },
	// { value: 24, label: 24 },
	// { value: 30, label: 30 },
	// { value: 36, label: 36 },
	// { value: 42, label: 42 },
	// { value: 48, label: 48 },
	// { value: 54, label: 54 },
	// { value: 60, label: 60 },
	// { value: 66, label: 66 },
	// { value: 72, label: 72 },
	// { value: 78, label: 78 },
	// { value: 84, label: 84 },
];

const paymentfrequency = [
	{ value: 1, label: 1 },
	{ value: 2, label: 2 },
	{ value: 3, label: 3 },
	{ value: 5, label: 5 },
	{ value: 7, label: 7 },
	{ value: 15, label: 15 },
	{ value: 30, label: 30 },
	{ value: 60, label: 60 },
	{ value: 90, label: 90 },
	{ value: 120, label: 120 },
	{ value: 150, label: 150 },
	{ value: 180, label: 180 },
];
const loantypes = [
	// { value: 1, label: "Term" },
	{ value: "0", label: "Bullet" },
];

export default function Account({ formData, handleNext, handleForm }) {
	const formik = useFormik({
		initialValues: formData,
		validationSchema: joiFormikAdapter(loanDetailsValidationSchema),
		onSubmit: (values, { resetForm }) => {
			console.log("clicked.", values);
			formik.values.loan_name = formik.values.loan_name.trim();
			formik.values.loan_amount = formik.values.loan_amount.trim();
			formik.values.loan_purpose = formik.values.loan_purpose.trim();
			formik.values.loan_interest = formik.values.loan_interest.trim();
			formik.values.ghg_emission_reduction =
				formik.values.ghg_emission_reduction.trim();
			handleNext(values, false);
		},
	});

	const inputRef = useRef();

	useEffect(() => {
		inputRef.current.focus();
	}, []);

	const [outlineType, setoutlineType] = useState(false);
	const [outlineRequestType, setoutlineRequestType] = useState(false);
	const [outlineLoan, setoutlineLoan] = useState(false);
	const [outlinePay, setoutlinePay] = useState(false);
	return (
		<>
			<div className=" flex flex-col mt-20 md:mt-14 md:px-5 ">
				<form onSubmit={formik.handleSubmit}>
					<div className="md:flex md:gap-3 md:w-1/2">
						<div className="w-full md:mr-3 mb-5 md:mb-0">
							<label className="label text-lg">
								<span className="text-black dark:text-white text-lg">
									Request Type
								</span>
							</label>
							<div
								className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] ${
									outlineRequestType
										? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
										: ""
								}`}
							>
								<Selection
									onChange={(value) =>
										formik.setFieldValue(
											"requestType",
											value.value
										)
									}
									value={formik.values.requestType}
									placeholder="Select&nbsp;Type"
									options={requestType}
									focus={setoutlineRequestType}
								></Selection>
							</div>
							{formik.touched.requestType &&
							formik.errors.requestType ? (
								<p style={{ color: "red" }}>
									<small>{formik.errors.requestType}</small>
								</p>
							) : null}
						</div>
					</div>
					<div className="md:flex md:gap-3">
						<TextField
							name="loan_name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							className="w-full md:w-1/2 md:mr-2 mb-5 md:mb-0"
							label="Request Name"
							placeholder="Enter Request Name"
							value={formik.values.loan_name}
							error={
								formik.touched.loan_name &&
								formik.errors.loan_name
									? formik.errors.loan_name
									: null
							}
						></TextField>
						<TextField
							name="loan_amount"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								formik.touched.loan_amount &&
								formik.errors.loan_amount
									? formik.errors.loan_amount
									: null
							}
							className="w-full md:w-1/2 md:mr-2 mb-5 md:mb-0"
							label="Amount"
							placeholder="Enter Amount"
							text={process.env.REACT_APP_TOKEN_NAME}
							value={formik.values.loan_amount}
						></TextField>
					</div>
					<div className="md:flex md:gap-3">
						<div className="w-full md:w-1/2 md:mr-2 mb-5 md:mb-0">
							{/* <label className="label">
								<span>Tenure</span>
							</label> */}
							<label className="label text-lg">
								<span className="text-black dark:text-white text-lg">
									Tenure
								</span>
							</label>
							<div
								className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] ${
									outlineLoan
										? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
										: ""
								}`}
							>
								<Selection
									onChange={(value) => {
										formik.setFieldValue(
											"loan_tenure",
											value.value
										);
										formik.setFieldValue(
											"payment_frequency",
											Math.round(value.value * 30)
										);
									}}
									value={formik.values.loan_tenure}
									placeholder="Enter&nbsp;Tenure"
									options={loantenure}
									focus={setoutlineLoan}
								></Selection>

								<div className="text-gray-400">Months</div>
							</div>
							{formik.touched.loan_tenure &&
							formik.errors.loan_tenure ? (
								<p style={{ color: "red" }}>
									<small>{formik.errors.loan_tenure}</small>
								</p>
							) : null}
						</div>

						<div className="w-full md:w-1/2 md:mr-2 mb-5 md:mb-0">
							<label className="label text-lg">
								<span className="text-black dark:text-white text-lg">
									Payment&nbsp;Frequency
								</span>
							</label>
							<div
								className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] ${
									outlinePay
										? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
										: ""
								}`}
							>
								<Selection
									onChange={(value) =>
										formik.setFieldValue(
											"payment_frequency",
											value.value
										)
									}
									value={formik.values.payment_frequency}
									placeholder="Enter&nbsp;Payment&nbsp;Frequency"
									options={paymentfrequency}
									focus={setoutlinePay}
									isDisabled={true}
								></Selection>

								<div className="text-gray-400">Days</div>
							</div>
							{formik.touched.payment_frequency &&
							formik.errors.payment_frequency ? (
								<p style={{ color: "red" }}>
									<small>
										{formik.errors.payment_frequency}
									</small>
								</p>
							) : null}
						</div>
					</div>
					<div className="md:flex md:gap-3">
						<TextField
							name="loan_interest"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								formik.touched.loan_interest &&
								formik.errors.loan_interest
									? formik.errors.loan_interest
									: null
							}
							text="% pa"
							className="w-full md:w-1/2 md:mr-2 mb-5 md:mb-0"
							label="Interest Rate"
							placeholder="Enter Interest % p.a."
							value={formik.values.loan_interest}
						></TextField>
						<div className="w-full md:w-1/2 md:mr-2 mb-5 md:mb-0">
							<label className="label text-lg">
								<span className="text-black dark:text-white text-lg">
									Sub Type
								</span>
							</label>

							<div
								className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] ${
									outlineType
										? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
										: ""
								}`}
							>
								<Selection
									onChange={(value) =>
										formik.setFieldValue(
											"loan_type",
											value.value
										)
									}
									value={formik.values.loan_type}
									placeholder="Select&nbsp;Type"
									options={loantypes}
									focus={setoutlineType}
								></Selection>
							</div>
							{formik.touched.loan_type &&
							formik.errors.loan_type ? (
								<p style={{ color: "red" }}>
									<small>{formik.errors.loan_type}</small>
								</p>
							) : null}
						</div>
					</div>
					<div className="md:flex md:gap-3">
						<TextField
							name="ghg_emission_reduction"
							value={formik.values.ghg_emission_reduction}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							reference={inputRef}
							text="t CO"
							subText="2"
							error={
								formik.touched.ghg_emission_reduction &&
								formik.errors.ghg_emission_reduction
									? formik.errors.ghg_emission_reduction
									: null
							}
							label="Targeted GHG Emission Reduction"
							placeholder="Enter value"
							className="w-full md:w-1/2 md:mr-2 mb-5 md:mb-0"
						></TextField>
						<FileUploader
							name="sop_document"
							handleFile={(file) => {
								formik.setFieldValue("sop_document", file);
							}}
							onBlur={formik.handleBlur}
							error={
								formik.touched.sop_document &&
								formik.errors.sop_document
									? formik.errors.sop_document
									: null
							}
							label="Upload SOP Document"
							className="w-full md:w-1/2 md:mr-2 mb-5 md:mb-0"
							fileName={
								formData.sop_document
									? formData.sop_document[0].name
									: ""
							}
						/>
					</div>
					<TextArea
						name="loan_purpose"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={
							formik.touched.loan_purpose &&
							formik.errors.loan_purpose
								? formik.errors.loan_purpose
								: null
						}
						className="w-full mb-5 md:mb-0"
						label="End Use of Funds"
						placeholder="Short Summary On End Use Of Funds"
						value={formik.values.loan_purpose}
						rows={2}
					></TextArea>

					<div className="flex justify-center items-center flex-col-reverse  md:flex-row md:justify-around gap-5  mt-6 -mb-2 overflow-hidden">
						<div className="">
							<label
								className="cursor-pointer md:pl-24 ml-1 text-lg"
								onClick={handleForm}
							>
								Cancel
							</label>
						</div>
						<div className="md:pr-40 lg:pr-10 xl:pr-0 py-1">
							<GradientButton type="submit">Next</GradientButton>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
