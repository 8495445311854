import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../uiTools/Button/PrimaryButton";
import DygnifyImage from "../../../../assets/Dygnify_Image.png";
import DollarImage from "../../../../assets/Dollar-icon.svg";
import SubscriberCheckCard from "../../SubscriberCheckCard";
import { useState } from "react";

const ViewPoolCard = ({ data, kycStatus, isSenior }) => {
	const path = useNavigate();
	const { opportunityAmount, loanInterest, isSeniorFull, isJuniorFull } =
		data;
	const [isCheckModal, setIsCheckModal] = useState({
		status: false,
		title: "",
		url: "",
		state: "",
	});

	console.log(data, "💸💸💸💸💸💸💸");

	const StatusButton = ({ className, isFullStatus }) => {
		return (
			<div
				style={{
					backgroundImage: `${
						isFullStatus
							? "linear-gradient(95.8deg, #FFE202 5%, #F2B24E 95.93%)"
							: "linear-gradient(97.78deg, #51B960 7.43%, #51B960 7.43%, #51B960 7.43%, #83DC90 90.63%)"
					}`,
				}}
				className={`${className} text-black px-3 rounded-2xl`}
			>
				<p>{isFullStatus ? "Full" : "Open"}</p>
			</div>
		);
	};

	return (
		<div
			className={`flex flex-col gap-6 px-4 py-6 rounded-xl sm:px-8 lg:flex-row md:w-[48%] 2xl:w-[min(32%,30rem)]  my-gradient`}
		>
			<SubscriberCheckCard
				isCheckModal={isCheckModal}
				setIsCheckModal={setIsCheckModal}
			/>
			<div className="flex items-center gap-6">
				<img
					alt=""
					style={{ borderRadius: "50%", aspectRatio: "1/1" }}
					className="w-[7rem] lg:w-[6rem] xl:w-[10rem] 2xl:w-[8rem]"
					src={
						data?.companyDetails?.companyLogoFile
							?.businessLogoFileCID
							? data?.companyDetails?.companyLogoFile
									?.businessLogoFileCID
							: DygnifyImage
					}
				/>

				<div className="lg:hidden">
					<p className="text-2xl font-normal">
						{data?.opportunityName}
					</p>
					<p>{data?.companyDetails?.companyName}</p>
				</div>
			</div>

			<div className="flex flex-col gap-6 lg:w-[75%]">
				<div className="hidden lg:block">
					<p className="text-2xl font-normal">
						{data?.opportunityName}
					</p>
					<p>{data?.companyDetails?.companyName}</p>
				</div>

				<div className="flex flex-col gap-1 font-normal">
					<div className="flex gap-1">
						<p className="">Tranche Amount</p>
						<img
							src={DollarImage}
							className="ml-auto w-[1rem]"
							alt=""
						/>
						{/* <p className="">{data?.opportunityAmount}</p> */}
						<p className="">
							{isSenior
								? data?.seniorTrancheDisplayAmount
								: data?.juniorTrancheDisplayAmount}
						</p>
					</div>

					<div className="flex">
						<p className="">Expected XIRR</p>
						{/* <p className="ml-auto ">{data?.loanInterest}</p> */}
						<p className="ml-auto ">
							{isSenior ? data?.seniorXirr : data?.juniorXirr}
						</p>
					</div>
					<div className="flex">
						<p className="">Status</p>

						<StatusButton
							className="ml-auto"
							isFullStatus={
								isSenior ? isSeniorFull : isJuniorFull
							}
						/>
					</div>
				</div>

				<div className="">
					<PrimaryButton
						className="w-[100%] text-white"
						onClick={() => {
							// if (!isSenior) {
							// path("/investorDashboard/viewPool", {
							// 	state: {
							// 		...data,
							// 		kycStatus: kycStatus,
							// 	},
							// });
							setIsCheckModal({
								status: true,
								title: "Subscribe",
								url: "/investorDashboard/viewPool",
								state: {
									...data,
									kycStatus: kycStatus,
									isSenior: isSenior,
								},
							});
							// } else {
							// 	onClick();
							// }
						}}
					>
						View Request
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
};

export default ViewPoolCard;
