import React, { createContext, useEffect, useState } from "react";
import { logoutUser } from "../services/BackendConnectors/userConnectors/userLogin";

const LoginContext = createContext();

const LoginProvider = ({ children }) => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [userData, setUserData] = useState(null);

	useEffect(() => {
		// Check if login data exists in local storage on component mount
		const storedData = sessionStorage.getItem("loginData");
		if (storedData) {
			const { isLoggedIn, userData } = JSON.parse(storedData);
			setIsLoggedIn(isLoggedIn);
			setUserData(userData);
		}
	}, []);

	const login = (user) => {
		setIsLoggedIn(true);
		setUserData(user);
		sessionStorage.setItem("selectedDataId", null);
		// Save login data to local storage
		sessionStorage.setItem(
			"loginData",
			JSON.stringify({ isLoggedIn: true, userData: user })
		);
	};

	const logout = () => {
		setIsLoggedIn(false);
		setUserData(null);
		logoutUser();
		sessionStorage.setItem("selectedDataId", null);
		// Clear login data from local storage
		sessionStorage.removeItem("loginData");
	};

	return (
		<LoginContext.Provider value={{ isLoggedIn, userData, login, logout }}>
			{children}
		</LoginContext.Provider>
	);
};

export { LoginContext, LoginProvider };
