import { Route, Routes, HashRouter, Navigate } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import BorrowerDashboard from "./pages/Borrower/BorrowerDashboard";
import Overview from "./pages/Borrower/Overview";
import BorrowList from "./pages/Borrower/BorrowList";
import InvestorDashboard from "./pages/Investor/InvestorDashboard";
import CustodianDashboard from "./pages/Custodian/CustodianDashboard.js";
import InvestorOverview from "./pages/Investor/InvestorOverview";
import Dashboard from "./pages/Custodian/Dashboard.js";
import Invest from "./pages/Investor/Invest";
import Withdraw from "./pages/Investor/Withdraw";
import ViewPool from "./pages/Investor/ViewPool";
import DiligenceExpert from "./pages/Underwriters/DiligenceExpert";
import BorrowRequest from "./pages/Underwriters/BorrowRequest";
import PoolDetails from "./pages/Underwriters/PoolDetails";
import ApprovalHistory from "./pages/Underwriters/ApprovalHistory";
import Admin from "./pages/Admin/Admin";
import AdminBorrowRequest from "./pages/Admin/AdminBorrowRequest";
import AdminPoolDetails from "./pages/Admin/AdminPoolDetails";
import AdminApprovalHistory from "./pages/Admin/AdminApprovalHistory";
import Transactions from "./pages/Investor/Transactions";
import GreenBond from "./pages/Custodian/GreenBond.js";
import GreenDetails from "./pages/Custodian/GreenDetails.js";
import ViewSeniorPool from "./pages/Investor/ViewSeniorPool";
import Transaction from "./pages/Borrower/Transaction";
import CustodianTransactions from "./pages/Custodian/Transaction.js";
import BorrowerProfile from "./pages/Borrower/BorrowerProfile";
import EditBorrowerProfile from "./pages/Borrower/EditBorrowerProfile";
import UnderwriterQueries from "./pages/Borrower/UnderwriterQueries";
import QueriesPoolDetails from "./pages/Borrower/QueriesPoolDetails";
import { LoginProvider } from "./LoginProvider/LoginProvider";
import Logout from "./Logout/Logout";
import EditInvestorProfile from "./pages/Investor/EditInvestorProfile";
import InvestorProfile from "./pages/Investor/InvestorProfile";
import UserManagement from "./pages/Admin/UserManagement.js";
import Regulator from "./pages/Regulator/Regulator.js";
import BondReports from "./pages/Regulator/BondReports.js";
import TransactionReports from "./pages/Regulator/TransactionReports.js";
import OpenBonds from "./pages/Custodian/OpenBonds.js";
import MaturedBonds from "./pages/Custodian/MaturedBonds.js";
import CustodianProfile from "./pages/Custodian/CustodianProfile.js";
import EditCustodianProfile from "./pages/Custodian/EditCustodianProfile.js";

const Paths = () => {
	return (
		<LoginProvider>
			<HashRouter>
				<Routes>
					<Route path="/" element={<LandingPage />} />
					{/* // rename */}
					<Route path="/investorDashboard" element={<InvestorDashboard />}>
						<Route index element={<Navigate to="overview" />} />
						<Route path="overview" element={<InvestorOverview />} />
						<Route path="invest" element={<Invest />} />
						<Route path="withdraw" element={<Withdraw />} />
						<Route path="transaction" element={<Transactions />} />
						<Route path="viewPool" element={<ViewPool />} />
						<Route path="viewSeniorPool" element={<ViewSeniorPool />} />
						<Route path="investorProfile" element={<InvestorProfile />} />
						<Route path="editProfile" element={<EditInvestorProfile />} />
					</Route>

					<Route path="/diligenceExpert" element={<DiligenceExpert />}>
						<Route index element={<Navigate to="borrowRequest" />} />
						<Route path="borrowRequest" element={<BorrowRequest />} />
						<Route path="poolDetail" element={<PoolDetails />} />
						<Route path="approvalHistory" element={<ApprovalHistory />} />
					</Route>

					<Route path="/admin" element={<Admin />}>
						<Route path="borrowRequest" element={<AdminBorrowRequest />} />
						<Route path="poolDetail" element={<AdminPoolDetails />} />
						<Route path="approvalHistory" element={<AdminApprovalHistory />} />
						<Route path="userManagement" element={<UserManagement />} />
					</Route>

					<Route path="/borrowerDashboard" element={<BorrowerDashboard />}>
						<Route index element={<Navigate to="overview" />} />
						<Route path="overview" element={<Overview />} />
						<Route path="borrowList" element={<BorrowList />} />
						<Route path="transaction" element={<Transaction />} />
						<Route path="borrowerProfile" element={<BorrowerProfile />} />
						<Route path="underwriterQueries" element={<UnderwriterQueries />} />
						<Route path="queriesPoolDetail" element={<QueriesPoolDetails />} />
						<Route path="editProfile" element={<EditBorrowerProfile />} />
						<Route path="viewPool" element={<ViewPool />} />
					</Route>

					<Route path="/custodianDashboard" element={<CustodianDashboard />}>
						<Route index element={<Navigate to="overview" />} />
						<Route path="openBonds" element={<OpenBonds />} />
						<Route path="overview" element={<Dashboard />} />
						<Route path="maturedBonds" element={<MaturedBonds />} />
						<Route path="greenbond" element={<GreenBond />} />
						<Route path="greenDetails" element={<GreenDetails />} />
						<Route path="transaction" element={<CustodianTransactions />} />
						<Route path="custodianProfile" element={<CustodianProfile />} />
						<Route path="editProfile" element={<EditCustodianProfile />} />
					</Route>

					<Route path="/regulatorDashboard" element={<Regulator />}>
						<Route index element={<Navigate to="bondReports" />} />
						<Route path="bondReports" element={<BondReports />} />
						<Route path="transactionReports" element={<TransactionReports />} />
					</Route>
				</Routes>
			</HashRouter>
		</LoginProvider>
	);
};

export default Paths;
