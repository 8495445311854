import axiosHttpService from "../axioscall";

export const getNft = async (nftId) => {
	let options = {
		url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/nft/getNft`,
		method: "POST",
		headers: {},
		data: {
			functionName: "QueryGreenBondNFT",
			args: [nftId],
		},
	};

	let result = await axiosHttpService(options);
	return result.res.success ? result.res : undefined;
};

export const createNft = async (nftData) => {
	let options = {
		url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/nft/createNft`,
		method: "POST",
		headers: {},
		data: {
			...nftData,
		},
	};
	let result = await axiosHttpService(options);
	return result.res.success ? result.res : undefined;
};
