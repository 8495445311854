import { useState, useRef, useEffect } from "react";

const ScrollableCardSection = ({ children }) => {
	const [scrollLeft, setScrollLeft] = useState(0);
	const scrollContainerRef = useRef(null);
	const [isScrollable, setIsScrollable] = useState(true);

	useEffect(() => {
		if (scrollContainerRef.current) {
			const container = scrollContainerRef.current;
			setIsScrollable(container.scrollWidth > container.clientWidth);
		}
	}, []);

	const handleScrollLeft = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({
				left: scrollContainerRef.current.scrollLeft - 400,
				behavior: "smooth",
			});
			setScrollLeft(scrollContainerRef.current.scrollLeft - 400);
		}
	};

	const handleScrollRight = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({
				left: scrollContainerRef.current.scrollLeft + 400,
				behavior: "smooth",
			});
			setScrollLeft(scrollContainerRef.current.scrollLeft + 400);
		}
	};

	console.log(
		scrollLeft,
		scrollContainerRef?.current?.scrollWidth -
			scrollContainerRef?.current?.offsetWidth
	);

	return (
		<div className="relative w-full px-2">
			<div
				className="flex gap-14 overflow-x-auto  pt-5 pb-8 px-1"
				ref={scrollContainerRef}
			>
				{children}
			</div>
			{isScrollable && (
				<div className="absolute top-1/2 left-[-35px] right-[-35px] flex justify-between items-center transform -translate-y-1/2">
					<button
						disabled={scrollLeft === 0}
						onClick={handleScrollLeft}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="50px"
							height="50px"
							viewBox="0 0 1024.00 1024.00"
							class="icon"
							stroke={`${
								scrollLeft === 0 || scrollLeft < 0
									? "#b3b3b3"
									: "#8110FE"
							} `}
							version="1.1"
							fill="#b5b5b5"
							stroke-width="102.4"
							transform="matrix(-1, 0, 0, 1, 0, 0)"
						>
							<g id="SVGRepo_bgCarrier" stroke-width="0" />

							<g
								id="SVGRepo_tracerCarrier"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>

							<g id="SVGRepo_iconCarrier">
								<path
									d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
									fill="#000000"
								/>
							</g>
						</svg>

						{/* <img src={left} className="w-7 h-16" alt="" /> */}
					</button>
					<button
						disabled={
							scrollContainerRef.current &&
							scrollContainerRef.current.scrollWidth -
								scrollContainerRef.current.offsetWidth ===
								scrollLeft
						}
						onClick={handleScrollRight}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="50px"
							height="50px"
							viewBox="0 0 1024.00 1024.00"
							class="icon"
							version="1.1"
							fill="#b5b5b5"
							stroke={`${
								scrollContainerRef.current &&
								scrollContainerRef.current.scrollWidth -
									scrollContainerRef.current.offsetWidth <
									scrollLeft
									? "#b3b3b3"
									: "#8110FE"
							} `}
							stroke-width="102.4"
						>
							<g id="SVGRepo_bgCarrier" stroke-width="0" />

							<g
								id="SVGRepo_tracerCarrier"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>

							<g id="SVGRepo_iconCarrier">
								<path
									d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
									fill="#000000"
								/>
							</g>
						</svg>

						{/* <img src={right} className="w-7 h-16" alt="" /> */}
					</button>
				</div>
			)}
		</div>
	);
};

export default ScrollableCardSection;
