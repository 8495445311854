import React, { useContext, useEffect, useState } from "react";
import Loader from "../../uiTools/Loading/Loader";
import { useTable, useSortBy } from "react-table";
import { getAllBonds } from "../../services/BackendConnectors/opportunityConnectors";
import filterSvg from "../../assets/filter.svg";
import Table from "../../uiTools/Table/Table";
import { DatePicker } from "antd";
import DollarImage from "../../assets/Dollar-icon.svg";
import { getDisplayAmount } from "../../services/Helpers/displayTextHelper";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import SuccessModal from "../../uiTools/Modal/SuccessModal";
import ChangePassowrdModal from "../../uiTools/Modal/ChangePasswordModal";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import { convertDate } from "../../services/BackendConnectors/userConnectors/commonConnectors";
import { removeTimeFromTimestamp } from "../../services/Helpers/dateFunctions";

const { RangePicker } = DatePicker;

const columns = [
	{
		Header: "Request Name",
		accessor: "opportunityName",
	},
	{
		Header: "Issuer name",
		accessor: "issuerName",
	},
	{
		Header: "Status",
		accessor: "bondDisplayStatus",
	},
	{
		Header: "Issued Date",
		accessor: "issuedDate",
		Cell: ({ value }) => {
			return value !== undefined ? <>{convertDate(value)}</> : "--";
		},
	},
	{
		Header: "Maturity Date",
		accessor: "maturityDate",
		Cell: ({ value }) => {
			return value !== undefined ? <>{convertDate(value)}</> : "--";
		},
	},
	{
		Header: "Issued Amount",
		accessor: "actualLoanAmount",
		Cell: ({ value }) => {
			return value !== undefined ? (
				<div className="flex justify-center items-center gap-2">
					<img src={DollarImage} className="w-4" alt="" />
					{getDisplayAmount(value)}
				</div>
			) : null;
		},
	},
	{
		Header: "Outstanding Amount",
		accessor: "outstandingAmount",
		Cell: ({ value }) => {
			return value !== "--" ? (
				<div className="flex justify-center items-center gap-2">
					<img src={DollarImage} className="w-4" alt="" />
					{getDisplayAmount(value)}
				</div>
			) : (
				<>{value}</>
			);
		},
	},
];

const bondStatusOptions = [
	{
		value: "",
		label: "Select Status",
	},
	{
		value: "Pending For Admin Approval",
		label: "Pending For Admin Approval",
	},
	{ value: "Rejected By Admin", label: "Rejected By Admin" },
	{ value: "Under Review", label: "Under Review" },
	{ value: "Rejected", label: "Rejected" },
	{ value: "Approved", label: "Approved" },
	{ value: "Tokenized", label: "Tokenized" },
	{ value: "Matured", label: "Matured" },
];

const BondReports = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	//Bookmark is used as pages in api calls. If backend has remaining data to send then it sends a bookmark.
	const [bookmark, setBookmark] = useState(null);
	const [showFilter, setShowFilter] = useState(false);

	// filters state
	const [issuerName, setIssuerName] = useState("");
	const [fromIssuedDate, setFromIssuedDate] = useState("");
	const [toIssuedDate, setToIssuedDate] = useState("");
	const [fromMaturityDate, setFromMaturityDate] = useState("");
	const [toMaturityDate, setToMaturityDate] = useState("");
	const [bondStatus, setBondStatus] = useState("");
	const [changePasswordModal, setChangePasswordModal] = useState(false);

	const [successmsg, setSuccessmsg] = useState({
		status: false,
		msg: "",
	});
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});

	const { userData, login } = useContext(LoginContext);

	// Load more handler
	const handleLoadMore = async () => {
		setLoading(true);
		let result = await getAllBonds(
			process.env.REACT_APP_SPYDRA_RESULT_PAGE_SIZE,
			bookmark
		);
		console.log("bond result", result);
		setBookmark(
			result?.bookmark
				? result.bookmark.codePointAt(result.bookmark.length - 2) !==
				  "𐑿".codePointAt(0)
					? result.bookmark
					: undefined
				: undefined
		);
		const newBonds = data.concat(result.opportunities);
		setData(newBonds);
		setLoading(false);
	};

	// Filter handlers
	const applyFilter = () => {
		let filtData = data;
		filtData = filtData.filter((bond) =>
			bond.issuerName
				.replaceAll(" ", "")
				.toLowerCase()
				.includes(issuerName.replaceAll(" ", "").toLowerCase())
		);

		filtData = filtData.filter(
			(data) =>
				!fromIssuedDate ||
				!toIssuedDate ||
				(removeTimeFromTimestamp(fromIssuedDate.unix() * 1000) <=
					removeTimeFromTimestamp(data.issuedDate) &&
					removeTimeFromTimestamp(data.issuedDate) <=
						removeTimeFromTimestamp(toIssuedDate.unix() * 1000))
		);

		filtData = filtData.filter(
			(data) =>
				!fromMaturityDate ||
				!toMaturityDate ||
				(removeTimeFromTimestamp(fromMaturityDate.unix() * 1000) <=
					removeTimeFromTimestamp(data.maturityDate) &&
					removeTimeFromTimestamp(data.maturityDate) <=
						removeTimeFromTimestamp(toMaturityDate.unix() * 1000))
		);

		filtData = filtData.filter((data) =>
			data.bondDisplayStatus.includes(bondStatus)
		);

		setFilteredData(filtData);
		setShowFilter(false);
	};

	const resetFilter = () => {
		setIssuerName("");
		setFromIssuedDate("");
		setToIssuedDate("");
		setFromMaturityDate("");
		setToMaturityDate("");
		setBondStatus("");
	};

	useEffect(() => {
		console.log("loading..");
		setLoading(true);
		getAllBonds(process.env.REACT_APP_SPYDRA_RESULT_PAGE_SIZE).then(
			(res) => {
				console.log("res", res);
				if (res.success) {
					setBookmark(
						res?.bookmark
							? res.bookmark.codePointAt(
									res.bookmark.length - 2
							  ) !== "𐑿".codePointAt(0)
								? res.bookmark
								: undefined
							: undefined
					);
					setData(res.opportunities);
					setFilteredData(res.opportunities);
					setChangePasswordModal(userData?.isNewUser);
				}
				setLoading(false);
				console.log("loading end...");
			}
		);
	}, []);

	useEffect(() => {
		applyFilter();
	}, [data]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		// setFilter,
	} = useTable(
		{
			columns,
			data: filteredData,
			initialState: {
				sortBy: [
					{
						id: "issuedDate",
						desc: true,
					},
				],
			},
		},
		// useFilters,
		useSortBy
	);

	return (
		<div className={`mb-16 mx-1 ${loading ? "relative h-[80vh]" : ""}`}>
			{loading && <Loader />}
			{changePasswordModal && (
				<ChangePassowrdModal
					userData={userData}
					login={login}
					isSelected={changePasswordModal}
					setIsSelected={setChangePasswordModal}
					setSuccessmsg={setSuccessmsg}
					setErrormsg={setErrormsg}
				/>
			)}
			<SuccessModal
				successmsg={successmsg}
				setSuccessmsg={setSuccessmsg}
			/>
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			<div className={`${loading ? "blur-sm" : ""}`}>
				<div className="flex items-center mb-4 md:pl-1 lg:pl-2 xl:pl-2">
					<h2 className="text-left text-2xl lg:mb-2 xl:mb-2 xl:mt-1">
						All Requests
					</h2>
				</div>
				<div className="flex justify-end mr-4">
					<button onClick={() => setShowFilter(!showFilter)}>
						<img src={filterSvg} />
					</button>
					{showFilter && (
						<div
							className="absolute z-20 m-4 max-w-screen-md rounded-xl"
							data-theme="light"
						>
							<div className="rounded-xl border border-gray-200 bg-white p-6 shadow-lg">
								<h2 className="text-stone-700 text-xl font-bold">
									Apply filters
								</h2>
								<button
									type="button"
									className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-2"
									onClick={() => {
										setShowFilter(false);
									}}
								>
									<svg
										aria-hidden="true"
										className="w-5 h-5"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										></path>
									</svg>
								</button>
								<div className="mt-8 grid grid-cols-2 gap-6 ">
									<div className="flex flex-col">
										<label className="text-stone-600 text-sm font-medium">
											Issuer Name
										</label>
										<input
											type="text"
											id="issuerName"
											placeholder="Enter Issuer Name"
											className="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none hover:border-[#4096ff] text-sm"
											onChange={(e) =>
												setIssuerName(e.target.value)
											}
											value={issuerName}
										/>
									</div>

									<div className="flex flex-col">
										<label className="text-stone-600 text-sm font-medium">
											Status
										</label>

										<select
											id="status"
											className="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none hover:border-[#4096ff] bg-white text-sm"
											onChange={(e) =>
												setBondStatus(e.target.value)
											}
											value={bondStatus}
											placeholder="Select Status"
										>
											{bondStatusOptions.map((option) => {
												return (
													<option
														value={option.value}
													>
														{option.label}
													</option>
												);
											})}
										</select>
									</div>

									<div className="flex flex-col">
										<label className="text-stone-600 text-sm font-medium mb-1">
											Issued Date
										</label>
										<RangePicker
											value={[
												fromIssuedDate,
												toIssuedDate,
											]}
											onChange={(date, dateString) => {
												if (date) {
													setFromIssuedDate(date[0]);
													setToIssuedDate(date[1]);
												} else {
													setFromIssuedDate("");
													setToIssuedDate("");
												}
											}}
											format="DD/MM/YYYY"
											className="mt-1 w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none"
										/>
									</div>

									<div className="flex flex-col">
										<label className="text-stone-600 text-sm font-medium mb-1">
											Maturity Date
										</label>
										<RangePicker
											value={[
												fromMaturityDate,
												toMaturityDate,
											]}
											onChange={(date, dateString) => {
												if (date) {
													setFromMaturityDate(
														date[0]
													);
													setToMaturityDate(date[1]);
												} else {
													setFromMaturityDate("");
													setToMaturityDate("");
												}
											}}
											format="DD/MM/YYYY"
											className="mt-1 w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none"
										/>
									</div>
								</div>

								<div className="mt-6 grid w-full grid-cols-2 justify-end space-x-4 md:flex">
									<button
										className="active:scale-95 rounded-lg bg-gray-200 px-8 py-2 font-medium text-gray-600 outline-none"
										onClick={resetFilter}
									>
										Reset
									</button>
									<button
										className="active:scale-95 rounded-lg bg-blue-600 px-8 py-2 font-medium text-white outline-none"
										onClick={applyFilter}
									>
										Apply
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
				<Table
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					rows={rows}
					prepareRow={prepareRow}
					tableName="Requests"
				/>
				{bookmark && (
					<div className="text-center">
						<button
							className="py-2 px-8 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
							onClick={handleLoadMore}
						>
							Load More
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default BondReports;
