import axiosHttpService from "../axioscall";

export const getTokenizedBondsByField = async (field, value) => {
	const options = {
		url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/tokenizedBond/getTokenizedBond`,
		method: "POST",
		headers: {},
		data: {
			field: field,
			value: value,
		},
	};

	const tokenizedBonds = await axiosHttpService(options);

	return tokenizedBonds;
};
