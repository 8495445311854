import React, { useState, useEffect } from "react";
import DygnifyImage from "../../../../assets/Dygnify_Image.png";
import DollarImage from "../../../../assets/Dollar-icon.svg";
import { getDisplayAmount } from "../../../../services/Helpers/displayTextHelper";
import BondDetailsModal from "../Modal/BondDetailsModal";
import { Badge, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { BondStatus } from "../../../../services/BackendConnectors/opportunityConnectors";

const PoolCard = ({ data, kycStatus }) => {
	const {
		opportunityAmount,
		estimatedAPY,
		capitalInvested,
		yieldGenerated,
		status,
	} = data;

	const [logoImgSrc, setLogoImgSrc] = useState();
	const [selected, setSelected] = useState(false);
	const path = useNavigate();
	useEffect(() => {
		setLogoImgSrc(
			data?.companyDetails?.companyLogoFile?.businessLogoFileCID
		);
	}, [data?.companyDetails?.companyLogoFile?.businessLogoFileCID]);

	return (
		<div className="md:w-[48%] 2xl:w-[min(37%,32rem)]">
			<div className="flex justify-end">
				{status === BondStatus.Tokenized ? (
					<Tooltip title={`Token Id: ${data?.nftId}`}>
						<div className="w-16 lg:w-18 cursor-pointer">
							<Badge.Ribbon text="Tokenized" color="#6A0DAD" />
						</div>
					</Tooltip>
				) : (
					<div className="w-16 lg:w-18">
						<Badge.Ribbon
							text={
								status === BondStatus.Approved
									? "Subscribed"
									: "Matured"
							}
							color={
								status === BondStatus.Approved
									? "#00AA00"
									: "#808080"
							}
						/>
					</div>
				)}
			</div>
			<div
				onClick={() => {
					setSelected(true);
				}}
				className="flex flex-col gap-6 px-4 py-6 rounded-xl sm:px-8 lg:flex-row my-gradient cursor-pointer"
			>
				<div className="flex items-center gap-6">
					<img
						style={{
							borderRadius: "50%",
							aspectRatio: "1/1",
						}}
						className="w-[7rem] lg:w-[12rem]"
						src={logoImgSrc ? logoImgSrc : DygnifyImage}
						alt=""
					/>

					<div className="lg:hidden">
						<p className="text-2xl font-normal">
							{data?.opportunityName}
						</p>
						<p>{data?.companyDetails?.companyName}</p>
					</div>
				</div>

				<div className="flex flex-col gap-6 lg:w-[75%]">
					<div className="hidden lg:block">
						<p className="text-2xl font-normal">
							{data?.opportunityName}
						</p>
						<p>{data?.companyDetails?.companyName}</p>
					</div>

					<div className="flex flex-col gap-1">
						<div className="flex gap-1 font-normal">
							<p>Amount</p>

							<img
								src={DollarImage}
								className="ml-auto w-[1rem]"
								alt=""
							/>
							<p>{opportunityAmount}</p>
						</div>
						<div className="flex gap-1 font-normal">
							<p>Invested Amount</p>

							<img
								src={DollarImage}
								className="ml-auto w-[1rem]"
								alt=""
							/>
							<p>{getDisplayAmount(capitalInvested)}</p>
						</div>
						<div className="flex gap-1 font-normal">
							<p>Interest Rate</p>

							<p className=" ml-auto">{estimatedAPY}</p>
						</div>
						<div className="flex gap-1 font-normal">
							<p>Yield Generated</p>

							<img
								src={DollarImage}
								className="ml-auto w-[1rem]"
								alt=""
							/>
							<p>
								{yieldGenerated
									? getDisplayAmount(+yieldGenerated)
									: "--"}
							</p>
						</div>
						<div className="font-normal">
							<label
								className="text-[#4B74FF] cursor-pointer"
								onClick={() => {
									path("/investorDashboard/viewPool", {
										state: {
											...data,
											kycStatus: kycStatus,
											// isSenior: isSenior,
											viewMore: "investor",
										},
									});
								}}
							>
								More Details
							</label>
						</div>
					</div>
				</div>
			</div>
			<BondDetailsModal
				selected={selected}
				data={data}
				setSelected={setSelected}
			/>
		</div>
	);
};

export default PoolCard;
