import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { DatePicker } from "antd";
import { captureException } from "@sentry/react";

const BankDetailsModal = ({ selected, setSelected, setUtrNo, onSubmit }) => {
	const [isSubmit, setisSubmit] = useState(false);
	const [isDisable, setIsDisable] = useState(true);

	const formik = useFormik({
		initialValues: {
			benificiaryName: "",
			benificiaryBank: "",
			benificiaryBankAccNo: "",
			utrNo: "",
			transactionDate: "",
		},
		onSubmit: async (values) => {
			// Handle login logic here
			setisSubmit(true);

			try {
				if (setUtrNo) {
					setUtrNo(values.utrNo);
				}

				await onSubmit({
					benificiaryName: values.benificiaryName,
					benificiaryBank: values.benificiaryBank,
					benificiaryBankAccNo: values.benificiaryBankAccNo,
					utrNo: values.utrNo,
					transactionDate:
						values.transactionDate?.format("DD/MM/YYYY"),
				});
				setSelected(false);
				setisSubmit(false);
			} catch (error) {
				captureException(error);
				setSelected(false);
				setisSubmit(false);
			}
		},
	});

	useEffect(() => {
		if (
			formik.values.benificiaryBank !== "" &&
			formik.values.benificiaryBankAccNo !== "" &&
			formik.values.benificiaryName !== "" &&
			formik.values.utrNo !== "" &&
			formik.values.transactionDate !== ""
		) {
			setIsDisable(false);
		} else {
			setIsDisable(true);
		}

		if (isSubmit) {
			setIsDisable(true);
		}
	}, [
		formik.values.benificiaryBank,
		formik.values.benificiaryBankAccNo,
		formik.values.benificiaryName,
		formik.values.utrNo,
		formik.values.transactionDate,
		isSubmit,
	]);
	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={selected}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal"
			>
				<div className="relative p-4 w-[100%] sm:w-[85%] md:w-[70%] lg:w-[50%] xl:w-[45%] 2xl:w-[45rem] h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<h3 className="font-normal text-xl">
								Transaction Details
							</h3>

							<button
								type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-3"
								data-modal-toggle="popup-modal"
								onClick={() => {
									setSelected(false);
								}}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>

						<div className="p-6">
							<form
								onSubmit={formik.handleSubmit}
								autoComplete="off"
							>
								<div className="md:gap-3 grid grid-cols-1">
									<div
										className={`w-full  md:mr-2 mb-5 md:mb-0`}
									>
										<label className="label text-base">
											<span className="text-black dark:text-white">
												Beneficiary Name
											</span>
										</label>
										<input
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name="benificiaryName"
											value={
												formik.values.benificiaryName
											}
											type="text"
											placeholder="Enter Beneficiary Name"
											className="input input-bordered w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
											style={{
												borderWidth: "1px",
												borderRadius: "8px",
											}}
											required
										/>
									</div>

									<div
										className={`w-full  md:mr-2 mb-5 md:mb-0`}
									>
										<label className="label text-base">
											<span className="text-black dark:text-white">
												Beneficiary Bank
											</span>
										</label>
										<input
											onChange={formik.handleChange}
											name="benificiaryBank"
											value={
												formik.values.benificiaryBank
											}
											type="text"
											placeholder="Enter Beneficiary Bank"
											className="input input-bordered w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
											style={{
												borderWidth: "1px",
												borderRadius: "8px",
											}}
											required
										/>
									</div>
									<div
										className={`w-full  md:mr-2 mb-5 md:mb-0`}
									>
										<label className="label text-base">
											<span className="text-black dark:text-white">
												Beneficiary Bank a/c no
											</span>
										</label>
										<input
											onChange={formik.handleChange}
											name="benificiaryBankAccNo"
											value={
												formik.values
													.benificiaryBankAccNo
											}
											type="text"
											placeholder="Enter Bank a/c no"
											className="input input-bordered w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
											style={{
												borderWidth: "1px",
												borderRadius: "8px",
											}}
											required
										/>
									</div>
									<div
										className={`w-full  md:mr-2 mb-5 md:mb-0`}
									>
										<label className="label text-base">
											<span className="text-black dark:text-white">
												UTR
											</span>
										</label>
										<input
											onChange={formik.handleChange}
											name="utrNo"
											value={formik.values.utrNo}
											type="text"
											placeholder="Enter UTR"
											className="input input-bordered w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
											style={{
												borderWidth: "1px",
												borderRadius: "8px",
											}}
											required
										/>
									</div>
									<div
										className={`w-full  md:mr-2 mb-5 md:mb-0`}
									>
										<label className="label text-base">
											<span className="text-black dark:text-white">
												Transaction Date
											</span>
										</label>
										<DatePicker
											onChange={(date, datestring) => {
												console.log(date, datestring);
												formik.setFieldValue(
													"transactionDate",
													date
												);
											}}
											name="transactionDate"
											value={
												formik.values.transactionDate
											}
											format="DD/MM/YYYY"
											className="input input-bordered w-full bg-lightmode-200 dark:bg-darkmode-800 hover:border-[#BBC0CC] active:border-[#BBC0CC] outline-none dark:border-[#3A3C43] border-[#BBC0CC]"
											style={{
												borderWidth: "1px",
												borderRadius: "8px",
											}}
											required
										/>
									</div>
								</div>
								<div className="mt-10 flex  justify-around">
									<button
										onClick={() => {
											setSelected(false);
										}}
										data-modal-toggle="popup-modal"
										type="button"
										className={`py-2 px-12 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]`}
									>
										Cancel
									</button>
									<button
										disabled={
											!formik.values.benificiaryName &&
											!formik.values.benificiaryBank &&
											!formik.values
												.benificiaryBankAccNo &&
											!formik.values.utrNo &&
											!formik.values.transactionDate
										}
										data-modal-toggle="popup-modal"
										type="submit"
										className={`${
											!isDisable
												? "py-2 relative px-12 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
												: "py-2 relative px-12 bg-gradient-to-r bg-neutral-400 cursor-not-allowed  opacity-40 capitalize font-medium border-none rounded-3xl"
										}`}
									>
										Submit
										{isSubmit && (
											<div className="animate-spin border-solid  top-3 left-16 absolute border-[2.4px] border-t-[#14171F] border-r-[#14171F] border-[#fff] w-[1.3rem] h-[1.3rem] rounded-full"></div>
										)}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BankDetailsModal;
