import React, { useEffect, useState, useContext } from "react";
import { getBonds } from "../../services/BackendConnectors/opportunityConnectors";
import OpportunityCardCollapsible from "./Components/Cards/OpportunityCardCollapsible";
import DashboardHeader from "./DashboardHeader";
import LoanFormModal from "./Components/Modal/LoanFormModal";
import ProcessingRequestModal from "./Components/Modal/ProcessingModal";
import KycCheckModal from "./Components/Modal/KycCheckModal";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import Loader from "../../uiTools/Loading/Loader";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import { captureException, captureMessage } from "@sentry/react";

const BorrowList = () => {
	const [opportunities, setOpportunities] = useState([]);
	const [selected, setSelected] = useState(null);
	const [kycSelected, setKycSelected] = useState();
	const [borrowReqProcess, setBorrowReqProcess] = useState(false);
	const [processModal, setProcessModal] = useState(false);

	const [loading, setLoading] = useState(true);
	const [kycStatus, setKycStatus] = useState();
	const [profileStatus, setProfileStatus] = useState();
	const [updateRepayment, setUpdateRepayment] = useState(12);
	const { userData } = useContext(LoginContext);
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});

	const [fileUpload, setFileUpload] = useState({});

	const handleForm = () => {
		setSelected(null);
		setKycSelected(null);
	};

	const cutProcessModal = () => {
		setSelected(null);
		setProcessModal(null);
	};

	useEffect(() => {
		if (!userData) {
			setLoading(false);
			return;
		}

		checkForKycAndProfile();
		setLoading(true);
		getBonds(userData.Id)
			.then((res) => {
				if (res) {
					if (res.success) {
						res.opportunities.sort(
							sortByProperty("epochCreationDate")
						);
						setOpportunities(res.opportunities);
					} else {
						captureMessage(res.msg, "error");
					}
				}
				setLoading(false);
			})
			.catch((error) => captureException(error));
	}, [userData, updateRepayment]);

	function sortByProperty(property) {
		return function (a, b) {
			if (a[property] < b[property]) return 1;
			else if (a[property] > b[property]) return -1;

			return 0;
		};
	}

	const checkForKycAndProfile = () => {
		setKycStatus(userData?.kycStatus);
		if (JSON.parse(userData?.profile)) {
			setProfileStatus(true);
		} else {
			setProfileStatus(false);
		}
	};

	return (
		<div className={`relative mb-16 ${loading ? "h-[100vh]" : ""}`}>
			{loading && <Loader />}
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			<div className={`${loading ? "blur-sm" : ""}`}>
				<DashboardHeader
					setSelected={setSelected}
					kycStatus={kycStatus}
					profileStatus={profileStatus}
					setKycSelected={setKycSelected}
				/>

				{selected && (
					<LoanFormModal
						handleForm={handleForm}
						setBorrowReqProcess={setBorrowReqProcess}
						setSelected={setSelected}
						setProcessModal={setProcessModal}
						setUpdateRepayment={setUpdateRepayment}
						setFileUpload={setFileUpload}
						setErrormsg={setErrormsg}
					/>
				)}

				{processModal && (
					<ProcessingRequestModal
						borrowReqProcess={borrowReqProcess}
						setSelected={setSelected}
						handleDrawdown={cutProcessModal}
						setProcessModal={setProcessModal}
						processModal={processModal}
						fileUpload={fileUpload}
						setUpdateRepayment={setUpdateRepayment}
					/>
				)}

				{kycSelected ? (
					<KycCheckModal
						kycStatus={kycStatus}
						profileStatus={profileStatus}
					/>
				) : (
					<></>
				)}

				<div className="my-16">
					<div className="collapse">
						<input type="checkbox" className="peer" />
						<div className="collapse-title my-4 font-bold flex gap-4 md:gap-8 text-center py-6 border-y border-darkmode-500">
							<p className="w-1/3 md:w-1/5 ">Request name</p>
							<p className="w-1/3 md:w-1/5 ">Request type</p>
							<p className="hidden md:block w-1/5 ">
								Amount requested
							</p>
							<p className="w-1/3  md:w-1/5 ">Created on</p>
							<p className="w-1/3  md:w-1/5 ">Status</p>
						</div>
					</div>
					{opportunities.length === 0 ? (
						<div className="h-[10rem] flex items-center justify-center">
							<p className="text-lg font-normal text-neutral-500">
								No borrow request available.
							</p>
						</div>
					) : (
						<div className="flex flex-col gap-3">
							{opportunities
								? opportunities.map((item) => (
										<OpportunityCardCollapsible
											key={item.Id}
											data={item}
											kycStatus={userData?.kycStatus}
										/>
								  ))
								: null}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default BorrowList;
