import img from "../../assets/loading.png";

// function Loader({ x }) {
// 	return (
// 		<div
// 			style={{
// 				display: "flex",
// 			}}
// 			className="z-20 absolute inset-0 items-center justify-center "
// 		>
// 			<div
// 				className={`animate-spin border-solid border-4 border-t-[#14171F] border-[#fff] w-[5rem] h-[5rem] rounded-full relative ${
// 					x ? "left-40" : ""
// 				}`}
// 			></div>
// 		</div>
// 	);
// }
function Loader({ x }) {
	return (
		<div
			style={{
				display: "flex",
			}}
			className="z-20 fixed inset-0 items-center justify-center "
		>
			<div className="relative">
				<div className="animate-spin w-[5rem] h-[5rem] relative">
					<div
						className={`border-solid border-4 border-t-[#14171F] border-[#fff] w-[5rem] h-[5rem] rounded-full absolute ${
							x ? "left-[calc(50% + 2.5rem)]" : "-left-2.5rem"
						}`}
					></div>
				</div>
				<div className="text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<img src={img} alt="" />
				</div>
			</div>
		</div>
	);
}
export default Loader;
