import { useFormik } from "formik";
import GradientButton from "../../../../uiTools/Button/GradientButton";

export default function Details({ formData, handleNext, handleForm }) {
	const formik = useFormik({
		initialValues: formData,
		onSubmit: (values, { resetForm }) => {
			values.investorUpfrontFeesPercentage = 0;
			values.juniorTranchFloatInterestPercentage = 0;
			console.log("clicked.", values);
			handleNext(values, false);
		},
	});

	return (
		<>
			<div className=" flex flex-col mt-20 md:mt-16 md:px-5 ">
				<form onSubmit={formik.handleSubmit}>
					<div className="py-12 my-4 mx-16 px-6 rounded-lg bg-slate-200">
						<div className="flex gap-8 items-center justify-center mb-6 h-6 w-full">
							<label
								htmlFor="delayChargeRatePercentage"
								className="ml-2 w-80"
							>
								Delay Charge Rate (%)
							</label>
							<input
								name="delayChargeRatePercentage"
								type="number"
								placeholder="Enter delayed charge percentage"
								className="input input-bordered input-sm w-56 max-w-xs"
								data-theme="light"
								step="0.001"
								value={formik.values.delayChargeRatePercentage}
								min={0}
								max={100}
								onChange={formik.handleChange}
								required
							/>
						</div>
						<div className="flex gap-8 items-center justify-center mb-6 h-6">
							<label
								htmlFor="juniorTranchPercentage"
								className="ml-2 w-80"
							>
								Junior Tranche (%)
							</label>
							<input
								name="juniorTranchPercentage"
								type="number"
								placeholder="Enter junior tranche percentage"
								className="input input-bordered input-sm w-56 max-w-xs"
								data-theme="light"
								step="0.001"
								value={formik.values.juniorTranchPercentage}
								min={0}
								max={100}
								onChange={formik.handleChange}
								required
							/>
						</div>
					</div>

					<div className="flex justify-center items-center flex-col-reverse  md:flex-row md:justify-around gap-5  mt-6 -mb-2 overflow-hidden">
						<div className="">
							<label
								className="cursor-pointer md:pl-24 ml-1 text-lg"
								onClick={handleForm}
							>
								Cancel
							</label>
						</div>
						<div className="md:pr-40 lg:pr-10 xl:pr-0 py-1">
							<GradientButton type="submit">Next</GradientButton>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
