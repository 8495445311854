import { getGUID } from "../../Helpers/displayTextHelper";
import {
	BorrowerTransactionType,
	TransactionStatus,
} from "../opportunityConnectors";
import axiosHttpService from "../../axioscall";
import convertTimestampToDate from "../../Helpers/dateFunctions";
const { ethers } = require("ethers");
const { requestAccount, getEthAddress } = require("./commonConnectors");
const borrowerContract = require("../../../artifacts/contracts/protocol/Borrower.sol/Borrower.json");
const opportunityPool = require("../../../artifacts/contracts/protocol/OpportunityPool.sol/OpportunityPool.json");
const { retrieveFileFromURL } = require("../../Helpers/fileHelper");
const {
	getIPFSFileURL,
	getIPFSFileURLOption2,
	getIPFSFileURLOption3,
} = require("../../Helpers/web3storageIPFS");
const Sentry = require("@sentry/react");

export const getBorrowerDetails = async (address) => {
	try {
		if (typeof window.ethereum !== "undefined") {
			await requestAccount();
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const contract = new ethers.Contract(
				process.env.REACT_APP_BORROWER,
				borrowerContract.abi,
				provider
			);

			if (!address) {
				Sentry.captureMessage("Address not found", "info");
				let { result } = await getEthAddress();
				address = result;
			}

			if (address) {
				Sentry.captureMessage("Address received", "info");

				const borrowerCid = await contract.borrowerProfile(address);
				return { borrowerCid, success: true };
			}
		} else {
			Sentry.captureMessage("Wallet not connected", "warning");
			return {
				success: false,
				msg: "please connect your wallet",
			};
		}
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}

	return undefined;
};

export const updateBorrowerDetails = async (cid) => {
	try {
		if (typeof window.ethereum !== "undefined" && cid) {
			await requestAccount();
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const signer = provider.getSigner();
			const contract = new ethers.Contract(
				process.env.REACT_APP_BORROWER,
				borrowerContract.abi,
				signer
			);

			let transaction = await contract.updateBorrowerProfile(cid);
			await transaction.wait();

			return { success: true };
		}

		Sentry.captureMessage("Wallet not connected", "warning");
		return {
			success: false,
			msg: "please connect your wallet",
		};
	} catch (error) {
		Sentry.captureException(error);

		return {
			success: false,
			msg: error.message,
		};
	}
};

export const repayment = async (bondData, bankDetails) => {
	try {
		if (!bondData) {
			return {
				success: false,
				msg: "Invalid request data",
			};
		}

		console.log("bonddata", bondData);
		let interest,
			principalPortion,
			principalAmt,
			totalPaidAmt,
			repaymentAmount,
			nextRepaymentAmount;
		// const interest = getTermLoanInterest(
		// 	bondData.totalOutstandingPrincipal,
		// 	bondData.loanActualInterest,
		// 	bondData.actualLoanTenure,
		// 	bondData.actualPaymentFrequency
		// );
		// const principalPortion = bondData.repaymentAmount - interest;
		// const principalAmt =
		// 	bondData.totalOutstandingPrincipal - principalPortion;
		// const totalPaidAmt =
		// 	bondData.totalRepaidAmount + +bondData.repaymentAmount;

		// Get the tokenized bond
		let options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/tokenizedBond/getTokenizedBond`,
			method: "POST",
			headers: {},
			data: {
				field: "Id",
				value: bondData.tokenizedBondId,
			},
		};

		let tokenizedBonds = await axiosHttpService(options);
		tokenizedBonds = tokenizedBonds.res.data;

		console.log("tokenizedBonds**  ", tokenizedBonds);

		if (bondData.loanType === "1") {
			// tokenizedBond.emiAmount = getTermLoanEMI(
			// 	bond.actualLoanAmount,
			// 	bond.loanActualInterest,
			// 	bond.actualLoanTenure,
			// 	bond.actualPaymentFrequency
			// );
			const options = {
				url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/accounting/getTermLoanAmortisationSchedule`,
				method: "post",
				headers: {},
				data: {
					loanAmount: bondData.actualLoanAmount,
					interestRatePercentage: bondData.loanActualInterest,
					tenureInMonths: bondData.actualLoanTenure,
					paymentFrequencyInDays: bondData.actualPaymentFrequency,
					disbursmentDate: convertTimestampToDate(
						tokenizedBonds.repaymentStartTime
					),
					investorUpfrontFees: bondData.investorUpfrontFeesPercentage,
					platformFeesPercentage:
						bondData.percentageOfCoupon !== undefined
							? bondData.percentageOfCoupon
							: 0,
					JuniorContributionPercentage:
						bondData.juniorTranchPercentage,
					JuniorPrincipalFloatPercentage:
						bondData.juniorTranchFloatInterestPercentage,
				},
			};
			let res = await axiosHttpService(options);
			const amortisationSchedule = res.res.amortisationSchedule;
			console.log(
				"getTermLoanAmortisationSchedule",
				amortisationSchedule
			);

			interest =
				amortisationSchedule[
					tokenizedBonds.repaymentCounter - 1
				].interest.toString();
			principalPortion =
				amortisationSchedule[tokenizedBonds.repaymentCounter - 1]
					.principal;
			principalAmt =
				amortisationSchedule[tokenizedBonds.repaymentCounter - 1]
					.remainingPrincipal;
			repaymentAmount =
				amortisationSchedule[tokenizedBonds.repaymentCounter - 1]
					.totalPayment;
			nextRepaymentAmount = repaymentAmount;
			totalPaidAmt = bondData.totalRepaidAmount + +repaymentAmount;
		} else {
			// tokenizedBond.emiAmount = getBulletLoanEMI(
			// 	bond.actualLoanAmount,
			// 	bond.loanActualInterest,
			// 	bond.actualPaymentFrequency
			// );
			const options = {
				url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/accounting/getBulletLoanAmortisationSchedule`,
				method: "post",
				headers: {},
				data: {
					loanAmount: bondData.actualLoanAmount,
					interestRatePercentage: bondData.loanActualInterest,
					tenureInMonths: bondData.actualLoanTenure,
					paymentFrequencyInDays: bondData.actualPaymentFrequency,
					disbursmentDate: convertTimestampToDate(
						tokenizedBonds.repaymentStartTime
					),
					investorUpfrontFees: bondData.investorUpfrontFeesPercentage,
					platformFeesPercentage: bondData.percentageOfCoupon
						? bondData.percentageOfCoupon
						: 10,
					JuniorContributionPercentage:
						bondData.juniorTranchPercentage,
					JuniorPrincipalFloatPercentage:
						bondData.juniorTranchFloatInterestPercentage,
				},
			};
			let res = await axiosHttpService(options);
			const amortisationSchedule = res.res.amortisationSchedule;
			console.log(
				"getBulletLoanAmortisationSchedule",
				amortisationSchedule
			);
			// interest =
			// 	amortisationSchedule[
			// 		tokenizedBonds.repaymentCounter - 1
			// 	].interest.toString();
			principalPortion = 0;
			principalAmt =
				amortisationSchedule[tokenizedBonds.repaymentCounter - 1]
					.principal;
			interest = bondData.finalRepaymentAmount - principalAmt;
			repaymentAmount =
				amortisationSchedule[tokenizedBonds.repaymentCounter - 1]
					.totalPayment;
			nextRepaymentAmount = amortisationSchedule[
				tokenizedBonds.repaymentCounter
			]?.totalPayment
				? amortisationSchedule[tokenizedBonds.repaymentCounter]
						.totalPayment
				: "0";
			totalPaidAmt =
				bondData.totalRepaidAmount + +bondData.finalRepaymentAmount;

			// if last repayment, then pay with principal amount
			if (tokenizedBonds.repaymentCounter === bondData.totalRepayments) {
				principalPortion = principalAmt;
				principalAmt = 0;
			}
		}

		const txHash = getGUID();
		const data = {
			txHash,
			issuerId: bondData.borrower,
			bondId: bondData.Id,
			amount: bondData.finalRepaymentAmount,
			bondName: bondData.opportunityName,
			investedOn: Date.now(),
			borrowerTransactionType: BorrowerTransactionType.Repaid,
			isCouponRateDistributionPending: true,
			interestPortion: interest.toString(),
			principalPortion: principalPortion,
			repaymentNumber: bondData.repaymentCounter - 1,
			status: TransactionStatus.InVerification,
			...bankDetails,
		};
		// const res = await addData(TransactionsCollection, data);
		options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/transaction/createTx`,
			method: "POST",
			headers: {},
			data: {
				...data,
			},
		};
		const res = await axiosHttpService(options);

		return res;
	} catch (error) {
		Sentry.captureException(error);

		return {
			success: false,
			msg: error.message,
		};
	}
};
export const drawdown = async (poolAddress) => {
	try {
		if (typeof window.ethereum !== "undefined") {
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			console.log({ provider });
			const signer = provider.getSigner();
			const poolContract = new ethers.Contract(
				poolAddress,
				opportunityPool.abi,
				signer
			);

			const transaction1 = await poolContract.drawdown();
			await transaction1.wait();
			return { success: true, hash: transaction1 };
		}
		Sentry.captureMessage("Wallet not connected", "warning");
		return {
			success: false,
			msg: "please connect your wallet",
		};
	} catch (error) {
		Sentry.captureException(error);

		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getBorrowerJson = async (address) => {
	Sentry.captureMessage("getBorrowerJson", "info");
	try {
		let res = await getBorrowerDetails(address);
		if (res.success) {
			let dataReader = await retrieveFileFromURL(
				getIPFSFileURL(res.borrowerCid) + "/borrower.json"
			);
			if (!dataReader) {
				dataReader = await retrieveFileFromURL(
					getIPFSFileURLOption2(res.borrowerCid) + "/borrower.json"
				);
			}
			if (!dataReader) {
				dataReader = await retrieveFileFromURL(
					getIPFSFileURLOption3(res.borrowerCid) + "/borrower.json"
				);
			}
			return dataReader;
		}
	} catch (error) {
		Sentry.captureException(error);
	}
};

export const getBorrowerLogoURL = (imgCID, fileName) => {
	if (imgCID && fileName) {
		let imgUrl = getIPFSFileURL(imgCID);
		let sanitizedFileName = encodeURI(fileName);
		return `${imgUrl}/${sanitizedFileName}`;
	}
};

export const getOpportunityJson = async (opportunityData) => {
	Sentry.captureMessage("getOpportunityJson", "info");
	try {
		if (opportunityData && opportunityData.opportunityInfo) {
			let dataReader = await retrieveFileFromURL(
				getIPFSFileURL(opportunityData.opportunityInfo) +
					`/${opportunityData.collateralDocument}.json`
			);
			if (!dataReader) {
				dataReader = await retrieveFileFromURL(
					getIPFSFileURLOption2(opportunityData.opportunityInfo) +
						`/${opportunityData.collateralDocument}.json`
				);
			}
			if (!dataReader) {
				dataReader = await retrieveFileFromURL(
					getIPFSFileURLOption3(opportunityData.opportunityInfo) +
						`/${opportunityData.collateralDocument}.json`
				);
			}
			return dataReader;
		}
	} catch (error) {
		Sentry.captureException(error);
	}
};
