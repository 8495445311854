import { captureException } from "@sentry/react";
import convertTimestampToDate from "../Helpers/dateFunctions";
import axiosHttpService from "../axioscall";

export const getAmortisationSchedule = async (bond, repaymentStartTime) => {
	try {
		let options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/accounting/${
				bond.loan_type == 0
					? "getBulletLoanAmortisationSchedule"
					: "getTermLoanAmortisationSchedule"
			}`,
			method: "post",
			headers: {},
			data: {
				loanAmount: +bond.loan_amount,
				interestRatePercentage: +bond.loan_interest,
				tenureInMonths: +bond.loan_tenure / 30,
				paymentFrequencyInDays: +bond.payment_frequency,
				disbursmentDate: convertTimestampToDate(repaymentStartTime),
				investorUpfrontFees: bond.investorUpfrontFeesPercentage
					? +bond.investorUpfrontFeesPercentage
					: 0,
				platformFeesPercentage: bond.percentageOfCoupon
					? +bond.percentageOfCoupon
					: 0,
				JuniorContributionPercentage: bond.juniorTranchPercentage
					? +bond.juniorTranchPercentage
					: 0,
				JuniorPrincipalFloatPercentage:
					bond.juniorTranchFloatInterestPercentage
						? +bond.juniorTranchFloatInterestPercentage
						: 0,
			},
		};
		let result = await axiosHttpService(options);
		return result;
	} catch (error) {
		captureException(error);
	}
};

export const getRepaymentAmount = async (
	loanStartDate,
	repaymentDueDate,
	delayedInterest,
	outstandingPrincipal,
	loanInterest,
	emiAmount
) => {
	try {
		let options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/accounting/getRepaymentAmount`,
			method: "post",
			headers: {},
			data: {
				loanStartDate: loanStartDate,
				repaymentDueDate: repaymentDueDate,
				delayedInterest: delayedInterest,
				outstandingPrincipal: outstandingPrincipal,
				loanInterest: loanInterest,
				emiAmount: emiAmount,
			},
		};
		let res = await axiosHttpService(options);
		return res;
	} catch (error) {
		captureException(error);
	}
};
