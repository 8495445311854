import React from "react";

const BorIcon = ({color}) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 4.5C4 3.83696 4.26339 3.20107 4.73223 2.73223C5.20107 2.26339 5.83696 2 6.5 2L18 2C18.3283 2 18.6534 2.06466 18.9567 2.1903C19.26 2.31594 19.5356 2.50009 19.7678 2.73223C19.9999 2.96438 20.1841 3.23998 20.3097 3.54329C20.4353 3.84661 20.5 4.1717 20.5 4.5L20.5 18.75C20.5 18.9489 20.421 19.1397 20.2803 19.2803C20.1397 19.421 19.9489 19.5 19.75 19.5L5.5 19.5C5.5 19.7652 5.60536 20.0196 5.79289 20.2071C5.98043 20.3946 6.23478 20.5 6.5 20.5L19.75 20.5C19.9489 20.5 20.1397 20.579 20.2803 20.7197C20.421 20.8603 20.5 21.0511 20.5 21.25C20.5 21.4489 20.421 21.6397 20.2803 21.7803C20.1397 21.921 19.9489 22 19.75 22L6.5 22C5.83696 22 5.20107 21.7366 4.73223 21.2678C4.26339 20.7989 4 20.163 4 19.5L4 4.5ZM10.197 7.464C9.622 7.739 9 8.24 9 9C9 9.76 9.622 10.26 10.197 10.536C10.819 10.833 11.634 11 12.5 11C13.366 11 14.181 10.833 14.803 10.536C15.378 10.261 16 9.76 16 9C16 8.24 15.379 7.74 14.803 7.464C14.18 7.167 13.366 7 12.5 7C11.634 7 10.819 7.167 10.197 7.464ZM15.995 10.89C15.17 11.567 13.91 12 12.5 12C11.09 12 9.83 11.567 9.005 10.89C9.0016 10.9266 8.99993 10.9633 9 11C9 12.105 10.567 13 12.5 13C14.433 13 16 12.105 16 11C16 10.963 15.998 10.927 15.995 10.89ZM12.5 14C11.09 14 9.83 13.567 9.005 12.89C9.0016 12.9266 8.99993 12.9633 9 13C9 14.105 10.567 15 12.5 15C14.433 15 16 14.105 16 13C16.0001 12.9633 15.9984 12.9266 15.995 12.89C15.17 13.567 13.91 14 12.5 14Z"
				fill={color ? "#9281FF" : "#64748B"}
			/>
		</svg>
	);
};

export default BorIcon;
