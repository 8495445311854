import axiosHttpService from "../axioscall";

export const getAllGreenBonds = async () => {
	const options = {
		url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/bond/getAllBonds`,
		method: "POST",
		headers: {},
	};
	const bonds = await axiosHttpService(options);
	return bonds;
};

export const getGreenBondsByField = async (field, value) => {
	const options = {
		url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/bond/getBond`,
		method: "POST",
		headers: {},
		data: {
			field: field,
			value: value,
		},
	};
	const bond = await axiosHttpService(options);
	return bond;
};
