import React, { useState, useEffect, useRef, useContext } from "react";
import { Formik } from "formik";
import TextField from "../../uiTools/Inputs/TextField";
import InputGroup from "../../uiTools/Inputs/InputGroup";
import TextArea from "../../uiTools/Inputs/TextArea";
import FileUploader from "../../uiTools/FileUploader/FileUploader";
import { useLocation, useNavigate } from "react-router-dom";
import GradientButton from "../../uiTools/Button/GradientButton";
import ProcessingModal from "./Components/Modal/FileUpload/ProcessingModal";
import { captureException, captureMessage } from "@sentry/react";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import { Field } from "formik";
import axiosHttpService from "../../services/axioscall";
import Joi from "joi";
import { joiFormikAdapter } from "joi-formik-adapter";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import { sustainableCheckedData } from "./SustainableData/sustainableData";
import { uploadFiles } from "../../services/Helpers/fileHelper";

const URL =
	/^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

const EditBorrowerProfileNew = () => {
	const navigate = useNavigate();

	const [fileErr, setFileErr] = useState({
		bip: false,
		bap: false,
		bicp: false,
	});

	const [profileState, setProfileState] = useState(null);
	const [hasKey, setHasKey] = useState();
	const [hasGstKey, setHasGstKey] = useState();
	const [hasBankKey, setBankHasKey] = useState();
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});
	const [logoFile, setLogoFile] = useState();
	const [businessIdentityFiles, setBusinessIdentityFiles] = useState();
	const [dueDiliAuFiles, setDueDiliAuFiles] = useState();
	const [dueDiliGstFiles, setDueDiliGstFiles] = useState();
	const [dueDiliRelFiles, setDueDiliRelFiles] = useState();
	const [dueDiliKycFiles, setDueDiliKycFiles] = useState();
	const [businessAddressFiles, setBusinessAddressFiles] = useState();
	const [businessIncorporationFiles, setBusinessIncorporationFiles] =
		useState();
	const [businessLicenseFiles, setBusinessLicenseFiles] = useState();

	const [uploading, setUploading] = useState(false);
	const [fileUploadStatus, setFileUploadStatus] = useState([]);
	const [logoError, setLogoError] = useState(false);
	const [checkLicense, setcheckLicense] = useState({
		err: false,
		msg: "",
	});

	const [lincenseText, setLincenseText] = useState("");
	const [lincenseFile, setLincenseFile] = useState(false);
	const [checkGst, setcheckGst] = useState({
		err: false,
		msg: "",
	});
	const [gstText, setGstText] = useState("");
	const [gstFile, setGstFile] = useState(false);
	const [checkBank, setcheckBank] = useState({
		err: false,
		msg: "",
	});
	const [bankText, setBankText] = useState("");
	const [bankFile, setBankFile] = useState(false);
	const { userData, login } = useContext(LoginContext);
	const [stainableCheckBoxData, setStainableCheckBoxData] = useState([]);
	const [checkedData, setcheckedData] = useState([]);

	const [collapse, setCollapse] = useState(true);

	useEffect(() => {
		if (checkedData.length > 0) {
			setCollapse(true);
		}
	}, [checkedData]);

	const location = useLocation();
	const oldBrJson = location.state;

	let logoFileCID = useRef();
	let businessIdFilesCID = useRef();
	let businessLicFilesCID = useRef();
	let businessAddFilesCID = useRef();
	let businessIncoFilesCID = useRef();

	let dueDiliAuFilesCID = useRef();
	let dueDiliGstFilesCID = useRef();
	let dueDiliRelFilesCID = useRef();
	let dueDiliKycFilesCID = useRef();

	let allowSubmit = true;

	const validationSchema = Joi.object({
		companyName: Joi.string().trim().required().label("Company Name"),
		companyRepName: Joi.string()
			.trim()
			.required()
			.label("Company Representative Name"),
		companyBio: Joi.string().trim().required().label("Company Bio"),
		bizIdFileName: Joi.string()
			.trim()
			.required("File name is required")
			.label("Business Identity Proof"),
		bizAddFileName: Joi.string()
			.trim()
			.required("File name is required")
			.label("Business Address Proof"),
		bizLicFileName: Joi.string().trim().label("Business License Proof"),
		bizIncoFileName: Joi.string()
			.trim()
			.required("File name is required")
			.label("Business Incorporation Proof"),
		dueAuFileName: Joi.string()
			.trim()
			.required("File name is required")
			.label("Financial Statement"),
		dueGstFileName: Joi.string().trim().label("GST Return"),
		dueRelFileName: Joi.string().trim().label("Bank Statement"),
		dueKycFileName: Joi.string()
			.trim()
			.required("File name is required")
			.label("KYC of the Promoters"),
		website: Joi.string()
			.regex(URL, "valid URL")
			.trim()
			.required("Website is required")
			.label("Website"),
		email: Joi.string()
			.email({ tlds: false })
			.trim()
			.required()
			.label("Email Address"),
		twitter: Joi.string().regex(URL, "valid URL").trim().label("Twitter"),
		linkedin: Joi.string().regex(URL, "valid URL").trim().label("Linkedin"),
	});

	useEffect(() => {
		if (location.state) {
			setProfileState(location.state);
			setHasKey(
				location.state ? "businessLicFile" in location.state : true
			);
			setHasGstKey(
				location.state ? "dueDiliGstFile" in location.state : true
			);
			setBankHasKey(
				location.state ? "dueDiliRelFile" in location.state : true
			);
		}

		if (location.state?.stainableCheckBoxData?.length > 0) {
			setStainableCheckBoxData(location.state?.stainableCheckBoxData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialValues = {
		companyName: profileState ? profileState.companyName : "",
		companyRepName: profileState ? profileState.companyRepName : "",
		companyBio: profileState ? profileState.companyBio : "",
		bizIdFileName: profileState
			? profileState.businessIdFile.businessIdDocName
			: "",
		bizAddFileName: profileState
			? profileState.businessAddFile.businessAddDocName
			: "",

		bizLicFileName:
			profileState && hasKey
				? profileState.businessLicFile.businessLicDocName
				: "",
		bizIncoFileName: profileState
			? profileState.businessIncoFile.businessIncoDocName
			: "",
		dueAuFileName: profileState
			? profileState?.dueDiliAuFile?.dueDiliAuDocName
			: "",
		dueGstFileName:
			profileState && hasGstKey
				? profileState?.dueDiliGstFile?.dueDiliGstDocName
				: "",
		dueRelFileName:
			profileState && hasBankKey
				? profileState?.dueDiliRelFile?.dueDiliRelDocName
				: "",
		dueKycFileName: profileState
			? profileState?.dueDiliKycFile?.dueDiliKycDocName
			: "",
		website: profileState ? profileState.website : "",
		email: profileState ? profileState.email : "",
		twitter: profileState ? profileState.twitter : "",
		linkedin: profileState ? profileState.linkedin : "",
		checked: profileState ? profileState.sustainableChecked : "",
	};

	useEffect(() => {
		if (profileState) {
			const {
				businessIdFile: IdFile,
				businessAddFile: AddFile,
				businessIncoFile: IncoFile,
				businessLicFile: LicFile,
				companyLogoFile: LogoFile,
				dueDiliGstFile: DueFile,
				dueDiliRelFile: DueBank,
			} = profileState;

			if (hasKey) {
				businessLicFilesCID.current = LicFile.businessLicFileCID;
			}
			if (hasGstKey) {
				dueDiliGstFilesCID.current = DueFile.dueDiliGstFileCID;
			}
			if (hasBankKey) {
				dueDiliRelFilesCID.current = DueBank.dueDiliRelFileCID;
			}

			logoFileCID.current = LogoFile.businessLogoFileCID;
			businessIdFilesCID.current = IdFile.businessIdFileCID;

			businessAddFilesCID.current = AddFile.businessAddFileCID;
			businessIncoFilesCID.current = IncoFile.businessIncoFileCID;
		}
		checkFunction(null);
		checkFunctionGst(null);
		checkFunctionBank(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profileState]);

	const checkEdited = (brJson) => {
		if (location.state) {
			if (JSON.stringify(brJson) === JSON.stringify(oldBrJson)) {
				return (allowSubmit = false);
			}
		}
	};

	const validations = () => {
		if (!logoFile && !location.state) setLogoError(true);

		const tempFileStatus = {
			bip: fileErr.bip,
			bap: fileErr.bap,
			bicp: fileErr.bicp,
			ssop: fileErr.ssop,
		};

		if (!location.state) {
			if (!businessIdentityFiles) tempFileStatus.bip = true;

			if (!businessAddressFiles) tempFileStatus.bap = true;

			if (!businessIncorporationFiles) tempFileStatus.bicp = true;
		}

		setFileErr(tempFileStatus);

		if (
			!(
				businessIdentityFiles &&
				businessIncorporationFiles &&
				businessAddressFiles &&
				logoFile
			) &&
			!location.state
		) {
			setUploading(false);
		}
	};

	const onLogoFileUpload = (files) => {
		setLogoFile(files);
		setLogoError(false);
	};

	const onBusinessIdentityFilesUpload = (files) => {
		setBusinessIdentityFiles(files);
		setFileErr((prev) => {
			return {
				...prev,
				bip: false,
			};
		});
	};
	const onBusinessAddressFilesUpload = (files) => {
		setBusinessAddressFiles(files);
		setFileErr((prev) => {
			return {
				...prev,
				bap: false,
			};
		});
	};
	const onBusinessIncorporationFilesUpload = (files) => {
		setBusinessIncorporationFiles(files);
		setFileErr((prev) => {
			return {
				...prev,
				bicp: false,
			};
		});
	};
	const onBusinessLicenseFilesUpload = (files) => {
		setBusinessLicenseFiles(files);
		checkFunction(files);
	};

	const onDueDiliAuFilesUpload = (files) => {
		setDueDiliAuFiles(files);
		setFileErr((prev) => {
			return {
				...prev,
				bip: false,
			};
		});
	};
	const onDueDiliGstFilesUpload = (files) => {
		setDueDiliGstFiles(files);
		checkFunctionGst(files);
	};
	const onDueDiliRelFilesUpload = (files) => {
		setDueDiliRelFiles(files);
		checkFunctionBank(files);
	};
	const onDueDiliKycFilesUpload = (files) => {
		setDueDiliKycFiles(files);
		setFileErr((prev) => {
			return {
				...prev,
				bip: false,
			};
		});
	};

	const uploadBorrowerData = async (formData) => {
		setUploading(true);
		try {
			const {
				companyName,
				companyRepName,
				companyBio,
				bizIdFileName,
				bizAddFileName,
				bizLicFileName,
				bizIncoFileName,
				dueAuFileName,
				dueGstFileName,
				dueRelFileName,
				dueKycFileName,
				website,
				email,
				twitter,
				linkedin,
				checked,
			} = formData;

			validations();
			let key = false;
			let gstKey = false;
			let bankKey = false;
			let tempFileStatus = [];
			if (businessLicenseFiles) key = true;
			if (dueDiliGstFiles) gstKey = true;
			if (dueDiliRelFiles) bankKey = true;

			if (!logoError || !fileErr.bip || !fileErr.bap || !fileErr.bicp) {
				if (logoFile && logoFile.length) {
					let logoFileObj = {
						fileName: logoFile[0].name,
						progress: 0,
						status: "Pending",
					};

					tempFileStatus.push(logoFileObj);
				}

				if (businessIdentityFiles && businessIdentityFiles.length) {
					let businessIdentityFilesObj = {
						fileName: businessIdentityFiles[0].name,
						progress: 0,
						status: "Pending",
					};

					tempFileStatus.push(businessIdentityFilesObj);
				}

				if (businessAddressFiles && businessAddressFiles.length) {
					let businessAddressFilesObj = {
						fileName: businessAddressFiles[0].name,
						progress: 0,
						status: "Pending",
					};

					tempFileStatus.push(businessAddressFilesObj);
				}

				if (
					businessIncorporationFiles &&
					businessIncorporationFiles.length
				) {
					let businessIncorporationFilesObj = {
						fileName: businessIncorporationFiles[0].name,
						progress: 0,
						status: "Pending",
					};

					tempFileStatus.push(businessIncorporationFilesObj);
				}

				if (businessLicenseFiles && businessLicenseFiles.length) {
					let businessLicenseFilesObj = {
						fileName: businessLicenseFiles[0].name,
						progress: 0,
						status: "Pending",
					};

					tempFileStatus.push(businessLicenseFilesObj);
				}
				setFileUploadStatus(tempFileStatus);

				if (
					businessIdentityFiles ||
					businessAddressFiles ||
					businessIncorporationFiles ||
					businessLicenseFiles ||
					logoFile
				)
					if ((logoFile && logoFile.length) || profileState) {
						logoFileCID.current = await uploadFiles(
							userData?.Id,
							logoFile ? logoFile : profileState.companyLogoFile
						);
					}
				if (
					(businessIdentityFiles && businessIdentityFiles.length) ||
					profileState
				) {
					businessIdFilesCID.current = await uploadFiles(
						userData?.Id,
						businessIdentityFiles
							? businessIdentityFiles
							: profileState.businessIdFile
					);
				}
				if (
					(businessAddressFiles && businessAddressFiles.length) ||
					profileState
				) {
					businessAddFilesCID.current = await uploadFiles(
						userData?.Id,
						businessAddressFiles
							? businessAddressFiles
							: profileState.businessAddFile
					);
				}
				if (
					(businessIncorporationFiles &&
						businessIncorporationFiles.length) ||
					profileState
				) {
					businessIncoFilesCID.current = await uploadFiles(
						userData?.Id,
						businessIncorporationFiles
							? businessIncorporationFiles
							: profileState.businessIncoFile
					);
				}
				if (
					(businessLicenseFiles &&
						businessLicenseFiles.length &&
						(hasKey || key)) ||
					(profileState && (hasKey || key))
				) {
					businessLicFilesCID.current = await uploadFiles(
						userData?.Id,
						businessLicenseFiles
							? businessLicenseFiles
							: profileState.businessLicFile
					);
				}
				if ((dueDiliAuFiles && dueDiliAuFiles.length) || profileState) {
					dueDiliAuFilesCID.current = await uploadFiles(
						userData?.Id,
						dueDiliAuFiles
							? dueDiliAuFiles
							: profileState.dueDiliAuFile
					);
				}
				if (
					(dueDiliGstFiles &&
						dueDiliGstFiles.length &&
						(hasGstKey || gstKey)) ||
					(profileState && (hasGstKey || gstKey))
				) {
					dueDiliGstFilesCID.current = await uploadFiles(
						userData?.Id,
						dueDiliGstFiles
							? dueDiliGstFiles
							: profileState.dueDiliGstFile
					);
				}
				if (
					(dueDiliRelFiles &&
						dueDiliRelFiles.length &&
						(hasBankKey || bankKey)) ||
					(profileState && (hasBankKey || bankKey))
				) {
					dueDiliRelFilesCID.current = await uploadFiles(
						userData?.Id,
						dueDiliRelFiles
							? dueDiliRelFiles
							: profileState.dueDiliRelFile
					);
				}
				if (
					(dueDiliKycFiles && dueDiliKycFiles.length) ||
					profileState
				) {
					dueDiliKycFilesCID.current = await uploadFiles(
						userData?.Id,
						dueDiliKycFiles
							? dueDiliKycFiles
							: profileState.dueDiliKycFile
					);
				}

				// Prepare a json file with borrower data
				let borrowerJsonData = {
					sustainableChecked: checked,
					companyName: companyName,
					companyRepName: companyRepName,
					companyBio: companyBio,
					companyLogoFile: {
						businessLogoFileName: logoFile
							? logoFile[0].name
							: profileState.companyLogoFile.businessLogoFileName,
						businessLogoFileCID: logoFile
							? logoFileCID.current
							: profileState.companyLogoFile.businessLogoFileCID,
					},
					businessIdFile: {
						businessIdDocName: bizIdFileName
							? bizIdFileName
							: profileState.businessIdFile.businessIdDocName,
						businessIdFileCID: businessIdentityFiles
							? businessIdFilesCID.current
							: profileState.businessIdFile.businessIdFileCID,
						businessIdFileName: businessIdentityFiles
							? businessIdentityFiles[0].name
							: profileState.businessIdFile.businessIdFileName,
					},
					businessAddFile: {
						businessAddDocName: bizAddFileName
							? bizAddFileName
							: profileState.businessAddFile.businessAddDocName,
						businessAddFileCID: businessAddressFiles
							? businessAddFilesCID.current
							: profileState.businessAddFile.businessAddFileCID,
						businessAddFileName: businessAddressFiles
							? businessAddressFiles[0].name
							: profileState.businessAddFile.businessAddFileName,
					},

					businessIncoFile: {
						businessIncoDocName: bizIncoFileName
							? bizIncoFileName
							: profileState.businessIncoFile.businessIncoDocName,
						businessIncoFileCID: businessIncorporationFiles
							? businessIncoFilesCID.current
							: profileState.businessIncoFile.businessIncoFileCID,
						businessIncoFileName: businessIncorporationFiles
							? businessIncorporationFiles[0].name
							: profileState.businessIncoFile
									.businessIncoFileName,
					},
					dueDiliAuFile: {
						dueDiliAuDocName: dueAuFileName
							? dueAuFileName
							: profileState.dueDiliAuFile.dueDiliAuDocName,
						dueDiliAuFileCID: dueDiliAuFiles
							? dueDiliAuFilesCID.current
							: profileState.dueDiliAuFile.dueDiliAuFileCID,
						dueDiliAuFileName: dueDiliAuFiles
							? dueDiliAuFiles[0].name
							: profileState.dueDiliAuFile.dueDiliAuFileName,
					},
					dueDiliKycFile: {
						dueDiliKycDocName: dueKycFileName
							? dueKycFileName
							: profileState.dueDiliKycFile.dueDiliKycDocName,
						dueDiliKycFileCID: dueDiliKycFiles
							? dueDiliKycFilesCID.current
							: profileState.dueDiliKycFile.dueDiliKycFileCID,
						dueDiliKycFileName: dueDiliKycFiles
							? dueDiliKycFiles[0].name
							: profileState.dueDiliKycFile.dueDiliKycFileName,
					},
					website: website,
					email: email,
					twitter: twitter,
					linkedin: linkedin,
				};

				if ((businessLicenseFiles || profileState) && (hasKey || key)) {
					const licenseFile = {
						businessLicFile: {
							businessLicDocName: bizLicFileName
								? bizLicFileName
								: profileState
								? profileState.businessLicFile
										.businessLicDocName
								: null,
							businessLicFileCID: businessLicenseFiles
								? businessLicFilesCID.current
								: profileState?.businessLicFile
										.businessLicFileCID,
							businessLicFileName: businessLicenseFiles
								? businessLicenseFiles[0].name
								: profileState?.businessLicFile
										.businessLicFileName,
						},
					};
					borrowerJsonData = { ...borrowerJsonData, ...licenseFile };
				}
				if (
					(dueDiliGstFiles || profileState) &&
					(hasGstKey || gstKey)
				) {
					const gstFile = {
						dueDiliGstFile: {
							dueDiliGstDocName: dueGstFileName
								? dueGstFileName
								: profileState.dueDiliGstFile.dueDiliGstDocName,
							dueDiliGstFileCID: dueDiliGstFiles
								? dueDiliGstFilesCID.current
								: profileState.dueDiliGstFile.dueDiliGstFileCID,
							dueDiliGstFileName: dueDiliGstFiles
								? dueDiliGstFiles[0].name
								: profileState.dueDiliGstFile
										.dueDiliGstFileName,
						},
					};
					borrowerJsonData = { ...borrowerJsonData, ...gstFile };
				}
				if (
					(dueDiliRelFiles || profileState) &&
					(hasBankKey || bankKey)
				) {
					const bankFile = {
						dueDiliRelFile: {
							dueDiliRelDocName: dueRelFileName
								? dueRelFileName
								: profileState.dueDiliRelFile.dueDiliRelDocName,
							dueDiliRelFileCID: dueDiliRelFiles
								? dueDiliRelFilesCID.current
								: profileState.dueDiliRelFile.dueDiliRelFileCID,
							dueDiliRelFileName: dueDiliRelFiles
								? dueDiliRelFiles[0].name
								: profileState.dueDiliRelFile
										.dueDiliRelFileName,
						},
					};
					borrowerJsonData = { ...borrowerJsonData, ...bankFile };
				}
				checkEdited(borrowerJsonData);

				if (allowSubmit) {
					try {
						const options = {
							url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/borrower/createProfile`,
							method: "POST",
							headers: {},
							data: {
								email: userData.email,
								role: userData.role,
								Id: userData.Id,
								profile: JSON.stringify(borrowerJsonData),
								kycStatus: userData.kycStatus,
								action: profileState
									? "ProfileUpdate"
									: "ProfileCreation",
							},
						};
						const result = await axiosHttpService(options);
					} catch (error) {
						captureException(error);
					}

					login({
						...userData,
						profile: JSON.stringify(borrowerJsonData),
					});
					captureMessage(
						"Borrower profile updted successful on blockchain",
						"info"
					);
					navigate("/borrowerDashboard/borrowerProfile", {
						state: borrowerJsonData,
					});
				} else {
					navigate("/borrowerDashboard/borrowerProfile", {
						state: borrowerJsonData,
					});
				}
			}
		} catch (error) {
			captureException(error);
			setUploading(false);
			setErrormsg({ status: true, msg: error.message });
		}
	};

	function checkFunction(file) {
		if (file || businessLicFilesCID.current) {
			setLincenseFile(true);
		}
	}
	function checkFunctionGst(file) {
		if (file || dueDiliGstFilesCID.current) {
			setGstFile(true);
		}
	}
	function checkFunctionBank(file) {
		if (file || dueDiliRelFilesCID.current) {
			setBankFile(true);
		}
	}

	useEffect(() => {
		if (lincenseText && lincenseFile) {
			setcheckLicense({
				err: false,
				msg: "",
			});
		} else {
			if (!lincenseFile && lincenseText) {
				setcheckLicense({
					err: true,
					msg: "File required",
				});
			} else if (!lincenseText && lincenseFile) {
				setcheckLicense({
					err: true,
					msg: "File name is required",
				});
			} else {
				setcheckLicense({
					err: false,
					msg: "",
				});
			}
		}
	}, [lincenseText, lincenseFile]);
	useEffect(() => {
		if (gstText && gstFile) {
			setcheckGst({
				err: false,
				msg: "",
			});
		} else {
			if (!gstFile && gstText) {
				setcheckGst({
					err: true,
					msg: "File required",
				});
			} else if (!gstText && gstFile) {
				setcheckGst({
					err: true,
					msg: "File name is required",
				});
			} else {
				setcheckGst({
					err: false,
					msg: "",
				});
			}
		}
	}, [gstText, gstFile]);
	useEffect(() => {
		if (bankText && bankFile) {
			setcheckBank({
				err: false,
				msg: "",
			});
		} else {
			if (!bankFile && bankText) {
				setcheckBank({
					err: true,
					msg: "File required",
				});
			} else if (!bankText && bankFile) {
				setcheckBank({
					err: true,
					msg: "File name is required",
				});
			} else {
				setcheckBank({
					err: false,
					msg: "",
				});
			}
		}
	}, [bankText, bankFile]);

	return (
		<div>
			{
				<ProcessingModal
					setUploading={setUploading}
					uploading={uploading}
					fileUploadStatus={fileUploadStatus}
				/>
			}

			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />

			<Formik
				initialValues={initialValues}
				onSubmit={(values) => uploadBorrowerData(values)}
				validationSchema={joiFormikAdapter(validationSchema)}
				enableReinitialize={true}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => (
					<>
						{setLincenseText(values.bizLicFileName)}
						{setGstText(values.dueGstFileName)}
						{setBankText(values.dueRelFileName)}
						<div>
							<div className="font-normal">
								<div className="">
									<h2 className="text-[1.4375rem] ">
										Issuer Details
									</h2>
									{setcheckedData(values.checked)}
									<div className="mt-2 flex flex-col gap-2">
										<div className=" flex flex-col gap-2 md:flex-row md:flex-wrap xl:justify-between">
											<FileUploader
												filetype={"image/*"}
												label="Business Logo *"
												className="md:w-[45%] xl:w-[30%]"
												handleFile={onLogoFileUpload}
												fileName={
													profileState
														? profileState
																.companyLogoFile
																.businessLogoFileName
														: null
												}
												error={
													logoError
														? "Please upload logo!"
														: null
												}
											/>
											<TextField
												name="companyName"
												label="Business Name *"
												placeholder="Enter Company Name"
												className="md:w-[45%] md:ml-auto xl:ml-0 xl:w-[30%]"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.companyName}
												error={
													touched.companyName &&
													errors.companyName
														? errors.companyName
														: null
												}
											/>
											<TextField
												name="companyRepName"
												label="Business Representative Name *"
												placeholder="Enter Name"
												className="md:w-[45%] xl:w-[30%]"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.companyRepName}
												error={
													touched.companyRepName &&
													errors.companyRepName
														? errors.companyRepName
														: null
												}
											/>
										</div>

										<div className="">
											<TextArea
												name="companyBio"
												label="Bio *"
												placeholder="Summary About the Organization/Company"
												className="w-full"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.companyBio}
												error={
													touched.companyBio &&
													errors.companyBio
														? errors.companyBio
														: null
												}
											/>
										</div>
									</div>
								</div>

								<div className="my-8 flex flex-col gap-3">
									<h2 className="text-[1.4375rem] font-normal">
										KYB Documents
									</h2>

									<InputGroup
										caption="Business Identity Proof *"
										name="bizIdFileName"
										value={values.bizIdFileName}
										onChangeText={handleChange}
										onChange={onBusinessIdentityFilesUpload}
										onBlur={handleBlur}
										error={
											errors.bizIdFileName &&
											touched.bizIdFileName
												? errors.bizIdFileName
												: fileErr.bip
												? "File required"
												: ""
										}
										fileName={
											profileState
												? profileState.businessIdFile
														.businessIdFileName
												: ""
										}
									/>
									<InputGroup
										caption="Business Address Proof *"
										name="bizAddFileName"
										value={values.bizAddFileName}
										onChangeText={handleChange}
										onChange={onBusinessAddressFilesUpload}
										onBlur={handleBlur}
										error={
											errors.bizAddFileName &&
											touched.bizAddFileName
												? errors.bizAddFileName
												: fileErr.bap
												? "File required"
												: ""
										}
										fileName={
											profileState
												? profileState.businessAddFile
														.businessAddFileName
												: ""
										}
									/>
									<InputGroup
										caption="Business Incorporation Proof *"
										name="bizIncoFileName"
										value={values.bizIncoFileName}
										onChangeText={handleChange}
										onChange={
											onBusinessIncorporationFilesUpload
										}
										onBlur={handleBlur}
										error={
											errors.bizIncoFileName &&
											touched.bizIncoFileName
												? errors.bizIncoFileName
												: fileErr.bicp
												? "File required"
												: ""
										}
										fileName={
											profileState
												? profileState?.businessIncoFile
														?.businessIncoFileName
												: ""
										}
									/>
									<InputGroup
										caption="Business License Proof"
										name="bizLicFileName"
										value={values.bizLicFileName}
										onChangeText={handleChange}
										onChange={onBusinessLicenseFilesUpload}
										error={
											checkLicense.err
												? checkLicense.msg
												: ""
										}
										onBlur={handleBlur}
										fileName={
											profileState && hasKey
												? profileState.businessLicFile
														.businessLicFileName
												: ""
										}
									/>
								</div>

								<div className="collapse collapse-arrow bg-lightmode-200 dark:bg-[#24272F] outline outline-1 outline-offset-0 dark:outline-[#3A3C43] outline-[#BBC0CC]  rounded-xl mb-5">
									<input
										type="checkbox"
										className="peer"
										checked={collapse}
										onClick={() => {
											setCollapse((prev) => !prev);
										}}
									/>

									<div className="collapse-title flex gap-4 md:gap-8 text-center text-lg">
										<h2 className="pt-2 font-bold text-[1.1875rem]">
											Sustainable Development Goals *
										</h2>
									</div>

									<div className="collapse-content ">
										<div className="font-normal pt-8 pb-4">
											<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
												{sustainableCheckedData.map(
													(data) => (
														<div className="flex items-center pl-4 border dark:border-[#3A3C43] border-[#BBC0CC] rounded">
															<label className="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 flex items-center gap-2">
																<Field
																	type="checkbox"
																	name="checked"
																	value={data}
																	className="checkbox w-[0.9rem] h-[0.9rem] text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
																/>
																<img
																	src={`IconSustainable/${data}.png`}
																	className="w-16 h-16 rounded-md"
																	alt=""
																/>
																{data}
															</label>
														</div>
													)
												)}
											</div>
										</div>
									</div>
								</div>

								<div className="my-8 flex flex-col gap-3">
									<h2 className="text-[1.4375rem] font-normal">
										Due Diligence Details
									</h2>

									<InputGroup
										caption="Audited Financial Statements for the past 3 years *"
										name="dueAuFileName"
										value={values?.dueAuFileName}
										onChangeText={handleChange}
										onChange={onDueDiliAuFilesUpload}
										onBlur={handleBlur}
										error={
											errors?.dueAuFileName &&
											touched?.dueAuFileName
												? errors?.dueAuFileName
												: fileErr.bip
												? "File required"
												: ""
										}
										fileName={
											profileState
												? profileState?.dueDiliAuFile
														?.dueDiliAuFileName
												: ""
										}
									/>
									<InputGroup
										caption="GST Returns for the past 1 year"
										name="dueGstFileName"
										value={values?.dueGstFileName}
										onChangeText={handleChange}
										onChange={onDueDiliGstFilesUpload}
										onBlur={handleBlur}
										error={checkGst.err ? checkGst.msg : ""}
										fileName={
											profileState && hasGstKey
												? profileState?.dueDiliGstFile
														?.dueDiliGstFileName
												: ""
										}
									/>
									<InputGroup
										caption="Relevant Bank Statements for the past 1 year"
										name="dueRelFileName"
										value={values?.dueRelFileName}
										onChangeText={handleChange}
										onChange={onDueDiliRelFilesUpload}
										onBlur={handleBlur}
										error={
											checkBank.err ? checkBank.msg : ""
										}
										fileName={
											profileState && hasBankKey
												? profileState?.dueDiliRelFile
														?.dueDiliRelFileName
												: ""
										}
									/>

									<InputGroup
										caption="KYC of the Promoters *"
										name="dueKycFileName"
										value={values?.dueKycFileName}
										onChangeText={handleChange}
										onChange={onDueDiliKycFilesUpload}
										onBlur={handleBlur}
										error={
											errors?.dueKycFileName &&
											touched?.dueKycFileName
												? errors?.dueKycFileName
												: fileErr.bicp
												? "File required"
												: ""
										}
										fileName={
											profileState
												? profileState?.dueDiliKycFile
														?.dueDiliKycFileName
												: ""
										}
									/>
								</div>

								<div className="mb-6">
									<h2 className="text-[1.4375rem] font-normal">
										Socials
									</h2>

									<div className="flex flex-col md:flex-row md:flex-wrap md:justify-between gap-3">
										<TextField
											name="website"
											label="Website *"
											placeholder="Enter Website URL"
											className="w-full md:w-[48%]"
											onChange={handleChange}
											value={values.website}
											onBlur={handleBlur}
											error={
												touched.website &&
												errors.website
													? errors.website
													: ""
											}
										/>
										<TextField
											name="email"
											label="Email Address *"
											placeholder="Enter Email Address"
											className="w-full md:w-[48%]"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.email}
											error={
												touched.email && errors.email
													? errors.email
													: ""
											}
										/>
										<TextField
											name="twitter"
											label="Twitter"
											placeholder="Enter Twitter URL"
											className="w-full md:w-[48%]"
											onChange={handleChange}
											value={values.twitter}
											onBlur={handleBlur}
											error={
												touched.twitter &&
												errors.twitter
													? errors.twitter
													: ""
											}
										/>
										<TextField
											name="linkedin"
											label="LinkedIn"
											placeholder="Enter LinkedIn URL"
											className="w-full md:w-[48%]"
											onChange={handleChange}
											value={values.linkedin}
											onBlur={handleBlur}
											error={
												touched.linkedin &&
												errors.linkedin
													? errors.linkedin
													: ""
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<p>Fields marked with an asterisk (*) are mandatory.</p>
						<div className="my-10 font-normal flex flex-col gap-5 md:gap-8 md:flex-row md:justify-center">
							<button
								onClick={() =>
									navigate(
										"/borrowerDashboard/borrowerProfile",
										{
											state: oldBrJson,
										}
									)
								}
								className="border-2 font-medium text-lg border-neutral-500 rounded-3xl py-3 md:w-[40%] xl:w-[min(40%,25rem)]"
							>
								Exit
							</button>
							<GradientButton
								className="w-full md:w-[40%] xl:w-[min(40%,25rem)]"
								onClick={() => {
									validations();
									if (
										!checkLicense.err &&
										!checkGst.err &&
										!checkBank.err
									) {
										handleSubmit();
									}
								}}
							>
								Save and Exit
							</GradientButton>
						</div>
					</>
				)}
			</Formik>
		</div>
	);
};

export default EditBorrowerProfileNew;
