import { auth } from "../firebase";
import {
	signInWithEmailAndPassword,
	signOut,
	updatePassword,
} from "firebase/auth";
import axiosHttpService from "../../axioscall";
import { captureException, captureMessage } from "@sentry/react";

export const Role = {
	Subscriber: 0,
	Issuer: 1,
	Custodian: 2,
	Regulator: 3,
	Admin: 4,
	DiligenceExpert: 5,
};

export async function signInWithEmail(email, password, role) {
	if (!email && !password && !role) {
		return undefined;
	}
	try {
		const userCredential = await signInWithEmailAndPassword(
			auth,
			email,
			password
		);

		captureMessage("userCreadential", userCredential);
		if (userCredential) {
			// get further details of user from db
			const user = await getUserWithEmailAndRole(
				email,
				role,
				userCredential._tokenResponse.idToken
			);

			captureMessage("Logged in user:", user);
			if (user) {
				return {
					...user,
					idToken: userCredential._tokenResponse.idToken,
				};
			}
		}
	} catch (error) {
		captureException(error);
		throw error;
	}
}

export async function createUserWithEmail(email, role, kycStatus) {
	if (!email && !role) {
		return undefined;
	}
	try {
		const user = await createUserWithEmailAndRole(email, role, kycStatus);

		if (user) {
			captureMessage("User created successfully: ", user);
			return {
				success: true,
			};
		}
	} catch (error) {
		captureException(error);
		throw error;
	}
}

export async function logoutUser() {
	try {
		await signOut(auth);
	} catch (error) {
		captureException(error);
	}
}

export async function changePassword(newPassword) {
	try {
		await updatePassword(auth.currentUser, newPassword);
		return true;
	} catch (error) {
		captureException(error);
		return false;
	}
}

// Get a list of cities from your database
async function getUserWithEmailAndRole(email, role, token) {
	if (!email || role == undefined || !token) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/borrower/getUsers`,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			data: {
				email: email,
				role: role,
			},
		};
		const result = await axiosHttpService(options);
		if (result?.res?.Id) {
			return result.res;
		} else {
			throw new Error("Invalid Credentials");
		}
	} catch (error) {
		captureException(error);
		throw error;
	}
}

async function createUserWithEmailAndRole(email, role, kycStatus) {
	if (!email) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/borrower/createProfile`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				role: role,
				kycStatus: kycStatus,
				profile: JSON.stringify({}),
				isNewUser: true,
			},
		};
		const result = await axiosHttpService(options);
		if (result.code === 201) {
			return result.res;
		} else {
			throw new Error("Unable to Create User");
		}
	} catch (error) {
		captureException(error);
		throw error;
	}
}

export async function getAllUsers(pageSize, bookmark) {
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/borrower/getAllUsers`,
			method: "POST",
			headers: {},
			data: {
				pageSize: pageSize,
				bookmark: bookmark,
			},
		};
		const result = await axiosHttpService(options);
		if (result.res) {
			return result.res;
		} else {
			return {
				count: 0,
				records: [],
			};
		}
	} catch (error) {
		captureException(error);
		throw error;
	}
}
