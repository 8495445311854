import React, { useState } from "react";
import RepaymentModal from "../Modal/RepaymentModal";
import DollarImage from "../../../../assets/Dollar-icon.svg";
import BankDetailsModal from "../../../Custodian/components/BankDetailsModal";
import { repayment } from "../../../../services/BackendConnectors/userConnectors/borrowerConnectors";
import { Badge, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

const RepaymentCard = ({
	data,
	kycStatus,
	setOpenProcessRepayment,
	setProcessRepayment,
	setwalletAddress,
	settransactionId,
	setpoolName,
	setamounts,
	setUpdateRepayment,
	setCheck,
	setErrormsg,
	setUtrNo,
}) => {
	const [selected, setSelected] = useState(null);
	const [selectedBankDetails, setSelectedBankDetails] = useState(false);
	const path = useNavigate();
	const handleRepayment = async () => {
		setSelected(null);
	};

	const onRepayment = async (bankDetails) => {
		settransactionId(null);
		setOpenProcessRepayment(true);
		setProcessRepayment(true);
		setpoolName(data?.opportunityName);
		setamounts(data?.finalRepaymentAmount);
		setwalletAddress(data.opportunityPoolAddress);
		handleRepayment();
		setCheck(true);
		const res = await repayment(data, bankDetails);
		if (!res.res) {
			setOpenProcessRepayment(false);
			setErrormsg({
				status: !res.success,
				msg: res.msg,
			});
		}
		console.log(res);
		settransactionId(res.id);
		setProcessRepayment(false);
		setUpdateRepayment(Math.random());
	};

	return (
		<div className="md:w-[48%] xl:w-[32%] 2xl:w-[min(32%,30rem)]">
			<div className="flex justify-end">
				<Tooltip title={`Token Id: ${data?.nftId}`}>
					<div className="w-16 lg:w-18 cursor-pointer">
						<Badge.Ribbon text="Tokenized" color="#6A0DAD" />
					</div>
				</Tooltip>
			</div>
			<div className="my-gradient px-4 py-8 flex flex-col gap-5 rounded-xl ">
				<h2 className="font-normal text-[1.4375rem] text-black dark:text-white">
					{data?.opportunityName}
				</h2>

				<div className="flex flex-col gap-2">
					<div className=" flex gap-1">
						<p>Capital Borrowed</p>

						<img
							src={DollarImage}
							className="w-4 ml-auto"
							alt="dollerimage"
						/>
						<p>{data?.opportunityAmount}</p>
					</div>
					<div className=" flex gap-1">
						{data?.isOverDue ? (
							<p className="text-error-500">Overdue Amount</p>
						) : (
							<p>Due Amount</p>
						)}

						<img
							src={DollarImage}
							className="w-4 ml-auto"
							alt="dollerimage"
						/>
						<p className={`${data?.isOverDue ? "" : ""}`}>
							{data?.repaymentDisplayAmount}
						</p>
					</div>
					{data?.delayInDays && (
						<>
							<div className=" flex justify-between">
								<p className="text-error-500">
									Overdue Charges
								</p>
								<p>{data?.delayChargeRatePercentage}%</p>
							</div>
							<div className=" flex justify-between">
								<p className="text-error-500">Overdue days</p>
								<p>{data?.delayInDays}</p>
							</div>
						</>
					)}
					<div className=" flex justify-between">
						<p>Due Date</p>
						<p>{data?.nextDueDate}</p>
					</div>

					<div className="font-normal">
						<label
							className="text-[#4B74FF] cursor-pointer"
							onClick={() => {
								path("/borrowerDashboard/viewPool", {
									state: {
										...data,
										kycStatus: kycStatus,
										// isSenior: isSenior,
										viewMore: "issuer",
									},
								});
							}}
						>
							More Details
						</label>
					</div>
				</div>

				<div className="text-center">
					<label
						htmlFor="repayment-modal"
						onClick={() => setSelected(data)}
						className="bg-secondary-500 w-[100%] inline-block text-white  cursor-pointer py-2 rounded-3xl"
					>
						Make Repayment now
					</label>
				</div>
				{selectedBankDetails && (
					<BankDetailsModal
						selected={selectedBankDetails}
						setSelected={setSelectedBankDetails}
						onSubmit={onRepayment}
						setUtrNo={setUtrNo}
					/>
				)}
				{selected && (
					<RepaymentModal
						key={data?.Id}
						data={selected}
						handleRepayment={handleRepayment}
						setSelectedBankDetails={setSelectedBankDetails}
						setSelected={setSelected}
					></RepaymentModal>
				)}
			</div>
		</div>
	);
};

export default RepaymentCard;
