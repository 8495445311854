import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutGreenChart = ({
	labels,
	data,
	color,
	width = "202px",
	cutout = 45,
	legendStyle,
	borderWidth = 1,
}) => {
	const dataValue = {
		labels: labels,
		datasets: [
			{
				label: "Number of Votes",
				data: data,
				backgroundColor: color,
				borderColor: color,
				borderWidth: borderWidth,
			},
		],
	};
	return (
		<div className="w-[10rem]">
			<Doughnut
				data={dataValue}
				options={{
					cutout: cutout,
					plugins: {
						legend: legendStyle,
					},
				}}
			/>
		</div>
	);
};

export default DoughnutGreenChart;
