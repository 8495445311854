import {
	getAmortisationSchedule,
	getRepaymentAmount,
} from "../ApiCalls/accountingCalls";
import { getGreenBondsByField } from "../ApiCalls/greenBondCalls";
import { getTokenizedBondsByField } from "../ApiCalls/tokenizedBondCalls";
import { getTransactionByField } from "../ApiCalls/transactionCalls";
import { getUserById } from "../ApiCalls/userCalls";
import convertTimestampToDate from "../Helpers/dateFunctions";
import { getUserProfile } from "../Helpers/usersHelper";
import axiosHttpService from "../axioscall";
import { getNextRepaymentDate } from "./accounting";
const { ethers } = require("ethers");
const {
	requestAccount,
	convertDate,
} = require("./userConnectors/commonConnectors");
const opportunityOrigination = require("../../artifacts/contracts/protocol/OpportunityOrigination.sol/OpportunityOrigination.json");
const opportunityPool = require("../../artifacts/contracts/protocol/OpportunityPool.sol/OpportunityPool.json");
const {
	getDisplayAmount,
	getTrimmedString,
	formatNumberWithCommas,
} = require("../Helpers/displayTextHelper");
const {
	getUserWalletAddress,
	getEthAddress,
} = require("./userConnectors/commonConnectors");

export const BondStatus = {
	PendingForAdminApproval: 0,
	RejectedByAdmin: 1,
	UnderReview: 2,
	Rejected: 3,
	Approved: 4,
	Tokenized: 5,
	Matured: 6,
};

export const BondDisplayStatus = {
	Open: 0,
	Subscribed: 1,
	Tokenized: 2,
	CouponPayReceived: 3,
	FullyPaid: 4,
	Matured: 5,
};

export const InvestorTransactionType = {
	Invest: 0,
	Payout: 1,
};

export const BorrowerTransactionType = {
	Borrowed: 0,
	Repaid: 1,
};

export const requestType = [
	{ value: 0, label: "Loan" },
	{ value: 1, label: "Bond" },
	{ value: 2, label: "Purchase order financing" },
	{ value: 3, label: "Securitization" },
];

export const TransactionStatus = {
	InVerification: 0,
	Completed: 1,
	Failed: 2,
};

const Sentry = require("@sentry/react");
const sixDecimals = 6;
const nullAddress = "0x0000000000000000000000000000000000000000";

export const createOpportunity = async (formData) => {
	Sentry.captureMessage("createOpportunity", "info");
	if (!formData) {
		return {
			success: false,
			msg: "formData is undefined or empty",
		};
	}
	try {
		let borrowerAdd = await getUserWalletAddress();

		if (typeof window.ethereum !== "undefined") {
			await requestAccount();
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const signer = provider.getSigner();
			const contract = new ethers.Contract(
				process.env.REACT_APP_OPPORTUNITY_ORIGINATION_ADDRESS,
				opportunityOrigination.abi,
				signer
			);
			const loanAmt = ethers.utils.parseUnits(
				formData.loan_amount,
				sixDecimals
			);
			const loanInterest = ethers.utils.parseUnits(
				formData.loan_interest,
				sixDecimals
			);
			const capitalLoss = formData.capital_loss
				? ethers.utils.parseUnits(formData.capital_loss, sixDecimals)
				: 0;
			const opData = [
				borrowerAdd.address,
				formData.loan_name,
				formData.loanInfoHash,
				formData.loan_type,
				loanAmt,
				formData.loan_tenure,
				loanInterest,
				formData.payment_frequency,
				formData.collateralHash,
				capitalLoss,
			];
			const transaction1 = await contract.createOpportunity(opData);
			await transaction1.wait();
			return { transaction1, success: true };
		} else {
			Sentry.captureMessage("Wallet connect error", "warning");
			return {
				success: false,
				msg: "please connect your wallet!",
			};
		}
	} catch (error) {
		Sentry.captureException(error);
		if (error?.data) {
			return {
				success: false,
				msg: error.data.message,
			};
		}
		return {
			success: false,
			msg: error.message,
		};
	}
};

export function getOpportunity(opportunity) {
	Sentry.captureMessage("getOpportunity", "info");
	try {
		if (!opportunity) {
			return {
				success: false,
				msg: "opportunity is undefined",
			};
		}

		// Create the opportunity object
		let obj = {};
		obj.Id = opportunity.Id;
		obj.borrower = opportunity.borrowerId;
		obj.opportunityName = opportunity.loan_name;
		obj.requestType = requestType[opportunity.requestType]
			? requestType[opportunity.requestType].label
			: "--";
		obj.companyDetails = JSON.parse(opportunity.companyDetails);
		obj.borrowerDisplayAdd = getTrimmedString(
			obj.companyDetails.companyName
		);
		obj.opportunityInfo = opportunity.loan_purpose;
		obj.loanType = opportunity.loan_type.toString(); // 0 or 1 need to be handled
		obj.actualLoanAmount = parseFloat(opportunity.loan_amount);
		obj.opportunityAmount = getDisplayAmount(obj.actualLoanAmount);
		obj.actualLoanTenure = opportunity.loan_tenure / 30;
		obj.loanTenure = obj.actualLoanTenure.toString() + " Months";
		obj.loanActualInterest = parseFloat(opportunity.loan_interest);
		obj.loanInterest = opportunity.loan_interest + "% pa";
		obj.actualPaymentFrequency = opportunity.payment_frequency;
		obj.paymentFrequencyInDays =
			opportunity.payment_frequency.toString() + " Days";
		obj.collateralDocument = opportunity.collateral_document_name;
		obj.capitalLoss = opportunity.capital_loss;
		obj.status = opportunity.status;

		obj.createdOn = convertDate(opportunity.createdOn);
		obj.epochCreationDate = opportunity.createdOn.toString();
		obj.collateralHash = opportunity.collateralHash;
		obj.issueNoteDoc = opportunity.issueNoteDoc;

		return { obj, success: true };
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
}

// to fetch created opportunities of specific borrower
// export const getBonds = async (id) => {
// 	Sentry.captureMessage("getOpportunitysOf", "info");
// 	try {
// 		if (!id) {
// 			return;
// 		}
// 		const bondsCol = collection(db, BondsCollection);
// 		const bondsQuery = query(bondsCol, where("borrowerId", "==", id));

// 		const BondsSnapshot = await getDocs(bondsQuery);
// 		const bonds = BondsSnapshot.docs.map(getFirebaseSnapshotData);
// 		let opportunities = [];
// 		for (const op of bonds) {
// 			let { obj } = getOpportunity(op);

// 			try {
// 				const transactionsCol = collection(db, TransactionsCollection);
// 				const transactionsQuery = query(
// 					transactionsCol,
// 					where("bondId", "==", op.id)
// 				);

// 				const TransactionsSnapshot = await getDocs(transactionsQuery);
// 				const transactions = TransactionsSnapshot.docs.map(
// 					getFirebaseSnapshotData
// 				);

// 				obj.poolBalance = transactions.reduce((total, trx) => {
// 					return total + +trx.amount;
// 				}, 0);
// 				obj.poolDisplayBalance = getDisplayAmount(obj.poolBalance);
// 			} catch (error) {
// 				console.log(error);
// 				obj.poolBalance = 0;
// 			}

// 			switch (obj.status) {
// 				case BondStatus.UnderReview:
// 					obj.displayStatus = "Under Review";
// 					break;
// 				case BondStatus.Rejected:
// 					obj.displayStatus = "Rejected";
// 					break;
// 				case BondStatus.Approved:
// 					obj.displayStatus = "Approved";
// 					break;
// 				case BondStatus.Tokenized:
// 					obj.displayStatus = "Tokenized";
// 					break;
// 				case BondStatus.Matured:
// 					obj.displayStatus = "Matured";
// 					break;
// 			}

// 			opportunities.push(obj);
// 		}
// 		return { opportunities, success: true };
// 	} catch (error) {
// 		Sentry.captureException(error);
// 		return {
// 			success: false,
// 			msg: error.message,
// 		};
// 	}
// };

export const getBonds = async (borrowerId) => {
	Sentry.captureMessage("getOpportunitysOf", "info");
	try {
		if (!borrowerId) {
			return;
		}

		let bonds = await getGreenBondsByField("borrowerId", borrowerId);
		bonds = bonds.res.records;

		let opportunities = [];

		for (const op of bonds) {
			try {
				let { obj } = getOpportunity(op.data);

				try {
					let tokenizedBonds;
					if (op.data.status >= BondStatus.Tokenized) {
						tokenizedBonds = await getTokenizedBondsByField(
							"bondId",
							op.data.Id
						);
						tokenizedBonds = tokenizedBonds.res.records.map(
							(tokenizedBond) => tokenizedBond.data
						);
					}

					let result = await getAmortisationSchedule(
						op.data,
						tokenizedBonds?.[0]?.repaymentStartTime
							? tokenizedBonds?.[0]?.repaymentStartTime
							: op.data.createdOn
					);

					if (op.data.custodian) {
						const custodian = await getUserProfile({
							email: op?.data?.custodian,
						});
						obj.custodian = custodian
							? custodian
							: op?.data?.custodian;
					}

					const { seniorXirr, juniorXirr } = result.res;

					obj.seniorXirr = seniorXirr.toFixed(2).toString() + "% pa";
					obj.juniorXirr = juniorXirr.toFixed(2).toString() + "% pa";

					let transactions = await getTransactionByField(
						"bondId",
						op.id
					);
					transactions = transactions.res.records
						? transactions.res.records
						: [];

					transactions = transactions.filter(
						(tx) => tx.data.status === TransactionStatus.Completed
					);

					obj.poolBalance = transactions.reduce((total, trx) => {
						return total + +trx.data.amount;
					}, 0);
					obj.poolDisplayBalance = getDisplayAmount(obj.poolBalance);

					obj.trx = getInvestmentDetailsByTx(
						transactions,
						obj.actualLoanAmount
					);
				} catch (error) {
					Sentry.captureException(error);
					obj.poolBalance = 0;
				}

				switch (obj.status) {
					case BondStatus.PendingForAdminApproval:
						obj.displayStatus = "Pending For Admin's Approval";
						break;
					case BondStatus.RejectedByAdmin:
						obj.displayStatus = "Rejected By Admin";
						break;
					case BondStatus.UnderReview:
						obj.displayStatus = "Under Review";
						break;
					case BondStatus.Rejected:
						obj.displayStatus = "Rejected";
						break;
					case BondStatus.Approved:
						obj.displayStatus = "Approved";
						break;
					case BondStatus.Tokenized:
						obj.displayStatus = "Tokenized";
						break;
					case BondStatus.Matured:
						obj.displayStatus = "Matured";
						break;
				}

				opportunities.push(obj);
			} catch (error) {
				Sentry.captureException(error);
			}
		}
		return { opportunities, success: true };
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const voteOpportunity = async (bond, vote, platformFeeObj) => {
	Sentry.captureMessage("voteOpportunity", "info");
	try {
		if (bond && vote) {
			let action;
			switch (vote) {
				case 1:
					action = "Admin Rejected";
					break;
				case 2:
					action = "Admin Approved";
					break;
				case 3:
					action = "Diligence Rejected";
					break;
				case 4:
					action = "Diligence Approved";
					break;

				default:
					break;
			}
			let data = {
				...bond,
				status: vote,
				action: action,
			};
			if (vote === 2 || vote === 4) {
				data = {
					...data,
					...platformFeeObj,
				};
			}
			const options = {
				url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/bond/createBond`,
				method: "POST",
				headers: {},
				data: {
					...data,
				},
			};
			const result = await axiosHttpService(options);
			console.log("vote ", result.res);
			if (result) {
				return { success: true };
			}
		} else {
			Sentry.captureMessage(
				"voteOpportunity error, id or vote value is invalid",
				"warning"
			);
			return {
				success: false,
				msg: "Invalid request id!",
			};
		}
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

// to fetch opportunity by id
export const getOpportunityAt = async (id) => {
	Sentry.captureMessage("getOpportunityAt", "info");
	try {
		if (typeof window.ethereum !== "undefined") {
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			console.log({ provider });
			const contract = new ethers.Contract(
				process.env.REACT_APP_OPPORTUNITY_ORIGINATION_ADDRESS,
				opportunityOrigination.abi,
				provider
			);

			console.log("check");
			let tx = await contract.opportunityToId(id);
			let { obj } = getOpportunity(tx);
			return { obj, success: true };
		} else {
			Sentry.captureMessage("Wallet connect error", "warning");
			return {
				success: false,
				msg: "Please connect your wallet!",
			};
		}
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getAllUnderReviewOpportunities = async () => {
	Sentry.captureMessage("getAllUnderReviewOpportunities", "info");
	try {
		if (typeof window.ethereum !== "undefined") {
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			console.log({ provider });
			const contract = new ethers.Contract(
				process.env.REACT_APP_OPPORTUNITY_ORIGINATION_ADDRESS,
				opportunityOrigination.abi,
				provider
			);

			const count = await contract.getTotalOpportunities();
			let opportunities = [];

			for (let i = 0; i < count; i++) {
				let id = await contract.opportunityIds(i);

				let tx = await contract.opportunityToId(id);
				if (tx.opportunityStatus.toString() === "0") {
					let { obj } = getOpportunity(tx);
					opportunities.push(obj);
				}
			}
			return { opportunities, success: true };
		} else {
			Sentry.captureMessage("Wallet connect error", "warning");
			return {
				success: false,
				msg: "Please connect your wallet!",
			};
		}
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getBondsWithDues = async (borrowerId) => {
	Sentry.captureMessage(
		"getBondsWithDues execution started inside opportunityConnectors.js",
		"info"
	);
	try {
		if (!borrowerId) {
			return;
		}

		let bonds = await getGreenBondsByField("borrowerId", borrowerId);
		bonds = bonds.res.records ? bonds.res.records : [];
		bonds = bonds.map((bond) => bond.data);
		bonds = bonds.filter((bond) => bond.status === BondStatus.Tokenized);

		Sentry.captureMessage(
			"bonds data received in getBondsWithDues function inside opportunityConnectors.js",
			"info"
		);

		let opportunities = [];
		for (const bond of bonds) {
			try {
				let tokenizedBonds = await getTokenizedBondsByField(
					"bondId",
					bond.Id
				);
				tokenizedBonds = tokenizedBonds.res.records.map(
					(tokenizedBond) => tokenizedBond.data
				);

				Sentry.captureMessage(
					"tokenizedBonds data received in getBondsWithDues function inside opportunityConnectors.js",
					"info"
				);

				let transactions = await getTransactionByField(
					"bondId",
					bond.Id
				);
				transactions = transactions.res.records
					? transactions.res.records
					: [];
				let completedTransactions = transactions.filter(
					(tx) => tx.data.status === TransactionStatus.Completed
				);
				transactions = transactions.map((tx) => tx.data);
				let inVerification = false;
				transactions = transactions.forEach((tx) => {
					if (
						tx.borrowerTransactionType ===
							BorrowerTransactionType.Repaid &&
						tx.status === TransactionStatus.InVerification
					) {
						inVerification = true;
					}
				});
				if (inVerification) {
					continue;
				}
				if (
					tokenizedBonds[0].repaymentCounter >
					tokenizedBonds[0].totalRepayments
				) {
					continue;
				}

				let reDate = getNextRepaymentDate(
					tokenizedBonds[0].repaymentStartTime,
					tokenizedBonds[0].repaymentCounter,
					tokenizedBonds[0].paymentFrequencyInDays
				);

				let totalRepayments = tokenizedBonds[0].totalRepayments;
				let repaymentCounter = tokenizedBonds[0].repaymentCounter;
				let repaymentAmount = tokenizedBonds[0].emiAmount;
				let totalRepaidAmt = tokenizedBonds[0].totalRepaidAmount;

				let { obj } = getOpportunity(bond);
				obj.nextDueDate = reDate.repaymentDisplayDate;
				obj.epochDueDate = reDate.repaymentDate;
				obj.repaymentCounter = repaymentCounter;
				obj.totalRepayments = totalRepayments;
				obj.totalOutstandingPrincipal =
					tokenizedBonds[0].totalOutstandingPrincipal;
				obj.tokenizedBondId = tokenizedBonds[0].Id;

				obj.nftId = tokenizedBonds[0].nftId;
				if (bond.custodian) {
					const custodian = await getUserProfile({
						email: bond?.custodian,
					});
					obj.custodian = custodian ? custodian : bond?.custodian;
				}

				let result = await getAmortisationSchedule(
					bond,
					tokenizedBonds?.[0]?.repaymentStartTime
						? tokenizedBonds?.[0]?.repaymentStartTime
						: bond.createdOn
				);

				Sentry.captureMessage(
					"Amortisation schedule received in getBondsWithDues function inside opportunityConnectors.js",
					"info"
				);

				const { seniorXirr, juniorXirr } = result.res;

				obj.seniorXirr = seniorXirr.toFixed(2).toString() + "% pa";
				obj.juniorXirr = juniorXirr.toFixed(2).toString() + "% pa";

				//get repayment amount
				let res = await getRepaymentAmount(
					convertTimestampToDate(
						tokenizedBonds[0].repaymentStartTime
					),
					convertTimestampToDate(reDate.repaymentDate.getTime()),
					bond.delayChargeRatePercentage,
					obj.totalOutstandingPrincipal,
					obj.loanActualInterest,
					+repaymentAmount
				);

				Sentry.captureMessage(
					"Repayment amount received in getBondsWithDues function inside opportunityConnectors.js",
					"info"
				);

				obj.trx = getInvestmentDetailsByTx(
					completedTransactions,
					obj.actualLoanAmount
				);

				obj.repaymentAmount = repaymentAmount;
				obj.finalRepaymentAmount = res.res;
				obj.finalRepaymentDisplayAmount = formatNumberWithCommas(
					obj.finalRepaymentAmount
				);
				obj.repaymentDisplayAmount = getDisplayAmount(
					obj.repaymentAmount
				);
				obj.totalRepaidAmount = totalRepaidAmt;
				// Loan type 0 Bullet Loan and 1 is Term Loan
				let principal = obj.loanType === "1" ? 0 : obj.actualLoanAmount;

				obj.TotalLoanRepaymentAmount =
					parseFloat(obj.repaymentAmount) * +totalRepayments +
					+principal;

				// Set tranch params on obj
				obj.investorUpfrontFeesPercentage =
					bond.investorUpfrontFeesPercentage;
				obj.delayChargeRatePercentage = bond.delayChargeRatePercentage;
				obj.percentageOfCoupon = bond.percentageOfCoupon;
				obj.juniorTranchPercentage = bond.juniorTranchPercentage;
				obj.juniorTranchFloatInterestPercentage =
					bond.juniorTranchFloatInterestPercentage;

				// Above logic does not work for last bullet repayment
				// hence this is little workaround to get the last outstanding amount
				if (+totalRepayments === +repaymentCounter) {
					obj.TotalLoanRepaymentAmount =
						+obj.repaymentAmount + +obj.totalRepaidAmount;
				}
				// const overdueTime = Math.floor(Date.now() / 1000) - repaymentDate;
				// obj.isOverDue = overdueTime > 0 ? true : false;
				const delay = compareDelayInDDMMYY(obj?.nextDueDate);
				if (delay) {
					obj.delayInDays = delay;
					obj.isOverDue = true;
					obj.overDueCharge = getDisplayAmount(
						+obj.finalRepaymentAmount - +repaymentAmount
					);
				}
				opportunities.push(obj);
			} catch (error) {
				Sentry.captureException(error);
			}
		}
		return { opportunities, success: true };
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getAllActiveOpportunities = async () => {
	try {
		let bonds = await getGreenBondsByField("status", BondStatus.Approved);
		bonds = bonds?.res?.data?.GreenBond;
		let opportunities = [];

		for (let i = 0; i < bonds.length; i++) {
			try {
				let { obj } = getOpportunity(bonds[i]);
				if (bonds[i].custodian) {
					const custodian = await getUserProfile({
						email: bonds[i]?.custodian,
					});
					obj.custodian = custodian ? custodian : bonds[i]?.custodian;
				}
				let transactions = await getTransactionByField(
					"bondId",
					bonds[i].Id
				);
				transactions = transactions.res.records
					? transactions.res.records
					: [];
				transactions = transactions.map((tx) => tx.data);

				transactions = transactions.filter(
					(tx) =>
						tx.status === TransactionStatus.Completed ||
						tx.status === TransactionStatus.InVerification
				);

				let seniorTransactions = transactions.filter(
					(tx) =>
						tx.investorTransactionType ===
							InvestorTransactionType.Invest &&
						tx.isSenior === true
				);

				let juniorTransactions = transactions.filter(
					(tx) =>
						tx.investorTransactionType ===
							InvestorTransactionType.Invest &&
						tx.isSenior === false
				);

				// Get Amortisation Schedule
				let options = {
					url: `${
						process.env.REACT_APP_FIREBASE_FUNCTIONS_URI
					}/accounting/${
						bonds[i].loan_type == 0
							? "getBulletLoanAmortisationSchedule"
							: "getTermLoanAmortisationSchedule"
					}`,
					method: "post",
					headers: {},
					data: {
						loanAmount: obj.actualLoanAmount,
						interestRatePercentage: obj.loanActualInterest,
						tenureInMonths: obj.actualLoanTenure,
						paymentFrequencyInDays: obj.actualPaymentFrequency,
						disbursmentDate: convertTimestampToDate(
							bonds[i].createdOn
						),
						investorUpfrontFees:
							bonds[i].investorUpfrontFeesPercentage,
						platformFeesPercentage: bonds[i].percentageOfCoupon,
						JuniorContributionPercentage:
							bonds[i].juniorTranchPercentage,
						JuniorPrincipalFloatPercentage:
							bonds[i].juniorTranchFloatInterestPercentage,
					},
				};
				let res = await axiosHttpService(options);
				const {
					amortisationSchedule,
					cashFlow,
					seniorAmortisationSchedule,
					seniorXirr,
					juniorAmortisationSchedule,
					juniorXirr,
				} = res.res;

				obj.amortisationSchedule = amortisationSchedule;
				obj.seniorAmortisationSchedule = seniorAmortisationSchedule;
				obj.seniorXirr = seniorXirr.toFixed(2).toString() + "% pa";
				obj.juniorAmortisationSchedule = juniorAmortisationSchedule;
				obj.juniorXirr = juniorXirr.toFixed(2).toString() + "% pa";

				obj.seniorTrancheAmount =
					(+obj.actualLoanAmount *
						(100 - bonds[i].juniorTranchPercentage)) /
					100;
				obj.seniorTrancheDisplayAmount = getDisplayAmount(
					obj.seniorTrancheAmount
				);

				obj.juniorTrancheAmount =
					(+obj.actualLoanAmount * bonds[i].juniorTranchPercentage) /
					100;
				obj.juniorTrancheDisplayAmount = getDisplayAmount(
					obj.juniorTrancheAmount
				);

				obj.totalInvestedAmt = transactions.reduce((total, trx) => {
					return total + +trx.amount;
				}, 0);
				obj.totalSeniorInvestedAmt = seniorTransactions.reduce(
					(total, trx) => {
						return total + +trx.amount;
					},
					0
				);
				obj.totalSeniorDisplayInvestedAmt = getDisplayAmount(
					obj.totalSeniorInvestedAmt
				);
				obj.totalJuniorInvestedAmt = juniorTransactions.reduce(
					(total, trx) => {
						return total + +trx.amount;
					},
					0
				);
				obj.totalJuniorDisplayInvestedAmt = getDisplayAmount(
					obj.totalJuniorInvestedAmt
				);
				obj.totalDisplayInvestedAmt = getDisplayAmount(
					obj.totalInvestedAmt
				);

				obj.investableAmount =
					obj.actualLoanAmount - obj.totalInvestedAmt;
				obj.investableDisplayAmount = getDisplayAmount(
					obj.investableAmount
				);

				obj.seniorInvestableAmount =
					+obj.seniorTrancheAmount - +obj.totalSeniorInvestedAmt;
				obj.seniorInvestableDisplayAmount = getDisplayAmount(
					obj.seniorInvestableAmount
				);

				obj.juniorInvestableAmount =
					+obj.juniorTrancheAmount - obj.totalJuniorInvestedAmt;
				obj.juniorInvestableDisplayAmount = getDisplayAmount(
					obj.juniorInvestableAmount
				);

				obj.isFull =
					parseFloat(obj.totalSeniorInvestedAmt) >=
					parseFloat(obj.actualLoanAmount);

				obj.isSeniorFull =
					parseFloat(obj.totalSeniorInvestedAmt) >=
					parseFloat(obj.seniorTrancheAmount);
				obj.isJuniorFull =
					parseFloat(obj.totalJuniorInvestedAmt) >=
					parseFloat(obj.juniorTrancheAmount);

				opportunities.push(obj);
			} catch (error) {
				Sentry.captureException(error);
			}
		}
		return { opportunities, success: true };
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getAllWithdrawableOpportunities = async () => {
	try {
		if (typeof window.ethereum !== "undefined") {
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const contract = new ethers.Contract(
				process.env.REACT_APP_OPPORTUNITY_ORIGINATION_ADDRESS,
				opportunityOrigination.abi,
				provider
			);

			let { result } = await getEthAddress();
			let borrower = result;
			const count = await contract.getOpportunityOf(borrower);
			let opportunities = [];

			for (let i = 0; i < count; i++) {
				let id = await contract.opportunityIds(i);

				let tx = await contract.opportunityToId(id);

				if (tx.opportunityStatus.toString() === "7") {
					let poolAddress = tx.opportunityPoolAddress.toString();
					console.log(poolAddress);
					const poolContract = new ethers.Contract(
						poolAddress,
						opportunityPool.abi,
						provider
					);
					let poolBal = await poolContract.poolBalance();
					if (poolBal.toString() !== "0") {
						const signer = provider.getSigner();
						const userStakingAmt =
							await poolContract.stakingBalance(
								await signer.getAddress()
							);
						const estimatedAPY =
							await poolContract.juniorYieldPerecentage();
						let { obj } = getOpportunity(tx);
						obj.capitalInvested = userStakingAmt;
						obj.estimatedAPY = estimatedAPY;
						obj.yieldGenerated = userStakingAmt * estimatedAPY;
						if (obj) {
							opportunities.push(obj);
						}
					}
				}
			}
			return { opportunities, success: true };
		}
	} catch (error) {
		Sentry.captureException(error);

		return {
			success: false,
			msg: error.message,
		};
	}

	return [];
};

export const getAllUnderwriterBonds = async (email) => {
	Sentry.captureMessage("getAllUnderwriterBonds", "info");
	try {
		let bonds = await getGreenBondsByField(
			["diligence", "status"],
			[email, BondStatus.UnderReview]
		);
		bonds = bonds?.res?.data?.GreenBond;
		return { bonds, success: true };
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getAllCreatedUnderwriterBonds = async () => {
	Sentry.captureMessage("getAllUnderwriterBonds", "info");
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/bond/getAllBonds`,
			method: "POST",
			headers: {},
		};
		let bonds = await axiosHttpService(options);
		bonds = bonds?.res?.records ? bonds?.res?.records : [];
		bonds = bonds.map((bond) => bond.data);
		bonds = bonds.filter(
			(bond) => bond.status === BondStatus.PendingForAdminApproval
		);
		console.log("getUnderReviewBonds ", bonds);
		return { bonds, success: true };
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getOpportunityName = async (poolAddress) => {
	if (!poolAddress || poolAddress === nullAddress) {
		return;
	}

	try {
		if (typeof window.ethereum !== "undefined") {
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const poolContract = new ethers.Contract(
				poolAddress,
				opportunityPool.abi,
				provider
			);
			const opName = await poolContract.getOpportunityName();
			return { opName, success: true };
		}
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
	return "";
};

export const getAllApprovedBonds = async (email, status) => {
	try {
		let bonds;
		if (status) {
			bonds = await getGreenBondsByField(
				["custodian", "status"],
				[email, BondStatus[status]]
			);
			bonds = bonds?.res?.data?.GreenBond;
		} else {
			bonds = await getGreenBondsByField("custodian", email);
			bonds = bonds?.res?.data?.GreenBond;
			bonds = bonds.filter((bond) => bond.status >= BondStatus.Approved);
		}
		bonds.sort(sortByProperty("status"));
		bonds.sort(sortByProperty("createdOn"));

		let opportunities = [];

		for (let i = 0; i < bonds.length; i++) {
			try {
				let { obj } = getOpportunity(bonds[i]);

				let transactions = await getTransactionByField(
					"bondId",
					bonds[i].Id
				);
				transactions = transactions.res.records
					? transactions.res.records
					: [];
				transactions = transactions.map((tx) => tx.data);
				transactions = transactions.filter(
					(tx) =>
						tx.status === TransactionStatus.Completed ||
						(tx.status === TransactionStatus.InVerification &&
							(tx.investorTransactionType ===
								InvestorTransactionType.Payout ||
								tx.borrowerTransactionType ===
									BorrowerTransactionType.Borrowed))
				);

				let tokenizedBonds = await getTokenizedBondsByField(
					"bondId",
					bonds[i].Id
				);
				tokenizedBonds = tokenizedBonds.res.records
					? tokenizedBonds.res.records.map(
							(tokenizedBond) => tokenizedBond.data
					  )
					: [];

				// Get Amortisation Schedule
				let options = {
					url: `${
						process.env.REACT_APP_FIREBASE_FUNCTIONS_URI
					}/accounting/${
						bonds[i].loan_type == 0
							? "getBulletLoanAmortisationSchedule"
							: "getTermLoanAmortisationSchedule"
					}`,
					method: "post",
					headers: {},
					data: {
						loanAmount: obj.actualLoanAmount,
						interestRatePercentage: obj.loanActualInterest,
						tenureInMonths: obj.actualLoanTenure,
						paymentFrequencyInDays: obj.actualPaymentFrequency,
						disbursmentDate: convertTimestampToDate(
							tokenizedBonds[0]?.repaymentStartTime
								? tokenizedBonds[0].repaymentStartTime
								: bonds[i].createdOn
						),
						investorUpfrontFees:
							bonds[i].investorUpfrontFeesPercentage,
						platformFeesPercentage: bonds[i].percentageOfCoupon
							? bonds[i].percentageOfCoupon
							: 10,
						JuniorContributionPercentage:
							bonds[i].juniorTranchPercentage,
						JuniorPrincipalFloatPercentage:
							bonds[i].juniorTranchFloatInterestPercentage,
					},
				};
				let res = await axiosHttpService(options);
				const {
					amortisationSchedule,
					seniorAmortisationSchedule,
					seniorXirr,
					juniorAmortisationSchedule,
					juniorXirr,
				} = res.res;

				obj.nftId = tokenizedBonds[0]?.nftId;
				if (tokenizedBonds[0]?.repaymentStartTime) {
					obj.issueDate = tokenizedBonds[0]?.repaymentStartTime;
					obj.issueDisplayDate = convertDate(
						tokenizedBonds[0]?.repaymentStartTime
					);
				}
				obj.totalRepaidAmount = tokenizedBonds[0]?.totalRepaidAmount
					? getDisplayAmount(tokenizedBonds[0]?.totalRepaidAmount)
					: "--";
				obj.amortisationSchedule = amortisationSchedule;
				obj.seniorAmortisationSchedule = seniorAmortisationSchedule;
				obj.seniorXirr = seniorXirr.toFixed(2).toString() + "% pa";
				obj.juniorAmortisationSchedule = juniorAmortisationSchedule;
				obj.juniorXirr = juniorXirr.toFixed(2).toString() + "% pa";

				// Set tranch params on obj
				obj.investorUpfrontFeesPercentage =
					bonds[i].investorUpfrontFeesPercentage;
				obj.percentageOfCoupon = bonds[i].percentageOfCoupon;
				obj.juniorTranchPercentage = bonds[i].juniorTranchPercentage;
				obj.juniorTranchFloatInterestPercentage =
					bonds[i].juniorTranchFloatInterestPercentage;

				let trx = [];
				let repayments = [];
				let paymentPendingTrx = [];
				for (let index = 0; index < transactions.length; index++) {
					const element = transactions[index];
					if (
						transactions[index].status ===
						TransactionStatus.InVerification
					) {
						obj.pending = true;
					}
					if (
						element.subscriberId &&
						element.investorTransactionType ===
							InvestorTransactionType.Invest
					) {
						// get the subscriber name
						const result = await getUserById(element.subscriberId);
						const subData = result.res.data;

						let subProfile = "";
						if (subData && subData.profile) {
							subProfile = JSON.parse(subData?.profile);
						}

						const subPercentage =
							((element.amount / obj.actualLoanAmount) * 100)
								.toFixed(2)
								.toString() + "%";
						let status;
						switch (obj.status) {
							case BondStatus.Approved:
								status = "Applied";
								break;
							case BondStatus.Tokenized:
								status = "Alloted";
								break;
							case BondStatus.Matured:
								status = "Matured";
								break;
						}
						trx.push({
							name: subProfile?.companyName,
							email: subData?.email,
							amount: element.amount,
							subPercentage,
							investedOn: convertDate(element.investedOn),
							status,
							Id: element.subscriberId,
							isSenior: transactions[index].isSenior
								? true
								: false,
						});
					} else if (
						element.issuerId &&
						element.isCouponRateDistributionPending
					) {
						paymentPendingTrx.push(element);
					}
					if (
						element.issuerId &&
						element.borrowerTransactionType ===
							BorrowerTransactionType.Repaid
					) {
						if (
							element.repaymentNumber ===
							tokenizedBonds[0]?.totalRepayments
						) {
							element.repaymentType = "Maturity Repayment";
						} else {
							element.repaymentType = "Regular Repayment";
						}
						repayments.push(element);
					}
					if (
						element.subscriberId &&
						obj.status === BondStatus.Matured &&
						element.investorTransactionType ===
							InvestorTransactionType.Payout
					) {
						obj.burningDate = element.investedOn;
						obj.burningDisplayDate = convertDate(
							element.investedOn
						);
					}
				}
				obj.repayments = repayments;
				obj.trx = trx;
				obj.paymentPendingTrx = paymentPendingTrx.sort(
					sortByPropertyAscending("repaymentNumber")
				);
				obj.totalInvestedAmt = transactions.reduce((total, trx) => {
					if (
						trx.investorTransactionType ===
						InvestorTransactionType.Invest
					) {
						return total + +trx.amount;
					}
					return total;
				}, 0);

				obj.subscribedPercentage =
					((obj.totalInvestedAmt * 100) / obj.actualLoanAmount)
						.toFixed()
						.toString() + "%";

				switch (obj.status) {
					case BondStatus.Approved:
						obj.bondDisplayStatus = "Open";
						break;
					case BondStatus.Tokenized:
						obj.bondDisplayStatus = "Tokenized";
						break;
					case BondStatus.Matured:
						obj.bondDisplayStatus = "Matured";
						break;
				}

				if (
					obj.actualLoanAmount - obj.totalInvestedAmt === 0 &&
					obj.status < BondStatus.Tokenized
				) {
					obj.bondDisplayStatus = "Subscribed";
				}
				if (obj.paymentPendingTrx.length > 0) {
					obj.bondDisplayStatus = "Part Paid";
				}
				if (
					obj.paymentPendingTrx.length > 0 &&
					tokenizedBonds[0].repaymentCounter >
						tokenizedBonds[0].totalRepayments
				) {
					obj.bondDisplayStatus = "Fully Paid";
				}
				if (
					obj.paymentPendingTrx.length === 0 &&
					obj.status === BondStatus.Tokenized
				) {
					const delayInDays = compareDelay(
						obj.amortisationSchedule[
							tokenizedBonds[0].repaymentCounter - 1
						].month
					);
					if (delayInDays) {
						obj.bondDisplayStatus = "Delayed";
						obj.delayInDays = delayInDays;
					}
				}
				opportunities.push(obj);
			} catch (error) {
				Sentry.captureException(error);
			}
		}

		return { opportunities, success: true };
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

export const getAllBonds = async (pageSize, bookmark) => {
	try {
		// const bondsCol = collection(db, BondsCollection);
		// const bondsQuery = query(
		// 	bondsCol,
		// 	where("status", ">=", BondStatus.Approved),
		// 	orderBy("status"),
		// 	orderBy("createdOn", "desc")
		// );
		// const BondsSnapshot = await getDocs(bondsQuery);

		// const bonds = BondsSnapshot.docs.map(getFirebaseSnapshotData);

		let options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/bond/getAllBonds`,
			method: "POST",
			headers: {},
			data: {
				pageSize: pageSize,
				bookmark: bookmark,
			},
		};

		let bonds = await axiosHttpService(options);
		const bookmarkString = bonds?.res?.bookmark;
		bonds = bonds.res.records;
		bonds = bonds.map((bond) => bond.data);
		bonds.sort(sortByProperty("status"));
		bonds.sort(sortByProperty("createdOn"));
		console.log("getAllBonds ", bonds);

		let opportunities = [];

		for (let i = 0; i < bonds.length; i++) {
			try {
				let { obj } = getOpportunity(bonds[i]);
				obj.bond = bonds[i];
				obj.issuerName = obj.companyDetails.companyName;

				// const transactionsCol = collection(db, TransactionsCollection);
				// const transactionsQuery = query(
				// 	transactionsCol,
				// 	where("bondId", "==", bonds[i].id)
				// );

				// const TransactionsSnapshot = await getDocs(transactionsQuery);
				// const transactions = TransactionsSnapshot.docs.map(
				// 	getFirebaseSnapshotData
				// );

				options = {
					url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/tokenizedBond/getTokenizedBond`,
					method: "POST",
					headers: {},
					data: {
						field: "bondId",
						value: bonds[i].Id,
					},
				};

				let tokenizedBonds = await axiosHttpService(options);
				tokenizedBonds = tokenizedBonds.res.records
					? tokenizedBonds.res.records.map(
							(tokenizedBond) => tokenizedBond.data
					  )
					: [];

				obj.outstandingAmount = tokenizedBonds[0]
					?.totalOutstandingPrincipal
					? tokenizedBonds[0]?.repaymentCounter < 6
						? tokenizedBonds[0]?.totalOutstandingPrincipal
						: "--"
					: "--";
				obj.issuedDate = tokenizedBonds[0]?.repaymentStartTime;

				if (obj?.issuedDate) {
					let date = new Date(+tokenizedBonds[0]?.repaymentStartTime);
					date.setDate(date.getDate() + obj.actualLoanTenure * 30);
					const maturityDate = Math.floor(date.getTime());
					obj.maturityDate = maturityDate;
				}

				switch (obj.status) {
					case BondStatus.PendingForAdminApproval:
						obj.bondDisplayStatus = "Pending For Admin Approval";
						break;
					case BondStatus.RejectedByAdmin:
						obj.bondDisplayStatus = "Rejected By Admin";
						break;
					case BondStatus.UnderReview:
						obj.bondDisplayStatus = "Under Review";
						break;
					case BondStatus.Rejected:
						obj.bondDisplayStatus = "Rejected";
						break;
					case BondStatus.Approved:
						obj.bondDisplayStatus = "Approved";
						break;
					case BondStatus.Tokenized:
						obj.bondDisplayStatus = "Tokenized";
						break;
					case BondStatus.Matured:
						obj.bondDisplayStatus = "Matured";
						let options = {
							url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/transaction/getTx`,
							method: "POST",
							headers: {},
							data: {
								field: "bondId",
								value: bonds[i].Id,
							},
						};
						let transactions = await axiosHttpService(options);
						transactions = transactions.res.records
							? transactions.res.records
							: [];
						transactions = transactions.map((tx) => tx.data);
						for (
							let index = 0;
							index < transactions.length;
							index++
						) {
							const element = transactions[index];
							if (
								element.subscriberId &&
								obj.status === BondStatus.Matured &&
								element.investorTransactionType ===
									InvestorTransactionType.Payout
							) {
								obj.maturityDate = element.investedOn;
							}
						}
						break;
				}
				opportunities.push(obj);
			} catch (error) {
				Sentry.captureException(error);
			}
		}
		console.log("opps", opportunities);

		return { bookmark: bookmarkString, opportunities, success: true };
	} catch (error) {
		Sentry.captureException(error);
		return {
			success: false,
			msg: error.message,
		};
	}
};

function getInvestmentDetailsByTx(transactions, loanAmount) {
	let trx = [];
	transactions.forEach(async (tx) => {
		const transaction = tx.data;
		if (
			transaction.subscriberId &&
			transaction.investorTransactionType ===
				InvestorTransactionType.Invest
		) {
			const result = await getUserById(transaction.subscriberId);
			const subData = result.res.data;

			let subProfile = "";
			if (subData && subData.profile) {
				subProfile = JSON.parse(subData?.profile);
			}

			const subPercentage =
				((transaction.amount / loanAmount) * 100)
					.toFixed(2)
					.toString() + "%";
			trx.push({
				name: subProfile?.companyName,
				email: subData?.email,
				amount: transaction.amount,
				subPercentage,
				investedOn: convertDate(transaction.investedOn),
			});
		}
	});

	return trx;
}

export function sortByProperty(property) {
	return function (a, b) {
		if (a[property] < b[property]) return 1;
		else if (a[property] > b[property]) return -1;

		return 0;
	};
}

export function sortByPropertyAscending(property) {
	return function (a, b) {
		if (a[property] > b[property]) return 1;
		else if (a[property] < b[property]) return -1;

		return 0;
	};
}

function compareDelay(deadline) {
	// Convert the deadline to a Date object
	var deadlineDate = new Date(deadline);

	// Get today's date
	var today = new Date();

	// Remove the time from the date objects
	deadlineDate.setHours(0, 0, 0, 0);
	today.setHours(0, 0, 0, 0);

	// Compare the dates and calculate the delay in days
	if (today > deadlineDate) {
		var delayInMilliseconds = today - deadlineDate;
		var delayInDays = Math.ceil(
			delayInMilliseconds / (1000 * 60 * 60 * 24)
		);
		return delayInDays;
	} else {
		return undefined;
	}
}

export function compareDelayInDDMMYY(deadline) {
	// Convert the deadline to a Date object
	var parts = deadline.split("/");
	var deadlineDate = new Date(parts[2], parts[1] - 1, parts[0]);

	// Get today's date
	var today = new Date();

	// Remove the time from the date objects
	deadlineDate.setHours(0, 0, 0, 0);
	today.setHours(0, 0, 0, 0);

	// Compare the dates and calculate the delay in days
	if (today > deadlineDate) {
		var delayInMilliseconds = today - deadlineDate;
		var delayInDays = Math.ceil(
			delayInMilliseconds / (1000 * 60 * 60 * 24)
		);
		return delayInDays;
	} else {
		return undefined;
	}
}
