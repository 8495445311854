import { React, useState, useEffect, useRef } from "react";
import WalletImage from "../../../../assets/wallet_white.png";
import DollarImage from "../../../../assets/Dollar-icon.svg";
import Loader from "../../../../uiTools/Loading/Loader";
import {
	formatNumberWithCommas,
	getGUID,
} from "../../../../services/Helpers/displayTextHelper";

const InvestModal = ({
	isSenior,
	poolAddress,
	poolName,
	poolLimit,
	estimatedAPY,
	investableAmount,
	investableDisplayAmount,
	setProcessDocModal,
	setDocProcessing,
	setSelected,
	settxhash,
	setcontractAdrress,
	setAmounts,
	setInvest,
	setTransactionList,
	handleDrawdown,
	setCheckDoc,
	setErrormsg,
	trancheAmount,
	xirr,
}) => {
	const [amount, setAmount] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({
		approveErr: true,
		investErr: true,
		msg: "",
	});
	const inputElement = useRef();

	useEffect(() => {
		inputElement.current.focus();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	async function investJunior() {
		setAmounts(amount);
		setcontractAdrress(getGUID());
		setProcessDocModal(true);
		setDocProcessing(true);
		handleDrawdown();
		setCheckDoc(true);
		setTimeout(() => {
			settxhash(getGUID());
			setSelected(null);
			setDocProcessing(false);
		}, 5000);
	}

	const handleAmount = (e) => {
		const value = e.target.value;
		let investInputCode = 0;

		// 0
		const defaultErr = {
			approveErr: false,
			investErr: true,
			msg: "",
		};

		// 1
		const allowedInvest = {
			approveErr: true,
			investErr: false,
			msg: "",
		};

		// 3
		const investableErr = {
			approveErr: true,
			investErr: true,
			// msg: "Can't approve / invest more than Investable Amount",
			msg: "Can't invest more than Investable Amount",
		};

		// 4
		const invalidErr = {
			approveErr: true,
			investErr: true,
			msg: "Invalid Amount",
		};
		console.log("investabe", investableAmount);

		if (investableAmount) {
			if (+value > +investableAmount) {
				setError(investableErr);
				investInputCode = 3;
			} else {
				if (investInputCode === 0) {
					setError(defaultErr);
				}
				if (investInputCode === 1) {
					setError(allowedInvest);
				}
			}
		}

		if (+value <= 0 || !value) {
			setError(invalidErr);
		} else if (+value <= +investableAmount) {
			// if (investInputCode === 0) {
			// 	setError(defaultErr);
			// }

			//if (investInputCode === 1) {
			setError(allowedInvest);
			//}
		}

		setAmount(value);
	};

	return (
		<>
			<input type="checkbox" id="InvestModal" className="modal-toggle" />
			<div className="modal backdrop-filter backdrop-brightness-[100%] backdrop-blur-lg">
				{loading && <Loader />}
				<div
					className={`bg-neutral-50 dark:bg-darkmode-800  w-[100vw] h-[100vh] flex flex-col md:block md:h-auto md:w-[70%] lg:w-[50%] xl:w-[45%] 2xl:w-[40%] pb-[6em] md:rounded-xl md:pb-8 ${
						loading ? "blur-sm" : ""
					}`}
				>
					<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
						<h3 className="font-normal text-xl">Subscribe</h3>

						<label
							htmlFor="InvestModal"
							// onClick={() => handleDrawdown()}
							className=" hover:text-primary-600 text-xl"
						>
							✕
						</label>
					</div>

					<div className="px-4 md:px-8 mt-[4em] md:mt-6 flex flex-col gap-8">
						<img
							src={WalletImage}
							style={{ aspectRatio: 1 / 1 }}
							className="w-[4rem] mx-auto p-4 bg-purple-500 rounded-[50%]"
							alt=""
						/>
					</div>

					<div className="px-4 md:px-8 mt-8 flex flex-col gap-1">
						<div className="flex justify-between font-normal">
							<p className="">Request Name</p>
							<p className="">{poolName}</p>
						</div>

						{poolLimit ? (
							<div className="flex gap-1 font-normal">
								<p className="">Tranche amount</p>

								<img
									alt=""
									src={DollarImage}
									className="ml-auto w-[1rem]"
								/>
								{/* <p className="">{poolLimit}</p> */}
								<p className="">{trancheAmount}</p>
							</div>
						) : (
							<></>
						)}

						<div className="flex justify-between font-normal">
							<p className="">Expected XIRR</p>
							<p className="">
								{/* {estimatedAPY}
								{isSenior ? "%" : ""} */}
								{xirr}
							</p>
						</div>

						{/* {!isSenior ? ( */}
						<div className="flex gap-1 font-normal">
							<p className="">Investible Amount</p>

							<img
								alt=""
								src={DollarImage}
								className="ml-auto w-[1rem]"
							/>
							<p className="">
								{formatNumberWithCommas(investableAmount)}
							</p>
						</div>
						{/* ) : (
							<></>
						)} */}
					</div>

					<div className="relative px-4 md:px-8 mt-8 flex flex-col gap-1">
						<label
							htmlFor="investModalAmount"
							className="font-normal"
						>
							Enter Amount
						</label>
						<input
							ref={inputElement}
							type="number"
							id="investModalAmount"
							placeholder="0.0"
							onChange={handleAmount}
							value={amount}
							className="bg-neutral-100 dark:bg-darkmode-700 border-2 border-neutral-300 dark:border-darkmode-50 outline-none px-2 py-3 pr-14 rounded-md placeholder:text-neutral-500 placeholder:font-normal"
						/>
						<span className="absolute right-7 md:right-11 text-neutral-500 top-10 font-normal">
							{process.env.REACT_APP_TOKEN_NAME}
						</span>

						{error.msg.length > 0 && (
							<p className="text-[0.875rem] text-error-500">
								{error.msg}
							</p>
						)}
					</div>

					<div className="px-4 md:px-8 mt-auto md:mt-8">
						<button
							onClick={() => {
								if (!error.investErr) investJunior();
							}} //if condition not true then investJunior will execute
							className={`block font-normal text-white focus:outline-offset-2 ${
								error.investErr
									? "bg-neutral-400 cursor-not-allowed w-full opacity-40"
									: "bg-gradient-to-r from-[#4B74FF] to-primary-500 w-[100%] cursor-pointer   focus:outline-none focus:outline-[#9281FF] hover:outline-[#9281FF]"
							}  text-center py-2 rounded-[1.8em] select-none`}
						>
							Subscribe
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default InvestModal;
