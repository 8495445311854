import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoImage from "../../assets/logo.png";
import Hamburger from "../../pages/SVGIcons/Hamburger";
import Cross from "../../assets/cross.svg";
import { useContext } from "react";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import Logout from "../../Logout/Logout";
import SessionExpiredModal from "../../uiTools/Modal/SessionExpiredModal";

const Header = ({ linkStatus, darkMode, setDarkMode, setMetaStatus }) => {
	const [showPopup, setShowPopup] = useState(false);
	const [isSelected, setIsSelected] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();
	const { logout, isLoggedIn } = useContext(LoginContext);
	localStorage.setItem("dark-mode", false);
	useEffect(() => {
		async function fetchData() {
			darkModeStatus();
		}
		fetchData();
		if (location.pathname !== "/" && location.pathname !== "/admin") {
			fetchSessionStorage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	useEffect(() => {
		const storageEventListener = (event) => {
			if (
				event.storageArea === sessionStorage &&
				event.key === "loginData"
			) {
				setIsSelected(true);
			}
		};

		window.addEventListener("storage", storageEventListener);

		// Cleanup function to remove event listener when component unmounts
		return () => {
			window.removeEventListener("storage", storageEventListener);
		};
	}, []);

	function fetchSessionStorage() {
		const storedData = sessionStorage.getItem("loginData");
		if (storedData) {
			const { isLoggedIn, userData } = JSON.parse(storedData);
			// console.log("header", userData);
			if (!userData) {
				setIsSelected(true);
			}
		} else {
			setIsSelected(true);
		}
	}

	function darkModeStatus() {
		if (localStorage.getItem("dark-mode") === "false") {
			setDarkMode(false);
		} else {
			setDarkMode(true);
		}
	}

	const changeTheme = () => {
		setDarkMode((prev) => {
			console.log(prev);
			localStorage.setItem("dark-mode", !prev);

			return !prev;
		});
	};

	const handleLogout = () => {
		logout();
		setShowPopup(false);
	};

	return (
		<>
			<Logout isLogout={showPopup} setIsLogout={setShowPopup} />
			<SessionExpiredModal
				isSelected={isSelected}
				setIsSelected={setIsSelected}
			/>
			<div className="flex gap-4 sm:gap-8 px-2  sm:px-4 md:px-6 py-2 relative items-center bg-transparent  text-neutral-700 dark:text-white justify-between">
				<div className="">
					<img
						className="md:w-40 h-11 w-28 md:h-16 cursor-pointer"
						src={LogoImage}
						alt="company logo"
						onClick={() => navigate("/")}
					/>
				</div>

				{location.pathname !== "/" && (
					<div>
						<h1 class="text-3xl font-bold  leading-tight tracking-wide text-[#8110FE]">
							Tokenization of Sustainable Finance Instruments
						</h1>
					</div>
				)}

				<div className="flex gap-5 align-middle justify-center">
					<div className="float-right">
						{location.pathname !== "/" ? (
							<button
								disabled={!isLoggedIn}
								className={`${
									isLoggedIn
										? "py-2 shadow-2xl relative px-12 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
										: "py-2 shadow-2xl relative px-12 bg-gradient-to-r bg-neutral-400 cursor-not-allowed border border-gray-600 opacity-40 capitalize font-medium  rounded-3xl"
								}`}
								onClick={() => setShowPopup(true)}
							>
								Logout
							</button>
						) : (
							""
						)}
					</div>

					<div className="lg:hidden mt-3">
						{location.pathname !== "/" ? (
							<label htmlFor="dashboard-sidebar">
								{!linkStatus ? (
									<Hamburger />
								) : (
									<img
										className="w-6"
										alt="Hamburger"
										src={Cross}
									/>
								)}
							</label>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
