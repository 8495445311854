function Hamburger() {
	return (
		<svg
			width="22"
			height="12"
			viewBox="0 0 22 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.66536 0.666992C0.928985 0.666992 0.332031 1.26395 0.332031 2.00033C0.332031 2.73671 0.928985 3.33366 1.66536 3.33366H20.332C21.0684 3.33366 21.6654 2.73671 21.6654 2.00033C21.6654 1.26395 21.0684 0.666992 20.332 0.666992H1.66536Z"
				fill="#A0ABBB"
			/>
			<path
				d="M1.66536 8.66699C0.928985 8.66699 0.332031 9.26395 0.332031 10.0003C0.332031 10.7367 0.928985 11.3337 1.66536 11.3337H20.332C21.0684 11.3337 21.6654 10.7367 21.6654 10.0003C21.6654 9.26395 21.0684 8.66699 20.332 8.66699H1.66536Z"
				fill="#A0ABBB"
			/>
		</svg>
	);
}

export default Hamburger;
