import React, { useEffect, useState } from "react";
import axiosHttpService from "../../../services/axioscall";
import * as Sentry from "@sentry/react";
import { Role } from "../../../services/BackendConnectors/userConnectors/userLogin";
import {
	getUserAccountStatus,
	enableDisableUser,
} from "../../../services/ApiCalls/userCalls";

const UsersCard = ({
	data,
	setSelectedEditModal,
	setCurrentUser,
	setErrormsg,
	setSuccessmsg,
	setLoading,
	setSelectedDeleteUserModal,
	setItem,
}) => {
	const [isDisabled, setIsDisabled] = useState(false);
	useEffect(() => {
		getUserAccountStatus(data.email).then((result) => {
			setIsDisabled(result?.disabled);
		});
	}, [isDisabled]);

	const RoleArray = Object.keys(Role);

	let role = RoleArray[data.role];

	const handleEnableDisable = async () => {
		try {
			setLoading(true);
			const result = await enableDisableUser(data.email);
			if (result.success) {
				setIsDisabled(result.disabled);
				setSuccessmsg({
					status: true,
					msg: `User Account ${
						result.disabled ? "Disabled" : "Enabled"
					}`,
				});
				getUserAccountStatus(data.email).then((result) => {
					setIsDisabled(result.disabled);
				});
			} else {
				setErrormsg({
					status: true,
					msg: "User does not exists...",
				});
			}
			setLoading(false);
		} catch (error) {
			Sentry.captureException(error);
			setErrormsg({
				status: true,
				msg: "User does not exists...",
			});
			setLoading(false);
		}
	};

	return (
		<div className="flex collapse-title pr-0 justify-between w-full flex-wrap overflow-hidden dark:bg-[#20232A] bg-[#E7EAEE] rounded-xl mb-2 items-center">
			<p className="w-1/3 md:w-1/6 font-light text-lg text-center">
				{data?.email}
			</p>
			<p className="w-1/3 md:w-1/6 font-light text-lg text-center">
				{role}
			</p>
			<div className="w-1/3 md:w-1/6 text-center">
				<button
					className="rounded-full h-8 w-20  transition ease-linear duration-500 overflow-hidden border-2 border-[#10B981] btn btn-xs btn-outline text-[#10B981] text-base  capitalize font-medium mr-2"
					onClick={() => {
						setCurrentUser(data);
						setSelectedEditModal(true);
					}}
				>
					Edit
				</button>
			</div>
			<div className="w-1/3 md:w-1/6 text-center">
				<button
					className="rounded-full h-8 w-20  transition ease-linear duration-500 overflow-hidden border-2 border-[#1059b9] btn btn-xs btn-outline text-[#1059b9] text-base  capitalize font-medium mr-8"
					onClick={handleEnableDisable}
				>
					{isDisabled ? "Enable" : "Disable"}
				</button>
			</div>
			<div className="w-1/3 md:w-1/6 text-center">
				<button
					className="rounded-full h-8 w-20  transition ease-linear duration-500 overflow-hidden border-2 border-[#b91010] btn btn-xs btn-outline text-[#b91010] text-base  capitalize font-medium mr-14"
					onClick={() => {
						setItem(data);
						setSelectedDeleteUserModal(true);
					}}
				>
					Delete
				</button>
			</div>
		</div>
	);
};

export default UsersCard;
