import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
	formatNumberWithCommas,
	getExtendableTextBreakup,
} from "../../services/Helpers/displayTextHelper";
import DocumentCard from "../../uiTools/Card/DocumentCard";
import Email from "../SVGIcons/Email";
import LinkedIn from "../SVGIcons/LinkedIn";
import Twitter from "../SVGIcons/Twitter";
import Website from "../SVGIcons/Website";
import Loader from "../../uiTools/Loading/Loader";
import { convertDate } from "../../services/BackendConnectors/userConnectors/commonConnectors";
import {
	BondStatus,
	voteOpportunity,
} from "../../services/BackendConnectors/opportunityConnectors";
import axiosHttpService from "../../services/axioscall";
import { kycOptions } from "../../services/KYC/blockpass";
import default_profile from "../../assets/default_profile.svg";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import { getTrimmedString } from "../../services/Helpers/displayTextHelper";
import { GreenScore } from "dygnify-green-score";
import ApprovalRequestModal from "./Components/Modal/ApprovalRequestModal";
import dollarIcon from "../../assets/Dollar-icon.svg";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import CryptoJS from "crypto-js";
import { getTokenizedBondsByField } from "../../services/ApiCalls/tokenizedBondCalls";
import { captureException, captureMessage } from "@sentry/react";
import { getNft, createNft } from "../../services/ApiCalls/nftCalls";

const AdminPoolDetails = () => {
	const location = useLocation();
	const [expand, setExpand] = useState(false);
	const [opDetails, setOpDetails] = useState();
	const [companyDetails, setCompanyDetails] = useState();
	const [info, setInfo] = useState([]);
	const [platformFee, setPlatformFee] = useState([]);
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});

	const [idproof, setIdproof] = useState();

	const [loanPurpose, setLoanPurpose] = useState({
		isSliced: false,
		firstText: "",
		secondText: "",
	});

	const [status, setStatus] = useState({
		approve: false,
		unsure: false,
		reject: false,
	});

	const [loading, setLoading] = useState(false);

	const [selected, setSelected] = useState(false);
	const [greenScore, setGreenScore] = useState({});
	const { userData } = useContext(LoginContext);

	const checkForKycAndProfile = async (refId) => {
		try {
			const result = await axiosHttpService(kycOptions(refId));

			if (
				result.res.status === "success" &&
				result.res.data.status === "approved"
			) {
				if (result.res.data.identities["passport"]) {
					setIdproof(result.res.data.identities.passport);
				} else if (result.res.data.identities["national_id"]) {
					setIdproof(result.res.data.identities.national_id);
				} else if (result.res.data.identities["driving_license"]) {
					setIdproof(result.res.data.identities.driving_license);
				}
			}
		} catch (error) {
			captureException(error);
		}
	};

	useEffect(() => {
		setOpDetails(location.state?.pool);
		if (location.state?.pool?.status === BondStatus.RejectedByAdmin) {
			updateStatus(BondStatus.RejectedByAdmin);
		} else if (location.state?.pool?.status >= BondStatus.UnderReview) {
			updateStatus(BondStatus.UnderReview);
		}
	}, [location.state]);

	useEffect(() => {
		if (opDetails) {
			loadInfo();
			loadPlatformFee();
			loadLoanPurpose();

			checkForKycAndProfile(opDetails.borrower);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [opDetails]);

	// If GreenScore updated, update the nft hash
	useEffect(
		() =>
			(async () => {
				try {
					if (Object.keys(greenScore).length) {
						if (opDetails.status === BondStatus.Tokenized) {
							let tokenizedBonds = await getTokenizedBondsByField(
								"bondId",
								opDetails.Id
							);
							tokenizedBonds = tokenizedBonds.res.records
								? tokenizedBonds.res.records.map(
										(tokenizedBond) => tokenizedBond.data
								  )
								: undefined;

							if (!tokenizedBonds) {
								throw new Error(
									`Unable to get TokenizedBond with bondId: ${opDetails.Id}`
								);
							}
							const nftId = tokenizedBonds[0].nftId;

							let nftData = await getNft(nftId);
							if (!nftData) {
								throw new Error(
									`Unable to get NFT with Id: ${nftId}`
								);
							}

							if (nftData.success) {
								let dateExistsInNft = false;
								nftData.greenScoreHashList.forEach(
									(element) => {
										if (element.time === greenScore.date) {
											dateExistsInNft = true;
											return;
										}
									}
								);
								if (!dateExistsInNft) {
									let stringObj = JSON.stringify(greenScore);
									let hash = CryptoJS.SHA256(stringObj);
									let hashString = hash.toString(
										CryptoJS.enc.Hex
									);
									let newNftData = {
										functionName:
											"UpdateGreenBondNFTDynamicData",
										identity: userData?.email,
										args: [
											tokenizedBonds[0].nftId,
											"greenScoreHashList",
											{
												time: greenScore.date,
												hash: hashString,
											},
										],
									};
									let result = await createNft(newNftData);
									if (!result) {
										throw new Error(
											`Unable to create NFT.`
										);
									}
								}
							}
						}
					}
				} catch (error) {
					captureException(error);
				}
			})(),
		[greenScore]
	);

	function loadInfo() {
		if (opDetails) {
			const compDetails = JSON.parse(opDetails.companyDetails);
			setCompanyDetails(compDetails);
			setInfo([
				{
					label: "Proposed Date",
					value: opDetails.createdOn
						? convertDate(opDetails.createdOn)
						: "--",
				},
				{
					label: "Payment Frequency",
					value: opDetails.payment_frequency
						? `${opDetails.payment_frequency} days`
						: "--",
				},
				{
					label: "Issuer",
					value: compDetails.companyName
						? getTrimmedString(compDetails.companyName)
						: "--",
				},
				{
					label: "Interest Rate",
					value: opDetails.loan_interest
						? `${opDetails.loan_interest}% pa`
						: "--",
				},
				{
					label: "Tenure",
					value: opDetails.loan_tenure
						? `${opDetails.loan_tenure / 30} Months`
						: "--",
				},
				{
					label: "Amount",
					value: opDetails.loan_amount
						? formatNumberWithCommas(opDetails.loan_amount)
						: "--",
				},
				{
					label: "First Loss Capital",
					value: opDetails.capital_loss
						? `${opDetails.capital_loss}%`
						: "--",
				},
				{
					label: "Request sub type",
					value: opDetails.loan_type === 0 ? "Bullet" : "Term",
				},
			]);
		}
	}
	function loadPlatformFee() {
		if (opDetails) {
			setPlatformFee([
				{
					label: "Fees Type",
					value:
						opDetails.isRecurring !== undefined
							? opDetails.isRecurring === true
								? "Recurring"
								: "Upfront"
							: "--",
				},
				{
					label:
						opDetails.isPercentageOfCoupon !== undefined
							? opDetails.isPercentageOfCoupon === true
								? `Percentage Of ${
										opDetails.isRecurring === true
											? "Interest"
											: "Request Amount"
								  }`
								: "Fixed Amount"
							: "Amount",
					value:
						opDetails.isPercentageOfCoupon !== undefined
							? opDetails.isPercentageOfCoupon === true
								? opDetails.percentageOfCoupon
								: opDetails.fixedAmount
							: "--",
				},
			]);
		}
	}

	function loadLoanPurpose() {
		if (!opDetails || !opDetails.loan_purpose) {
			return;
		}
		const { isSliced, firstText, secondText } = getExtendableTextBreakup(
			opDetails.loan_purpose,
			200
		);

		if (isSliced) {
			setLoanPurpose({
				firstText: firstText,
				secondText: secondText,
				isSliced: isSliced,
			});
		} else {
			setLoanPurpose({
				firstText: firstText,
				isSliced: isSliced,
			});
		}
	}

	function updateStatus(vote) {
		if (vote === 1) {
			setStatus({ approve: false, unsure: false, reject: true });
		} else if (vote === 2) {
			setStatus({ approve: true, unsure: false, reject: false });
		} else if (vote === 3) {
			setStatus({ approve: false, unsure: true, reject: false });
		}
	}

	async function vote(voteID, platformFeeObj) {
		try {
			setLoading(true);
			const result = await voteOpportunity(
				opDetails,
				voteID,
				platformFeeObj
			);
			if (result.success) {
				updateStatus(voteID);
			} else {
				captureMessage(result.msg, "error");
				setErrormsg({
					status: !result.success,
					msg: result.msg,
				});
			}
			setLoading(false);
		} catch (error) {
			captureException(error);
			setErrormsg({
				status: true,
				msg: error.message,
			});
			setLoading(false);
		}
	}

	const viewDocument = (docCid) => {
		if (!docCid) return null;
		window.open(docCid, "_blank");
	};

	const redirectToURl = (event) => {
		let url;

		switch (event.target.id) {
			case "twitter":
				url = companyDetails?.twitter;
				break;
			case "linkedin":
				url = companyDetails?.linkedin;
				break;
			case "website":
				url = companyDetails?.website;
				break;
			default:
				break;
		}

		if (url) {
			let protocol = "https://";
			let position = url.search(protocol);
			// if there is no "https://" in the url then it is not opened correctly
			if (position === -1) {
				url = protocol + url;
			}
			window.open(url, "_blank");
		}
	};

	const redirectForEmail = () => {
		if (companyDetails?.email) {
			window.location.href = "mailto:" + companyDetails?.email;
		}
	};

	const handleForm = () => {
		setSelected(false);
	};

	return (
		<div className={`${loading ? "" : ""}`}>
			{loading && <Loader />}
			{/* main container  */}
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />

			<div className={`md:pt-7 ${loading ? "blur-sm" : ""}`}>
				{selected && (
					<ApprovalRequestModal
						handleForm={handleForm}
						setSelected={setSelected}
						title="Approve GreenBond"
						steps={[
							"Platform Fees",
							"Assign Custodian",
							"Assign Diligence",
							"Summary",
						]}
						vote={vote}
						setPlatformFee={setPlatformFee}
					/>
				)}
				{/*section-1*/}
				<div className="flex flex-col gap-6 overflow-hidden flex-wrap md:flex-row md:justify-between  ">
					{/* section-1-1 --profile  */}
					<div className="flex items-center gap-6 ">
						<div>
							<img
								alt="logo"
								src={
									companyDetails?.companyLogoFile
										?.businessLogoFileCID
										? companyDetails?.companyLogoFile
												?.businessLogoFileCID
										: default_profile
								}
								className="w-20 h-20 rounded-full lg:w-24 lg:h-24 xl:w-28  xl:h-28"
							></img>
						</div>
						<div>
							<div className="font-medium text-2xl -mb-1 xl:text-3xl">
								{opDetails?.loan_name}
							</div>
							<div className="font-normal text-[#64748B]  xl:text-xl xl:font-light">
								{companyDetails?.companyName}
							</div>
						</div>
					</div>
					{/* section-1-2 --buttons */}
					<div className="flex  justify-around  gap-5 md:justify-end md:gap-2 ">
						{status.approve ||
						!(status.approve || status.reject || status.unsure) ? (
							<label
								disabled={status.approve}
								// onClick={() => vote(2)}
								onClick={() => {
									if (!status.approve) {
										setSelected(true);
									}
								}}
								htmlFor="approvalForm-modal"
								className={
									!status.approve
										? "rounded-full h-12 w-[29%]  transition ease-linear duration-500 overflow-hidden border-2 border-[#10B981] btn btn-xs btn-outline text-[#10B981] text-base  capitalize font-medium md:px-14"
										: "rounded-3xl py-1 px-2 approved-btn capitalize text-[#000000] font-medium md:h-8 md:mr-10"
								}
							>
								{status.approve ? "Approved" : "Approve"}
							</label>
						) : null}

						{status.reject ||
						!(status.approve || status.reject || status.unsure) ? (
							<button
								disabled={status.reject}
								onClick={() => vote(1)}
								className={
									!status.reject
										? "rounded-full h-12 w-[29%] transition ease-linear duration-500 overflow-hidden  border-2 border-[#EF4444] btn btn-xs btn-outline text-[#EF4444] text-base  capitalize font-medium md:px-14"
										: "rounded-3xl py-1 px-2 rejected-btn capitalize text-[#000000]  font-medium md:h-8 md:mr-10"
								}
							>
								{status.reject ? "Rejected" : "Reject"}
							</button>
						) : null}
					</div>
				</div>
				{/* section-2  --Deals overview*/}
				<div className="flex-row justify-between w-full felx">
					{/* section-2-1 --heading */}

					<div className="mt-10 mb-3 text-lg font-medium">
						Proposal Overview
					</div>

					{/* Section-2-2 --text */}
					<div className="dark:text-[#D0D5DD] text-[323A46]  tracking-wide font-light text-lg">
						{loanPurpose.isSliced ? (
							<div>
								{loanPurpose.firstText}
								<span
									className=" font-normal cursor-pointer"
									onClick={() => setExpand(true)}
								>
									{expand ? null : "... view more"}
								</span>
								{expand ? (
									<div>{loanPurpose.secondText}</div>
								) : null}
								<span
									className=" font-normal cursor-pointer"
									onClick={() => setExpand(false)}
								>
									{expand ? "view less" : null}
								</span>
							</div>
						) : (
							<div className="font-light text-lg">
								{loanPurpose.firstText}
							</div>
						)}
					</div>
				</div>

				{/* section-3 --Deal Terms */}
				<div className="flex-col w-full flex">
					{/* section-3-1 --heading  */}
					<div className="flex-row justify-between mt-10 mb-3 flex">
						<div className="mb-0 text-lg font-medium">
							Proposal terms
						</div>
					</div>
					{/* section-3-2 --item  */}
					<div className="rounded-box w-auto dark:bg-[#292C33] bg-[#A0ABBB] overflow-hidden">
						<div className="grid grid-cols-2 gap-[2px] my-0.5 md:my-0 md:grid-cols-3 xl:grid-cols-4">
							{info ? (
								info.map((e, i) => {
									return (
										<div
											key={i}
											className="flex justify-center flex-col items-center dark:bg-[#20232A] bg-[#D0D5DD] py-10"
										>
											<div className="font-medium text-base text-center dark:text-[#A0ABBB] text-[#64748B]">
												{e.label}
											</div>
											<div className="font-medium text-xl text-center  flex justify-center items-center gap-2">
												{e.label === "Amount" && (
													<span className="w-4">
														<img
															src={dollarIcon}
															alt=""
														/>
													</span>
												)}
												{e.value}
											</div>
										</div>
									);
								})
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
				{/* section-3.5 --Deal Terms */}
				<div className="flex-col w-full flex">
					{/* section-3.5-1 --heading  */}
					<div className="flex-row justify-between mt-10 mb-3 flex">
						<div className="mb-0 text-lg font-medium">
							Platform Fees
						</div>
					</div>
					{/* section-3-2 --item  */}
					<div className="rounded-box w-1/2 dark:bg-[#292C33] bg-[#A0ABBB] overflow-hidden">
						<div className="grid grid-cols-2 gap-[2px] my-0.5 md:my-0 md:grid-cols-2 xl:grid-cols-2">
							{platformFee ? (
								platformFee.map((e, i) => {
									return (
										<div
											key={i}
											className="flex justify-center flex-col items-center dark:bg-[#20232A] bg-[#D0D5DD] py-10"
										>
											<div className="font-medium text-base text-center dark:text-[#A0ABBB] text-[#64748B]">
												{e.label}
											</div>
											<div className="font-medium text-xl text-center flex justify-center items-center gap-2">
												{e.label.includes(
													" Amount"
												) && (
													<span className="w-4">
														<img
															src={dollarIcon}
															alt=""
														/>
													</span>
												)}
												{e.value}
											</div>
										</div>
									);
								})
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
				{/*section-4  --Collateral*/}
				<div>
					<div className="text-lg font-medium mt-10 mb-3">
						Collateral
					</div>
					<div className="w-full dark:bg-[#20232A] bg-[#D0D5DD] rounded-xl p-3">
						<div className="dark:text-[#A0ABBB] text-[#4B5768] font-medium text-lg flex flex-col md:flex-row">
							<span>
								Name of documents{" "}
								<span className="text-[#323A46] dark:text-[white] pr-1">
									-
								</span>
							</span>
							<span className="text-[#323A46] dark:text-[white]">
								{opDetails?.collateral_document_name}
								<span
									className="pl-1 text-sm text-[#5375FE] cursor-pointer"
									onClick={() =>
										viewDocument(opDetails?.collateralHash)
									}
								>
									(view document)
								</span>
							</span>
						</div>
						<div className="dark:text-[#A0ABBB] text-[#4B5768] font-medium text-lg mb-1">
							Document descripton
						</div>
						<div className="dark:text-[#D0D5DD] text-[#323A46] tracking-wide font-light text-lg px-1 mr-1 pr-6 items-start ">
							{opDetails?.collateral_document_description}
						</div>
					</div>
				</div>

				{/* section-5 --Borrower Details  */}
				<div className="flex flex-col w-full">
					<div className="flex items-center gap-2 text-lg font-medium mt-10 ">
						<img
							alt="logoimage"
							src={
								companyDetails?.companyLogoFile
									?.businessLogoFileCID
									? companyDetails?.companyLogoFile
											?.businessLogoFileCID
									: default_profile
							}
							className="w-16 h-16 rounded-full md:hidden"
						></img>
						Issuer Details
					</div>
					{/* section-5-1 --social media*/}
					<div className=" flex flex-row-reverse  justify-between mt-5 md:hidden">
						{companyDetails?.twitter ? (
							<button
								id="twitter"
								className="btn CreateProfileIcon btn-sm px-2 dark:border-none btn-outline dark:bg-[#292C33] border border-neutral-500  dark:text-white text-black py-2 gap-1 rounded-full  lowercase flex pb-5"
								onClick={redirectToURl}
							>
								<Twitter /> twitter
							</button>
						) : (
							<></>
						)}
						{companyDetails?.linkedin ? (
							<button
								id="linkedin"
								className="btn CreateProfileIcon btn-sm px-2 dark:border-none btn-outline dark:bg-[#292C33] border border-neutral-500  dark:text-white text-black py-2 gap-1 rounded-full  capitalize flex pb-5"
								onClick={redirectToURl}
							>
								<LinkedIn />
								LinkedIn
							</button>
						) : (
							<></>
						)}
						{companyDetails?.email ? (
							<button
								id="email"
								className="btn CreateProfileIcon btn-sm px-2 dark:border-none btn-outline dark:bg-[#292C33] border border-neutral-500  dark:text-white text-black py-2 gap-1 rounded-full  capitalize flex pb-5"
								onClick={redirectForEmail}
							>
								<Email />
								Email
							</button>
						) : (
							<></>
						)}
						{companyDetails?.website ? (
							<button
								id="website"
								className="btn btn-sm px-2 dark:border-none btn-outline dark:bg-[#292C33] border border-neutral-500  dark:text-white text-black py-2 gap-1 rounded-full  capitalize flex pb-5"
								onClick={redirectToURl}
							>
								<Website />
								Website
							</button>
						) : (
							<></>
						)}
					</div>
					{/* section-5-2 --Companyname*/}
					<div className="md:flex md:justify-between md:items-center md:mt-2 md:mb-3 ">
						<div className="text-lg font-medium mt-10 md:flex md:items-center gap-4 md:mt-0">
							<img
								alt="logoimage"
								src={
									companyDetails?.companyLogoFile
										?.businessLogoFileCID
										? companyDetails?.companyLogoFile
												?.businessLogoFileCID
										: default_profile
								}
								className="w-16 h-16 rounded-full hidden md:block"
							></img>

							{companyDetails
								? companyDetails.companyName
								: "Name of the Company"}
						</div>
						<div className=" md:flex md:flex-row-reverse gap-4 md:justify-between hidden">
							{companyDetails?.twitter ? (
								<button
									id="twitter"
									className="btn CreateProfileIcon btn-sm px-2 dark:border-none btn-outline dark:bg-[#292C33] border border-neutral-500  dark:text-white text-black py-2 gap-1 rounded-full  lowercase flex pb-5"
									onClick={redirectToURl}
								>
									<Twitter /> twitter
								</button>
							) : (
								<></>
							)}
							{companyDetails?.linkedin ? (
								<button
									id="linkedin"
									className="btn CreateProfileIcon btn-sm px-2 dark:border-none btn-outline dark:bg-[#292C33] border border-neutral-500  dark:text-white text-black py-2 gap-1 rounded-full  capitalize flex pb-5"
									onClick={redirectToURl}
								>
									<LinkedIn />
									LinkedIn
								</button>
							) : (
								<></>
							)}
							{companyDetails?.email ? (
								<button
									id="email"
									className="btn CreateProfileIcon btn-sm px-2 dark:border-none btn-outline dark:bg-[#292C33] border border-neutral-500  dark:text-white text-black py-2 gap-1 rounded-full  capitalize flex pb-5"
									onClick={redirectForEmail}
								>
									<Email />
									Email
								</button>
							) : (
								<></>
							)}
							{companyDetails?.website ? (
								<button
									id="website"
									className="btn  btn-sm px-2 dark:border-none btn-outline dark:bg-[#292C33] border border-neutral-500  dark:text-white text-black py-2 gap-1 rounded-full  capitalize flex pb-5"
									onClick={redirectToURl}
								>
									<Website />
									Website
								</button>
							) : (
								<></>
							)}
						</div>
					</div>

					{/* section-5-2 --Companybio*/}
					<div className="dark:text-[#D0D5DD] text-[#323A46] tracking-wide font-light text-lg  items-start">
						{companyDetails ? companyDetails.companyBio : ""}
					</div>
				</div>

				{/* Green Score*/}
				<div>
					{opDetails && (
						<GreenScore
							email={userData?.email}
							role="admin"
							projectId={opDetails?.loan_name}
							setGreenScore={setGreenScore}
							apiKey={process.env.REACT_APP_GREENSCORE_API_KEY}
						/>
					)}
				</div>
				{/*section-6  --KYC detaial  */}
				{/* <div className="w-full my-3 mt-10 text-lg font-medium xl:w-1/2">
					<div className="text-xl">KYC Details</div>
					<DocumentCard
						docName={idproof ? "Id proof" : ""}
						docCid={idproof ? idproof.value : null}
						fileName={idproof ? idproof.type : null}
					/>
				</div> */}

				{/*section-7  --KYB detaial  */}
				<div className="w-full my-3 mt-10 text-lg font-medium xl:w-1/2">
					<div className="text-xl">KYB Details</div>
					<h6 className="dark:text-[#A0ABBB] text-[#4B5768] mt-2 mb-0.5">
						Business Identity Proof
					</h6>
					<DocumentCard
						docName={
							companyDetails
								? companyDetails.businessIdFile
										?.businessIdDocName
								: ""
						}
						docCid={
							companyDetails
								? companyDetails.businessIdFile
										?.businessIdFileCID
								: null
						}
						fileName={
							companyDetails
								? companyDetails.businessIdFile
										?.businessIdFileName
								: null
						}
					/>

					<h6 className="dark:text-[#A0ABBB] text-[#4B5768]  mb-0.5">
						Business Address Proof
					</h6>
					<DocumentCard
						docName={
							companyDetails
								? companyDetails.businessAddFile
										?.businessAddDocName
								: ""
						}
						docCid={
							companyDetails
								? companyDetails.businessAddFile
										?.businessAddFileCID
								: null
						}
						fileName={
							companyDetails
								? companyDetails.businessAddFile
										?.businessAddFileName
								: null
						}
					/>
					<h6 className="dark:text-[#A0ABBB] text-[#4B5768]  mb-0.5">
						Business Incorporation Proof
					</h6>
					<DocumentCard
						docName={
							companyDetails
								? companyDetails.businessIncoFile
										?.businessIncoDocName
								: ""
						}
						docCid={
							companyDetails
								? companyDetails.businessIncoFile
										?.businessIncoFileCID
								: null
						}
						fileName={
							companyDetails
								? companyDetails.businessIncoFile
										?.businessIncoFileName
								: null
						}
					/>
					{companyDetails && companyDetails.businessLicFile ? (
						<>
							<h6 className="dark:text-[#A0ABBB] text-[#4B5768]  mb-0.5">
								Business License Proof
							</h6>
							<DocumentCard
								docName={
									companyDetails
										? companyDetails.businessLicFile
												?.businessLicDocName
										: ""
								}
								docCid={
									companyDetails
										? companyDetails.businessLicFile
												?.businessLicFileCID
										: null
								}
								fileName={
									companyDetails
										? companyDetails.businessLicFile
												?.businessLicFileName
										: null
								}
							/>
						</>
					) : (
						<></>
					)}
				</div>

				{/*section-7  --due diligence details  */}
				<div className="w-full my-3 mt-10 text-lg font-medium xl:w-1/2">
					<div className="text-xl">Due Diligence Details</div>
					<h6 className="dark:text-[#A0ABBB] text-[#4B5768] mt-2 mb-0.5">
						Audited Financial Statements For The Past 3 Years
					</h6>
					<DocumentCard
						docName={
							companyDetails
								? companyDetails.dueDiliAuFile?.dueDiliAuDocName
								: ""
						}
						docCid={
							companyDetails
								? companyDetails.dueDiliAuFile?.dueDiliAuFileCID
								: null
						}
						fileName={
							companyDetails
								? companyDetails.dueDiliAuFile
										?.dueDiliAuFileName
								: null
						}
					/>

					<h6 className="dark:text-[#A0ABBB] text-[#4B5768]  mb-0.5">
						GST Returns For The Past 1 Year
					</h6>
					<DocumentCard
						docName={
							companyDetails
								? companyDetails.dueDiliGstFile
										?.dueDiliGstDocName
								: ""
						}
						docCid={
							companyDetails
								? companyDetails.dueDiliGstFile
										?.dueDiliGstFileCID
								: null
						}
						fileName={
							companyDetails
								? companyDetails.dueDiliGstFile
										?.dueDiliGstFileName
								: null
						}
					/>
					<h6 className="dark:text-[#A0ABBB] text-[#4B5768]  mb-0.5">
						Relevant Bank Statements For The Past 1 Year
					</h6>
					<DocumentCard
						docName={
							companyDetails
								? companyDetails.dueDiliRelFile
										?.dueDiliRelDocName
								: ""
						}
						docCid={
							companyDetails
								? companyDetails.dueDiliRelFile
										?.dueDiliRelFileCID
								: null
						}
						fileName={
							companyDetails
								? companyDetails.dueDiliRelFile
										?.dueDiliRelFileName
								: null
						}
					/>

					<h6 className="dark:text-[#A0ABBB] text-[#4B5768]  mb-0.5">
						KYC of The Promoters
					</h6>
					<DocumentCard
						docName={
							companyDetails
								? companyDetails.dueDiliKycFile
										?.dueDiliKycDocName
								: ""
						}
						docCid={
							companyDetails
								? companyDetails.dueDiliKycFile
										?.dueDiliKycFileCID
								: null
						}
						fileName={
							companyDetails
								? companyDetails.dueDiliKycFile
										?.dueDiliKycFileName
								: null
						}
					/>
				</div>
				<br />
				<br />
				<br />
				<br />
				<br />
			</div>
		</div>
	);
};

export default AdminPoolDetails;
