import React from "react";
import { useNavigate } from "react-router-dom";
import BorrowerProfileConf from "../../BorrowerProfileConf";
import { useState } from "react";

const KycCheckModal = ({ kycStatus, profileStatus, isSubscriber }) => {
	const navigate = useNavigate();
	const [isCheckModal, setIsCheckModal] = useState({
		status: false,
		title: "",
		url: "",
	});
	return (
		<>
			<BorrowerProfileConf
				isCheckModal={isCheckModal}
				setIsCheckModal={setIsCheckModal}
			/>
			<input
				type="checkbox"
				id="kycAlertModal"
				className="modal-toggle"
			/>
			<div
				style={{ backdropFilter: "brightness(40%) blur(8px)" }}
				className="modal"
			>
				<div
					style={{ borderRadius: "16px" }}
					className="bg-white dark:bg-darkmode-800   modal-box w-1/3 max-w-5xl p-0"
				>
					<label
						for="kycAlertModal"
						className="btn btn-ghost absolute right-2 top-2 pb-2"
						// onClick={() => handleForm()}
					>
						✕
					</label>
					<h3
						style={{ borderBottom: "2px solid #292C33" }}
						className="font-bold text-lg py-3 px-4"
					>
						Complete your Profile
					</h3>
					<p
						style={{
							display: "flex",
							fontSize: 40,
							fontWeight: 600,
						}}
						className="justify-center"
					>
						{!kycStatus && profileStatus
							? `Incomplete KYC!`
							: "Incomplete Profile!"}
					</p>
					<p
						style={{
							display: "flex",
							fontSize: 23,
							fontWeight: 600,
						}}
						className="justify-center mb-2"
					>
						{!kycStatus && profileStatus
							? "Please complete your KYC."
							: "Please complete your profile."}
					</p>

					<label
						for="kycAlertModal"
						className="modal-action mx-4 mt-2 mb-4 text-sm py-3 px-4 items-center justify-center"
						style={{
							width: 400,
							height: 45,
							marginLeft: 24,
							borderRadius: 100,
							borderWidth: 1,
							borderColor: "#64748B",
							alignSelf: "center",
							marginTop: 60,
							marginInline: "auto",
						}}
						onClick={() => {
							// navigate(
							// 	!kycStatus && profileStatus
							// 		? `/borrowerDashboard/borrowerProfile`
							// 		: `/borrowerDashboard/editProfile`
							// );

							setIsCheckModal({
								status: true,
								title: "Borrower Profile",
								url: isSubscriber
									? !kycStatus && profileStatus
										? `/investorDashboard/investorProfile`
										: `/investorDashboard/editProfile`
									: !kycStatus && profileStatus
									? `/borrowerDashboard/borrowerProfile`
									: `/borrowerDashboard/editProfile`,
							});
						}}
					>
						<p
							className="justify-center"
							style={{ display: "flex", cursor: "pointer" }}
						>
							{!kycStatus && profileStatus
								? "Go to Profile"
								: "Create Profile"}
						</p>
					</label>
				</div>
			</div>
		</>
	);
};

export default KycCheckModal;
