import React, { useEffect, useState } from "react";
import Loader from "../../uiTools/Loading/Loader";
import { useTable, useSortBy } from "react-table";
import filterSvg from "../../assets/filter.svg";
import Table from "../../uiTools/Table/Table";
import { getAllTransactions } from "../../services/Helpers/transactionsHelper";
import { DatePicker, Tooltip } from "antd";
import DollarImage from "../../assets/Dollar-icon.svg";
import { getDisplayAmount } from "../../services/Helpers/displayTextHelper";
import iIcon from "../../assets/Information.png";
import { convertDate } from "../../services/BackendConnectors/userConnectors/commonConnectors";
import { removeTimeFromTimestamp } from "../../services/Helpers/dateFunctions";

const { RangePicker } = DatePicker;

const columns = [
	{
		Header: "Request Name",
		accessor: "bondName",
	},
	{
		Header: () => (
			<span className="flex items-center">
				Transaction Type
				<Tooltip
					title={
						<div>
							Invest - Fund transfer from Subscriber to Custodian
							<br />
							Borrow - Fund transfer from Custodian to Borrower
							<br />
							Repay - Fund transfer from Borrower to Custodian
							<br />
							Payout - Fund transfer from Custodian to Subscriber
						</div>
					}
				>
					<span className="ml-2 mb-1">
						<img src={iIcon} className="w-4 h-4" alt="" />
					</span>
				</Tooltip>
			</span>
		),
		accessor: "transactionType",
	},
	{
		Header: "Transaction Amount",
		accessor: "amount",
		Cell: ({ value }) => {
			return value !== undefined ? (
				<div className="flex justify-center items-center gap-2">
					<img src={DollarImage} className="w-4" alt="" />
					{getDisplayAmount(value)}
				</div>
			) : null;
		},
	},
	{
		Header: "Transaction Date",
		accessor: "investedOn",
		Cell: ({ value }) => {
			return value !== undefined ? <>{convertDate(value)}</> : "--";
		},
	},
	{
		Header: "Transaction Status",
		accessor: "status",
	},
];

const transactionTypeOptions = [
	{
		value: "",
		label: "Select Transaction Type",
	},
	{
		value: "Invest",
		label: "Invest",
	},
	{
		value: "Payout",
		label: "Payout",
	},
	{
		value: "Borrow",
		label: "Borrow",
	},
	{
		value: "Repay",
		label: "Repay",
	},
];

const TransactionReports = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	//Bookmark is used as pages in api calls. If backend has remaining data to send then it sends a bookmark.
	const [bookmark, setBookmark] = useState(null);
	const [showFilter, setShowFilter] = useState(false);

	// filters state
	const [fromTxDate, setFromTxDate] = useState("");
	const [toTxDate, setToTxDate] = useState("");
	const [transactionType, setTransactionType] = useState("");

	// Load more handler
	const handleLoadMore = async () => {
		setLoading(true);
		let result = await getAllTransactions(
			process.env.REACT_APP_SPYDRA_RESULT_PAGE_SIZE,
			bookmark
		);
		console.log("tx result", result);
		setBookmark(
			result?.bookmark
				? result.bookmark.codePointAt(result.bookmark.length - 2) !==
				  "𐑿".codePointAt(0)
					? result.bookmark
					: undefined
				: undefined
		);
		const newTxs = data.concat(result.transactions);
		setData(newTxs);
		setLoading(false);
	};

	// Filter handlers
	const applyFilter = () => {
		let filtData = data;

		filtData = filtData.filter(
			(data) =>
				!fromTxDate ||
				!toTxDate ||
				(removeTimeFromTimestamp(fromTxDate.unix() * 1000) <=
					removeTimeFromTimestamp(data.investedOn) &&
					removeTimeFromTimestamp(data.investedOn) <=
						removeTimeFromTimestamp(toTxDate.unix() * 1000))
		);

		filtData = filtData.filter((data) =>
			data.transactionType.includes(transactionType)
		);

		setFilteredData(filtData);
		setShowFilter(false);
	};

	const resetFilter = () => {
		setFromTxDate("");
		setToTxDate("");
		setTransactionType("");
	};

	useEffect(() => {
		console.log("loading..");
		setLoading(true);
		getAllTransactions(process.env.REACT_APP_SPYDRA_RESULT_PAGE_SIZE).then(
			(res) => {
				console.log("res", res);
				if (res.success) {
					setBookmark(
						res?.bookmark
							? res.bookmark.codePointAt(
									res.bookmark.length - 2
							  ) !== "𐑿".codePointAt(0)
								? res.bookmark
								: undefined
							: undefined
					);
					setData(res.transactions);
					setFilteredData(res.transactions);
				}
				setLoading(false);
				console.log("loading end...");
			}
		);
	}, []);

	useEffect(() => {
		applyFilter();
	}, [data]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		// setFilter,
	} = useTable(
		{
			columns,
			data: filteredData,
			initialState: {
				sortBy: [
					{
						id: "displayInvestedOn",
						desc: true,
					},
				],
			},
		},
		// useFilters,
		useSortBy
	);

	return (
		<div className={`mb-16 mx-1 ${loading ? "relative h-[80vh]" : ""}`}>
			{loading && <Loader />}
			<div className={`${loading ? "blur-sm" : ""}`}>
				<div className="flex items-center mb-4 md:pl-1 lg:pl-2 xl:pl-2">
					<h2 className="text-left text-2xl lg:mb-2 xl:mb-2 xl:mt-1">
						All Transactions
					</h2>
				</div>
				<div className="flex justify-end mr-4">
					<button onClick={() => setShowFilter(!showFilter)}>
						<img src={filterSvg} />
					</button>
					{showFilter && (
						<div
							className="absolute z-20 m-4 max-w-screen-md rounded-xl"
							data-theme="light"
						>
							<div className="rounded-xl border border-gray-200 bg-white p-6 shadow-lg">
								<h2 className="text-stone-700 text-xl font-bold">
									Apply filters
								</h2>
								<button
									type="button"
									className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-2"
									onClick={() => {
										setShowFilter(false);
									}}
								>
									<svg
										aria-hidden="true"
										className="w-5 h-5"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										></path>
									</svg>
								</button>
								<div className="mt-8 grid grid-cols-1 gap-6 ">
									<div className="flex flex-col">
										<label className="text-stone-600 text-sm font-medium mb-1">
											Transaction Date
										</label>
										<RangePicker
											value={[fromTxDate, toTxDate]}
											onChange={(date, dateString) => {
												if (date) {
													setFromTxDate(date[0]);
													setToTxDate(date[1]);
												} else {
													setFromTxDate("");
													setToTxDate("");
												}
											}}
											format="DD/MM/YYYY"
											className="mt-2 w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none"
										/>
									</div>

									<div className="flex flex-col">
										<label className="text-stone-600 text-sm font-medium">
											Transaction Type
										</label>

										<select
											id="status"
											className="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none hover:border-[#4096ff] bg-white text-sm"
											onChange={(e) =>
												setTransactionType(
													e.target.value
												)
											}
											value={transactionType}
											placeholder="Select Txn Type"
										>
											{transactionTypeOptions.map(
												(option) => {
													return (
														<option
															value={option.value}
														>
															{option.label}
														</option>
													);
												}
											)}
										</select>
									</div>
								</div>

								<div className="mt-6 grid w-full grid-cols-2 justify-end space-x-4 md:flex">
									<button
										className="active:scale-95 rounded-lg bg-gray-200 px-8 py-2 font-medium text-gray-600 outline-none"
										onClick={resetFilter}
									>
										Reset
									</button>
									<button
										className="active:scale-95 rounded-lg bg-blue-600 px-8 py-2 font-medium text-white outline-none"
										onClick={() => {
											applyFilter();
											setShowFilter(false);
										}}
									>
										Apply
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
				<Table
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					rows={rows}
					prepareRow={prepareRow}
					tableName="Transactions"
				/>
				{bookmark && (
					<div className="text-center">
						<button
							className="py-2 px-8 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
							onClick={handleLoadMore}
						>
							Load More
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default TransactionReports;
