import React from "react";
import DollarImage from "../../../../assets/Dollar-icon.svg";

const BondDetailsModal = ({ selected, data, setSelected }) => {
	return (
		<div>
			<input
				type="checkbox"
				checked={selected}
				className="modal-toggle"
				readOnly
			/>
			<div className="modal backdrop-filter backdrop-brightness-[100%] backdrop-blur-lg">
				<div
					className={`bg-white dark:bg-darkmode-800  w-[100vw] h-[100vh] flex flex-col md:block md:h-auto md:w-[70%] lg:w-[50%] xl:w-[45%] 2xl:w-[40%] pb-[6em] md:rounded-xl md:pb-8`}
				>
					<div className=" flex justify-between px-4 md:px-8 md:border-b mt-[4em] md:mt-0 py-4">
						<h3 className="font-normal text-xl">
							Subscription Details
						</h3>

						<label
							htmlFor="bondDetails-modal"
							className="hover:text-primary-600 text-xl"
							onClick={() => setSelected(false)}
						>
							✕
						</label>
					</div>

					<div className="px-4 md:px-8 mt-10 flex flex-col gap-1">
						<div className="flex justify-between font-normal">
							<p>Status</p>
							<p>{data?.displayStatus}</p>
						</div>
						<div className="flex justify-between font-normal">
							<p>Next Due Date</p>
							<p>{data?.nextDueDate}</p>
						</div>

						<div className="flex gap-1 font-normal">
							<p className="flex justify-start">
								Total Due Amount
							</p>

							<img
								src={DollarImage}
								className="ml-auto w-[1rem]"
								alt="DollarImage"
							/>
							<p className="font-normal text-[1.125rem]">
								{data?.totalAmountDue}
							</p>
						</div>
						{data?.isOverDue && (
							<>
								<div className="flex justify-between font-normal">
									<p className="text-error-500">
										Overdue Charges
									</p>

									<p className="font-normal text-[1.125rem]">
										{data?.delayChargeRatePercentage}%
									</p>
								</div>
								<div className="flex justify-between font-normal">
									<p className="text-error-500">
										Overdue Days
									</p>

									<p className="font-normal text-[1.125rem]">
										{data?.delayInDays}
									</p>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BondDetailsModal;
