import { useState, useEffect, useContext } from "react";
import PoolCard from "./components/Cards/PoolCard";
import GradientButtonHeader from "../../uiTools/Button/GradientButtonHeader";
import {
	getJuniorWithdrawableOp,
	getTotalInvestmentOfInvestor,
} from "../../services/BackendConnectors/userConnectors/investorConncector";
import { useNavigate } from "react-router-dom";
import DoughnutChart from "../../uiTools/Chart/DoughnutChart";
import {
	formatNumberWithCommas,
	getDisplayAmount,
} from "../../services/Helpers/displayTextHelper";
import Loader from "../../uiTools/Loading/Loader";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import KycCheckModal from "../Borrower/Components/Modal/KycCheckModal";
import SuccessModal from "../../uiTools/Modal/SuccessModal";
import ChangePassowrdModal from "../../uiTools/Modal/ChangePasswordModal";
import { captureMessage } from "@sentry/react";

const InvestorOverview = () => {
	const [totalInvestment, setTotalInvestment] = useState(0);
	const [totalYield, setTotalYield] = useState(0);
	const [totalPrincipalOutstanding, setTotalPrincipalOutstanding] =
		useState(0);
	const [juniorPool, setJuniorPool] = useState([]);
	const [sdgs, setSdgs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [juniorPoolLoading, setJuniorPoolLoading] = useState(true);
	const [ghgEmissionReduction, setGhgEmissionReduction] = useState(0);
	const [successmsg, setSuccessmsg] = useState({
		status: false,
		msg: "",
	});
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});
	const [kycSelected, setKycSelected] = useState();
	const [kycStatus, setKycStatus] = useState(false);
	const [profileStatus, setProfileStatus] = useState(false);
	const [changePasswordModal, setChangePasswordModal] = useState(false);

	const { userData, login } = useContext(LoginContext);
	const path = useNavigate();
	async function updateSummery() {
		setLoading(true);
		let data = await getTotalInvestmentOfInvestor(userData?.Id);

		if (data?.success) {
			setTotalInvestment(data.totalInvestment);
			setTotalYield(data.totalYield);
			setTotalPrincipalOutstanding(data.totalPrincipalReceived);
		}
		return data;
	}

	const checkForKycAndProfile = () => {
		if (!userData) {
			return;
		}

		setChangePasswordModal(userData?.isNewUser);
		setKycStatus(userData?.kycStatus);
		if (Object.keys(JSON.parse(userData?.profile)).length) {
			captureMessage("InvestorOverview: User profile available");
			setProfileStatus(true);
		} else {
			captureMessage("InvestorOverview: User profile not available");
			setProfileStatus(false);
		}
	};

	useEffect(() => {
		(async () => {
			if (userData?.Id) {
				var data = await updateSummery();
				const juniorPools = await getJuniorWithdrawableOp(
					data?.transactions
				);
				if (juniorPools?.success) {
					captureMessage(
						"InvestorOverview: juniorPools - ",
						juniorPools.opportunityList
					);
					setJuniorPool(juniorPools.opportunityList);
					const sdg = juniorPools.opportunityList.reduce(
						(accumulator, currentValue) => {
							return accumulator.concat(
								currentValue.companyDetails.sustainableChecked
							);
						},
						[]
					);
					setSdgs([...new Set(sdg)]);
					setGhgEmissionReduction(juniorPools.ghgEmissionReduction);
				} else {
					captureMessage(juniorPools?.msg);
					setErrormsg({
						status: !juniorPools?.status,
						msg: juniorPools?.msg,
					});
				}

				setJuniorPoolLoading(false);
				checkForKycAndProfile();
				setLoading(false);
			}
		})();
	}, [userData]);

	useEffect(() => {
		if (!juniorPoolLoading) setLoading(false);
	}, [juniorPoolLoading]);

	return (
		<div className="lg:relative">
			{loading && <Loader />}
			<SuccessModal
				successmsg={successmsg}
				setSuccessmsg={setSuccessmsg}
			/>
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			{kycSelected ? (
				<KycCheckModal
					kycStatus={kycStatus}
					profileStatus={profileStatus}
					isSubscriber={true}
				/>
			) : (
				<></>
			)}
			{changePasswordModal && (
				<ChangePassowrdModal
					userData={userData}
					login={login}
					isSelected={changePasswordModal}
					setIsSelected={setChangePasswordModal}
					setSuccessmsg={setSuccessmsg}
					setErrormsg={setErrormsg}
				/>
			)}
			<div className={`${loading ? "blur-sm" : ""}`}>
				<div className="flex items-center mb-8">
					<h2 className="text-[1.4rem] lg:text-[1.75rem] w-[50%] font-normal">
						Subscriber's Dashboard
					</h2>
					<GradientButtonHeader
						onClick={() => {
							if (!kycStatus || !profileStatus)
								return setKycSelected(true);
							path("/investorDashboard/invest");
						}}
						className="ml-auto text-white"
						htmlFor={
							!kycStatus || !profileStatus
								? "kycAlertModal"
								: null
						}
					>
						+ Subscribe
					</GradientButtonHeader>
				</div>

				{/* parent div */}
				<div className="flex flex-col md:flex-row gap-6">
					{/* child 1 */}
					<div className="flex flex-col gap-4 card-gradient px-4 py-4 rounded-xl md:flex-row sm:px-[4vw] md:pl-2 md:pr-[1.5vw] md:gap-3 lg:px-[1vw] xl:px-[1vw] xl:gap-8 md:w-1/2 2xl:items-center 2xl:justify-around">
						<div className="flex flex-col items-center gap-2 md:items-start	">
							<div className="">
								{totalInvestment || totalYield ? (
									<DoughnutChart
										data={[
											totalInvestment,
											totalYield ? totalYield : 0,
										]}
										color={["#5375FE", "#ffffff"]}
										width={200}
										labels={[
											"Total Investment",
											"Total Yield",
										]}
										borderWidth={[1, 8]}
										legendStyle={{ display: false }}
									/>
								) : (
									<DoughnutChart
										data={[1]}
										color={["#64748B"]}
										width={200}
										labels={[
											"Total Investment",
											"Total Yield",
										]}
										borderWidth={[1, 8]}
										legendStyle={{ display: false }}
									/>
								)}
							</div>
						</div>
						{/* Change this total implementation */}

						<div className="flex flex-col gap-3 justify-center">
							<div className="flex items-center md:flex-col md:items-start gap-1">
								<p className="text-sm lg:text-base text-[#64748B] flex gap-1 items-center">
									<span className="inline-block w-3 h-2 bg-[#5375FE] rounded-3xl"></span>
									Total amount invested
								</p>

								{totalInvestment === 0 ? (
									<p className="ml-auto md:ml-0  text-xl lg:text-[1.75rem] px-5">
										- -
									</p>
								) : (
									<div className="ml-auto md:ml-0 font-normal flex items-end gap-2 px-4">
										<p className=" text-xl lg:text-[1.75rem] ">
											{getDisplayAmount(totalInvestment)}
										</p>
										<p className="text-base lg:text-xl">
											{process.env.REACT_APP_TOKEN_NAME}
										</p>
									</div>
								)}
							</div>

							<div className="flex items-center md:flex-col md:items-start gap-1">
								<p className="text-sm lg:text-base text-[#64748B]  flex gap-1 items-center">
									<span className="inline-block w-3 h-2 bg-white rounded-3xl"></span>
									Total Yield Earned
								</p>

								{totalYield === 0 ? (
									<p className="ml-auto md:ml-0 text-xl lg:text-[1.75rem] px-5">
										- -
									</p>
								) : (
									<div className="ml-auto md:ml-0 font-normal flex items-end gap-2 px-5">
										<p className=" text-xl lg:text-[1.75rem] ">
											{getDisplayAmount(totalYield)}
										</p>
										<p className="text-base lg:text-xl">
											{process.env.REACT_APP_TOKEN_NAME}
										</p>
									</div>
								)}
							</div>
						</div>
					</div>

					{/* child 2 */}
					<div className="card-gradient md:w-1/2 px-2 py-4 flex flex-col gap-4 rounded-xl 2xl:justify-center xl:px-6 ">
						<div className="flex gap-7 w-full py-8 px-10 mt-5">
							<div className="w-[55%] space-y-3">
								<p>Number of Requests</p>

								<p>Principal Received</p>

								<p>Targeted GHG Reduction</p>

								<p>SDGs Impacted</p>
							</div>
							<div className="space-y-3">
								<p>{juniorPool ? juniorPool.length : "- -"}</p>
								<p>
									{totalPrincipalOutstanding == 0
										? "- -"
										: getDisplayAmount(
												totalPrincipalOutstanding
										  )}
								</p>
								<p>
									{formatNumberWithCommas(
										ghgEmissionReduction
									)}{" "}
									t CO<sub>2</sub>
								</p>
								<p className="flex gap-1">
									{sdgs.map((data) => (
										<img
											src={`IconSustainable/${data}.png`}
											className="w-10 h-10 rounded-sm"
											alt=""
										/>
									))}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-col gap-5 mt-[4em] md:mt-[5em]">
					<h1 className="text-[1.75rem] md:text-2xl font-normal">
						Your Subscriptions
					</h1>

					<div>
						{juniorPool.length === 0 ? (
							<div className="text-center">
								<p className="text-neutral-500 text-lg">
									No stats available.
								</p>
							</div>
						) : (
							<div className="flex flex-col gap-4 md:flex-row md:flex-wrap">
								{juniorPool.map((juniorPoolData) => (
									<PoolCard
										key={juniorPoolData.Id}
										data={juniorPoolData}
										kycStatus={kycStatus}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default InvestorOverview;
