import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../uiTools/Button/PrimaryButton";
import dollarIcon from "../../../assets/Dollar-icon.svg";
import default_profile from "../../../assets/default_profile.svg";
import { convertDate } from "../../../services/BackendConnectors/userConnectors/commonConnectors";
import { formatNumberWithCommas } from "../../../services/Helpers/displayTextHelper";
const AdminCard = ({ data }) => {
	const path = useNavigate();
	const [companyDetails, setCompanyDetails] = useState();

	useEffect(() => {
		const companyDetails = JSON.parse(data.companyDetails);
		setCompanyDetails(companyDetails);
	}, []);

	return (
		<div className="relative">
			<div
				className={
					"my-gradient dark:text-white text-black max-w-lg md:max-w-full rounded-2xl grid  grid-1 overflow-hidden  pt-7 lg:pt-0 lg:grid-cols-10  xl:pr-5 2xl:gap-3 md:px-2 lg:pr-5"
				}
			>
				{/* section-1 */}
				<div className="flex-row flex space-x-5 px-4 col-span-4 lg:pl-5 lg:pr-1">
					<img
						alt=""
						src={
							companyDetails?.companyLogoFile?.businessLogoFileCID
								? companyDetails?.companyLogoFile
										?.businessLogoFileCID
								: default_profile
						}
						className="w-28 h-28 lg:my-auto rounded-full xl:w-36  xl:h-36 2xl:w-[7.5rem] 2xl:h-[7.5rem]"
					/>
					<div className="mt-7 -space-y-1 lg:hidden ">
						<p className="font-medium text-2xl">{data.loan_name}</p>
						<p className="font-light text-sm">
							{companyDetails?.companyName}
						</p>
					</div>
				</div>
				{/* section-2 */}
				<div className="col-span-6 xl:-ml-4">
					{/* section-2-1  */}
					<div className="mt-5 px-4 lg:pr-4 lg:pl-1 ">
						<div className="hidden -space-y-1 lg:block lg:my-7 ">
							<p className="font-medium text-2xl">
								{data.loan_name}
							</p>
							<p className="font-light text-sm">
								{companyDetails?.companyName}
							</p>
						</div>
						<div className="flex justify-between space-y-1 font-medium">
							<p>Amount</p>
							<p className="flex gap-1 justify-center">
								<span className="w-6 h-6">
									<img src={dollarIcon} alt="" />
								</span>
								{formatNumberWithCommas(data.loan_amount)}
							</p>
						</div>
						<div className="flex justify-between space-y-1  font-medium ">
							<p>Interest Rate</p>
							<p>{data.loan_interest}% pa</p>
						</div>
						<div className="flex justify-between space-y-1  font-medium ">
							<p>Proposed On</p>
							<p>{convertDate(data.createdOn)}</p>
						</div>
					</div>
					{/* section-2-2*/}
					<div className="flex mt-5 justify-center mb-5  px-4  lg:px-1 ">
						<PrimaryButton
							disable={false}
							onClick={() =>
								path("/admin/poolDetail", {
									state: {
										pool: data,
									},
								})
							}
						>
							View details
						</PrimaryButton>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminCard;
