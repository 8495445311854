import axiosHttpService from "../axioscall";
import * as Sentry from "@sentry/react";

export const getUser = async (email, role) => {
	let options = {
		url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/borrower/getUsers`,
		method: "POST",
		headers: {},
		data: {
			email: email,
			role: role,
		},
	};
	let result = await axiosHttpService(options);
	let userData = result?.res;
	return userData;
};

export const getUsersWithRole = async (role) => {
	let options = {
		url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/borrower/getUsers`,
		method: "POST",
		headers: {},
		data: {
			role: role,
		},
	};
	let result = await axiosHttpService(options);
	let usersData = result?.res;
	return usersData;
};

export const getUserById = async (Id) => {
	const options = {
		url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/borrower/getUsers`,
		method: "POST",
		headers: {},
		data: {
			Id: Id,
		},
	};

	const result = await axiosHttpService(options);

	return result;
};

export const getUserAccountStatus = async (email) => {
	if (!email || email === "") {
		return;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/borrower/getUserAccountStatus`,
			method: "POST",
			headers: {},
			data: {
				email: email,
			},
		};
		const result = await axiosHttpService(options);
		if (result?.res?.success) {
			return result.res;
		}
		return;
	} catch (error) {
		Sentry.captureException(error);
		throw error;
	}
};

export const enableDisableUser = async (email) => {
	if (!email || email === "") {
		return;
	}

	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/borrower/enableDisableUserAccount`,
			method: "POST",
			headers: {},
			data: {
				email: email,
			},
		};
		const result = await axiosHttpService(options);
		if (result?.res?.success) {
			return result.res;
		} else {
			return;
		}
	} catch (error) {
		Sentry.captureException(error);
		throw error;
	}
};

export const deleteUser = async (email, role) => {
	if (!email || email === "" || role === undefined) {
		return;
	}

	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/borrower/deleteUser`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				role: role,
			},
		};
		const result = await axiosHttpService(options);
		if (result?.res?.success) {
			return result.res;
		} else {
			return;
		}
	} catch (error) {
		Sentry.captureException(error);
		throw error;
	}
};
