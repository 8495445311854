import { useFormik } from "formik";
import GradientButton from "../../../../uiTools/Button/GradientButton";

export default function Details({ formData, handleNext, handleForm }) {
	const formik = useFormik({
		initialValues: formData,
		validate: (values) => {
			const errors = {};

			if (
				values.percentageOfCoupon > 100 ||
				values.percentageOfCoupon < 0
			) {
				errors.percentageOfCoupon = "Value should be between 0 and 100";
			}

			return errors;
		},
		onSubmit: (values, { resetForm }) => {
			handleNext(values, false);
		},
	});

	const handleRadioChange = (event) => {
		formik.setValues({
			...formik.values,
			percentageOfCoupon: "",
			fixedAmount: "",
		});
		formik.handleChange(event);
	};

	return (
		<>
			<div className=" flex flex-col mt-20 md:mt-16 md:px-5 ">
				<form onSubmit={formik.handleSubmit}>
					<div className="flex justify-around ml-4">
						<div className="flex items-center">
							<input
								id="isRecurring"
								type="radio"
								name="isRecurring"
								className="radio radio-primary"
								data-theme="light"
								onChange={handleRadioChange}
								value="yes"
								checked={formik.values.isRecurring === "yes"}
							/>
							<label htmlFor="isRecurring" className="ml-2">
								Recurring
							</label>
						</div>
						<div className="flex items-center">
							<input
								id="isUpfront"
								type="radio"
								name="isRecurring"
								data-theme="light"
								className="radio radio-primary"
								onChange={handleRadioChange}
								value="no"
								checked={formik.values.isRecurring === "no"}
							/>
							<label htmlFor="isUpfront" className="ml-2">
								Upfront
							</label>
						</div>
					</div>
					<div className="py-12 my-4 mx-16 px-6 rounded-lg bg-slate-100 border-slate-200 border-2">
						<div className="flex items-center justify-center mb-4">
							<input
								id="isPercentageOfCoupon"
								type="radio"
								name="isPercentageOfCoupon"
								className="radio radio-primary"
								data-theme="light"
								value="yes"
								onChange={handleRadioChange}
								checked={
									formik.values.isPercentageOfCoupon === "yes"
								}
							/>
							<label
								htmlFor="isPercentageOfCoupon"
								className="ml-2 w-60"
							>
								{`Percentage Of ${
									formik.values.isRecurring === "yes"
										? "Interest (%)"
										: "Request Amount"
								}`}
							</label>
							<div className="flex flex-col">
								<input
									name="percentageOfCoupon"
									type="number"
									min={0}
									step="0.001"
									placeholder="Enter value"
									className="input input-bordered input-sm lg:w-56 max-w-xs md:w-32"
									data-theme="light"
									disabled={
										formik.values.isPercentageOfCoupon ===
										"no"
									}
									value={formik.values.percentageOfCoupon}
									onChange={formik.handleChange}
									required
								/>
								{formik.touched.percentageOfCoupon &&
									formik.errors.percentageOfCoupon && (
										<p
											style={{
												color: "red",
												margin: "0px",
											}}
										>
											<small>
												{
													formik.errors
														.percentageOfCoupon
												}
											</small>
										</p>
									)}
							</div>
						</div>
						<div className="flex items-center justify-center">
							<input
								id="isFixedAmount"
								type="radio"
								name="isPercentageOfCoupon"
								data-theme="light"
								className="radio radio-primary"
								value="no"
								onChange={handleRadioChange}
								checked={
									formik.values.isPercentageOfCoupon === "no"
								}
							/>
							<label
								htmlFor="isFixedAmount"
								className="ml-2 w-60"
							>
								Fixed Amount
							</label>
							<input
								name="fixedAmount"
								type="number"
								min={0}
								placeholder="Enter value"
								className="input input-bordered input-sm lg:w-56 max-w-xs md:w-32"
								data-theme="light"
								disabled={
									formik.values.isPercentageOfCoupon === "yes"
								}
								value={formik.values.fixedAmount}
								onChange={formik.handleChange}
								required
							/>
						</div>
					</div>

					<div className="flex justify-center items-center flex-col-reverse  md:flex-row md:justify-around gap-5  mt-6 -mb-2 overflow-hidden">
						<div className="">
							<label
								className="cursor-pointer md:pl-24 ml-1 text-lg"
								onClick={handleForm}
							>
								Cancel
							</label>
						</div>
						<div className="md:pr-40 lg:pr-10 xl:pr-0 py-1">
							<GradientButton type="submit">Next</GradientButton>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
