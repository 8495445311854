import React, { useState, useEffect, useRef, useContext } from "react";
import ViewPoolCard from "./components/Cards/ViewPoolCard";
import { useNavigate } from "react-router-dom";
import { getAllActiveOpportunities } from "../../services/BackendConnectors/opportunityConnectors";
import Loader from "../../uiTools/Loading/Loader";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import iIcon from "../../assets/Information.png";
import { Tooltip } from "antd";

const Invest = () => {
	const path = useNavigate();
	const [juniorPools, setJuniorPools] = useState([]);
	const [seniorPool, setSeniorPool] = useState([]);

	const [kycStatus, setKycStatus] = useState();

	const [loading, setLoading] = useState(true);
	const cardData = useRef({
		opportunityInfo: "",
		opportunityAmount: "",
		loanInterest: "",
		isFull: "",
	});
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});
	const { userData } = useContext(LoginContext);

	useEffect(() => {
		try {
			if (userData) {
				setKycStatus(userData?.kycStatus);
			}

			getAllActiveOpportunities().then((res) => {
				if (res.opportunities && res.opportunities.length) {
					console.log("senior Junior", res.opportunities);
					const seniorOpportunities = res.opportunities.filter(
						(opportunity) => opportunity.seniorTrancheAmount !== 0
					);
					const juniorOpportunities = res.opportunities.filter(
						(opportunity) => opportunity.juniorTrancheAmount !== 0
					);
					setSeniorPool(seniorOpportunities);
					setJuniorPools(juniorOpportunities);
					setLoading(false);
				} else {
					setLoading(false);
				}
			});
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, [userData]);

	const loadingCard = (
		<ViewPoolCard
			onClick={() => console.log("card for loading")}
			data={cardData.current}
		/>
	);

	return (
		<div className="">
			{loading && <Loader />}
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			{/* <div className="mb-4">
				<h2 className="font-normal text-[1.4375rem] lg:text-[2.0625rem] ">
					Green Requests
				</h2>
			</div> */}

			<div className={`py-4 ${loading ? "blur-sm" : ""}`}>
				<div className="flex flex-col gap-5 mb-[3rem] md:mb-[4rem]">
					{/* <h2 className="font-normal text-[1.4375rem] md:text-[1.75rem]">
						Requests
					</h2> */}

					<h2 className="font-normal text-[1.4375rem] md:text-[1.75rem]">
						<div className="flex items-center">
							Senior Secured Requests
							<Tooltip title="Stable yields, regular interest servicing. Principal repayments protected by mezzanine tranches">
								<span className="ml-2 mb-1">
									<img
										src={iIcon}
										className="w-4 h-4"
										alt=""
									/>
								</span>
							</Tooltip>
						</div>
					</h2>
					<div className={`relative ${loading ? "h-[18rem]" : ""}`}>
						{seniorPool.length === 0 ? (
							<div className="text-center">
								<p className="text-neutral-500 text-lg">
									{loading
										? ""
										: "No subscriptions are available."}
								</p>
							</div>
						) : (
							<div className="flex flex-col gap-4 md:flex-row md:flex-wrap">
								{seniorPool.map((item) => (
									<ViewPoolCard
										isSenior={true}
										data={item}
										key={item.Id}
										kycStatus={kycStatus}
									/>
								))}
							</div>
						)}
					</div>
				</div>

				<div className="flex flex-col gap-5 py-4">
					<h2 className="font-normal text-[1.4375rem] md:text-[1.75rem]">
						<div className="flex items-center">
							Mezzanine Requests
							<Tooltip title="Higher yields, subordinated to senior tranche. Regular interest payments, principal payment post complete repayment of Senior Secured tranche.">
								<span className="ml-2 mb-1">
									<img
										src={iIcon}
										className="w-4 h-4"
										alt=""
									/>
								</span>
							</Tooltip>
						</div>
					</h2>
					<div className={`relative ${loading ? "h-[18rem]" : ""}`}>
						{juniorPools.length === 0 ? (
							<div className="text-center">
								<p className="text-neutral-500 text-lg">
									{loading
										? ""
										: "No subscriptions are available."}
								</p>
							</div>
						) : (
							<div className="flex flex-col gap-4 md:flex-row md:flex-wrap">
								{juniorPools.map((item) => (
									<ViewPoolCard
										isSenior={false}
										data={item}
										key={item.Id}
										kycStatus={kycStatus}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Invest;
