import React, { useEffect } from "react";
import DygnifyImage from "../../../../assets/Dygnify_Image.png";
import { useState } from "react";
import DollarImage from "../../../../assets/Dollar-icon.svg";
import { getTrimmedString } from "../../../../services/Helpers/displayTextHelper";
import { Badge, Tooltip } from "antd";
import { BondStatus } from "../../../../services/BackendConnectors/opportunityConnectors";

const PoolCard = ({ data, setDetails, Id, details }) => {
	const { opportunityName, bondDisplayStatus } = data;

	const [logoImgSrc, setLogoImgSrc] = useState();

	const [bgshads, setBgshads] = useState(false);

	useEffect(() => {
		if (Id === details?.Id) {
			setBgshads(true);
		} else {
			setBgshads(false);
		}
	}, [details]);

	useEffect(() => {
		setLogoImgSrc(
			data?.companyDetails?.companyLogoFile?.businessLogoFileCID
		);
	}, [data?.companyDetails?.companyLogoFile?.businessLogoFileCID]);

	return (
		<div className="relative">
			<div className="flex justify-end">
				{data?.status === BondStatus.Tokenized ? (
					<Tooltip title={`Token Id: ${data?.nftId}`}>
						<div className="w-16 lg:w-18 cursor-pointer">
							<Badge.Ribbon
								text={bondDisplayStatus}
								className="z-30"
								color={
									bondDisplayStatus === "Open"
										? "#0000FF" // Blue
										: bondDisplayStatus === "Subscribed"
										? "#00AA00" // Green
										: bondDisplayStatus === "Tokenized"
										? "#6A0DAD" // Purple
										: bondDisplayStatus === "Part Paid"
										? "#FFC107" // Amber
										: bondDisplayStatus === "Delayed"
										? "#E01B1B" // Red
										: bondDisplayStatus === "Fully Paid"
										? "#000080" // Navy Blue (Closer to black)
										: bondDisplayStatus === "Matured"
										? "#808080" // Gray
										: undefined // Default color (no value)
								}
							/>
						</div>
					</Tooltip>
				) : (
					<div className="w-16 lg:w-18">
						<Badge.Ribbon
							text={bondDisplayStatus}
							className="z-30"
							color={
								bondDisplayStatus === "Open"
									? "#0000FF" // Blue
									: bondDisplayStatus === "Subscribed"
									? "#00AA00" // Green
									: bondDisplayStatus === "Tokenized"
									? "#6A0DAD" // Purple
									: bondDisplayStatus === "Part Paid"
									? "#FFC107" // Amber
									: bondDisplayStatus === "Delayed"
									? "#E01B1B" // Red
									: bondDisplayStatus === "Fully Paid"
									? "#000080" // Navy Blue (Closer to black)
									: bondDisplayStatus === "Matured"
									? "#808080" // Gray
									: undefined // Default color (no value)
							}
						/>
					</div>
				)}
			</div>

			<div
				className={`flex flex-col gap-0 px-4 py-8 rounded-xl  sm:px-4 lg:flex-row relative w-[100%] ${
					bgshads
						? "my-gradient2 shadow-xl shadow-[#9281FF] "
						: "my-gradient"
				} `}
			>
				<div className="flex items-center w-52">
					<img
						style={{ borderRadius: "50%", aspectRatio: "1/1" }}
						className="w-[6.5rem] lg:w-[6.5rem] avatar"
						src={logoImgSrc ? logoImgSrc : DygnifyImage}
						alt=""
					/>
				</div>

				<div className="flex flex-col gap-6 w-[100%] mb-8">
					<div className="flex flex-col gap-1">
						<div className="font-normal">
							<h4 className="text-2xl font-normal mb-2 h-16">
								{getTrimmedString(opportunityName, 20)}
							</h4>

							<div className="flex justify-between font-medium">
								<div>
									<p>Issuer</p>
									<p>Amount</p>
								</div>
								<div>
									<p className=" text-right">
										{getTrimmedString(
											data?.companyDetails?.companyName
										)}
									</p>
									<p className="flex justify-between items-center align-middle gap-1 text-right">
										<span>
											<img
												src={DollarImage}
												alt=""
												className="w-4 h-4"
											/>
										</span>
										{data?.opportunityAmount}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="bottom-4 right-0 absolute w-[40%]">
						<button
							className={`${
								bgshads
									? "w-[80%] shadow-2xl py-[0.3rem]   bg-gradient-to-r bg-neutral-400 cursor-not-allowed  opacity-40 capitalize font-medium border-none rounded-3xl"
									: "w-[80%] shadow-2xl py-[0.3rem] bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
							}`}
							onClick={() => {
								setDetails({ ...data });
							}}
						>
							Details
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PoolCard;
