import React, { useContext, useEffect, useState } from "react";
import TransactionsCardCustodian from "../Custodian/components/Cards/TransactionsCardCustodian.js";
import Loader from "../../uiTools/Loading/Loader.js";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import { getAllApprovedBonds } from "../../services/BackendConnectors/opportunityConnectors.js";
import { captureException } from "@sentry/react";
import { LoginContext } from "../../LoginProvider/LoginProvider";

const GreenBond = () => {
	const [transactions, setTransactions] = useState([]);
	const [loading, setLoading] = useState(true);
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});

	const { userData } = useContext(LoginContext);

	useEffect(() => {
		getAllApprovedBonds(userData?.email)
			.then((res) => {
				if (res.success) {
					setTransactions(res.opportunities);
				}
				setLoading(false);
			})
			.catch((error) => {
				captureException(error);
			});
	}, []);

	return (
		<div className={`relative mb-16 ${loading ? "h-[100vh]" : ""}`}>
			{loading && <Loader />}
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			<div className={` ${loading ? "filter blur-sm" : ""}`}>
				<h2 className="font-normal text-[1.4375rem] lg:text-[2.0625rem]">
					Green Requests
				</h2>
				{transactions?.length !== 0 ? (
					<>
						<div className="px-1 mt-8 py-6 gap-4 md:gap-0 md:justify-around flex font-bold border-y border-neutral-300 dark:border-darkmode-500 text-center">
							<p className="hidden md:block w-1/6 md:w-1/6 my-auto pr-2">
								Name
							</p>
							<p className="hidden md:block w-1/6 md:w-1/6 my-auto ">
								Issuer
							</p>
							<p className=" w-1/6 md:w-1/6 my-auto ">
								Issued Amount
							</p>
							<p className="w-1/6 md:w-1/6 my-auto ">
								Repaid Amount
							</p>
							<p className="w-1/6 md:w-1/6 my-auto ">
								%Subscribed
							</p>
							<p className="hidden md:block w-1/6 md:w-1/6 my-auto ">
								Status
							</p>
						</div>

						<div className="my-5 flex flex-col gap-3">
							{transactions
								? transactions.map((item) => (
										<TransactionsCardCustodian
											key={item.Id}
											data={item}
										/>
								  ))
								: null}
						</div>
					</>
				) : (
					<div className="relative h-screen flex justify-center">
						<div className="text-[#64748B] text-xl text-center mt-3 absolute top-40">
							<p>No transactions available.</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default GreenBond;
