import axiosHttpService from "../axioscall";
import { getDisplayAmount } from "./displayTextHelper";
const Sentry = require("@sentry/react");

export const TransactionAction = {
	Confirm: "Confirm",
	Reject: "Reject",
};

export const TransactionType = {
	Invest: "Invest",
	Borrow: "Borrow",
	Repay: "Repay",
	Payout: "Payout",
};

export const InvestorTransactionType = {
	Subscribed: "Subscribed",
	Received: "Received",
};

export const BorrowerTransactionType = {
	Disbursed: "Disbursed",
	Repayment: "Repayment",
};

export const getTokenTransactions = async (
	fieldName,
	fieldValue,
	orderByField
) => {
	Sentry.captureMessage("getTokenTransactions", "info");
	if (!fieldName || !fieldValue || !orderByField) {
		return [];
	}
	try {
		let options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/transaction/getTx`,
			method: "POST",
			headers: {},
			data: {
				field: fieldName,
				value: fieldValue,
			},
		};
		let transactions = await axiosHttpService(options);
		transactions = transactions.res.records ? transactions.res.records : [];
		transactions = transactions.map((tx) => tx.data);
		transactions = transactions.map((tx) => {
			if (tx?.investorTransactionType !== undefined) {
				tx.transactionType =
					tx?.investorTransactionType === 0
						? InvestorTransactionType.Subscribed
						: InvestorTransactionType.Received;
			} else if (tx?.borrowerTransactionType !== undefined) {
				tx.transactionType =
					tx?.borrowerTransactionType === 0
						? BorrowerTransactionType.Disbursed
						: BorrowerTransactionType.Repayment;
			}
			return tx;
		});
		transactions.sort(sortByProperty(orderByField));
		return transactions;
	} catch (error) {
		Sentry.captureException(error);
		return [];
	}
};

export const getAllTransactions = async (pageSize, bookmark) => {
	try {
		let options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/transaction/getAllTx`,
			method: "POST",
			headers: {},
			data: {
				pageSize: pageSize,
				bookmark: bookmark,
			},
		};
		let transactions = await axiosHttpService(options);
		const bookmarkString = transactions?.res?.bookmark;
		transactions = transactions.res.records ? transactions.res.records : [];
		transactions = transactions.map((tx) => tx.data);
		transactions = transactions.map((tx) => {
			tx.status = "Success";
			tx.displayAmount = getDisplayAmount(tx.amount);
			tx.investedOn = tx.investedOn;
			if (tx?.investorTransactionType !== undefined) {
				tx.transactionType =
					tx?.investorTransactionType === 0
						? TransactionType.Invest
						: TransactionType.Payout;
			} else if (tx?.borrowerTransactionType !== undefined) {
				tx.transactionType =
					tx?.borrowerTransactionType === 0
						? TransactionType.Borrow
						: TransactionType.Repay;
			}
			return tx;
		});
		return { transactions, bookmark: bookmarkString, success: true };
	} catch (error) {
		Sentry.captureException(error);
		return { transactions: [], success: false };
	}
};

function sortByProperty(property) {
	return function (a, b) {
		if (a[property] < b[property]) return 1;
		else if (a[property] > b[property]) return -1;

		return 0;
	};
}
