import { useState } from "react";

const ConfirmOrRejectModal = ({
	isSelected,
	handleSubmit,
	setIsSelected,
	text,
}) => {
	const [isSubmit, setisSubmit] = useState(false);

	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={isSelected}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal"
			>
				<div className="relative p-4 w-full max-w-md h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<h3 className="font-normal text-xl">
								Confirmation
							</h3>

							<button
								type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-3"
								data-modal-toggle="popup-modal"
								onClick={() => {
									setIsSelected(false);
								}}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>

						<div className="p-6  overflow-hidden">
							<div className="mb-2 pl-2">
								{text.map((txt) => (
									<p className="font-normal text-lg mb-2">
										{txt}
									</p>
								))}
							</div>
							<div className="mt-10 flex mx-10 justify-around">
								<button
									onClick={() => {
										setIsSelected(false);
									}}
									data-modal-toggle="popup-modal"
									type="button"
									className={`py-2 px-12 capitalize font-medium border-2 border-neutral-500 rounded-3xl hover:bg-gray-50`}
								>
									NO
								</button>
								<button
									onClick={() => {
										setisSubmit(true);
										handleSubmit();
									}}
									data-modal-toggle="popup-modal"
									type="button"
									className={`${
										!isSubmit
											? "py-2 relative px-12 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
											: "py-2 relative px-12 bg-gradient-to-r bg-neutral-400 cursor-not-allowed  opacity-40 capitalize font-medium border-none rounded-3xl"
									}`}
								>
									Yes
									{isSubmit && (
										<div className="animate-spin border-solid  top-3 left-[3.25rem] absolute border-[2.4px] border-t-[#14171F] border-r-[#14171F] border-[#fff] w-[1.3rem] h-[1.3rem] rounded-full"></div>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConfirmOrRejectModal;
