import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import InvestModal from "../Investor/components/Modal/InvestModal";
import TransactionCard from "./components/Cards/TransactionCard";
import Twitter from "../SVGIcons/Twitter";
import Website from "../SVGIcons/Website";
import LinkedIn from "../SVGIcons/LinkedIn";
import {
	formatNumberWithCommas,
	getExtendableTextBreakup,
} from "../../services/Helpers/displayTextHelper";
import Loader from "../../uiTools/Loading/Loader";
import ProcessingFundsModal from "./components/Modal/ProcessingFundsModal";
import ProcessingDocModal from "./components/Modal/ProcessingDocModal";
import DygnifyImage from "../../assets/Dygnify_Image.png";
import DollarImage from "../../assets/Dollar-icon.svg";
import ErrorModal from "../../uiTools/Modal/ErrorModal";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import { GreenScore } from "dygnify-green-score";
import DiliginceDocument from "./DiliginceDocument";
import BankDetailsModal from "../Custodian/components/BankDetailsModal";
import { GreenData, GreenDataMonitoring } from "dygnify-green-data-monitoring";
import { investInJuniorPool } from "../../services/BackendConnectors/userConnectors/investorConncector";
import {
	BondStatus,
	InvestorTransactionType,
	getAllActiveOpportunities,
} from "../../services/BackendConnectors/opportunityConnectors";
import { captureException } from "@sentry/react";

const ViewPool = () => {
	const location = useLocation();
	const [poolData, setPoolData] = useState();
	const [utrNo, setUtrNo] = useState("");
	const [transactionData, setTransactionData] = useState([]);
	const [expand, setExpand] = useState(false);
	const [bioExpand, setBioExpand] = useState(false);
	const [companyDetails, setCompanyDetails] = useState();
	const [kycStatus, setKycStatus] = useState();
	const [poolBal, setPoolBal] = useState();
	const [info, setInfo] = useState([]);
	const [info2, setInfo2] = useState([]);
	const [loanPurpose, setLoanPurpose] = useState({
		isSliced: false,
		firstText: "",
		secondText: "",
	});
	const [companyBio, setCompanyBio] = useState({
		isSliced: false,
		firstText: "",
		secondText: "",
	});
	const [selected, setSelected] = useState(null);
	const [logoImgSrc, setLogoImgSrc] = useState();
	const [processFundModal, setProcessFundModal] = useState();
	const [processDocModal, setProcessDocModal] = useState();
	const [investProcessing, setInvestProcessing] = useState();
	const [docProcessing, setDocProcessing] = useState();

	const [loading, setLoading] = useState(true);
	const [txhash, settxhash] = useState("");
	const [contractAdrress, setcontractAdrress] = useState("");
	const [amounts, setAmounts] = useState("");
	const [invest, setInvest] = useState(12);
	const [transactionList, setTransactionList] = useState(13);
	const [isFullStatus, setIsFullStatus] = useState();
	const [checkInvest, setCheckInvest] = useState();
	const [checkDoc, setCheckDoc] = useState();
	const { userData } = useContext(LoginContext);
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});

	const [activeTab, setActiveTab] = useState("Dygnify's Green Score");
	const [bankDetailsModal, setBankDetailsModal] = useState(false);
	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	const handleDrawdown = () => {
		setSelected(null);
	};

	const loadBlockpassWidget = (address) => {
		const blockpass = new window.BlockpassKYCConnect(
			process.env.REACT_APP_CLIENT_ID, // service client_id from the admin console
			{
				refId: address, // assign the local user_id of the connected user
			}
		);

		blockpass.startKYCConnect();

		blockpass.on("KYCConnectSuccess", () => {
			//add code that will trigger when data have been sent.
		});
	};

	useEffect(() => {
		if (location?.state && !poolData) {
			setPoolData(location.state);
			if (location?.state?.viewMore === "issuer") {
				setActiveTab("Green Data");
			}
			setKycStatus(
				location.state?.kycStatus ? location.state?.kycStatus : false
			);
			if (!location.state.kycStatus) {
				loadBlockpassWidget(userData?.Id);
			}
			setIsFullStatus(
				location.state.isSenior
					? location.state.isSeniorFull
						? location.state.isSeniorFull
						: false
					: location.state.isJuniorFull
					? location.state.isJuniorFull
					: false
			);
			setCompanyDetails(
				location.state.companyDetails
					? location.state.companyDetails
					: false
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (poolData) {
			//setPoolBal(getDisplayAmount(res.balance));

			loadInfo();
			setLogoImgSrc(
				poolData?.companyDetails?.companyLogoFile?.businessLogoFileCID
			);
			// get the loan purpose
			const { isSliced, firstText, secondText } =
				getExtendableTextBreakup(poolData.opportunityInfo, 200);

			if (isSliced) {
				setLoanPurpose({
					firstText: firstText,
					secondText: secondText,
					isSliced: isSliced,
				});
			} else {
				setLoanPurpose({
					firstText: firstText,
					isSliced: isSliced,
				});
			}

			//get company Bio
			const compBio = getExtendableTextBreakup(
				poolData?.companyDetails.companyBio,
				200
			);

			if (compBio.isSliced) {
				setCompanyBio({
					firstText: compBio.firstText,
					secondText: compBio.secondText,
					isSliced: compBio.isSliced,
				});
			} else {
				setCompanyBio({
					firstText: compBio.firstText,
					isSliced: compBio.isSliced,
				});
			}
		}
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [poolData, invest]);

	useEffect(() => {
		if (poolData) {
			setIsFullStatus(
				location.state.isSenior
					? poolData.isSeniorFull
						? poolData.isSeniorFull
						: false
					: poolData.isJuniorFull
					? poolData.isJuniorFull
					: false
			);
			setCompanyDetails(
				poolData.companyDetails ? poolData.companyDetails : false
			);
		}
	}, [poolData, transactionList]);

	function loadInfo() {
		if (poolData) {
			setInfo([
				{
					label: "Interest Rate",
					value: poolData.loanInterest ? poolData.loanInterest : "--",
				},
				{
					label: "Payment Tenure",
					value: poolData.loanTenure ? poolData.loanTenure : "--",
				},
				{
					label: "Drawdown Cap",
					value: poolData.opportunityAmount
						? poolData.opportunityAmount
						: "--",
				},
			]);

			setInfo2([
				{
					label: "Opening Date",
					value: poolData.createdOn ? poolData.createdOn : "--",
				},
				{
					label: "Payment Frequency",
					value: poolData.paymentFrequencyInDays
						? poolData.paymentFrequencyInDays
						: "--",
				},
				{
					label: "Borrower Address",
					value: poolData.borrowerDisplayAdd
						? poolData.borrowerDisplayAdd
						: "--",
				},
			]);
		}
	}

	const redirectToURl = (event) => {
		let url;

		switch (event.target.id) {
			case "twitter":
				url = poolData.companyDetails?.twitter;
				break;
			case "linkedin":
				url = poolData.companyDetails?.linkedin;
				break;
			case "website":
				url = poolData.companyDetails?.website;
				break;
			default:
				break;
		}

		if (url) {
			let protocol = "https://";
			let position = url.search(protocol);
			// if there is no "https://" in the url then it is not opened correctly
			if (position === -1) {
				url = protocol + url;
			}
			window.open(url, "_blank");
		}
	};

	const investJunior = async (bankDetails) => {
		handleDrawdown();
		setCheckDoc(false);
		setBankDetailsModal(false);
		setCheckInvest(true);
		setProcessFundModal(true);
		setInvestProcessing(true);
		const data = await investInJuniorPool({
			txHash: txhash,
			subscriberId: userData.Id,
			bondId: poolData.Id,
			amount: amounts,
			bondName: poolData.opportunityName,
			investedOn: Date.now(),
			investorTransactionType: InvestorTransactionType.Invest,
			isSenior: poolData.isSenior,
			...bankDetails,
		});
		if (data.success) {
			try {
				const res = await getAllActiveOpportunities();
				if (res.opportunities && res.opportunities.length) {
					res.opportunities.forEach((bond) => {
						if (bond.Id === poolData.Id) {
							setPoolData(bond);
						}
					});
				}
			} catch (error) {
				captureException(error);
			}

			setSelected(null);
			setInvestProcessing(false);

			setTimeout(() => {
				setInvest(Math.random());
			}, 1000);

			setTimeout(() => {
				setTransactionList(Math.random());
			}, 15000);
		} else {
			setSelected(null);
			setInvestProcessing(true);
			setProcessDocModal(false);

			setErrormsg({
				status: !data.status,
				msg: data.msg,
			});
		}
	};

	return (
		<div>
			{loading && <Loader />}
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			<div className={`${loading ? "blur-sm" : ""}`}>
				{selected ? (
					<InvestModal
						handleDrawdown={handleDrawdown}
						poolAddress={poolData?.opportunityPoolAddress}
						poolName={poolData?.opportunityName}
						poolLimit={poolData?.opportunityAmount}
						estimatedAPY={poolData?.loanInterest}
						investableAmount={
							poolData?.isSenior
								? poolData?.seniorInvestableAmount
								: poolData?.juniorInvestableAmount
						}
						investableDisplayAmount={
							poolData?.isSenior
								? poolData?.seniorInvestableDisplayAmount
								: poolData?.juniorInvestableDisplayAmount
						}
						setProcessDocModal={setProcessDocModal}
						setDocProcessing={setDocProcessing}
						setSelected={setSelected}
						settxhash={settxhash}
						setcontractAdrress={setcontractAdrress}
						setAmounts={setAmounts}
						setInvest={setInvest}
						setTransactionList={setTransactionList}
						setCheckDoc={setCheckDoc}
						setErrormsg={setErrormsg}
						trancheAmount={
							poolData?.isSenior
								? poolData?.seniorTrancheDisplayAmount
									? poolData?.seniorTrancheDisplayAmount
									: "--"
								: poolData?.juniorTrancheDisplayAmount
								? poolData?.juniorTrancheDisplayAmount
								: "--"
						}
						xirr={
							poolData.isSenior
								? poolData?.seniorXirr
								: poolData?.juniorXirr
						}
					/>
				) : null}

				{processFundModal ? (
					<ProcessingFundsModal
						investProcessing={investProcessing}
						invest={true}
						contractAddress={contractAdrress}
						amounts={amounts}
						checkInvest={checkInvest}
						setCheckInvest={setCheckInvest}
						utrNo={utrNo}
					/>
				) : (
					<></>
				)}
				{processDocModal ? (
					<ProcessingDocModal
						docProcessing={docProcessing}
						invest={true}
						txhash={txhash}
						contractAddress={contractAdrress}
						amount={amounts}
						checkDoc={checkDoc}
						setCheckInvest={setCheckInvest}
						setCheckDoc={setCheckDoc}
						setProcessFundModal={setProcessFundModal}
						setFundProcessing={setInvestProcessing}
						handleDrawdown={handleDrawdown}
						userData={userData}
						setSelected={setSelected}
						setInvest={setInvest}
						setTransactionList={setTransactionList}
						setProcessDocModal={setProcessDocModal}
						setErrormsg={setErrormsg}
						poolData={poolData}
						setBankDetailsModal={setBankDetailsModal}
					/>
				) : (
					<></>
				)}

				{bankDetailsModal ? (
					<BankDetailsModal
						selected={bankDetailsModal}
						setSelected={setBankDetailsModal}
						setUtrNo={setUtrNo}
						onSubmit={investJunior}
					/>
				) : (
					<></>
				)}

				<div className=" flex items-center">
					<div className="flex items-center gap-3 md:gap-5">
						<img
							alt="dygnigyImage"
							src={logoImgSrc ? logoImgSrc : DygnifyImage}
							style={{ aspectRatio: "1/1" }}
							className="rounded-[50%] w-[4em] sm:w-[5em] md:w-[6em]"
						/>

						<div>
							<p className="text-lg font-normal">
								{poolData?.opportunityName}
							</p>
							<p className="text-neutral-500">
								{companyDetails
									? companyDetails.companyName
									: ""}
							</p>
						</div>
					</div>

					{/* social links */}
					<div className="ml-auto flex items-center gap-3 sm:gap-6 md:gap-4 text-white">
						{companyDetails?.linkedin ? (
							<button
								id="linkedin"
								className="btn CreateProfileIcon btn-sm px-2 dark:border-none btn-outline dark:bg-[#292C33] border border-neutral-500  dark:text-white text-black py-2 gap-1 rounded-full  capitalize flex pb-5"
								onClick={redirectToURl}
							>
								<LinkedIn />
								LinkedIn
							</button>
						) : (
							<></>
						)}
						{companyDetails?.website ? (
							<button
								id="website"
								className="btn btn-sm px-2 dark:border-none btn-outline dark:bg-[#292C33] border border-neutral-500  dark:text-white text-black py-2 gap-1 rounded-full  capitalize flex pb-5"
								onClick={redirectToURl}
							>
								<Website />
								Website
							</button>
						) : (
							<></>
						)}

						{companyDetails?.twitter ? (
							<button
								id="twitter"
								className="btn CreateProfileIcon btn-sm px-2 dark:border-none btn-outline dark:bg-[#292C33] border border-neutral-500  dark:text-white text-black py-2 gap-1 rounded-full  lowercase flex pb-5"
								onClick={redirectToURl}
							>
								<Twitter /> twitter
							</button>
						) : (
							<></>
						)}
					</div>
				</div>

				<div className="mt-[2.5em] md:mt-[3.5em] flex flex-col gap-[1.5em] md:flex-row md:items-start xl:gap-[5em] 2xl:gap-[8em]">
					<div className="md:w-[60%]">
						<div className="flex items-center mb-4">
							<h2 className="text-xl font-normal md:text-2xl">
								Request Overview
							</h2>
						</div>

						{loanPurpose.isSliced ? (
							<div className="text-neutral-700 dark:text-neutral-200">
								{loanPurpose.firstText}
								<span
									onClick={() => setExpand(true)}
									className="cursor-pointer font-normal"
								>
									{expand ? null : " ...view more"}
								</span>
								{expand ? (
									<span>{loanPurpose.secondText}</span>
								) : null}
								<span
									onClick={() => setExpand(false)}
									className="cursor-pointer font-normal"
								>
									{expand ? " view less" : null}
								</span>
							</div>
						) : (
							<div className="text-neutral-700 dark:text-neutral-200">
								{loanPurpose.firstText}{" "}
							</div>
						)}
						<div className="flex items-center mb-4">
							<h2 className="mt-4 text-xl font-normal md:text-2xl">
								Company Overview
							</h2>
						</div>
						{companyBio.isSliced ? (
							<div className="text-neutral-700 dark:text-neutral-200">
								{companyBio.firstText}
								<span
									onClick={() => setBioExpand(true)}
									className="cursor-pointer font-normal"
								>
									{bioExpand ? null : " ...view more"}
								</span>
								{bioExpand ? (
									<span>{companyBio.secondText}</span>
								) : null}
								<span
									onClick={() => setBioExpand(false)}
									className="cursor-pointer font-normal"
								>
									{bioExpand ? " view less" : null}
								</span>
							</div>
						) : (
							<div className="text-neutral-700 dark:text-neutral-200">
								{companyBio.firstText}{" "}
							</div>
						)}
					</div>

					<div className="rounded-md px-4 py-6 border dark:border-[#363637] flex flex-col gap-6 md:w-[25rem] xl:w-[30rem] bg-gradient-to-tl from-[#ffffff00] dark:from-[#20232a00] to-[#D1D1D1] dark:to-[#20232A]">
						<div className="flex ">
							<div className="flex flex-col justify-start text-neutral-500 dark:text-neutral-200">
								{/* <p className=" font-bold text-md mb-2">
									Interest rate
								</p> */}
								{poolData?.viewMore ? (
									<>
										<p className=" font-bold text-md mb-2">
											Amount
										</p>
										{parseInt(
											poolData?.seniorXirr.split(".")[0]
										) !== 0 && (
											<p className=" font-bold text-md mb-2">
												Senior XIRR
											</p>
										)}
										{parseInt(
											poolData?.juniorXirr.split(".")[0]
										) !== 0 && (
											<p className=" font-bold text-md mb-2">
												Junior XIRR
											</p>
										)}
									</>
								) : (
									<>
										<p className=" font-bold text-md mb-2">
											Expected XIRR
										</p>

										<p className=" font-bold text-md mb-2">
											Tranche amount
										</p>

										<p className=" font-bold text-md mb-2">
											Total subscribed
										</p>
									</>
								)}

								<p className=" font-bold text-md mb-2">
									Tenure
								</p>
								<p className=" font-bold text-md mb-2">
									Payment frequency
								</p>
								{poolData?.custodian && (
									<p className=" font-bold text-md mb-2">
										Custodian
									</p>
								)}
							</div>
							<div className="ml-auto flex flex-col justify-start">
								{poolData?.viewMore ? (
									<>
										<p className="font-normal text-xl mb-1 flex gap-1 items-center">
											<img
												src={DollarImage}
												className="w-4"
												alt="DollarImage"
											/>
											{poolData?.opportunityAmount
												? poolData?.opportunityAmount
												: "--"}
										</p>
										{parseInt(
											poolData?.seniorXirr.split(".")[0]
										) !== 0 && (
											<p className="font-normal text-xl mb-1">
												{poolData?.seniorXirr}
											</p>
										)}
										{parseInt(
											poolData?.juniorXirr.split(".")[0]
										) !== 0 && (
											<p className="font-normal text-xl mb-1">
												{poolData?.juniorXirr}
											</p>
										)}
									</>
								) : (
									<>
										<p className="font-normal text-xl mb-1">
											{location.state
												? location.state.isSenior
													? poolData?.seniorXirr
													: poolData?.juniorXirr
												: "--"}
										</p>

										<p className="font-normal text-xl mb-1 flex gap-1 items-center">
											<img
												src={DollarImage}
												className="w-4"
												alt="Dollar"
											/>
											{location.state?.isSenior
												? poolData?.seniorTrancheDisplayAmount
													? poolData?.seniorTrancheDisplayAmount
													: "--"
												: poolData?.juniorTrancheDisplayAmount
												? poolData?.juniorTrancheDisplayAmount
												: "--"}
										</p>
										<p className="font-normal text-xl mb-1 flex gap-1 items-center">
											<img
												src={DollarImage}
												className="w-4"
												alt="DollarImage"
											/>
											{location.state?.isSenior
												? poolData?.totalSeniorDisplayInvestedAmt
													? poolData.totalSeniorDisplayInvestedAmt
													: "--"
												: poolData?.totalJuniorDisplayInvestedAmt
												? poolData.totalJuniorDisplayInvestedAmt
												: "--"}
										</p>
									</>
								)}
								<p className="font-normal text-xl mb-1">
									{poolData ? poolData.loanTenure : "--"}
								</p>
								<p className="font-normal text-xl mb-1">
									{poolData
										? poolData.paymentFrequencyInDays
										: "--"}
								</p>
								{poolData?.custodian && (
									<p className="font-normal text-xl">
										{poolData?.custodian}
									</p>
								)}
							</div>
						</div>

						{poolData?.status < BondStatus.Tokenized &&
							!poolData?.viewMore && (
								<label
									htmlFor={kycStatus ? "InvestModal" : ""}
									id={
										kycStatus && kycStatus === false
											? "blockpass-kyc-connect"
											: ""
									}
									onClick={() => {
										if (kycStatus && !isFullStatus)
											return setSelected(true);
										else return null;
									}}
									className={`block font-normal text-white ${
										isFullStatus
											? "bg-neutral-400 cursor-not-allowed w-full opacity-40"
											: "bg-gradient-to-r from-[#4B74FF] to-primary-500 w-[100%] cursor-pointer"
									}  text-center py-2 rounded-[1.8em] select-none`}
								>
									{kycStatus
										? "Subscribe"
										: "Complete your KYC"}
								</label>
							)}
						{isFullStatus ? (
							<div className="flex  justify-center  font-normal text-sm text-[#FBBF24]">
								<div className="text-center">
									Note - Cannot invest as opportunity is full
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
				</div>

				{poolData?.viewMore && poolData?.trx?.length > 0 && (
					<div>
						<div className="flex items-center mb-4">
							<h2 className="mt-4 text-xl font-normal md:text-2xl">
								Subscription Details
							</h2>
						</div>
						<div class="relative overflow-x-auto rounded-md border border-gray-300 mt-4 mb-8">
							<table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
								<thead class="text-xs text-gray-700 uppercase bg-[#E7EAEE] dark:bg-gray-700 dark:text-gray-400 border-b-[1px] border-gray-300">
									<tr>
										<th
											scope="col"
											class="px-6 py-4"
											align="center"
										>
											Subscriber
										</th>
										<th
											scope="col"
											class="px-6 border-l border-gray-300"
											align="center"
										>
											% subscription
										</th>
										<th
											scope="col"
											class="px-6 border-l border-gray-300"
											align="center"
										>
											Amount
										</th>
										<th
											scope="col"
											class="px-6 border-l border-gray-300"
											align="center"
										>
											Date
										</th>
									</tr>
								</thead>
								<tbody>
									{poolData?.trx.map((item) => {
										return (
											<tr
												class="bg-neutral-100 border-b border-gray-300 dark:bg-gray-800 dark:border-gray-700"
												align="center"
											>
												<th
													scope="row"
													class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
												>
													{item?.name}
												</th>
												<td class="px-6 py-4 border-l border-gray-300">
													{item?.subPercentage}
												</td>
												<td class="px-6 py-4 border-l border-gray-300 flex justify-center items-center gap-1">
													<img
														src={DollarImage}
														className="w-4"
														alt="DollarImage"
													/>
													{formatNumberWithCommas(
														item?.amount
													)}
												</td>
												<td class="px-6 py-4 border-l border-gray-300">
													{item?.investedOn}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				)}

				<div className="w-full mx-auto mt-6 rounded-md">
					<nav className="box-shadow5 flex items-center  text-[#fff]  viewPool-navbar">
						<ul className="flex  w-full ">
							{poolData?.viewMore !== "issuer" && (
								<li
									className={`${
										poolData?.viewMore === "issuer"
											? "w-1/3"
											: "w-[25%]"
									} ${
										activeTab === "Dygnify's Green Score"
											? "active"
											: "inactive"
									}`}
									onClick={() =>
										handleTabClick("Dygnify's Green Score")
									}
								>
									<span>Dygnify's Green Score</span>
								</li>
							)}
							<li
								className={`${
									poolData?.viewMore === "issuer"
										? "w-1/3"
										: "w-[25%]"
								} ${
									activeTab === "Green Data"
										? "active"
										: "inactive"
								}`}
								onClick={() => handleTabClick("Green Data")}
							>
								<span>Green Data</span>
							</li>
							<li
								className={`${
									poolData?.viewMore === "issuer"
										? "w-1/3"
										: "w-[25%]"
								} ${
									activeTab === "Green Project Monitoring"
										? "active"
										: "inactive"
								}`}
								onClick={() =>
									handleTabClick("Green Project Monitoring")
								}
							>
								<span>Green Project Monitoring</span>
							</li>
							<li
								className={`${
									poolData?.viewMore === "issuer"
										? "w-1/3"
										: "w-[25%]"
								} ${
									activeTab === "Diligence Documents"
										? "active"
										: "inactive"
								}`}
								onClick={() =>
									handleTabClick("Diligence Documents")
								}
							>
								<span>Diligence Documents</span>
							</li>
						</ul>
					</nav>
					<div className="mt-5 text-slate-800">
						{activeTab === "Dygnify's Green Score" && poolData && (
							<GreenScore
								email={userData?.email}
								projectId={poolData?.opportunityName}
								apiKey={
									process.env.REACT_APP_GREENSCORE_API_KEY
								}
							/>
						)}
						{activeTab === "Green Data" && (
							<GreenData
								projectId={poolData?.opportunityName}
								apiKey={process.env.REACT_APP_GREENDATA_API_KEY}
							/>
						)}
						{activeTab === "Green Project Monitoring" && (
							<GreenDataMonitoring
								projectId={poolData?.opportunityName}
								apiKey={process.env.REACT_APP_GREENDATA_API_KEY}
							/>
						)}
						{activeTab === "Diligence Documents" && (
							<DiliginceDocument
								companyDetails={companyDetails}
								opDetails={poolData}
							/>
						)}
					</div>
				</div>

				<div className="mt-[3em] md:mt-[4em] md:w-[58%]">
					{transactionData?.length ? (
						<div className=" mt-8 py-6  justify-start gap-4    flex font-bold border-y border-neutral-300 dark:border-darkmode-500">
							<p className="w-1/3 md:w-1/4 pl-4">Address</p>
							<p className="w-1/3 md:w-1/4 pl-4 sm:pl-10 md:pl-3 xl:pl-5">
								Type
							</p>
							<p className="w-1/3 md:w-1/4  text-end pr-4 sm:pr-10 md:pr-2 xl:pr-5 2xl:pr-8">
								Amount
							</p>
							<p className="hidden md:block w-1/3 md:w-1/4 text-end pr-4 ">
								Date
							</p>
						</div>
					) : (
						<></>
					)}

					{transactionData?.length ? (
						<div className="mt-6 flex flex-col gap-3">
							{transactionData.map((item) => (
								<TransactionCard
									key={item.hash}
									data={item}
									address={poolData.opportunityPoolAddress}
								/>
							))}
						</div>
					) : (
						""
					)}
				</div>
			</div>
		</div>
	);
};

export default ViewPool;
