function WalletFill() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="#64748B"
			width="22px"
			height="22px"
			viewBox="0 0 256 256"
			id="Flat"
		>
			<path d="M216,72H56a8,8,0,0,1,0-16H192a8,8,0,0,0,0-16H56A24.0275,24.0275,0,0,0,32,64V192a24.0275,24.0275,0,0,0,24,24H216a16.01833,16.01833,0,0,0,16-16V88A16.01833,16.01833,0,0,0,216,72Zm-36,84a12,12,0,1,1,12-12A12,12,0,0,1,180,156Z" />
		</svg>
	);
}

export default WalletFill;
