import React from "react";

const UpArrowSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="currentColor"
		class="bi bi-arrow-up-short"
		viewBox="0 0 16 16"
	>
		<path
			fill-rule="evenodd"
			d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5"
		/>
	</svg>
);

export default UpArrowSvg;
