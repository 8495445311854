import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getOpportunityJson } from "../../../services/BackendConnectors/userConnectors/borrowerConnectors";
import DollarImage from "../../../assets/Dollar-icon.svg";
import { BondStatus } from "../../../services/BackendConnectors/opportunityConnectors";

const TransactionsCard = ({ data }) => {
	return (
		<div className="flex collapse-title pr-0 justify-between w-full flex-wrap overflow-hidden dark:bg-[#20232A] bg-[#E7EAEE] rounded-xl mb-2 items-center">
			<p className="w-1/3 md:w-1/6 font-light text-lg text-start">
				{data?.opportunityName}
			</p>
			<p className="w-1/3 md:w-1/6 font-light text-lg text-start">
				{data?.companyDetails?.companyName}
			</p>
			<p className="flex gap-1 items-center w-1/3 md:w-1/6 my-auto justify-start text-start text-lg font-light">
				<img src={DollarImage} className="w-4" alt="Dollarimage" />
				{data?.opportunityAmount}
			</p>
			<p className="w-1/3 md:w-1/6 font-light text-lg text-center hidden md:block">
				{data?.createdOn}
			</p>

			{data?.status >= BondStatus.Approved && (
				<div className="w-1/3 md:w-1/6 text-center">
					<p className="btn btn-xs btn-success approved-btn border-none px-2 text-base font-medium pb-6 pt-0 rounded-full capitalize">
						Approved
					</p>
				</div>
			)}
			{data?.status === BondStatus.Rejected && (
				<div className="w-1/3 md:w-1/6 text-center ">
					<p className="btn btn-xs btn-success rejected-btn border-none px-2 text-base font-medium pb-6 pt-0 rounded-full capitalize">
						Rejected
					</p>
				</div>
			)}
			{/* {data?.status === 3 && (
				<div className="w-1/3 md:w-1/6 text-center ">
					<p className="btn btn-xs btn-success unsured-btn border-none px-2 text-base font-medium pb-6 pt-0 rounded-full capitalize">
						Unsure
					</p>
				</div>
			)} */}
		</div>
	);
};

export default TransactionsCard;
