import React from "react";
import UpArrowSvg from "../../pages/SVGIcons/UpArrowSvg";
import DownArrowSvg from "../../pages/SVGIcons/DownArrowSvg";

const Table = ({
	getTableProps,
	getTableBodyProps,
	headerGroups,
	rows,
	prepareRow,
	tableName,
}) => {
	return (
		<div className="w-full overflow-auto">
			<div {...getTableProps()}>
				<div>
					{headerGroups.map((headerGroup) => (
						<div
							className="mt-4 py-6 gap-4 md:gap-0 md:justify-between flex font-bold border-y border-darkmode-500 text-center w-full"
							{...headerGroup.getHeaderGroupProps()}
						>
							{headerGroup.headers.map((column) => (
								<div
									className="w-1/3 md:w-1/6 my-auto ml-0 select-none relative flex justify-center"
									{...column.getHeaderProps(
										column.getSortByToggleProps({
											title: undefined,
										})
									)}
								>
									{column.isSorted && (
										<span className="invisible ml-2">
											<DownArrowSvg />
										</span>
									)}
									{column.render("Header")}
									{column.isSorted && (
										<span className="ml-2">
											{column.isSortedDesc ? (
												<DownArrowSvg />
											) : (
												<UpArrowSvg />
											)}
										</span>
									)}
								</div>
							))}
						</div>
					))}
				</div>
				<div
					className="my-4 flex flex-col gap-3 overflow-auto"
					style={{ maxHeight: "62vh" }} // Set this to the desired height
					{...getTableBodyProps()}
				>
					{rows.map((row) => {
						prepareRow(row);
						return (
							<div
								className="flex collapse-title px-0 justify-between w-full overflow-hidden dark:bg-[#20232A] bg-[#E7EAEE] rounded-xl mb-2 items-center"
								{...row.getRowProps()}
							>
								{row.cells.map((cell) => (
									<div
										className="w-1/3 md:w-1/6 font-light text-lg text-center"
										{...cell.getCellProps()}
									>
										{cell.render("Cell")}
									</div>
								))}
							</div>
						);
					})}
					{rows.length === 0 && (
						<div className="relative h-screen flex justify-center">
							<div className="text-[#64748B] text-xl text-center mt-3 absolute top-40">
								<p>{`No ${tableName} Available.`}</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Table;
