import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DollarImage from "../../../assets/Dollar-icon.svg";
import { BondStatus } from "../../../services/BackendConnectors/opportunityConnectors";
import { useNavigate } from "react-router-dom";
import { getDisplayAmount } from "../../../services/Helpers/displayTextHelper";
import { convertDate } from "../../../services/BackendConnectors/userConnectors/commonConnectors";

const TransactionsCard = ({ data }) => {
	const path = useNavigate();
	const [companyName, setCompanyName] = useState();

	useEffect(() => {
		let companyDetails = JSON.parse(data?.companyDetails);
		setCompanyName(companyDetails?.companyName);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className="flex collapse-title pr-0 justify-between w-full flex-wrap overflow-hidden dark:bg-[#20232A] bg-[#E7EAEE] rounded-xl mb-2 items-center cursor-pointer"
			onClick={() =>
				path("/admin/poolDetail", {
					state: {
						pool: data,
					},
				})
			}
		>
			<p className="w-1/3 md:w-1/6 font-light text-lg text-start">
				{data?.loan_name}
			</p>
			<p className="w-1/3 md:w-1/6 font-light text-lg text-start">
				{companyName}
			</p>
			<p className="flex gap-1 items-center w-1/3 md:w-1/6 my-auto justify-start text-start text-lg font-light">
				<img src={DollarImage} className="w-4" alt="Dollarimage" />
				{getDisplayAmount(data?.loan_amount)}
			</p>
			<p className="w-1/3 md:w-1/6 font-light text-lg text-center hidden md:block">
				{convertDate(data?.createdOn)}
			</p>

			{data?.status >= BondStatus.UnderReview && (
				<div className="w-1/3 md:w-1/6 text-center">
					<p className="btn btn-xs btn-success approved-btn border-none px-2 text-base font-medium pb-6 pt-0 rounded-full capitalize">
						Approved
					</p>
				</div>
			)}
			{data?.status === BondStatus.RejectedByAdmin && (
				<div className="w-1/3 md:w-1/6 text-center ">
					<p className="btn btn-xs btn-success rejected-btn border-none px-2 text-base font-medium pb-6 pt-0 rounded-full capitalize">
						Rejected
					</p>
				</div>
			)}
		</div>
	);
};

export default TransactionsCard;
