import React, { useEffect, useState } from "react";
import TransactionsCard from "./Components/TransactionsCard";
import { getAdminApprovalHistory } from "../../services/BackendConnectors/userConnectors/underwriterConnectors";
import Loader from "../../uiTools/Loading/Loader";
import ErrorModal from "../../uiTools/Modal/ErrorModal";

const AdminApprovalHistory = () => {
	const [bonds, setBonds] = useState([]);
	const [bookmark, setBookmark] = useState(null);
	const [loading, setLoading] = useState(true);
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});

	useEffect(() => {
		(async function () {
			await getHistory();
			setLoading(false);
		})();
	}, []);

	async function getHistory() {
		let list = await getAdminApprovalHistory(
			process.env.REACT_APP_SPYDRA_RESULT_PAGE_SIZE,
			bookmark
		);
		if (list.success) {
			list.modifiedBonds = list.modifiedBonds?.sort(
				sortByProperty("createdOn")
			);
			setBonds(list?.modifiedBonds);
			setBookmark(list.bookmark);
		} else {
			setErrormsg({ status: !list.success, msg: list.msg });
		}
	}

	const handleLoadMore = async () => {
		setLoading(true);
		let list = await getAdminApprovalHistory(
			process.env.REACT_APP_SPYDRA_RESULT_PAGE_SIZE,
			bookmark
		);
		if (list.success) {
			let newBonds = bonds.concat(list?.modifiedBonds);
			newBonds = newBonds?.sort(sortByProperty("createdOn"));
			setBonds(newBonds);
			setBookmark(list?.bookmark);
		}
		setLoading(false);
	};

	function sortByProperty(property) {
		return function (a, b) {
			if (a[property] < b[property]) return 1;
			else if (a[property] > b[property]) return -1;

			return 0;
		};
	}

	return (
		<div className={`mb-16 -mx-4 ${loading ? "relative h-[80vh]" : ""}`}>
			{loading && <Loader />}
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />

			<div className={`${loading ? "blur-sm" : ""}`}>
				<h2 className="text-2xl mb-2 md:mb-12 ml-5  md:ml-7">
					Approval history
				</h2>
				{bonds?.length === 0 ? (
					<div className="relative h-screen flex justify-center">
						<div className="text-[#64748B] text-xl text-center mt-3 absolute top-40">
							<p>No requests are present at the moment.</p>
						</div>
					</div>
				) : (
					<div>
						<div className="collapse mb-3">
							<input type="checkbox" className="peer" />
							<div className="flex collapse-title  border-t-[1px] border-b-[1px]  border-t-[#B8C0CC] dark:border-t-[#20232A] border-b-[#B8C0CC] dark:border-b-[#20232A]  justify-between w-full items-center font-bold flex-wrap md:pr-5 md:pl-9 pr-2 pl-5">
								<p className="w-1/3 md:w-1/6 text-start">
									Request Name
								</p>
								<p className="w-1/3 md:w-1/6 text-start">
									Issuer Name
								</p>
								<p className="w-1/3 md:w-1/6 text-start">
									Amount
								</p>
								<p className="w-1/3 md:w-1/6 text-center  hidden md:block">
									Proposed On
								</p>
								<p className="w-1/3 md:w-1/6 text-center">
									Decision
								</p>
							</div>
						</div>
						<div className="space-y-3 md:pl-5 md:pr-5 px-2">
							{bonds
								? bonds.map((item) => (
										<TransactionsCard
											key={item.Id}
											data={item}
										/>
								  ))
								: null}
						</div>
						{bookmark && (
							<div className="text-center mt-4">
								<button
									className="py-2 px-8 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
									onClick={handleLoadMore}
								>
									Load More
								</button>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default AdminApprovalHistory;
