import React from "react";

const TransactionIcon = ({ color }) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.207 2.29047L19.207 6.28647C19.3793 6.45854 19.4829 6.68757 19.4984 6.9306C19.5139 7.17363 19.4401 7.41395 19.291 7.60647L19.208 7.70047L15.208 11.7065C15.0285 11.8877 14.7864 11.9935 14.5315 12.0022C14.2765 12.0108 14.0278 11.9217 13.8364 11.753C13.645 11.5843 13.5253 11.3489 13.5018 11.0948C13.4783 10.8408 13.5528 10.5874 13.71 10.3865L13.793 10.2925L16.083 8.00047L5.5 8.00047C5.25507 8.00044 5.01866 7.91052 4.83563 7.74776C4.65259 7.585 4.53566 7.36072 4.507 7.11747L4.5 6.99947C4.50003 6.75454 4.58996 6.51813 4.75272 6.3351C4.91547 6.15206 5.13975 6.03513 5.383 6.00647L5.5 5.99947L16.09 5.99947L13.794 3.70647C13.6217 3.53441 13.5181 3.30537 13.5026 3.06234C13.4871 2.81931 13.5609 2.57899 13.71 2.38647L13.793 2.29147C13.9651 2.11915 14.1941 2.01553 14.4371 2.00007C14.6802 1.9846 14.9205 2.05835 15.113 2.20747L15.207 2.29147L19.207 6.28647L15.207 2.29147V2.29047ZM19.491 16.8825L19.497 16.9995C19.497 17.2444 19.407 17.4808 19.2443 17.6638C19.0815 17.8469 18.8573 17.9638 18.614 17.9925L18.497 17.9995L7.914 17.9995L10.207 20.2925C10.3793 20.4645 10.4829 20.6936 10.4984 20.9366C10.5139 21.1796 10.4401 21.4199 10.291 21.6125L10.208 21.7065C10.0359 21.8788 9.8069 21.9824 9.56387 21.9979C9.32084 22.0133 9.08052 21.9396 8.888 21.7905L8.794 21.7065L4.794 17.7105C4.62168 17.5384 4.51806 17.3094 4.50259 17.0663C4.48713 16.8233 4.56088 16.583 4.71 16.3905L4.793 16.2965L8.793 12.2925C8.97254 12.1112 9.21457 12.0054 9.46955 11.9968C9.72453 11.9881 9.97317 12.0773 10.1646 12.2459C10.356 12.4146 10.4757 12.6501 10.4992 12.9041C10.5227 13.1581 10.4482 13.4116 10.291 13.6125L10.208 13.7065L7.918 15.9995L18.498 15.9995C18.7429 15.9995 18.9793 16.0894 19.1624 16.2522C19.3454 16.4149 19.4623 16.6392 19.491 16.8825L19.497 16.9995L19.491 16.8825Z"
				fill={color ? "#9281FF" : "#64748B"}
			/>
		</svg>
	);
};

export default TransactionIcon;
