import React, { useEffect, useRef } from "react";

const AnimatedCheckmark = () => {
	const pathRef = useRef(null);
	const circleRef = useRef(null);

	useEffect(() => {
		const path = pathRef.current;
		const circle = circleRef.current;
		const length = path.getTotalLength();
		const circleLength = circle.getTotalLength();

		path.style.transition = path.style.WebkitTransition = "none";
		path.style.strokeDasharray = `${length} ${length}`;
		path.style.strokeDashoffset = length;

		circle.style.transition = circle.style.WebkitTransition = "none";
		circle.style.strokeDasharray = `${circleLength} ${circleLength}`;
		circle.style.strokeDashoffset = circleLength;

		path.getBoundingClientRect();
		circle.getBoundingClientRect();

		path.style.transition = path.style.WebkitTransition =
			"stroke-dashoffset 2s ease-in-out";
		circle.style.transition = circle.style.WebkitTransition =
			"stroke-dashoffset 2s ease-in-out";

		path.style.strokeDashoffset = "0";
		circle.style.strokeDashoffset = "0";
	}, []);

	return (
		<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
			<circle
				ref={circleRef}
				cx="25"
				cy="25"
				r="22"
				fill="none"
				stroke="#4CAF50"
				strokeWidth="4"
			/>
			<path
				ref={pathRef}
				d="M15 25 L22 32 L35 18"
				fill="none"
				stroke="#4CAF50"
				strokeWidth="4"
			/>
		</svg>
	);
};

export default AnimatedCheckmark;
