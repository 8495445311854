import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GradientButton from "../../uiTools/Button/GradientBtnForLanding";
const SubscriberCheckCard = ({ isCheckModal, setIsCheckModal }) => {
	const [agreeChecked, setAgreeChecked] = useState(false);
	const [disagreeChecked, setDisagreeChecked] = useState(false);
	const path = useNavigate();

	const handleAgreeChange = () => {
		setAgreeChecked(!agreeChecked);
		path(isCheckModal?.url, {
			state: isCheckModal?.state,
		});
		setIsCheckModal({
			status: false,
			title: "",
			url: "",
			state: "",
		});
	};

	const handleDisagreeChange = () => {
		setDisagreeChecked(!disagreeChecked);

		setIsCheckModal({
			status: false,
			title: "",
			url: "",
			state: "",
		});
	};

	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={isCheckModal?.status}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal"
			>
				<div className="relative p-4 w-full max-w-3xl h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<h3 className="font-normal text-xl ">
								Non-Disclosure Agreement (NDA)
							</h3>

							<button
								type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-3"
								data-modal-toggle="popup-modal"
								onClick={() => {
									setIsCheckModal({
										status: false,
										title: "",
										url: "",
									});
								}}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>

						<div className="p-5 px-10 mb-[-3rem] overflow-y-auto max-h-[60vh]">
							{/* Add overflow-y-auto and max-h-[60vh] classes */}
							<div className="container mx-auto ">
								<h2 className="text-xl font-medium mt-6">
									Introduction:
								</h2>
								<p>
									This Non-Disclosure Agreement (“Agreement”)
									is entered into by and between the
									undersigned parties, the&nbsp;
									<span className="font-medium">
										Subscriber and the Issuer.&nbsp;
									</span>
									Collectively referred to as the “Parties”.
								</p>

								<h2 className="text-xl font-medium mt-6">
									Purpose:
								</h2>

								<p>
									The Parties intend to share confidential
									information related to the exchange of
									information regarding issuance and
									subscription of debt instruments
									(“Purpose”).
								</p>

								<h2 className="text-xl font-medium mt-6">
									Confidential Information
								</h2>

								<ol className="list-decimal ml-8">
									<li>
										“Confidential Information” refers to any
										information, data, documents, or
										materials disclosed by one Party to the
										other, directly or indirectly, in
										connection with the Purpose.
									</li>
									<li>
										Confidential Information includes, but
										is not limited to:{" "}
										<ul className="list-disc ml-8">
											<li>Business plans</li>
											<li>Financial data</li>
											<li>Intellectual property</li>
											<li>Trade secrets</li>
											<li>Customer lists</li>
											<li>Technical specifications</li>
											<li>Marketing strategies</li>
											<li>
												Any other proprietary
												information
											</li>
										</ul>
									</li>
								</ol>

								<h2 className="text-xl font-medium mt-6">
									Obligations:
								</h2>

								<ol className="list-decimal ml-8">
									<li>
										Both Parties agree to:
										<ul className="list-disc ml-8">
											<li>
												Use the Confidential Information
												solely for the Purpose.
											</li>
											<li>
												Maintain the Confidential
												Information’s confidentiality
												and not disclose it to any third
												party without prior written
												consent.
											</li>
											<li>
												Take reasonable measures to
												protect the Confidential
												Information from unauthorized
												access, use, or disclosure.
											</li>
										</ul>
									</li>
									<li>
										The obligations under this Agreement
										shall continue for a period of 1 (One
										year) from the Effective Date.
									</li>
								</ol>

								<h2 className="text-xl font-medium mt-6">
									Exceptions:
								</h2>

								<p>
									The obligations of confidentiality shall not
									apply to information that:
								</p>

								<ol className="list-decimal ml-8">
									<li>
										Is already in the public domain at the
										time of disclosure.
									</li>
									<li>
										Becomes publicly available through no
										fault of the receiving Party.
									</li>
									<li>
										Is independently developed by the
										receiving Party without reference to the
										Confidential Information.
									</li>
									<li>
										Is required to be disclosed by law,
										regulation, or court order.
									</li>
								</ol>

								<h2 className="text-xl font-medium mt-6">
									Return or Destruction of Information:
								</h2>

								<p>
									Upon written request or termination of this
									Agreement, the receiving Party shall
									promptly return or destroy all Confidential
									Information received under this Agreement.
								</p>

								<h2 className="text-xl font-medium mt-6">
									Governing Law and Jurisdiction:
								</h2>

								<p>
									This Agreement shall be governed by and
									construed in accordance with the laws of
									India. Any disputes arising out of or
									related to this Agreement shall be subject
									to the exclusive jurisdiction of the courts
									in GIFT City.
								</p>
								<p className="mt-4">
									By agreeing with the above, the Parties
									acknowledge their understanding of the terms
									and agree to be bound by this NDA.
								</p>
							</div>
						</div>

						<div className="p-6 overflow-hidden">
							<div className="mt-10 flex justify-around items-center gap-24 md:gap-0 md:flex-row md:px-5 md:justify-between lg:px-32 xl:px-40 2xl:px-74">
								<GradientButton onClick={handleDisagreeChange}>
									Disagree
								</GradientButton>
								<GradientButton
									onClick={handleAgreeChange}
									className="ml-8"
								>
									Agree
								</GradientButton>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SubscriberCheckCard;
