import React from "react";
import DoughnutChart from "../../../../uiTools/Chart/DoughnutChart";
import DollarImage from "../../../../assets/Dollar-icon.svg";
import { BondStatus } from "../../../../services/BackendConnectors/opportunityConnectors";
import { useNavigate } from "react-router-dom";
const OpportunityCardCollapsible = ({ data, kycStatus }) => {
	const path = useNavigate();
	return (
		<div className="collapse collapse-arrow dark:bg-darkmode-500 bg-lightmode-200 rounded-xl">
			<input type="checkbox" className="peer" />

			<div className="collapse-title flex gap-4 md:gap-8 text-center">
				<p className="w-1/3 md:w-1/5  my-auto">
					{data?.opportunityName}
				</p>
				<p className="w-1/3 md:w-1/5  my-auto">{data?.requestType}</p>
				<p className="md:flex gap-1 hidden items-center w-1/5  justify-center">
					<img src={DollarImage} className="w-4" alt="dollerImage" />
					{data?.opportunityAmount}
				</p>
				<p className="w-1/3 md:w-1/5  my-auto">{data?.createdOn}</p>
				<p className="w-1/3 md:w-1/5  my-auto">{data.displayStatus}</p>
			</div>

			<div className="collapse-content">
				<div className="font-normal pt-8 pb-1">
					<div className="hidden md:flex justify-evenly">
						<div className="">
							<DoughnutChart
								data={[
									+data?.status >= BondStatus.Tokenized
										? 0
										: data.actualLoanAmount -
										  data.poolBalance,
									+data?.status >= BondStatus.Tokenized
										? data.actualLoanAmount
										: data.poolBalance,
								]}
								color={["#5375FE", "#ffffff"]}
								width={200}
								labels={["Capital Requested", "Total Raised"]}
								borderWidth={[1, 8]}
								legendStyle={{ display: false }}
							/>
						</div>

						<div className="flex gap-8 xl:gap-14 2xl:gap-[8vw] ">
							<div className="border-r-2 border-darkmode-500 pr-6 xl:pr-12 2xl:pr-[6vw] flex flex-col justify-evenly">
								<div className="">
									<p className="text-neutral-500">
										Amount requested
									</p>

									<p className="text-lg flex gap-1">
										{data ? data.opportunityAmount : "--"}
										<span>
											{process.env.REACT_APP_TOKEN_NAME}
										</span>
									</p>
								</div>

								<div className="">
									<p className="text-neutral-500">
										Total raised till now
									</p>
									<p className="text-lg flex gap-1">
										{data && data.poolDisplayBalance
											? +data?.status >=
											  BondStatus.Tokenized
												? data.opportunityAmount
												: data.poolDisplayBalance
											: "-- "}

										<span>
											{process.env.REACT_APP_TOKEN_NAME}
										</span>
									</p>
								</div>
							</div>
							<div className="flex flex-col justify-evenly 2xl:pr-[6vw]">
								<div>
									<p className="text-neutral-500">
										Interest rate
									</p>
									<p className="text-lg">
										{data?.loanInterest}
									</p>
								</div>

								<div>
									<p className="text-neutral-500">Tenure</p>
									<p className="text-lg">
										{data?.loanTenure}
									</p>
								</div>
							</div>

							<div className="flex flex-col justify-evenly">
								<div className="">
									<p className="text-neutral-500">
										Payment frequency
									</p>
									<p className="text-lg">
										{data?.paymentFrequencyInDays}
									</p>
								</div>

								<div className="">
									<p className="text-neutral-500">Sub type</p>
									<p className="text-lg">
										{data?.loanType === "0"
											? "Bullet"
											: "Term"}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="font-normal flex w-full flex-row-reverse items-center pr-6">
						<label
							className="text-[#4B74FF] cursor-pointer"
							onClick={() => {
								path("/borrowerDashboard/viewPool", {
									state: {
										...data,
										kycStatus: kycStatus,
										viewMore: "issuer",
									},
								});
							}}
						>
							More Details
						</label>
					</div>

					<div className="flex flex-col items-center md:hidden gap-6">
						<DoughnutChart
							data={[data.actualLoanAmount, data.poolBalance]}
							color={["#5375FE", "#ffffff"]}
							width={200}
							labels={["Capital Requested", "Total Raised"]}
							borderWidth={[1, 8]}
							legendStyle={{ display: false }}
						/>

						<div className="flex w-full justify-around">
							<div className="flex flex-col gap-3">
								<div className="">
									<p className="text-neutral-500">
										Amount requested
									</p>

									<p className="text-lg flex gap-1">
										<img
											src={DollarImage}
											className="w-4"
											alt="dollerImage"
										/>
										{data ? data.opportunityAmount : "--"}
									</p>
								</div>
								<div>
									<p className="text-neutral-500">
										Interest Rate
									</p>
									<p className="text-lg">
										{data?.loanInterest}
									</p>
								</div>

								<div>
									<p className="text-neutral-500">
										Loan Tenure
									</p>
									<p className="text-lg">
										{data?.loanTenure}
									</p>
								</div>
							</div>

							<div className="flex flex-col gap-3">
								<div className="">
									<p className="text-neutral-500">
										Total raised till now
									</p>
									<p className="text-lg flex gap-1">
										<img
											src={DollarImage}
											className="w-4"
											alt="dollerImage"
										/>
										{data && data.poolDisplayBalance
											? data.poolDisplayBalance
											: "-- "}
									</p>
								</div>

								<div className="">
									<p className="text-neutral-500">
										Payment Frequency
									</p>
									<p className="text-lg">
										{data?.paymentFrequencyInDays}
									</p>
								</div>

								<div className="">
									<p className="text-neutral-500">
										Loan Type
									</p>
									<p className="text-lg">
										{data?.loanType === 0
											? "Bullet"
											: "Term"}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OpportunityCardCollapsible;
